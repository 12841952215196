package supergenerous.app.donor.donation.request

import com.hipsheep.kore.model.network.Response
import com.supergenerous.common.donation.request.DonationRequest
import com.supergenerous.common.donation.request.DonationRequestDbo
import com.supergenerous.common.firebase.DONATION_REQUESTS
import com.supergenerous.common.firebase.DbField.Name.DONOR_ID
import com.supergenerous.common.firebase.DbOperator.EQUAL
import com.supergenerous.common.firebase.DbQuery
import com.supergenerous.common.firebase.FirebaseAuth
import com.supergenerous.common.firebase.FirestoreClientService
import com.supergenerous.common.firebase.FirestoreCollection
import firebase.auth

/**
 * Service used to access [DonationRequest] data.
 */
public class DonationRequestService(

    collection: FirestoreCollection<DonationRequestDbo> = FirestoreCollection.DONATION_REQUESTS

): FirestoreClientService<DonationRequest, DonationRequestDbo>(collection, firebaseAuth = FirebaseAuth(auth())) {

    /**
     * Returns the [DonationRequest]s of the currently signed in donor.
     */
    public suspend fun getDonationRequests(): Response<List<DonationRequest>> {
        return getDataAuth { userId ->
            getDocs(query = DbQuery(field = DONOR_ID, operator = EQUAL, value = userId))
        }
    }

}