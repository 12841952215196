package supergenerous.app.core.location.addy

import kotlinx.serialization.Serializable

/**
 * Address info as returned from the Addy service.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class AddressAddy(

    val id: Long,

    /**
     * Address.
     */
    val a: String

)