package com.supergenerous.common.admin

import com.supergenerous.common.data.DtoDataModel
import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable

/**
 * Administrator of the SG data.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class Admin(

    override val id: String,

    override val creationTimestamp: Timestamp = 0,

    override val lastUpdateTimestamp: Timestamp = 0,

    /**
     * Name of the admin.
     */
    val name: String,

    /**
     * Email of the admin (must be a `supergenerous.com` email).
     */
    val email: String

): DtoDataModel<AdminDbo> {

    override fun toDbo(): AdminDbo {
        return AdminDbo(id = id,
                        creationTimestamp = creationTimestamp,
                        lastUpdateTimestamp = lastUpdateTimestamp,
                        name = name,
                        email = email)
    }

}