package supergenerous.app.core.res.image

/**
 * Image used in the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public interface Image {

    /**
     * Name of the image file (including extension).
     */
    public val fileName: String

    /**
     * Relative path to the image file.
     */
    public val path: String
        get() = "images/$fileName"

    /**
     * URL to the image file.
     */
    public val url: String
        get() = "url(\"$path\")"

    /**
     * Value used to add this image to CSS.
     */
    public val cssValue: kotlinx.css.Image
        get() = kotlinx.css.Image(url)

}