package supergenerous.app.core.res.image

/**
 * List of images used in more than one app.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public enum class CoreImage(

    override val fileName: String

) : Image {

    SG_HEADER_BAR("sg_header_bar.svg"),

    SG_LOGO_GREEN("sg_logo_green.svg"),
    SG_LOGO_WHITE("sg_logo_white.svg"),

    PERSON_DONATING("person_donating.png")

}