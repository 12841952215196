package supergenerous.app.donor.setup.view

import com.hipsheep.kore.error.ErrorType
import kotlinx.css.Display
import kotlinx.css.GridTemplateColumns
import kotlinx.css.GridTemplateRows
import kotlinx.css.columnGap
import kotlinx.css.display
import kotlinx.css.fr
import kotlinx.css.gridTemplateColumns
import kotlinx.css.gridTemplateRows
import kotlinx.css.marginTop
import kotlinx.css.px
import kotlinx.css.rowGap
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.inputTitle
import supergenerous.app.core.component.textfield.searchTextField
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.core.util.format.TextCapitalization
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.setup.model.SetupError.*
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.PERSONAL_INFO
import supergenerous.app.donor.setup.viewmodel.SetupViewModel

/**
 * Screen that allows donors to enter some personal information.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class PersonalInfoScreen : SetupScreen<SetupScreenProps, PersonalInfoScreenState>() {

    override val setupStep: SetupStep = PERSONAL_INFO


    override fun PersonalInfoScreenState.init() {
        addressSearchResults = emptyList()
    }

    override fun RBuilder.render() {
        setupInputContainer(
            title = { +"The basics" },
            subtitle = { +"Next, we need a few details to set up your account." },
            setupViewModel = props.viewModel,
            onNextBtnClick = ::savePersonalInfo,
            onBackBtnClick = ::goBack,
        ) {
            // Legal name
            inputTitle(
                title = "Full legal name",
                subtitle = "Please provide your full legal name"
            )

            styledDiv {
                css {
                    display = Display.grid
                    gridTemplateRows = GridTemplateRows.auto
                    gridTemplateColumns = GridTemplateColumns(1.fr, 1.fr, 1.fr)
                    columnGap = 24.px
                    mobileScreen {
                        gridTemplateRows = GridTemplateRows(1.fr, 1.fr, 1.fr)
                        gridTemplateColumns = GridTemplateColumns.auto
                        rowGap = 8.px
                    }
                }

                textField(
                    placeholder = "First name",
                    textCapitalization = TextCapitalization.CAPITALIZE,
                    value = state.firstName,
                    onTextChange = { text ->
                        setState {
                            firstName = text
                            firstNameError = null
                        }
                    },
                    errorMessage = state.firstNameError
                )
                textField(
                    placeholder = "Middle name",
                    textCapitalization = TextCapitalization.CAPITALIZE,
                    value = state.middleName,
                    onTextChange = { text -> setState { middleName = text } }
                )
                textField(
                    placeholder = "Last name",
                    textCapitalization = TextCapitalization.CAPITALIZE,
                    value = state.lastName,
                    onTextChange = { text ->
                        setState {
                            lastName = text
                            lastNameError = null
                        }
                    },
                    errorMessage = state.lastNameError
                )
            }

            // Address
            styledDiv {
                css {
                    marginTop = 24.px
                }

                searchTextField(
                    title = "Home address",
                    placeholder = "Start typing to find address",
                    value = state.homeAddress,
                    onTextChange = ::onAddressTextChange,
                    searchResults = state.addressSearchResults,
                    onResultSelect = ::updateAddressSelected,
                    errorMessage = state.homeAddressError,
                    maxResultsShown = 5
                )
            }
        }
    }

    /**
     * Processes the address text entered by the user.
     */
    private fun onAddressTextChange(address: String) {
        props.viewModel.searchAddress(address)

        // Reset home address value when the text in the field changes but no address was selected yet
        setState {
            homeAddress = null
            homeAddressError = null
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // Load the values already provided by the donor (if any)
        props.viewModel.donor.observe { donor ->
            setState {
                firstName = donor.legalName?.firstName
                middleName = donor.legalName?.middleName
                lastName = donor.legalName?.lastName
                homeAddress = donor.address
            }
        }

        props.viewModel.addressSearchResults.observeEvent { setState { addressSearchResults = it } }

        props.viewModel.errors.observeEvent { errors ->
            errors.forEach { error ->
                when ((error as? ErrorType.AppError)?.code) {
                    FIRST_NAME_MISSING -> setState { firstNameError = "Required" }
                    LAST_NAME_MISSING -> setState { lastNameError = "Required" }
                    ADDRESS_MISSING -> setState { homeAddressError = "Required" }
                }
            }
        }
    }

    /**
     * Updates the [PersonalInfoScreenState.homeAddress] with [addressResult] and resets
     * [PersonalInfoScreenState.addressSearchResults] so the address results menu disappears.
     */
    private fun updateAddressSelected(addressResult: TextSearchResult<out String?>?) {
        setState {
            homeAddress = addressResult?.data
            homeAddressError = null
            addressSearchResults = listOf()
        }
    }

    /**
     * Saves the personal info provided through the UI.
     */
    private fun savePersonalInfo() {
        props.viewModel.savePersonalInfo(firstName = state.firstName,
                                         middleName = state.middleName,
                                         lastName = state.lastName,
                                         address = state.homeAddress)
    }

}

/**
 * State of the [PersonalInfoScreen] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface PersonalInfoScreenState : State {

    /**
     * First name provided by the user.
     */
    var firstName: String?

    /**
     * The error that occurred on the first name field.
     */
    var firstNameError: String?

    /**
     * Middle name provided by the user.
     */
    var middleName: String?

    /**
     * Last name provided by the user.
     */
    var lastName: String?

    /**
     * The error that occurred on the last name field.
     */
    var lastNameError: String?

    /**
     * Physical address selected by the user.
     */
    var homeAddress: String?

    /**
     * The error that occurred on the address field.
     */
    var homeAddressError: String?

    /**
     * Addresses that match the search performed in [SetupViewModel.searchAddress].
     */
    var addressSearchResults: List<TextSearchResult<String>>

}

/**
 * Renders a [PersonalInfoScreen] component.
 */
public fun RBuilder.personalInfoScreen(setupViewModel: SetupViewModel) {
    withRouter(PersonalInfoScreen::class) {
        attrs.viewModel = setupViewModel
    }
}