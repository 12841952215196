package com.supergenerous.common.donor

import com.supergenerous.common.data.Dbo
import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [ActiveStatus] class.
 */
@Serializable
public data class ActiveStatusDbo(

    val id: ActiveStatus.Id? = null,

    val lastUpdateTimestamp: Timestamp? = null

) : Dbo<ActiveStatus> {

    override fun toDto(): ActiveStatus {
        return id!!.createActiveStatus(lastUpdateTimestamp = lastUpdateTimestamp)
    }

}