package supergenerous.app.core.location

import com.hipsheep.kore.model.network.HttpMethod.GET
import com.hipsheep.kore.model.network.HttpService
import com.hipsheep.kore.model.network.Response
import com.hipsheep.kore.model.network.sendRequest
import supergenerous.app.core.location.addy.AddressSearchResultAddy

/**
 * [HttpService] used to read physical address data from the third-party service used.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public class AddressService(

    /**
     * API key used to make calls to the location system used.
     */
    private val locationApiKey: String

) : HttpService() {

    /**
     * URL used to search addresses.
     */
    private val addressSearchUrl = "https://api-nz.addysolutions.com/search"


    /**
     * Searches for addresses using the [query] received, and returns the ones that match.
     */
    public suspend fun searchAddress(query: String): Response<AddressSearchResultAddy> {
        return httpClient.sendRequest(httpMethod = GET,
                                      url = addressSearchUrl,
                                      queryParams = mapOf("key" to locationApiKey,
                                                          "s" to query))
    }

}