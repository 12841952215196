package supergenerous.app.donor.kindo.model

import com.supergenerous.common.name.FullName

/**
 * Information provided by Kindo via query params when they refer a customer to SG inside their platform (by adding the
 * SG ap as an iframe).
 *
 * The params are stored in this class to make it easier to pass them around different parts of the app.
 *
 * @author Raymond Feng (raymond@supergenerous.com)
 */
public data class KindoDonorLinkParams(

    /**
     * The type of operation requested.
     */
    val operationType: String,

    /**
     * The ID for the referral itself.
     */
    val referralId: String,

    /**
     * The name of the referrer (always "Kindo").
     */
    val referrer: String,

    /**
     * The ID of the school/donee on Kindo's system.
     */
    val doneeExtId: String,

    /**
     * The ID of the donor on Kindo's system.
     */
    val donorExtId: String,

    /**
     * The email of the donor.
     */
    val donorEmail: String,

    /**
     * The name of the donor.
     */
    val donorName: FullName,

    /**
     * Hash of [donorExtId] + [donorEmail] + [donorName].firstName + [donorName].lastName + `salt`
     */
    val checksum: String

)

