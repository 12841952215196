package supergenerous.app.core.common

import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.Donee.Type.*

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

/**
 * Donee type as a singular word/s, to use in the UI.
 */
public val Donee.Type.valueSingular: String
    get()  = when (this) {
        CHARITY -> "charity"
        SCHOOL -> "school"
        RELIGIOUS_ORG -> "religious organisation"
    }

/**
 * Donee type as a plural word/s, to use in the UI.
 */
public val Donee.Type.valuePlural: String
    get()  = when (this) {
        CHARITY -> "charities"
        SCHOOL -> "schools"
        RELIGIOUS_ORG -> "religious organisations"
    }