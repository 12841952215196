package com.supergenerous.common.util

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

/**
 * Replaces alphanumeric characters with "●".
 */
public fun String.mask(): String {
    return this.replace(Regex(pattern = "[A-Za-z0-9]"), replacement = "●")
}

/**
 * Removes all whitespaces from the `String` and returns the result.
 */
public fun String.removeWhitespaces(): String {
    return this.filterNot { it.isWhitespace() }
}