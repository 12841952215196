package com.supergenerous.common.organisation

import com.supergenerous.common.donee.DoneeId
import com.supergenerous.common.organisation.Organisation.Type.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import com.supergenerous.common.donation.platform.DonationPlatform as DonationPlatformCommon
import com.supergenerous.common.donee.Donee.Type as DoneeType

/**
 * An organisation in the system.
 */
@Serializable
public sealed class Organisation {

    /**
     * Name of the organisation.
     */
    public abstract val name: String

    /**
     * ID of the organisation.
     */
    // TODO: Rename to `id` (this means renaming the DoneeId in Donee)
    public val orgId: String
        get() = when (this) {
            is Donee -> this.id.value
            is DonationPlatform -> this.value.id
        }

    /**
     * Converts the [Organisation] to a [String] for saving in the DB.
     */
    internal fun toDbo(): String {
        val orgData = when (this) {
            is Donee -> listOf(DONEE, id.value, name, type)
            is DonationPlatform -> listOf(DONATION_PLATFORM, value.name)
        }

        return orgData.joinToString(separator = DB_SEPARATOR)
    }

    /*
     * Inner types
     */

    internal companion object {

        /**
         * String used to separate values when saving the [Organisation] to the DB.
         */
        private const val DB_SEPARATOR = ";"

        /**
         * Converts the [Organisation] in [orgDataStr] from a [String].
         */
        internal fun fromDbo(orgDataStr: String): Organisation {
            val orgData = orgDataStr.split(DB_SEPARATOR)

            return when (Type.valueOf(orgData.first())) {
                DONEE -> Donee(id = DoneeId(orgData[1]), name = orgData[2], type = DoneeType.valueOf(orgData[3]))
                DONATION_PLATFORM -> DonationPlatform(value = DonationPlatformCommon.valueOf(orgData[1]))
            }
        }

    }

    /**
     * Types of organisation.
     *
     * This value is used for encoding/decoding the value to/from the server DB only.
     */
    private enum class Type {

        DONEE, DONATION_PLATFORM

    }

    /**
     * A donee organisation.
     */
    @Serializable
    public data class Donee(

        val id: DoneeId,

        override val name: String,

        // Change serial name to avoid serialisation error
        // (see https://github.com/Kotlin/kotlinx.serialization/issues/1664)
        @SerialName("doneeType")
        val type: DoneeType

    ) : Organisation()

    /**
     * A donation platform organisation.
     */
    @Serializable
    public data class DonationPlatform(

        val value: DonationPlatformCommon

    ) : Organisation() {

        override val name: String = value.displayName

    }

}