package supergenerous.app.core.component.picker

/**
 * Type of [datePicker] to display.
 */
public enum class DatePickerType {

    /**
     * The [datePicker] will show the full date (day, month and year).
     */
    DATE,

    /**
     * The [datePicker] will show the month and year only (days will be ignored).
     */
    MONTH

}