package supergenerous.app.donor.donation.request

import com.hipsheep.kore.model.repo.Repository
import com.hipsheep.kore.resource.Resource
import com.supergenerous.common.donation.request.DonationRequest

/**
 * [Repository] used to manage the [DonationRequest]s data.
 */
public class DonationRequestRepository(

    private val donationRequestService: DonationRequestService

): Repository() {

    /**
     * Returns the [DonationRequest]s of the currently signed in donor.
     */
    public suspend fun getDonationRequests(): Resource<List<DonationRequest>> {
        return getResource { donationRequestService.getDonationRequests() }
    }

}