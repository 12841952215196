package com.supergenerous.common.donor

import com.supergenerous.common.aml.AmlCheckResultDbo
import com.supergenerous.common.data.Dbo
import com.supergenerous.common.data.DboDataModel
import com.supergenerous.common.donation.platform.DonationPlatform
import com.supergenerous.common.donee.DoneeId
import com.supergenerous.common.donor.DonorStateDbo.Companion.createDoneeDonorId
import com.supergenerous.common.id.IdVerificationResultDbo
import com.supergenerous.common.organisation.Organisation
import com.supergenerous.common.util.Timestamp
import com.supergenerous.common.util.toLocalDate
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [DonorState] class.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class DonorStateDbo(

    override var id: String? = null,

    val crmId: String? = null,

    override var creationTimestamp: Timestamp? = null,

    override var lastUpdateTimestamp: Timestamp? = null,

    val taxLinkRequestTimestamp: Timestamp? = null,

    val taxLinkStatus: TaxLinkStatus? = null,

    val taxUnlinkReminderTimestamps: List<Timestamp>? = null,

    val idVerificationResults: List<IdVerificationResultDbo>? = null,

    val amlCheckResults: List<AmlCheckResultDbo>? = null,

    val amlInfoRequestDate: Timestamp? = null,

    val provider: DonorProvider? = null,

    val receivedRebateNotClaimedBySg: Boolean? = null,

    val referralCode: String? = null,

    val donationPlatformLinkStates: Map<String, DonationPlatformLinkStateDbo>? = null,

    val orgsInvalid: List<String>? = null,

    val doneesDonorInfo: List<DoneeDonorInfoDbo>? = null,

    val rebateClaimUpdatesLastCheckDate: Timestamp? = null,

    val donationsNotFoundEmailTimestamp: Timestamp? = null,

    val govIdRequiredEmailTimestamp: Timestamp? = null

) : DboDataModel<DonorState> {

    /**
     * [doneesDonorInfo] mapped to searchable strings in the format of [createDoneeDonorId].
     */
    public val doneesDonorIds: List<String>? = doneesDonorInfo?.map {
        createDoneeDonorId(doneeId = DoneeId(it.donee!!.id!!), donorExtId = it.donorExtId!!)
    }

    override fun toDto(): DonorState {
        return DonorState(id = id!!,
                          crmId = crmId,
                          creationTimestamp = creationTimestamp!!,
                          lastUpdateTimestamp = lastUpdateTimestamp!!,
                          taxLinkRequestTimestamp = taxLinkRequestTimestamp,
                          taxLinkStatus = taxLinkStatus,
                          taxUnlinkReminderTimestamps = taxUnlinkReminderTimestamps ?: emptyList(),
                          idVerificationResults = idVerificationResults?.map { it.toDto() } ?: emptyList(),
                          amlCheckResults = amlCheckResults?.map { it.toDto() } ?: emptyList(),
                          amlInfoRequestDate = amlInfoRequestDate?.toLocalDate(),
                          provider = provider,
                          receivedRebateNotClaimedBySg = receivedRebateNotClaimedBySg ?: false,
                          referralCode = referralCode,
                          donationPlatformLinkStates = donationPlatformLinkStates?.entries?.associate {
                              DonationPlatform.valueOf(it.key) to it.value.toDto()
                          } ?: emptyMap(),
                          orgsInvalid = orgsInvalid?.map { Organisation.fromDbo(it) }?.toSet() ?: emptySet(),
                          doneesDonorInfo = doneesDonorInfo?.map { it.toDto() }?.toSet() ?: emptySet(),
                          rebateClaimUpdatesLastCheckDate = rebateClaimUpdatesLastCheckDate?.toLocalDate(),
                          donationsNotFoundEmailTimestamp = donationsNotFoundEmailTimestamp,
                          govIdRequiredEmailTimestamp = govIdRequiredEmailTimestamp)
    }

    /*
     * Inner types
     */

    public companion object {

        /**
         * Returns a string used to search for the [Donor]'s external ID for the donee.
         *
         * Formatted as `"<donee ID>:<donor external ID>"`
         */
        public fun createDoneeDonorId(doneeId: DoneeId, donorExtId: String): String {
            return "${doneeId.value}:${donorExtId}"
        }

    }

}