package supergenerous.app.donor.dashboard.viewmodel

import com.hipsheep.kore.resource.Resource.Success
import com.hipsheep.kore.viewmodel.BaseViewModel
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.coroutine.asLiveData
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.donation.Donation
import com.supergenerous.common.donation.request.DonationRequest
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.organisation.Organisation
import com.supergenerous.common.rebate.Rebate
import com.supergenerous.common.rebate.RebateClaimer
import com.supergenerous.common.tax.region.TaxRegion
import com.supergenerous.common.util.taxYear
import com.supergenerous.common.util.toLocalDate
import kotlinx.coroutines.async
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.distinctUntilChangedBy
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import supergenerous.app.core.progress.ProgressStatus.IN_PROGRESS
import supergenerous.app.donor.dashboard.model.RebateClaimsError.REBATE_CLAIMS_MISSING
import supergenerous.app.donor.dashboard.view.rebateClaimsPanel
import supergenerous.app.donor.donation.DonationRepository
import supergenerous.app.donor.donation.request.DonationRequestRepository
import supergenerous.app.donor.donor.DonorRepository
import supergenerous.app.donor.rebate.RebateClaim
import supergenerous.app.donor.rebate.RebateClaim.Status.*
import supergenerous.app.donor.rebate.RebateRepository
import kotlin.js.Date

/**
 * [ViewModel] that provides data to the [rebateClaimsPanel].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
public class RebateClaimsViewModel(

    donorRepo: DonorRepository,
    private val donationRepo: DonationRepository,
    private val donationRequestRepo: DonationRequestRepository,
    private val rebateRepo: RebateRepository

) : BaseViewModel() {

    /**
     * Flow that emits updates when the data of the currently signed in [Donor] changes.
     */
    private val donor: Flow<Donor> = donorRepo.getDonorUpdates()
            .mapNotNull { resource -> (resource as? Success)?.data }

    /**
     * Rebate claims data used to calculate the [RebateClaim]s.
     *
     * This is cached to avoid fetching it again when some of the donor info changes, as that might trigger a call to
     * [calculateRebateClaims].
     */
    private var rebateClaimsRawDataCache: RebateClaimsRawData? = null

    /**
     * Observable that contains the rebate claim data.
     */
    public val rebateClaims: LiveData<List<RebateClaim>> = donor
            // Only re-calculate the rebate claims data when the properties of the donor that affect the calculations
            // change
            .distinctUntilChangedBy { donor -> Pair(donor.disbursementSettings, donor.bankAccountNumber) }
            .map { donor -> calculateRebateClaims(donor) }
            .asLiveData()


    /**
     * Calculates the [RebateClaim]s info for the signed in donor.
     */
    private suspend fun calculateRebateClaims(donor: Donor): List<RebateClaim> {
        val rebateClaimsRawData = getRebateClaimsRawData()

        // If the raw data was not fetched successfully then report an error
        if (rebateClaimsRawData == null) {
            reportError(REBATE_CLAIMS_MISSING)

            return emptyList()
        }

        val rebateClaims = mutableListOf<RebateClaim>()

        val donations = rebateClaimsRawData.donations
        val donationRequests = rebateClaimsRawData.donationRequests
        val rebates = rebateClaimsRawData.rebates

        /*
         * Create a rebate claim for each donation request that is in any of the following states:
         *
         * * It is open.
         * * The donee has not answered the request in the timeframe established by SG.
         * * The donee answered the request saying there were no donations found for the donor on the tax years
         * requested.
         */
        rebateClaims += donationRequests
                .mapNotNull { donationRequest ->
                    val rebateClaimStatus = when {
                        // The request is still open, so we are still waiting for the donations
                        donationRequest.isOpen -> DONATIONS_REQUESTED
                        // The request is closed and not answered so the organisation did not get back to us in the
                        // timeframe established
                        !donationRequest.isAnswered -> DONATION_REQUEST_NOT_ANSWERED
                        else -> {
                            // The request is closed and answered, so check if any donations were returned from the org
                            val donationsReturnedForReq = donations
                                    .filter {
                                        (it.donationPlatform?.id ?: it.donee.id) == donationRequest.organisation.orgId
                                                && it.date.taxYear in donationRequest.taxYears
                                    }

                            if (donationsReturnedForReq.isEmpty()) {
                                // There were no donations for the requested tax year for this org
                                NO_DONATIONS_FOUND
                            } else {
                                // The There were donations for the requested tax years for the org, so skip this
                                // request as we'll process the donation on the next step
                                null
                            }
                        }
                    }

                    rebateClaimStatus?.let {
                        RebateClaim(organisation = donationRequest.organisation,
                                    donationRequest = donationRequest,
                                    donation = null,
                                    rebate = null,
                                    status = it)
                    }
                }

        // Map every donation that is included in a rebate to that rebate to speed up search on the next step
        val donationIdsRebates = rebates.flatMap { rebate ->
            rebate.donations.map { donation -> donation.id to rebate }
        }.toMap()

        // Get the current tax year
        val taxYearNow = Date().toLocalDate().taxYear

        // Create a rebate claim for each of the donations
        rebateClaims += donations.map { donation ->
            val rebate = donationIdsRebates[donation.id]
            val disbRecipientType = donor.disbursementSettings[donation.donee.type]

            var rebateClaimStatus = when {
                !donation.isValid -> DONATION_INVALID
                rebate != null -> getRebateClaimStatus(rebate = rebate, disbRecipientType = disbRecipientType)
                donation.rebateClaimer == RebateClaimer.OTHER -> DONATION_CLAIMED_BY_OTHER
                // The donation was made in the current tax year
                donation.date.taxYear >= taxYearNow -> DONATION_CURRENT_TAX_YEAR
                // The donation was made more than X years ago so it is no longer claimable
                // TODO: Get the tax region from the DonorState once we move to other markets
                donation.date.taxYear < taxYearNow - TaxRegion.NZ.rebateClaimValidTaxYearsNum -> DONATION_TOO_OLD_TO_CLAIM
                // The donation is valid
                else -> DONATION_READY_TO_CLAIM
            }

            // If the claim is successfully in progress and the donor is the recipient of the disbursement but they
            // have not provided their bank account then set the status for them to provide this
            if (rebateClaimStatus.progressStatus == IN_PROGRESS
                && disbRecipientType == DisbursementRecipient.Type.DONOR
                && donor.bankAccountNumber.isNullOrBlank()) {
                rebateClaimStatus = DONOR_BANK_ACCOUNT_MISSING
            }

            RebateClaim(organisation = Organisation.Donee(id = donation.donee.doneeId,
                                                          name = donation.donee.name,
                                                          type = donation.donee.type),
                        donationRequest = null,
                        donation = donation,
                        rebate = rebate,
                        status = rebateClaimStatus)
        }

        // Create a rebate claim for each rebate received by SG that was claimed by someone else
        rebateClaims += rebates
                .filter { rebate -> !rebate.claimedBySg }
                .mapNotNull { rebate ->
                    val rebateClaimStatus = when {
                        // Rebates not claimed by SG are always sent to the donor so if they have not provided their
                        // bank account ask for it
                        rebate.status == Rebate.Status.FUNDS_RECEIVED && donor.bankAccountNumber.isNullOrBlank() -> {
                            DONOR_BANK_ACCOUNT_MISSING
                        }
                        rebate.status == Rebate.Status.FUNDS_RECEIVED -> REBATE_CLAIMED_BY_OTHER_RECEIVED
                        rebate.status == Rebate.Status.FUNDS_DISBURSED -> REBATE_CLAIMED_BY_OTHER_DISBURSED
                        else -> return@mapNotNull null
                    }

                    RebateClaim(organisation = null,
                                donationRequest = null,
                                donation = null,
                                rebate = rebate,
                                status = rebateClaimStatus)
                }

        return rebateClaims
    }

    /**
     * Returns the raw data used to calculate the [RebateClaim]s, fetching it from the server if
     * [rebateClaimsRawDataCache] is `null` or returning the cached value when it's not.
     */
    private suspend fun getRebateClaimsRawData(): RebateClaimsRawData? {
        if (rebateClaimsRawDataCache != null) {
            return rebateClaimsRawDataCache
        }

        // Fetch data asynchronously to speed up the load
        val donationsJob = async { donationRepo.getDonations() }
        val donationRequestsJob = async { donationRequestRepo.getDonationRequests() }
        val rebatesJob = async { rebateRepo.getRebates() }

        val donationsRes = donationsJob.await()
        val donationRequestsRes = donationRequestsJob.await()
        val rebatesRes = rebatesJob.await()

        // If any resources fail to load then return
        if (donationsRes !is Success || donationRequestsRes !is Success || rebatesRes !is Success) {
            return null
        }

        // Cache data so it is not fetched again next time
        rebateClaimsRawDataCache = RebateClaimsRawData(donations = donationsRes.data,
                                                       donationRequests = donationRequestsRes.data,
                                                       rebates = rebatesRes.data)

        return rebateClaimsRawDataCache
    }

    /**
     * Returns the [RebateClaim.Status] of the [RebateClaim] that contains the [rebate].
     */
    private fun getRebateClaimStatus(rebate: Rebate,
                                     disbRecipientType: DisbursementRecipient.Type?): RebateClaim.Status {
        return when (rebate.status) {
            Rebate.Status.SUBMITTED -> REBATE_CLAIM_SUBMITTED
            Rebate.Status.FUNDS_RECEIVED -> when (disbRecipientType) {
                DisbursementRecipient.Type.DONOR -> DISBURSEMENT_READY_FOR_DONOR
                null -> DISBURSEMENT_RECIPIENT_MISSING
                else -> DISBURSEMENT_READY_FOR_DONEE
            }
            Rebate.Status.FUNDS_SENT_TO_DONOR -> FEE_INVOICE_PENDING
            Rebate.Status.FEE_INVOICE_SENT_TO_DONOR -> FEE_INVOICE_SENT
            Rebate.Status.FEE_INVOICE_PAID, Rebate.Status.FEE_INVOICE_VOIDED -> FEE_INVOICE_RESOLVED
            Rebate.Status.FUNDS_DISBURSED -> REBATE_DISBURSED
            Rebate.Status.DECLINED -> REBATE_CLAIM_FAILED
        }
    }

    /*
     * Inner types
     */

    /**
     * Raw data used to calculate the [RebateClaim]s in [calculateRebateClaims].
     */
    private data class RebateClaimsRawData(

        val rebates: List<Rebate>,
        val donations: List<Donation>,
        val donationRequests: List<DonationRequest>

    )

}