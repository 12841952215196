package supergenerous.app.donor.dashboard.view

import com.hipsheep.kore.error.ErrorType
import com.supergenerous.common.email.EmailAddress
import com.supergenerous.common.rebate.Rebate
import com.supergenerous.common.util.taxYear
import com.supergenerous.common.util.toLocalDate
import com.supergenerous.common.util.toLocaleString
import kotlinx.css.Overflow
import kotlinx.css.OverflowWrap
import kotlinx.css.marginBottom
import kotlinx.css.marginTop
import kotlinx.css.maxHeight
import kotlinx.css.overflowWrap
import kotlinx.css.overflowY
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.Month.APRIL
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import styled.styledSpan
import supergenerous.app.core.common.valuePlural
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.contentSection
import supergenerous.app.core.component.heading1
import supergenerous.app.core.component.table.TableColumnConfig.*
import supergenerous.app.core.component.table.TableColumnConfig.HorizontalAlignment.LEFT
import supergenerous.app.core.component.table.TableColumnConfig.MobileColumn.*
import supergenerous.app.core.component.table.table
import supergenerous.app.core.component.tabs.Tab
import supergenerous.app.core.component.tabs.tabs
import supergenerous.app.core.component.textError
import supergenerous.app.core.component.textLinkEmail
import supergenerous.app.core.progress.ProgressStatus.*
import supergenerous.app.core.util.ScreenSizeProps
import supergenerous.app.core.util.withScreenSize
import supergenerous.app.donor.dashboard.model.RebateClaimsError.REBATE_CLAIMS_MISSING
import supergenerous.app.donor.dashboard.view.RebateClaimsTab.*
import supergenerous.app.donor.dashboard.viewmodel.RebateClaimsViewModel
import supergenerous.app.donor.rebate.RebateClaim
import supergenerous.app.donor.rebate.RebateClaim.Status.*

/**
 * Panel used to show the information of a group of [RebateClaim]s.
 */
private class RebateClaimsPanel : LifecycleOwnerComponent<RebateClaimsPanelProps, RebateClaimsPanelState>() {

    /**
     * The date when the next tax year starts.
     */
    private val nextTaxYearStartDate = LocalDate(year = Clock.System.now().toEpochMilliseconds().toLocalDate().taxYear,
                                                 month = APRIL,
                                                 dayOfMonth = 1)


    override fun RebateClaimsPanelState.init() {
        rebateClaimsTabSelected = CLAIMS_IN_PROGRESS
        rebateClaimsCompleted = emptyList()
        rebateClaimsInProgress = emptyList()
        rebateClaimsLoadFailed = false
    }

    override fun RBuilder.render() {
        contentSection {
            styledDiv {
                css {
                    width = 100.pct
                }

                heading1 {
                    css {
                        marginBottom = 16.px
                    }

                    +"My claims"
                }

                if (state.rebateClaimsLoadFailed) {
                    body1 {
                        css {
                            marginTop = 16.px
                        }

                        textError {
                            +"Oops! Please try again shortly to view your donations or contact "
                            supportEmail()
                            +" to let us know you’re having trouble."
                        }
                    }
                } else {
                    rebateClaims()
                }
            }
        }
    }

    /**
     * Renders the [RebateClaim]s data obtained from the [RebateClaimsPanelProps.viewModel].
     */
    private fun RBuilder.rebateClaims() {
        // Get the claims data to show depending on the tab selected
        val rebateClaims = when (state.rebateClaimsTabSelected) {
            CLAIMS_IN_PROGRESS -> state.rebateClaimsInProgress
            CLAIMS_COMPLETED -> state.rebateClaimsCompleted
        }

        // Render the tabs at the top of the table
        tabs(
            tabs = listOf(Tab(value = CLAIMS_IN_PROGRESS.toString(),
                              label = "Current claims"),
                          Tab(value = CLAIMS_COMPLETED.toString(),
                              label = "Completed claims")),
            tabSelectedValue = state.rebateClaimsTabSelected.toString(),
            onSelect = { selectedValue ->
                setState { rebateClaimsTabSelected = RebateClaimsTab.valueOf(selectedValue) }
            }
        )

        // Render the table
        rebateClaimsTable(rebateClaims)
    }

    /**
     * Renders a table with the [rebateClaims] data.
     */
    private fun RBuilder.rebateClaimsTable(rebateClaims: List<RebateClaim>) {
        styledDiv {
            css {
                width = 100.pct
                // Set max height to force the table to scroll when it is too big
                maxHeight = 600.px
                overflowY = Overflow.auto

                marginTop = 16.px
            }

            // Text to show when no data is available to display
            val textNoData = if (state.rebateClaimsCompleted.isEmpty() && state.rebateClaimsInProgress.isEmpty()) {
                // If there are no items at all then display a message asking the donor to check back later (this is
                // only shown right after they signed up and therefore no donation requests have been created yet)
                "We're preparing to get your donation receipts, details coming soon!"
            } else {
                "No information available to display at the moment."
            }

            // Whether the currently shown tab is the "claims in progress" one
            val isClaimsInProgressTab = state.rebateClaimsTabSelected == CLAIMS_IN_PROGRESS

            table(
                columnsConfig = listOf(Text(header = "Organisation",
                                            mobileColumn = FIRST,
                                            getValue = { rebateClaim ->
                                                // Show "IRD" for rebates received by SG but claimed by someone else
                                                rebateClaim.organisation?.name ?: "IRD"
                                            }),
                                       Text(header = "Donation date",
                                            mobileColumn = FIRST,
                                            getValue = { rebateClaim -> rebateClaim.donation?.date?.toLocaleString() }),
                                       Money(header = "Donation amount",
                                           // In mobile view only show the donation amount
                                             mobileColumn = if (isClaimsInProgressTab) FIRST else null,
                                             getValue = { rebateClaim -> rebateClaim.donation?.amount }),
                                       Money(header = "Rebate amount",
                                           // In mobile view only show the rebate amount
                                             mobileColumn = if (isClaimsInProgressTab) null else FIRST,
                                             getValue = { rebateClaim -> getRebateAmount(rebateClaim) }),
                                       ProgressStatus(header = "Status",
                                                      mobileColumn = SECOND,
                                                      getValue = { rebateClaim -> rebateClaim.status.progressStatus }),
                                       Custom(header = "Details",
                                              mobileColumn = SECOND,
                                              horizontalAlignment = LEFT,
                                              renderCell = { rebateClaim -> rebateClaimDetails(rebateClaim) })),
                content = rebateClaims,
                textNoContent = textNoData
            )
        }
    }

    /**
     * Returns the rebate amount to display for the [rebateClaim].
     */
    private fun getRebateAmount(rebateClaim: RebateClaim): Float? {
        val rebate = rebateClaim.rebate
        val rebateAmountReceived = rebate?.amountReceived
        val rebateAmountExpected = rebate?.amountExpected

        return when {
            // The rebate was claimed by SG but declined by the tax authority
            rebate?.status == Rebate.Status.DECLINED -> 0f
            // The rebate was NOT claimed by SG but it was received by SG
            rebate?.claimedBySg == false -> rebateAmountReceived
            // The rebate was claimed by SG and some or all of it was received by SG
            rebateAmountExpected != null && rebateAmountReceived != null -> {
                rebate.calculateDisbForDonation(donation = rebateClaim.donation!!)
            }
            else -> null
        }
    }

    /**
     * Renders the details of the [rebateClaim].
     */
    private fun RBuilder.rebateClaimDetails(rebateClaim: RebateClaim) {
        body1 {
            when (rebateClaim.status) {
                DONATIONS_REQUESTED -> +"Requested donation receipts from organisation."
                NO_DONATIONS_FOUND -> {
                    +"We could not find any donation receipts for you with this organisation. If you believe this to be incorrect please contact "
                    supportEmail()
                    +"."
                }
                DONATION_REQUEST_NOT_ANSWERED -> {
                    +"Unfortunately we have not heard back from this organisation. If you have a copy of this donation receipt please contact "
                    supportEmail()
                    +"."
                }
                DONATION_INVALID -> +"Unfortunately this donation can't be claimed. This happens when the value is under \$5 or the donation was cancelled by the organisation."
                DONATION_TOO_OLD_TO_CLAIM -> +"Unfortunately this donation can't be claimed as it was made more than 4 financial years ago."
                DONATION_READY_TO_CLAIM -> +"Donation ready to be claimed with IRD."
                DONATION_CURRENT_TAX_YEAR -> +"This donation was made during the current financial year which will be processed on ${nextTaxYearStartDate.toLocaleString()}."
                DONATION_CLAIMED_BY_OTHER -> {
                    +"This donation looks to have already been claimed so we are unable to claim it. If you think this is incorrect please contact "
                    supportEmail()
                    +"."
                }
                REBATE_CLAIM_SUBMITTED -> +"New rebate claim submitted. Processing time with IRD can be up to 12 weeks."
                REBATE_CLAIM_FAILED -> {
                    +"Unfortunately this donation did not pass the IRD Assessment. For further info please contact "
                    supportEmail()
                    +"."
                }
                DISBURSEMENT_READY_FOR_DONOR -> +"Your rebate will be transferred to your bank account next Wednesday."
                DISBURSEMENT_READY_FOR_DONEE -> +"Your rebate will be sent to ${rebateClaim.organisation?.name ?: "the organisation"} on the next 20th of the month."
                DISBURSEMENT_RECIPIENT_MISSING -> {
                    // Get the donee from the donation. This should always exist for MISSING_DISBURSEMENT_SETTING
                    val donee = rebateClaim.donation?.donee
                    if (donee == null) {
                        +"Oops, there was a problem displaying the details of this donation. Please contact "
                        supportEmail()
                        +"."
                    } else {
                        +"Please add where you would like us to send your rebate for ${donee.name} (${donee.type.valuePlural}) or get in touch and we can help you out at "
                        supportEmail()
                        +"."
                    }
                }
                DONOR_BANK_ACCOUNT_MISSING -> {
                    +"We're missing your bank account. Please edit your details or get in touch and we can help you out at "
                    supportEmail()
                    +"."
                }
                FEE_INVOICE_PENDING -> +"This situation is a little strange - it looks like your rebate was transferred to another bank account, we’ll be in touch with further information."
                FEE_INVOICE_SENT -> {
                    +"Your rebate was transferred to another bank account and we've sent you a service fee invoice that's awaiting payment. If you have any doubts, please get in touch at "
                    supportEmail()
                    +"."
                }
                FEE_INVOICE_RESOLVED -> +"Thank you for settling your service fee invoice."
                REBATE_DISBURSED -> +"Your rebate is on its way! You've made your donations go a little further. What a legend!"
                REBATE_CLAIMED_BY_OTHER_RECEIVED -> +"We received a rebate for you that we didn't claim. This will be transferred to your bank account next Wednesday."
                REBATE_CLAIMED_BY_OTHER_DISBURSED -> +"We received a rebate for you that we didn't claim and have sent it to your bank account."
            }
        }
    }

    /**
     * Renders the SG support email address as a link.
     */
    private fun RBuilder.supportEmail() {
        styledSpan {
            css {
                // Allow the email to be split if it is too long to display on the line
                overflowWrap = OverflowWrap.anywhere
            }

            textLinkEmail(email = EmailAddress.SG_SUPPORT)
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        props.viewModel.rebateClaims.observe { rebateClaims ->
            val (rebateClaimsCompleted, rebateClaimsInProgress) = rebateClaims.partition { rebateClaim ->
                rebateClaim.status.progressStatus == COMPLETED_SUCCESS ||
                        rebateClaim.status.progressStatus == COMPLETED_FAILURE
            }

            setState {
                this.rebateClaimsCompleted = rebateClaimsCompleted.sortedWith(::compareRebateClaims)
                this.rebateClaimsInProgress = rebateClaimsInProgress.sortedWith(::compareRebateClaims)
            }
        }

        props.viewModel.errors.observeEvent { errors ->
            if (errors.any { (it as? ErrorType.AppError)?.code == REBATE_CLAIMS_MISSING }) {
                setState { rebateClaimsLoadFailed = true }
            }
        }
    }

    /**
     * Compares [rebateClaim1] and [rebateClaim2] to determine order.
     *
     * How it sorts:
     * 1. If one of the claims status is [ON_HOLD], it will be positioned first.
     * 2. Otherwise, the claims will be sorted in descending order by the date of the [RebateClaim.donation].
     *
     * @return -1 if [rebateClaim1] should be positioned first, or 1 if [rebateClaim2] should be first.
     */
    private fun compareRebateClaims(rebateClaim1: RebateClaim, rebateClaim2: RebateClaim): Int {
        // Return values named to make it easier to read in the algorithm
        val rebateClaim1GoesFirst = -1
        val rebateClaim2GoesFirst = 1

        // If there is no donation date, then use the epoch date to put the rebate claim at the top
        val rebateClaim1DonationDate = rebateClaim1.donation?.date ?: LocalDate.fromEpochDays(epochDays = 0)
        val rebateClaim2DonationDate = rebateClaim2.donation?.date ?: LocalDate.fromEpochDays(epochDays = 0)

        return when {
            rebateClaim1.status.progressStatus == ON_HOLD && rebateClaim2.status.progressStatus != ON_HOLD -> {
                rebateClaim1GoesFirst
            }
            rebateClaim1.status.progressStatus != ON_HOLD && rebateClaim2.status.progressStatus == ON_HOLD -> {
                rebateClaim2GoesFirst
            }
            rebateClaim1DonationDate > rebateClaim2DonationDate -> rebateClaim1GoesFirst
            else -> rebateClaim2GoesFirst
        }
    }

}

/**
 * Props for the [RebateClaimsPanel].
 */
private external interface RebateClaimsPanelProps : ScreenSizeProps {

    var viewModel: RebateClaimsViewModel

}

/**
 * State of the [RebateClaimsPanel].
 */
private external interface RebateClaimsPanelState : State {

    /**
     * [RebateClaim]s that are complete.
     */
    var rebateClaimsCompleted: List<RebateClaim>

    /**
     * [RebateClaim]s that are still in progress.
     */
    var rebateClaimsInProgress: List<RebateClaim>

    /**
     * The tab that is currently being shown by the panel.
     */
    var rebateClaimsTabSelected: RebateClaimsTab

    /**
     * `true` if there was an error loading rebate claim data, or `false` otherwise.
     */
    var rebateClaimsLoadFailed: Boolean

}

/**
 * Tabs shown on the [RebateClaimsPanel] to switch between completed and not completed claims.
 */
private enum class RebateClaimsTab {

    CLAIMS_IN_PROGRESS,
    CLAIMS_COMPLETED

}

/**
 * Renders a [RebateClaimsPanel] component.
 */
public fun RBuilder.rebateClaimsPanel(viewModel: RebateClaimsViewModel) {
    withScreenSize(RebateClaimsPanel::class) {
        attrs.viewModel = viewModel
    }
}