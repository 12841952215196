package supergenerous.app.core.component.image

import kotlinx.css.LinearDimension
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import supergenerous.app.core.res.image.SvgFile

/**
 * An SVG Element. This should be used for non-icon SVG elements, e.g. images or logos.
 *
 * Icon SVG elements should instead be rendered using [Icon].
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@JsExport
private class Svg : RComponent<SvgProps, State>() {

    override fun RBuilder.render() {
        val svgProps: dynamic = object{}

        props.size?.let {
            svgProps["width"] = it.value
            svgProps["height"] = it.value
        }

        child(props.svgFile.default(svgProps))
    }

}

/**
 * Properties of the [Svg] component.
 */
private external interface SvgProps : Props {

    /**
     * The svg to render.
     */
    var svgFile: SvgFile

    /**
     * The size to render the [Svg].
     */
    var size: LinearDimension?

}

/**
 * Renders an [Svg] component. This should be used for non-icon SVG elements.
 *
 * Icon SVG elements should instead be rendered using [Icon].
 */
internal fun RBuilder.svg(svgFile: SvgFile, size: LinearDimension? = null) {
    child(Svg::class) {
        attrs.svgFile = svgFile
        attrs.size = size
    }
}