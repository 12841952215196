package supergenerous.app.core.component.textfield

import com.hipsheep.kore.util.isNotNullOrBlank
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.GridColumn
import kotlinx.css.GridRow
import kotlinx.css.GridTemplateColumns
import kotlinx.css.GridTemplateRows
import kotlinx.css.LinearDimension
import kotlinx.css.alignItems
import kotlinx.css.borderColor
import kotlinx.css.columnGap
import kotlinx.css.display
import kotlinx.css.fr
import kotlinx.css.gridColumn
import kotlinx.css.gridRow
import kotlinx.css.gridTemplateColumns
import kotlinx.css.gridTemplateRows
import kotlinx.css.margin
import kotlinx.css.paddingTop
import kotlinx.css.px
import kotlinx.css.title
import materialui.MuiInputVariant
import materialui.textfield.InputProps
import materialui.textfield.MuiTextField
import org.w3c.dom.events.KeyboardEvent
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.SyntheticEvent
import react.dom.div
import react.setState
import styled.css
import styled.styled
import styled.styledDiv
import supergenerous.app.core.component.TextStyle
import supergenerous.app.core.component.body2
import supergenerous.app.core.component.button.ButtonSize.SMALL
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.button.iconButton
import supergenerous.app.core.component.inputTitle
import supergenerous.app.core.component.style.InputStyle
import supergenerous.app.core.component.textError
import supergenerous.app.core.component.textfield.TextFieldType.*
import supergenerous.app.core.component.toInputFieldId
import supergenerous.app.core.res.Color
import supergenerous.app.core.res.image.CoreIcon
import supergenerous.app.core.util.KeyboardKey.ENTER
import supergenerous.app.core.util.format.BankAccountFormatter
import supergenerous.app.core.util.format.EmailFormatter
import supergenerous.app.core.util.format.FreeTextFormatter
import supergenerous.app.core.util.format.IrdNumberFormatter
import supergenerous.app.core.util.format.PhoneNumberFormatter
import supergenerous.app.core.util.format.TextCapitalization
import supergenerous.app.core.util.format.TextFormatter

/**
 * Text field component used throughout the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class TextField : RComponent<TextFieldProps, TextFieldState>() {

    private val textFormatter: TextFormatter? by lazy {
        when (props.type) {
            IRD_NUMBER -> IrdNumberFormatter()
            BANK_ACCOUNT_NUMBER -> BankAccountFormatter()
            PHONE_NUMBER -> PhoneNumberFormatter()
            EMAIL -> EmailFormatter()
            TEXT -> FreeTextFormatter(props.textCapitalization)
            NUMBER, PASSWORD -> null
        }
    }

    /**
     * Type of [MuiTextField] equivalent to this [TextFieldType].
     */
    private val TextFieldType.muiType: String
        get() = when (this) {
            NUMBER -> "number"
            PHONE_NUMBER -> "tel"
            EMAIL -> "email"
            PASSWORD -> if (state.showPassword) "text" else "password"
            TEXT, IRD_NUMBER, BANK_ACCOUNT_NUMBER -> "text"
        }


    override fun TextFieldState.init() {
        showPassword = false
    }

    override fun RBuilder.render() {
        // We only need an ID if we are setting a label, so they are "linked" for accessibility
        val textFieldId = props.title?.toInputFieldId()

        div {
            props.title?.let { label ->
                inputTitle(
                    title = label,
                    subtitle = props.subtitle,
                    helpInfo = props.helpInfo,
                    inputFieldId = textFieldId
                )
            }

            styledDiv {
                css {
                    display = Display.grid
                    gridTemplateColumns = GridTemplateColumns(1.fr, LinearDimension.auto)
                    gridTemplateRows = GridTemplateRows.auto
                    alignItems = Align.start

                    // If "Add" button is shown then add a gap between the text field and the button
                    props.onTextSelect?.let {
                        columnGap = 32.px
                    }
                }

                @Suppress("DEPRECATION")
                styled(MuiTextField)() {
                    css {
                        gridRow = GridRow("1")
                        gridColumn = GridColumn("1")

                        specific {
                            // Hide up/down arrows inside the field when the type is number (see https://github.com/fundafuture/faf-app/issues/117)
                            if (props.type == NUMBER) {
                                "input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button" {
                                    put("-webkit-appearance", "none")
                                    margin = "0"
                                }
                                "input[type=number]" {
                                    put("-moz-appearance", "textfield")
                                }
                            }

                            // Add our border to the input
                            child(".MuiInputBase-root") {
                                +InputStyle.focusRing
                                +InputStyle.border

                                // If there is a warning then add warning styling
                                props.warningMessage?.let {
                                    borderColor = Color.WARNING.cssValue
                                }

                                // If there is an error then add error styling (this overwrites the warning styling)
                                props.errorMessage?.let {
                                    borderColor = Color.ERROR.cssValue
                                }
                            }

                            // Add text style to the input
                            descendants("input.MuiInputBase-input") {
                                +TextStyle.INPUT_TEXT.cssStyle
                            }
                        }
                    }

                    attrs {
                        // Set the ID to link the text field to the label for accessibility
                        id = textFieldId

                        type = props.type.muiType
                        variant = MuiInputVariant.outlined.name
                        multiline = props.isMultiline
                        margin = "dense"

                        // Add auto-complete attribute when the field is an email or password so Password Managers work
                        // properly (see https://www.chromium.org/developers/design-documents/create-amazing-password-forms)
                        when (props.type) {
                            EMAIL -> autoComplete = "username"
                            PASSWORD -> autoComplete = "current-password"
                            else -> {
                                // Nothing to do for other types
                            }
                        }

                        props.placeholder?.let { placeholder = it }

                        // The value shows whatever was sent as a property by default, but if that is not available then
                        // show what the user is typing or an empty string if it's the first time the component is created
                        val baseValue = props.value ?: state.textEntered ?: ""
                        value = textFormatter?.format(baseValue) ?: baseValue

                        onChange = { event -> onTextChange(text = event.currentTarget.value) }
                        props.onTextSelect?.let { onKeyDown = ::onKeyDown }

                        onFocus = { props.onFocus?.invoke() }

                        onWheel = ::onScroll

                        // Only show the "clear" button if there is text entered
                        val showClearIcon = props.isClearable && baseValue.isNotNullOrBlank()
                        val showPwdVisibilityIcon = props.type == PASSWORD

                        if (showClearIcon || showPwdVisibilityIcon) {
                            inputProps = InputProps.buildInputProps {
                                endAdornment = react.buildElement {
                                    styledDiv {
                                        css {
                                            // Remove extra margin to position the icon properly
                                            margin(top = (-4).px,
                                                   bottom = (-4).px,
                                                   right = (-14).px)
                                        }

                                        if (showClearIcon) {
                                            iconButton(
                                                icon = CoreIcon.CLOSE,
                                                size = 40.px,
                                                onClick = { onTextChange(text = "") }
                                            )
                                        }

                                        if (showPwdVisibilityIcon) {
                                            iconButton(
                                                icon = if (state.showPassword) {
                                                    CoreIcon.HIDE
                                                } else {
                                                    CoreIcon.SHOW
                                                },
                                                onClick = {
                                                    setState { showPassword = !showPassword }
                                                }
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // Show a footer if there is and error or warning, and if there are both show only the error
                (props.errorMessage ?: props.warningMessage)?.let { footer ->
                    body2 {
                        css {
                            gridRow = GridRow("2")
                            gridColumn = GridColumn("1")
                        }

                        textError(isWarning = props.errorMessage == null) { +footer }
                    }
                }

                // Only show the "add" button if the onTextSelect callback is set
                props.onTextSelect?.let {
                    styledDiv {
                        css {
                            gridRow = GridRow("1")
                            gridColumn = GridColumn("2")
                            paddingTop = 10.px
                        }

                        button(
                            size = SMALL,
                            isDisabled = state.textEntered.isNullOrBlank(),
                            label = props.selectButtonLabel,
                            onClick = ::onTextSelect
                        )
                    }
                }
            }
        }
    }

    /**
     * Called when the user edits the [text] in the [TextField].
     */
    private fun onTextChange(text: String) {
        val valueFormatted = textFormatter?.format(text) ?: text

        // Don't allow the field to be edited if it is expected to match a regex, but doesn't match
        if (textFormatter != null && !textFormatter!!.matchesFormat(valueFormatted)) {
            return
        }

        props.onTextChange?.invoke(valueFormatted)
        setState { textEntered = valueFormatted }
    }

    /**
     * Called when the user clicks a key while the focus is on the [TextField].
     */
    private fun onKeyDown(event: SyntheticEvent) {
        if ((event.nativeEvent as? KeyboardEvent)?.key == ENTER.value) {
            onTextSelect()

            // Prevent a call to onSubmit (see https://github.com/mui-org/material-ui/issues/5393)
            event.preventDefault()
        }
    }

    /**
     * Function called when the text entered is "selected" by the user (e.g., the user clicks "enter").
     */
    private fun onTextSelect() {
        state.textEntered?.let { props.onTextSelect!!(it) }

        // Clear current text once it's "selected"
        setState { textEntered = "" }
    }

    /**
     * Handles the scroll [event].
     */
    private fun onScroll(event: SyntheticEvent) {
        // Disable scrolling for numbers to stop numbers incrementing/decrementing accidentally
        // (See https://github.com/supergenerous/issues/issues/369)
        if (props.type == NUMBER) {
            // Use blur instead of preventDefault because it doesn't always work
            // See https://github.com/mui/material-ui/issues/7960#issuecomment-760367956
            event.target.blur()
        }
    }

}

/**
 * Properties used by the [TextField] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
internal external interface TextFieldProps : Props {

    /**
     * Type of input that is accepted by the [TextField].
     */
    var type: TextFieldType

    /**
     * Text capitalization used for the [TextField] value. The [placeholder] is not affected by this.
     */
    var textCapitalization: TextCapitalization

    /**
     * Title shown above the [TextField].
     */
    var title: String?

    /**
     * Subtitle shown below the [title].
     */
    var subtitle: String?

    /**
     * Placeholder shown in the [TextField] before the user starts typing.
     */
    var placeholder: String?

    /**
     * Value shown in the [TextField], or `null` if the [placeholder] should be shown.
     */
    var value: String?

    /**
     * Label used for the "select" button that appears to the right of the text field when [onTextSelect] is set.
     */
    var selectButtonLabel: String

    /**
     * `true` if the [TextField] should be multiple, or `false` if it should be single-line.
     */
    var isMultiline: Boolean

    /**
     * `true` if the [TextField] should be clearable (i.e., a "clear" button is shown inside the field), or `false`
     * otherwise.
     */
    var isClearable: Boolean

    /**
     * Adds an error message and styles the input as invalid.
     */
    var errorMessage: String?

    /**
     * The warning message to display.
     *
     * If an [errorMessage] is set then this message won't be displayed.
     */
    var warningMessage: String?

    /**
     * If not `null`, a question mark icon is added to the field with a popup that displays the given text.
     */
    var helpInfo: String?

    /**
     * Function called when the user focuses the [TextField].
     */
    var onFocus: (() -> Unit)?

    /**
     * Function called when the user changes the text in the [TextField].
     */
    var onTextChange: ((String) -> Unit)?

    /**
     * Function called when the text entered is "selected" by the user (e.g., the user clicks "enter").
     */
    var onTextSelect: ((String) -> Unit)?

}

/**
 * State of the [TextField] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface TextFieldState : State {

    /**
     * Text entered by the user.
     */
    var textEntered: String?

    /**
     * If this is a [PASSWORD] [TextField], reveals the password if `true` otherwise masks it.
     */
    var showPassword: Boolean

}

/**
 * Renders a [TextField] component.
 */
public fun RBuilder.textField(type: TextFieldType = TEXT,
                              textCapitalization: TextCapitalization = TextCapitalization.NONE,
                              title: String? = null,
                              subtitle: String? = null,
                              placeholder: String? = null,
                              value: String? = null,
                              selectButtonLabel: String = "Add",
                              isMultiline: Boolean = false,
                              isClearable: Boolean = false,
                              errorMessage: String? = null,
                              warningMessage: String? = null,
                              helpInfo: String? = null,
                              onFocus: (() -> Unit)? = null,
                              onTextChange: ((String) -> Unit)? = null,
                              onTextSelect: ((String) -> Unit)? = null) {
    child(TextField::class) {
        attrs.type = type
        attrs.textCapitalization = textCapitalization
        attrs.title = title
        attrs.subtitle = subtitle
        attrs.placeholder = placeholder
        attrs.value = value
        attrs.selectButtonLabel = selectButtonLabel
        attrs.onFocus = onFocus
        attrs.onTextChange = onTextChange
        attrs.onTextSelect = onTextSelect
        attrs.isMultiline = isMultiline
        attrs.isClearable = isClearable
        attrs.errorMessage = errorMessage
        attrs.warningMessage = warningMessage
        attrs.helpInfo = helpInfo
    }
}