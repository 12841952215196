package com.supergenerous.common.file

import com.supergenerous.common.data.Dbo
import com.supergenerous.common.util.FileBucket
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [CloudFile] class.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@Serializable
public data class CloudFileDbo(

    val name: String? = null,

    val bucket: FileBucket? = null,

    val path: String? = null

) : Dbo<CloudFile> {

    override fun toDto(): CloudFile {
        return CloudFile(name = name!!,
                         bucket = bucket ?: FileBucket.ADMIN,
                         path = path!!)
    }

}