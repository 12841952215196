package supergenerous.app.core.util

import kotlinx.browser.window
import org.w3c.dom.events.Event
import react.Component
import react.ElementType
import react.Props
import react.RBuilder
import react.RElementBuilder
import react.RHandler
import react.State
import react.fc
import react.react
import react.useEffect
import react.useState
import kotlin.reflect.KClass

/**
 * The size to start displaying the UI for mobiles at or below.
 */
public const val MOBILE_SCREEN_MAX_SIZE_PIXELS: Int = 768

/**
 * React hook that returns `true` if the window is a mobile screen, or `false` if it is a desktop screen.
 *
 * See https://usehooks.com/useWindowSize/
 */
public fun useIsMobileScreen(): Boolean {
    val resizeEventName = "resize"
    var isMobileScreen by useState(isMobileScreen())

    useEffect(dependencies = emptyArray()) {
        // Inline function that updates state when a resize occurs
        val handleScreenResize = { _: Event? ->
            isMobileScreen = isMobileScreen()
        }

        // Add event listener
        window.addEventListener(resizeEventName, handleScreenResize)

        // Remove event listener on cleanup
        this.cleanup { window.removeEventListener(resizeEventName, handleScreenResize) }
    }

    return isMobileScreen
}

/**
 * Returns `true` if the window width is <= [MOBILE_SCREEN_MAX_SIZE_PIXELS].
 */
private fun isMobileScreen(): Boolean {
    return window.innerWidth <= MOBILE_SCREEN_MAX_SIZE_PIXELS
}

/**
 * Properties that include screen size details.
 */
public external interface ScreenSizeProps : Props {

    /**
     * `true` if the screen size is currently <= [MOBILE_SCREEN_MAX_SIZE_PIXELS].
     */
    public var isMobileScreen: Boolean

}

/**
 * Creates a wrapper component that injects [ScreenSizeProps] into the [component].
 */
public fun <P : ScreenSizeProps, S : State, C : Component<P, S>> RBuilder.withScreenSize(component: KClass<C>,
                                                                                         handler: RHandler<P> = {}) {
    child(screenSizeProvider) {
        attrs.innerProps = handler as (RElementBuilder<ScreenSizeProps>) -> Unit
        attrs.component = component.react as ElementType<ScreenSizeProps>
    }
}

/*
 * Screen size provider
 */

/**
 * Component that injects [ScreenSizeProps] into [ScreenSizeProviderProps.component].
 */
private val screenSizeProvider = fc<ScreenSizeProviderProps<ScreenSizeProps>> { props ->
    val isMobileScreen = useIsMobileScreen()

    child(props.component) {
        props.innerProps(this)
        attrs.isMobileScreen = isMobileScreen
    }
}

/**
 * [Props] for the [screenSizeProvider] component.
 */
private external interface ScreenSizeProviderProps<P : ScreenSizeProps> : Props {

    /**
     * The component that will have screen size info injected as props.
     */
    var component: ElementType<P>

    /**
     * The prop builder function for passing props to [component].
     */
    // We have to use RElementBuilder because RHandler is an alias of an extension function and extension functions
    // can't be used in external interfaces (`typealias RHandler<P> = RElementBuilder<P>.() -> Unit`)
    var innerProps: (RElementBuilder<P>) -> Unit

}