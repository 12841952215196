package com.hipsheep.kore.util

/**
 * Splits this char sequence to a list of strings around occurrences of the specified [delimiters].
 *
 * @param trim `true` to trim the value before splitting it and each list items after splitting, or `false` to keep
 * them as they are.
 *
 * @see [kotlin.text.split]
 */
public fun CharSequence.split(delimiters: String, ignoreCase: Boolean = false, limit: Int = 0, trim: Boolean): List<String> {
    return this.trim()
            .split(delimiters, ignoreCase = ignoreCase, limit = limit).let { list ->
                if (trim) list.map { it.trim() } else list
            }
}

/**
 * Returns `true` when the `String` is neither `null` nor blank, or `false` otherwise.
 */
public fun CharSequence?.isNotNullOrBlank(): Boolean {
    return !this.isNullOrBlank()
}

/**
 * Returns a copy of this string having the first letter of each word capitalized using the rules of the default locale.
 *
 * @see String.capitalize
 */
public fun String.capitalizeWords(): String {
    return split(" ").joinToString(" ") { it.capitalize() }
}