package supergenerous.app.core.component.table

import kotlinx.css.Align
import kotlinx.css.BorderCollapse
import kotlinx.css.BorderStyle
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.JustifyContent
import kotlinx.css.Overflow
import kotlinx.css.Position
import kotlinx.css.TextAlign.*
import kotlinx.css.alignItems
import kotlinx.css.backgroundColor
import kotlinx.css.borderBottomColor
import kotlinx.css.borderBottomStyle
import kotlinx.css.borderBottomWidth
import kotlinx.css.borderCollapse
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.gap
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.overflowY
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.position
import kotlinx.css.properties.boxShadow
import kotlinx.css.px
import kotlinx.css.textAlign
import kotlinx.css.top
import kotlinx.css.width
import react.Component
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.dom.tbody
import styled.css
import styled.styledDiv
import styled.styledTable
import styled.styledTd
import styled.styledTh
import styled.styledThead
import styled.styledTr
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.table.TableColumnConfig.HorizontalAlignment
import supergenerous.app.core.component.table.TableColumnConfig.MobileColumn.FIRST
import supergenerous.app.core.component.textBold
import supergenerous.app.core.res.Color
import supergenerous.app.core.util.ScreenSizeProps
import supergenerous.app.core.util.withScreenSize
import kotlin.reflect.KClass

/**
 * A table component for displaying data in a tabular format.
 *
 * To make the body scrollable the container element of this [Table] should have [overflowY] set to [Overflow.auto], and
 * a max height.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@JsExport
private class Table<T> : RComponent<TableProps<T>, State>() {

    /**
     * Color of the borders of the table.
     */
    private val borderColor = Color.BLACK

    /**
     * Horizontal padding used for every table cell.
     */
    private val cellHorizontalPadding = 8.px


    override fun RBuilder.render() {
        styledTable {
            css {
                // Make the table full width
                width = 100.pct

                // Hack to make div fit cell (https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell)
                height = 1.px

                // Collapse the borders so they make a single line (the default is 2 lines for table borders)
                // see https://developer.mozilla.org/en-US/docs/Web/CSS/border-collapse#try_it
                borderCollapse = BorderCollapse.collapse
            }

            // Only show headers for desktop view
            if (!props.isMobileScreen) {
                header()
            }

            tbody {
                if (props.content.isNotEmpty()) {
                    content()
                } else {
                    // If there are no content to display then render the "no content message"
                    noContentMessage()
                }
            }
        }
    }

    /**
     * Renders the header of the table.
     */
    private fun RBuilder.header() {
        styledThead {
            css {
                // Make the header stick to the top when the body scrolls
                position = Position.sticky
                top = 0.px

                // Add a background color, otherwise if it is transparent then when the body of the table gets
                // scrolled, while the header is stickied to the top, the body text is still visible while it is
                // underneath the header
                backgroundColor = Color.SCREEN_BACKGROUND.cssValue

                // Use a box shadow instead of a border for the header because with a stickied element, borders
                // don't stick to the element. They instead scroll away as if the element was not sticky
                boxShadow(offsetX = 0.px, offsetY = 1.px, blurRadius = 0.px, color = borderColor.cssValue)

                // Hack to make div fit cell (https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell)
                height = 100.pct
            }

            styledTr {
                css {
                    // Hack to make div fit cell (https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell)
                    height = 100.pct
                }

                props.columnsConfig.forEach { columnConfig ->
                    styledTh {
                        css {
                            textAlign = start
                            padding(vertical = 16.px,
                                    horizontal = cellHorizontalPadding)

                            // Hack to make div fit cell (https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell)
                            height = 100.pct
                        }

                        body1 {
                            textBold { +columnConfig.header }
                        }
                    }
                }
            }
        }
    }

    /**
     * Renders the table content on desktop or mobile according to the value of [TableProps.isMobileScreen].
     */
    private fun RBuilder.content() {
        if (props.isMobileScreen) {
            contentMobile()
        } else {
            contentDesktop()
        }
    }

    /**
     * Renders the content of the table for desktop screens.
     */
    private fun RBuilder.contentDesktop() {
        // Render each row
        props.content.forEach { rowContent ->
            row {
                props.columnsConfig.forEach { columnConfig ->
                    cell {
                        styledDiv {
                            css {
                                // Make div full height of cell so that flex can be used to position the contents
                                height = 100.pct

                                // Position content inside cell
                                display = Display.flex
                                justifyContent = when (columnConfig.horizontalAlignment) {
                                    HorizontalAlignment.LEFT -> JustifyContent.start
                                    HorizontalAlignment.CENTER -> JustifyContent.center
                                    HorizontalAlignment.RIGHT -> JustifyContent.end
                                }
                                // Always align content to the top
                                alignItems = Align.start
                            }

                            (columnConfig.renderCellContent)(rowContent)
                        }
                    }
                }
            }
        }
    }

    /**
     * Renders the content of the table for mobile screens.
     */
    private fun RBuilder.contentMobile() {
        val (firstColConfigs, secondColConfigs) = props.columnsConfig
                // Only render content that has a mobile column defined
                .filter { columnConfig -> columnConfig.mobileColumn != null }
                .partition { columnConfig -> columnConfig.mobileColumn == FIRST }

        // Render each row
        props.content.forEach { rowContent ->
            row {
                if (firstColConfigs.isNotEmpty()) {
                    cellMobile {
                        firstColConfigs.forEach { columnConfig ->
                            (columnConfig.renderCellContent)(rowContent)
                        }
                    }
                }

                if (secondColConfigs.isNotEmpty()) {
                    cellMobile {
                        secondColConfigs.forEach { columnConfig ->
                            (columnConfig.renderCellContent)(rowContent)
                        }
                    }
                }
            }
        }
    }

    /**
     * Renders a single row with a single cell that spans all the columns and displays [TableProps.textNoContent].
     */
    private fun RBuilder.noContentMessage() {
        row {
            styledTd {
                css {
                    padding(all = 24.px)
                    textAlign = center

                    height = 100.pct
                }

                // Make the row span all columns
                attrs.colSpan = if (props.isMobileScreen) {
                    // If there are both `FIRST` and `SECOND` mobile columns this will be size 2, otherwise 1
                    props.columnsConfig.groupBy { it.mobileColumn }.size.toString()
                } else {
                    props.columnsConfig.size.toString()
                }

                body1 { +props.textNoContent }
            }
        }
    }

    /**
     * Renders a table row with the [content] inside.
     */
    private fun RBuilder.row(content: RBuilder.() -> Unit) {
        styledTr {
            css {
                // Add a divider line between each row
                borderBottomWidth = 1.px
                borderBottomColor = borderColor.cssValue
                borderBottomStyle = BorderStyle.solid

                // Hack to make div fit cell (https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell)
                height = 100.pct
            }

            content()
        }
    }

    /**
     * Renders a mobile view [cell] with the [content] inside.
     */
    private fun RBuilder.cellMobile(content: RBuilder.() -> Unit) {
        cell {
            styledDiv {
                css {
                    // Make div full height of cell so that flex can position the content
                    height = 100.pct

                    display = Display.flex

                    // Make items render downwards with a gap between them
                    flexDirection = FlexDirection.column
                    gap = 8.px

                    // Render items left and top aligned
                    justifyContent = JustifyContent.start
                    alignItems = Align.start
                }

                content()
            }
        }
    }

    /**
     * Renders a table cell with the [content] inside.
     */
    private fun RBuilder.cell(content: RBuilder.() -> Unit) {
        styledTd {
            css {
                // Hack to make div fit cell (https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell)
                height = 100.pct

                padding(vertical = 24.px, horizontal = cellHorizontalPadding)
            }

            content()
        }
    }

}

/**
 * [Props] of the [Table] component.
 */
private external interface TableProps<T> : ScreenSizeProps {

    /**
     * The configuration for the columns in the [Table].
     */
    var columnsConfig: List<TableColumnConfig<T>>

    /**
     * The [content] to display in the [Table].
     */
    var content: List<T>

    /**
     * The text to display if there is no [content] in the [Table].
     */
    var textNoContent: String

}

/**
 * Renders a [Table] component.
 */
public fun <T> RBuilder.table(columnsConfig: List<TableColumnConfig<T>>,
                              content: List<T>,
                              textNoContent: String) {
    // Cast the Table class to a KClass to make the generics work
    withScreenSize(Table::class as KClass<out Component<TableProps<T>, State>>) {
        attrs.columnsConfig = columnsConfig
        attrs.content = content
        attrs.textNoContent = textNoContent
    }
}