package supergenerous.app.donor.dashboard.view

import com.hipsheep.kore.error.ErrorType
import com.hipsheep.kore.error.ErrorType.*
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.disbursement.DisbursementRecipient.Type.*
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.Donee.Type.*
import com.supergenerous.common.donee.DoneeBasicInfo
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.FlexWrap
import kotlinx.css.JustifyContent
import kotlinx.css.alignItems
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.flexWrap
import kotlinx.css.gap
import kotlinx.css.justifyContent
import kotlinx.css.marginTop
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.dom.div
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.common.valuePlural
import supergenerous.app.core.common.valueSingular
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.button.ButtonType
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.chip.Chip
import supergenerous.app.core.component.chip.chipSet
import supergenerous.app.core.component.contentSection
import supergenerous.app.core.component.radio.RadioButton
import supergenerous.app.core.component.radio.radioGroup
import supergenerous.app.core.component.snackbar.SnackbarMessageQueue
import supergenerous.app.core.component.subheading1
import supergenerous.app.core.component.textfield.doneeSearchField
import supergenerous.app.core.component.textfield.selectTextField
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.core.util.AlignContent
import supergenerous.app.core.util.JustifySelf
import supergenerous.app.core.util.alignContent
import supergenerous.app.core.util.format.TextCapitalization.CAPITALIZE
import supergenerous.app.core.util.justifySelf
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.donor.dashboard.model.DashboardError.DISB_SETTINGS_MISSING

/**
 * Component that allows the user to edit their [Donee] selections.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
@JsExport
private class DoneeSelectionPanel : RComponent<DoneeSelectionPanelProps, DoneeSelectionPanelState>() {

    /**
     * Disbursement recipient type equivalent to the [DoneeSelectionPanelProps.doneeType] for donating rebates.
     */
    private val disbRecipientTypeDonate: DisbursementRecipient.Type
        get() = when (props.doneeType) {
            CHARITY -> CHARITIES
            SCHOOL -> SCHOOLS
            RELIGIOUS_ORG -> RELIGIOUS_ORGS
        }


    override fun DoneeSelectionPanelState.init() {
        childrenNamesSelected = setOf()
        doneesSelected = setOf()
        showDisbSettingError = false
    }

    override fun RBuilder.render() {
        val doneeTypeSingular = props.doneeType.valueSingular
        val doneeTypePlural = props.doneeType.valuePlural

        div {
            contentSection {
                styledDiv {
                    css {
                        width = 100.pct

                        display = Display.inlineGrid
                        alignContent(AlignContent.start)
                        gap = 24.px
                    }

                    subheading1 { +"Add $doneeTypeSingular" }

                    styledDiv {
                        css {
                            display = Display.inlineGrid
                            gap = 32.px
                        }

                        doneeSearchField(
                            doneeType = props.doneeType,
                            showTitle = false,
                            searchResults = props.doneeSearchResults,
                            showInputAsResult = true,
                            onTextChange = { text -> props.onDoneeSearch(text, props.doneeType) },
                            onDoneeSelect = { donee ->
                                setState { doneesSelected += donee }
                            }
                        )

                        // Chips
                        if (state.doneesSelected.isNotEmpty()) {
                            styledDiv {
                                css {
                                    marginTop = 8.px

                                    display = Display.flex
                                    flexDirection = FlexDirection.column
                                    gap = 8.px
                                }

                                body1 { +"Selected ${doneeTypePlural}:" }

                                chipSet(
                                    chips = state.doneesSelected.map { Chip(id = it.name, label = it.name) },
                                    onChipRemove = { doneeName ->
                                        val doneeToRemove = state.doneesSelected.first { it.name == doneeName }

                                        setState {
                                            if (state.doneesSelected.size == 1) {
                                                // Reset rebate recipient selected when all the donees are removed from the list
                                                disbRecipientTypeSelected = null
                                            }
                                            doneesSelected -= doneeToRemove
                                        }
                                    }
                                )
                            }
                        }

                        if (state.doneesSelected.isNotEmpty()) {
                            radioGroup(
                                title = "What would you like to do with your $doneeTypeSingular rebates?",
                                radioButtons = listOf(RadioButton(label = "I want to be super generous and regift it to the $doneeTypePlural I donated to",
                                                                  value = disbRecipientTypeDonate.name),
                                                      RadioButton(label = "I want to keep it for myself",
                                                                  value = DONOR.name)),
                                valueSelected = state.disbRecipientTypeSelected?.name,
                                onSelect = { disbRecipientTypeName ->
                                    setState {
                                        showDisbSettingError = false
                                        disbRecipientTypeSelected = DisbursementRecipient.Type.valueOf(disbRecipientTypeName)
                                    }
                                },
                                showError = state.showDisbSettingError
                            )

                            // Only show the "children names" field on the schools selection screen
                            if (props.doneeType == SCHOOL) {
                                selectTextField(
                                    title = "Children names (optional)",
                                    subtitle = "Schools typically require a child's name to find the relevant donations.",
                                    placeholder = "Enter a name and click \"Add\"",
                                    textCapitalization = CAPITALIZE,
                                    chips = state.childrenNamesSelected.map { Chip(id = it, label = it) },
                                    onItemAdd = { setState { childrenNamesSelected += it } },
                                    onItemRemove = { setState { childrenNamesSelected -= it } }
                                )
                            }
                        }
                    }
                }
            }

            if (state.doneesSelected != props.donees
                || state.disbRecipientTypeSelected != props.disbRecipientType
                || state.childrenNamesSelected != props.childrenNames) {
                styledDiv {
                    css {
                        width = 100.pct
                        justifySelf(JustifySelf.end)
                        marginTop = 56.px

                        display = Display.flex
                        flexWrap = FlexWrap.wrapReverse
                        justifyContent = JustifyContent.flexEnd
                        gap = 24.px

                        mobileScreen {
                            marginTop = 32.px

                            flexDirection = FlexDirection.columnReverse
                            gap = 16.px
                            alignItems = Align.center
                        }
                    }

                    button(
                        label = "Cancel",
                        type = ButtonType.SECONDARY,
                        onClick = {
                            setState {
                                doneesSelected = props.donees
                                disbRecipientTypeSelected = props.disbRecipientType
                                childrenNamesSelected = props.childrenNames
                            }
                        }
                    )

                    button(
                        label = "Submit",
                        type = ButtonType.PRIMARY,
                        showLoadingIcon = props.isSavingData,
                        onClick = ::saveDonees
                    )
                }
            }
        }
    }

    /**
     * Saves the donee selections.
     */
    private fun saveDonees() {
        props.onDoneesChange(state.doneesSelected, state.disbRecipientTypeSelected, state.childrenNamesSelected)
    }

    override fun componentDidMount() {
        setState {
            doneesSelected = props.donees
            disbRecipientTypeSelected = props.disbRecipientType
            childrenNamesSelected = props.childrenNames
        }
    }

    // TODO: remove this when we add a view model with error observable into the component
    override fun componentDidUpdate(prevProps: DoneeSelectionPanelProps, prevState: DoneeSelectionPanelState, snapshot: Any) {
        // If props.error has updated then process it
        val errors = props.errors
        if (errors != null && errors != prevProps.errors) {
            errors.forEach {
                when (val error = it) {
                    is HttpError -> {
                        SnackbarMessageQueue.add(title = null,
                                                 body = "An unknown error occurred: (Code ${error.statusCode})")
                    }
                    is AppError -> if (error.code == DISB_SETTINGS_MISSING) { setState { showDisbSettingError = true } }
                }
            }
        }
    }
}


/**
 * Properties of the [DoneeSelectionPanel] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface DoneeSelectionPanelProps : Props {

    /**
     * Initial donees already selected by the user when the component is created.
     */
    var donees: Set<DoneeBasicInfo>

    /**
     * The type of [donees] that will be selected.
     */
    var doneeType: Donee.Type

    /**
     * Initial disbursement recipient type selected by the user when the component is created.
     */
    var disbRecipientType: DisbursementRecipient.Type?

    /**
     * Initial children names selected by the user when the component is created.
     */
    var childrenNames: Set<String>

    /**
     * `true` if the saving action is occurring. `false` otherwise.
     */
    var isSavingData: Boolean

    /**
     * The latest errors to occur.
     */
    var errors: Set<ErrorType>?

    /**
     * Function called when the donees or its related settings change.
     */
    var onDoneesChange: (donees: Set<DoneeBasicInfo>, disbRecipientType: DisbursementRecipient.Type?, childrenNames: Set<String>) -> Unit

    /**
     * List of donees returned from the donor's search.
     */
    var doneeSearchResults: List<TextSearchResult<DoneeBasicInfo>>

    /**
     * Function performed when the donor searches for a donee.
     */
    var onDoneeSearch: (namePartial: String, type: Donee.Type) -> Unit

}

/**
 * State of the [DoneeSelectionPanel] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface DoneeSelectionPanelState : State {

    /**
     * The donees (of type [DoneeSelectionPanelProps.doneeType]) the user wants to get receipts for.
     */
    var doneesSelected: Set<DoneeBasicInfo>

    /**
     * The selected disbursement recipient type for this [DoneeSelectionPanelProps.doneeType].
     */
    var disbRecipientTypeSelected: DisbursementRecipient.Type?

    /**
     * The names of the children to help receipt gathering if the donee type is [Donee.Type.SCHOOL].
     */
    var childrenNamesSelected: Set<String>

    /**
     * `true` if an error occurred on the disbursement setting radio group, or `false` otherwise.
     */
    var showDisbSettingError: Boolean

}

/**
 * Renders a [DoneeSelectionPanel] component.
 */
public fun RBuilder.doneeSelectionPanel(donees: Set<DoneeBasicInfo>,
                                        doneeType: Donee.Type,
                                        disbursementRecipientType: DisbursementRecipient.Type?,
                                        childrenNames: Set<String>,
                                        isSavingData: Boolean,
                                        errors: Set<ErrorType>?,
                                        onDoneesChange: (Set<DoneeBasicInfo>, DisbursementRecipient.Type?, childrenNames: Set<String>) -> Unit,
                                        doneeSearchResults: List<TextSearchResult<DoneeBasicInfo>>,
                                        onDoneeSearch: (namePartial: String, doneeType: Donee.Type) -> Unit) {
    child(DoneeSelectionPanel::class) {
        attrs.donees = donees
        attrs.doneeType = doneeType
        attrs.disbRecipientType = disbursementRecipientType
        attrs.childrenNames = childrenNames
        attrs.isSavingData = isSavingData
        attrs.errors = errors
        attrs.onDoneesChange = onDoneesChange
        attrs.doneeSearchResults = doneeSearchResults
        attrs.onDoneeSearch = onDoneeSearch
    }
}