package supergenerous.app.donor.donor

import com.hipsheep.kore.model.repo.Repository
import com.hipsheep.kore.resource.Resource
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.donation.platform.DonationPlatform
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeBasicInfo
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.donor.DonorProvider
import com.supergenerous.common.donor.DonorState
import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.name.FullName
import com.supergenerous.common.signature.Signature
import com.supergenerous.common.user.User
import kotlinx.coroutines.flow.Flow
import kotlinx.datetime.LocalDate
import supergenerous.app.donor.kindo.model.KindoDonorLinkParams
import kotlin.js.Date

/**
 * [Repository] used to manage the [Donor]s data.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public class DonorRepository(

    private val donorService: DonorService,
    private val donorStateService: DonorStateService,
    private val donorLinkService: DonorLinkService

) : Repository() {

    /**
     * [Donor] linked to the signed-in user, or `null` if it was not created yet or no user is signed in.
     */
    // TODO: Update properties separately using FirestoreService.updateField() and remove this var
    private var donor: Donor? = null


    /**
     * Gets the [Donor] for the [user] received and returns it, or returns an error if it was not created yet.
     */
    public suspend fun getDonor(user: User): Resource<Donor> {
        return getResource {
            val resp = donorService.getDonor(id = user.id)

            // Intercept the value so it can be cached inside the repository to use it later
            if (resp.isSuccessful) {
                donor = resp.body
            }

            resp
        }
    }

    /**
     * Returns a [Flow] that will receive updates as the data of the currently signed in [Donor] is updated.
     */
    public fun getDonorUpdates(): Flow<Resource<Donor>> {
        return loadResource(loadServerData = { donorService.loadDonor(id = donor!!.id) })
    }

    /**
     * Creates a [donor] and [DonorState] in the system with the info received.
     */
    public suspend fun createDonor(donor: Donor, provider: DonorProvider? = null, referralCode: String? = null): Resource<Donor> {
        // Cache donor locally to use it later
        this.donor = donor

        /*
         * Create donor state. We do this just once for the application, at the same time we create the donor object to
         * ensure there is always a donor state for the donor. There will be an error logged to the console when this
         * operation happens, because the donor is not allowed to read the DonorState object after creating it.
         */
        donorStateService.updateDonorState(DonorState(id = donor.id,
                                                      crmId = null,
                                                      amlCheckResults = emptyList(),
                                                      idVerificationResults = emptyList(),
                                                      taxLinkStatus = null,
                                                      taxLinkRequestTimestamp = null,
                                                      provider = provider,
                                                      referralCode = referralCode,
                                                      donationPlatformLinkStates = emptyMap()))

        // Update the donor instead of creating it so the ID used for the donor is the same as the one used for the user
        return updateDonor(donor)
    }

    /**
     * Saves the [otherEmails], [phoneNumber] and [isMarketingEmailsEnabled] received for the signed in [Donor].
     */
    public suspend fun saveContactInfo(otherEmails: Set<String>,
                                       phoneNumber: String,
                                       isMarketingEmailsEnabled: Boolean): Resource<Donor> {
        return updateDonor(donor!!.copy(otherEmails = otherEmails.map { it.trim() }.toSet(),
                                        phoneNumber = phoneNumber.trim(),
                                        isMarketingEmailsEnabled = isMarketingEmailsEnabled))
    }

    /**
     * Overrides the [donees] of a certain [doneeType] for the signed in [Donor], sets the [disbRecipientType] for
     * the [doneeType] and the [childrenNames] of the [Donor].
     */
    public suspend fun saveDoneesAndDisbSetting(donees: Set<DoneeBasicInfo>,
                                                doneeType: Donee.Type,
                                                disbRecipientType: DisbursementRecipient.Type?,
                                                childrenNames: Set<String>): Resource<Donor> {
        val updatedDonees = donor!!.donees.toMutableSet().apply {
            // Replace all the donees for the type received with the donees received
            removeAll { it.type == doneeType }
            addAll(donees)
        }.toSet()

        val disbursementSettings = if (disbRecipientType != null) {
            // Update the disbursement setting if a recipient was set
            donor!!.disbursementSettings + Pair(doneeType, disbRecipientType)
        } else {
            // Remove the disb setting if no recipient was set
            donor!!.disbursementSettings - doneeType
        }

        return updateDonor(donor!!.copy(donees = updatedDonees,
                                        childrenNames = childrenNames.map { it.trim() }.toSet(),
                                        disbursementSettings = disbursementSettings.toMutableMap()))
    }

    /**
     * Removes the disbursement setting for the [doneeType] from the [donor] for the signed in [Donor].
     */
    public suspend fun removeDisbursementSetting(doneeType: Donee.Type): Resource<Donor> {
        donor?.disbursementSettings?.remove(doneeType)

        return updateDonor(donor!!)
    }

    /**
     * Saves the signed in [Donor]'s [signature].
     */
    public suspend fun saveSignature(signature: Signature): Resource<Donor> {
        // Set the setupCompleteTimestamp of the user when the signature is entered, provided the user hasn't completed
        // the setup in the past
        val setupCompleteTimestamp = if (donor!!.isSetupComplete) {
            donor!!.setupCompleteTimestamp
        } else {
            Date.now().toLong()
        }

        val updatedDonor = donor!!.copy(signature = signature,
                                        setupCompleteTimestamp = setupCompleteTimestamp)

        return updateDonor(updatedDonor)
    }

    /**
     * Saves the [govId] and the [dateOfBirth] for the signed in [Donor].
     */
    public suspend fun saveGovId(govId: IdDocument, dateOfBirth: LocalDate): Resource<Donor> {
        return updateDonor(donor!!.copy(govId = govId, dateOfBirth = dateOfBirth))
    }

    /**
     * Saves the personal info received for the signed in [Donor].
     */
    public suspend fun savePersonalInfo(legalName: FullName, address: String): Resource<Donor> {
        return updateDonor(donor!!.copy(legalName = legalName, address = address.trim()))
    }

    /**
     * Saves the [taxId] received for the signed in [Donor].
     */
    public suspend fun saveTaxId(taxId: String): Resource<Donor> {
        return updateDonor(donor!!.copy(taxId = taxId))
    }

    /**
     * Saves the [bankAccountNumber] received for the signed in [Donor].
     */
    public suspend fun saveBankAccountNumber(bankAccountNumber: String): Resource<Donor> {
        return updateDonor(donor!!.copy(bankAccountNumber = bankAccountNumber))
    }

    /**
     * Saves the [donationPlatforms] received for the signed in [Donor].
     */
    public suspend fun saveDonationPlatforms(donationPlatforms: Set<DonationPlatform>): Resource<Donor> {
        return updateDonor(donor!!.copy(donationPlatforms = donationPlatforms))
    }

    /**
     * Saves the [ipAddress] received for the signed in [Donor].
     */
    public suspend fun saveIpAddress(ipAddress: String): Resource<Donor> {
        return updateDonor(donor!!.copy(lastKnownIPAddress = ipAddress))
    }

    /**
     * Saves the [donor] data received to the signed in [Donor].
     */
    public suspend fun saveDonor(donor: Donor): Resource<Donor> {
        return updateDonor(donor)
    }

    /**
     * Updates the [donor] received in the server.
     */
    private suspend fun updateDonor(donor: Donor): Resource<Donor> {
        // Update the cached donor to the donor object being saved.
        this.donor = donor
        return getResource { donorService.updateDonor(donor) }
    }

    /**
     * Links the donor to Kindo on the server.
     */
    public suspend fun linkDonorToKindo(donorId: String, kindoLinkParams: KindoDonorLinkParams): Resource<Unit> {
        return getResource { donorLinkService.linkDonorToKindo(donorId, kindoLinkParams) }
    }

}