package supergenerous.app.donor.aml.viewmodel

import com.hipsheep.kore.error.AppErrorCode
import com.hipsheep.kore.resource.Resource.Success
import com.hipsheep.kore.viewmodel.BaseViewModel
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.coroutine.asLiveData
import com.hipsheep.kore.viewmodel.event.Event
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValueAsync
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.id.IdDocument.*
import com.supergenerous.common.id.IdDocument.Type.*
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate
import supergenerous.app.donor.aml.view.amlInfoScreen
import supergenerous.app.donor.donor.DonorRepository
import supergenerous.app.donor.setup.model.SetupError.*

/**
 * [ViewModel] that provides data to the [amlInfoScreen] screen.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
// TODO: Remove this once the ID verification is working for all donors and everyone that signed up before releasing that went through the process already
public class AmlInfoViewModel(

    private val donorRepo: DonorRepository

) : BaseViewModel() {

    /**
     * Backing property for [saveSuccessEvent].
     */
    private val _saveSuccessfulEvent = MutableLiveData<Event<Unit>>()
    /**
     * Observable that an [Event] when the AML info is saved successfully through [saveAmlInfo].
     */
    public val saveSuccessEvent: LiveData<Event<Unit>> = _saveSuccessfulEvent

    /**
     * Observable that receives updates when the data of the currently signed in [Donor] changes.
     */
    public val donor: LiveData<Donor> = donorRepo.getDonorUpdates()
            .filter { resource ->
                // TODO: Careful when doing this as it sends an error when the user signs out
                //                if (resource is Error) {
                //                    reportError(resource)
                //                }

                resource is Success
            }.map { resource -> (resource as Success).data }
            .asLiveData()


    /**
     * @see [DonorRepository.saveGovId]
     */
    public fun saveAmlInfo(dateOfBirth: LocalDate?,
                           govIdType: Type?,
                           govIdNumber: String?,
                           driverLicenceVersion: String?,
                           govIdExpiryDate: LocalDate?) {
        val govIdNumberTrim = govIdNumber?.trim()

        val errors = mutableSetOf<AppErrorCode>()

        if (dateOfBirth == null) {
            errors += DATE_OF_BIRTH_MISSING
        }

        errors += when (govIdType) {
            null -> setOf(GOV_ID_TYPE_MISSING)
            DRIVER_LICENCE_NZ -> validateDriverLicence(number = govIdNumberTrim, version = driverLicenceVersion)
            PASSPORT -> validatePassport(number = govIdNumberTrim, expiryDate = govIdExpiryDate)
        }

        if (errors.isNotEmpty()) {
            reportErrors(errors)
            return
        }

        val govId = when (govIdType!!) {
            DRIVER_LICENCE_NZ -> DriverLicenceNz(number = govIdNumberTrim!!, version = driverLicenceVersion!!.trim())
            PASSPORT -> Passport(number = govIdNumberTrim!!, expiryDate = govIdExpiryDate!!)
        }

        launch {
            executeAction { donorRepo.saveGovId(govId, dateOfBirth!!) }?.let {
                _saveSuccessfulEvent.updateValueAsync(Event(Unit))
            }
        }
    }

    /**
     * Validates the driver licence values are valid.
     *
     * @return A `Set` of all the errors that occurred, or an empty `Set` if it is valid.
     */
    private fun validateDriverLicence(number: String?, version: String?): Set<AppErrorCode> {
        return buildSet {
            // Check the number is valid. Should be 2 alphabet characters then 6 numbers exactly.
            when {
                number.isNullOrBlank() -> add(GOV_ID_NUMBER_MISSING)
                !Regex("^[A-Za-z]{2}[0-9]{6}$").matches(number) -> add(DRIVER_LICENCE_NUMBER_INVALID)
            }

            // Check the version is valid. Should be 3 numbers long exactly.
            when {
                version.isNullOrBlank() -> add(DRIVER_LICENCE_VERSION_MISSING)
                !Regex("^[0-9]{3}$").matches(version) -> add(DRIVER_LICENCE_VERSION_INVALID)
            }
        }
    }

    /**
     * Validates the passport values are valid.
     *
     * @return A `Set` of all the errors that occurred, or an empty `Set` if it is valid.
     */
    private fun validatePassport(number: String?, expiryDate: LocalDate?): Set<AppErrorCode> {
        return buildSet {
            // Check the number is valid. Should be 7 or 8 characters exactly.
            when {
                number.isNullOrBlank() -> add(GOV_ID_NUMBER_MISSING)
                number.length < 7 || number.length > 8 -> add(PASSPORT_NUMBER_INVALID)
            }

            // Check the expiry date is valid. // TODO: Make sure it is not expired.
            if (expiryDate == null) {
                add(PASSPORT_EXPIRY_DATE_MISSING)
            }
        }
    }

}