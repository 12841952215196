package com.supergenerous.common.tax.region

/**
 * Tax regions supported by the system.
 */
public enum class TaxRegion(

    /**
     * Percentage returned from donation rebate claims.
     */
    public val rebateClaimPercent: Float,

    /**
     * The minimum amount a donation must be to be able to claim a rebate.
     */
    public val donationMinClaimAmount: Int,

    /**
     * The amount of tax years in the past within which a donation can be claimed from the tax authority.
     *
     * If the donation was made more than this amount of tax years in the past then it can't be claimed.
     */
    public val rebateClaimValidTaxYearsNum: Int,

    /**
     * The number of days before a rebate claim times out.
     */
    public val rebateClaimTimeoutDays: Int,

    /**
     * Rebates amount expected over which an AML check is required.
     *
     * Any amount equal or under this threshold doesn't require an AML check as per the AML exemption obtained by
     * SG from the required government organisation (e.g., the Minister of Justice in NZ).
     */
    public val amlExemptionRebateThreshold: Int,

    /**
     * Number of months during which the exemption applies for the [amlExemptionRebateThreshold].
     *
     * This means that over this period, the exemption applies for a donor as long as we have not claimed more than
     * [amlExemptionRebateThreshold] in rebates.
     */
    public val amlExemptionMonthsThreshold: Int,

    /**
     * Tax added to the service fee.
     *
     * E.g. For 15% GST in NZ the value is 0.15.
     */
    public val serviceFeeAddedTaxPercent: Float

) {

    NZ(rebateClaimPercent = 0.333333f,
       donationMinClaimAmount = 5,
       rebateClaimValidTaxYearsNum = 4,
       rebateClaimTimeoutDays = 84, // 12 weeks
       amlExemptionRebateThreshold = 10_000,
       amlExemptionMonthsThreshold = 12,
       serviceFeeAddedTaxPercent = 0.15f)

}