package supergenerous.app.donor.util.component

import kotlinx.css.*
import kotlinx.html.DIV
import react.RBuilder
import styled.StyledDOMBuilder
import styled.css
import styled.styledDiv
import supergenerous.app.core.util.*

/**
 * Container that renders [buttons] at the bottom of the screen and organises/resizes them appropriately.
 */
public fun RBuilder.buttonFooter(buttons: StyledDOMBuilder<DIV>.() -> Unit) {
    styledDiv {
        css {
            marginTop = 64.px
            marginBottom = 64.px

            display = Display.flex
            alignContent(AlignContent.end)
            justifyContent = JustifyContent.end
            justifySelf(JustifySelf.end)
            alignItems = Align.center

            gap = 24.px
            flexWrap = FlexWrap.wrap

            // Reverse buttons order on mobile to show the "primary" (e.g., submit) one first
            mobileScreen {
                width = 100.pct
                flexDirection = FlexDirection.columnReverse
                flexWrap = FlexWrap.nowrap
            }
        }

        buttons()
    }
}