package com.supergenerous.common.donation

import com.supergenerous.common.data.Dbo
import com.supergenerous.common.data.DboDataModel
import com.supergenerous.common.donation.platform.DonationPlatform
import com.supergenerous.common.donation.receipt.toReceiptId
import com.supergenerous.common.donee.DoneeBasicInfoDbo
import com.supergenerous.common.donor.DonorBasicInfoDbo
import com.supergenerous.common.file.CloudFileDbo
import com.supergenerous.common.rebate.Rebate
import com.supergenerous.common.rebate.RebateClaimer
import com.supergenerous.common.util.Timestamp
import kotlinx.datetime.toLocalDate
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [Donation] class.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class DonationDbo(

    override var id: String? = null,

    override var creationTimestamp: Timestamp? = null,

    override var lastUpdateTimestamp: Timestamp? = null,

    val donor: DonorBasicInfoDbo? = null,

    val donee: DoneeBasicInfoDbo? = null,

    val amount: Float? = null,

    val date: String? = null,

    val recurring: Boolean? = null,

    val receipt: CloudFileDbo? = null,

    val receiptId: String? = null,

    val donationPlatform: DonationPlatform? = null,

    // Property name doesn't start with "is" because of Firebase issue: https://github.com/firebase/firebase-admin-java/issues/472
    // TODO: Change when the issue is fixed on Firebase
    val verified: Boolean? = null,

    // Property name doesn't start with "is" because of Firebase issue: https://github.com/firebase/firebase-admin-java/issues/472
    // TODO: Change when the issue is fixed on Firebase
    val valid: Boolean? = null,

    val comments: String? = null,

    val rebateClaimer: RebateClaimer? = null,

    val importedFromCrm: Boolean? = null

) : DboDataModel<Donation> {

    override fun toDto(): Donation {
        return toDtoInternal().copy(creationTimestamp = creationTimestamp!!,
                                    lastUpdateTimestamp = lastUpdateTimestamp!!)
    }

    /**
     * Converts the [DonationDbo] to a [Donation] ignoring the timestamps.
     *
     * This method should be used when parsing the data for the purpose of saving/reading to/from another data model,
     * e.g., [Rebate.donations].
     */
    internal fun toDtoInternal(): Donation {
        return Donation(id = id!!,
                        donor = donor!!.toDto(),
                        donee = donee!!.toDto(),
                        amount = amount!!,
                        date = date!!.toLocalDate(),
                        isRecurring = recurring,
                        receipt = receipt?.toDto(),
                        receiptId = receiptId?.toReceiptId(),
                        donationPlatform = donationPlatform,
                        isVerified = verified!!,
                        isValid = valid ?: true,
                        comments = comments,
                        rebateClaimer = rebateClaimer!!,
                        isImportedFromCrm = importedFromCrm ?: false)
    }

}