package com.supergenerous.common.invoice

import com.supergenerous.common.data.Dto
import kotlinx.serialization.Serializable

/**
 * Invoice sent to a customer for services provided by SG.
 *
 * Invoices are created by an external system (e.g., Xero).
 *
 * @author Murray Kinsman (murray@supergenerous.com)
 */
@Serializable
public data class Invoice(

    /**
     * ID of the invoice in the external system that created it.
     */
    val extId: String,

    /**
     * URL used to access the invoice online.
     */
    val url: String

) : Dto<InvoiceDbo> {

    override fun toDbo(): InvoiceDbo {
        return InvoiceDbo(extId = extId, url = url)
    }

}