package com.hipsheep.kore.model.network

/**
 * Commonly used HTTP header keys and values.
 */
public object Header {

    public object Key {

        public const val AUTHORIZATION: String = "Authorization"

        public const val CONTENT_TYPE: String = "Content-Type"

        public const val ACCEPT: String = "Accept"

        public const val ORIGIN: String = "Origin"

        public const val COOKIE: String = "Cookie"

        public const val RETRY_AFTER: String = "Retry-After"

        // CORS policy headers
        public const val ACCESS_CONTROL_ALLOW_ORIGIN: String = "Access-Control-Allow-Origin"
        public const val ACCESS_CONTROL_ALLOW_METHODS: String = "Access-Control-Allow-Methods"
        public const val ACCESS_CONTROL_ALLOW_HEADERS: String = "Access-Control-Allow-Headers"
        public const val ACCESS_CONTROL_MAX_AGE: String = "Access-Control-Max-Age"

    }

    public object Value {

        public const val BEARER: String = "Bearer"
        public const val BASIC: String = "Basic"

        // TODO: Move these to a ContentType enum??
        public const val APP_JSON: String = "application/json"
        public const val APP_FORM_URLENCODED: String = "application/x-www-form-urlencoded"
        public const val APP_PDF: String = "application/pdf"
        public const val TEXT_CSV: String = "text/csv"
        public const val MULTIPART_FORM_DATA: String = "multipart/form-data"

    }

}