package supergenerous.app.core.auth.model

import com.hipsheep.kore.error.AppErrorCode

/**
 * The authentication errors that can happen on the app.
 */
public enum class AuthError : AppErrorCode {

    /**
     * Failed to load auth session on [AuthRepository.loadAuthSession].
     */
    SESSION_LOAD_FAILED,

    /**
     * The user tried to sign in with an email address that is not registered in the system.
     */
    USER_NOT_FOUND,

    /**
     * The user tried to sign in to an existing account but used the wrong password.
     */
    WRONG_PASSWORD,

    /**
     * The email is already in use by an already registered account in the system.
     *
     * This only occurs when the user is using email/password to sign up.
     */
    EMAIL_ALREADY_IN_USE,

    /**
     * The password does not satisfy the auth requirements.
     */
    WEAK_PASSWORD,

    /**
     * The user's email address for this provider is already in use by a different account registered in our system.
     *
     * This only occurs when the user is using a provider to sign up (i.e., not email/password).
     */
    ACCOUNT_EXISTS_WITH_DIFFERENT_PROVIDER,

    /**
     * The user's auth has been disabled.
     */
    USER_DISABLED,

    /**
     * The submitted email was incorrectly formatted.
     */
    INVALID_EMAIL,

    /**
     * Unknown network failure.
     */
    NETWORK_REQUEST_FAILED,

    /**
     * Failed to sign out.
     */
    SIGN_OUT_FAILED,

    /**
     * The user is not signed in.
     */
    NOT_SIGNED_IN,

    /**
     * An unknown error occurred.
     */
    OTHER

}