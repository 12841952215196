package supergenerous.app.donor.donor

import com.hipsheep.kore.model.network.Header.Key.CONTENT_TYPE
import com.hipsheep.kore.model.network.Header.Value.APP_JSON
import com.hipsheep.kore.model.network.HttpClient
import com.hipsheep.kore.model.network.HttpMethod.POST
import com.hipsheep.kore.model.network.HttpService
import com.hipsheep.kore.model.network.Response
import com.hipsheep.kore.model.network.sendRequest
import com.supergenerous.common.kindo.KindoDonorLink
import com.supergenerous.common.util.MILLIS_IN_SECOND
import supergenerous.app.core.util.EnvConfig
import supergenerous.app.donor.kindo.model.KindoDonorLinkParams

/**
 * Service used to manage link requests between SG and Kindo.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
// TODO: Merge this into the DonorService once all endpoints are provided by the same backend
public class DonorLinkService(

    /**
     * Time to wait for a request before timing out.
     *
     * This is a band-aid solution to requests very infrequently timing out when POSTing to `/kindo`.
     */
    timeoutMs: Long = 30 * MILLIS_IN_SECOND

) : HttpService(httpClient = HttpClient(timeoutMs = timeoutMs)) {

    /**
     * Links the donor from Kindo to SG by making a call to the SG server.
     */
    public suspend fun linkDonorToKindo(donorId: String, kindoLinkParams: KindoDonorLinkParams): Response<Unit> {
        return httpClient.sendRequest(httpMethod = POST,
                                      url = "${EnvConfig.gcpFunctionsUrlBase}/kindo",
                                      headers = mapOf(CONTENT_TYPE to listOf(APP_JSON)),
                                      httpBody = KindoDonorLink(donorId = donorId,
                                                                isSuccess = true,
                                                                type = kindoLinkParams.operationType,
                                                                referrer = kindoLinkParams.referrer,
                                                                referralId = kindoLinkParams.referralId,
                                                                doneeExtId = kindoLinkParams.doneeExtId,
                                                                donorExtId = kindoLinkParams.donorExtId,
                                                                donorEmail = kindoLinkParams.donorEmail,
                                                                donorFirstName = kindoLinkParams.donorName.firstName,
                                                                donorLastName = kindoLinkParams.donorName.lastName,
                                                                checksum = kindoLinkParams.checksum))
    }

}