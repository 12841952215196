package com.supergenerous.common.name

import com.supergenerous.common.data.Dbo
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [FullName] class.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class FullNameDbo(

    var firstName: String? = null,
    var middleName: String? = null,
    var lastName: String? = null

) : Dbo<FullName> {

    override fun toDto(): FullName {
        return FullName(firstName = firstName!!,
                        middleName = middleName,
                        lastName = lastName!!)
    }

}