package supergenerous.app.core.search

import com.hipsheep.kore.model.network.Response
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeBasicInfo
import supergenerous.app.core.search.model.SearchCollection.DONEES
import supergenerous.app.core.search.model.SearchField.*
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.core.search.typesense.TypesenseDonee
import supergenerous.app.core.search.typesense.TypesenseService

/**
 * Service used to search for donees by name.
 */
public class DoneeSearchService : TypesenseService<DoneeBasicInfo>(collection = DONEES) {

    /**
     * Searches for donees with [namePartial] in any of their known names. If [type] is specified then only searches
     * for donees of that type.
     */
    public suspend fun searchDonees(namePartial: String,
                                    type: Donee.Type? = null): Response<List<TextSearchResult<DoneeBasicInfo>>> {
        return search(queryFields = setOf(NAME, LEGAL_NAME, OTHER_NAMES),
                      query = namePartial,
                      filters = type?.let { mapOf(TYPE to type.name) } ?: emptyMap())
    }

    override fun convertDocToData(doc: TypesenseDonee): DoneeBasicInfo {
        return DoneeBasicInfo(id = doc.id,
                              type = doc.type,
                              name = doc.name)
    }

}