package supergenerous.app.core.component

import com.hipsheep.kore.viewmodel.event.Event
import com.hipsheep.kore.viewmodel.lifecycle.LifecycleOwner
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.Observer
import react.Props
import react.RComponent
import react.State

/**
 * Component that is lifecycle aware by implementing [LifecycleOwner], and allows its children
 * to observe changes in [LiveData] through the [observe] method.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
// TODO: Move this to kore
public abstract class LifecycleOwnerComponent<P : Props, S : State> : RComponent<P, S>, LifecycleOwner {

    override val isActive: Boolean
        get() = isMounted

    /**
     * `true` if the [LifecycleOwnerComponent] is mounted, or `false` otherwise.
     *
     * We use this `private` property so we don't have to change [isActive] to be a `var`
     * and expose its value to be changed outside this class.
     */
    private var isMounted: Boolean = false

    /**
     * Map of [LiveData] to the list of [Observer]s that were added to it inside this component.
     */
    private val liveDataObservers = mutableMapOf<LiveData<*>, MutableList<Observer<*>>>()

    public constructor() : super()
    public constructor(props: P) : super(props)

    override fun componentDidMount() {
        isMounted = true
    }

    override fun componentWillUnmount() {
        isMounted = false

        // Remove all the observers that were added to all the LiveData objects inside this component
        for ((liveData, observers) in liveDataObservers) {
            observers.forEach { liveData.removeObserver(it) }
        }

        liveDataObservers.clear()
    }

    /**
     * Does the same as [LiveData.observe], and it also adds the [LiveData] and [observer] to the [liveDataObservers]
     * list so the observers can be removed from the [LiveData] when the component is unmounted.
     */
    protected fun <T> LiveData<T>.observe(observer: Observer<T>) {
        if (!liveDataObservers.containsKey(this)) {
            // If the key is not in the map yet, add it first or the "add" step will fail
            liveDataObservers[this] = mutableListOf()
        }

        liveDataObservers[this]?.add(observer)

        observe(owner = this@LifecycleOwnerComponent, observer = observer)
    }

    /**
     * Observes changes in an [Event] and reports its content through the [observer] if the [Event] was not handled yet.
     *
     * This method works the same as the [observe] method above (with the extra functionality mentioned above).
     */
    protected fun <T> LiveData<Event<T>>.observeEvent(observer: Observer<T>) {
        observe { event -> event.getContentIfNotHandled()?.let { observer(it) } }
    }

}