package supergenerous.app.core.util

import kotlinx.html.LABEL
import kotlinx.html.Tag

/**
 * Adds the `htmlFor` attribute to the `label` with the [inputId] as its value.
 *
 * We have to use this rather than the [LABEL.htmlFor] property because it uses the `for` attribute instead of the
 * `htmlFor` one, which is necessary for React JSX to link the label to the input.
 */
public fun LABEL.htmlFor(inputId: String) {
    attributes["htmlFor"] = inputId
}

/**
 * A label to show in place of an element for people with accessibility needs.
 */
public fun Tag.ariaLabel(label: String) {
    attributes["aria-label"] = label
}