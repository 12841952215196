package supergenerous.app.donor.donation

import com.hipsheep.kore.model.network.Response
import com.supergenerous.common.donation.Donation
import com.supergenerous.common.donation.DonationDbo
import com.supergenerous.common.firebase.DONATIONS
import com.supergenerous.common.firebase.DbField.Name.DONOR_ID
import com.supergenerous.common.firebase.DbOperator.EQUAL
import com.supergenerous.common.firebase.DbQuery
import com.supergenerous.common.firebase.FirebaseAuth
import com.supergenerous.common.firebase.FirestoreClientService
import com.supergenerous.common.firebase.FirestoreCollection
import firebase.auth

/**
 * Service used to access [Donation] data.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class DonationService(

    collection: FirestoreCollection<DonationDbo> = FirestoreCollection.DONATIONS

) : FirestoreClientService<Donation, DonationDbo>(collection, firebaseAuth = FirebaseAuth(auth())) {

    /**
     * Returns the [Donation]s of the currently signed in donor.
     */
    public suspend fun getDonations(): Response<List<Donation>> {
        return getDataAuth { userId ->
            getDocs(query = DbQuery(field = DONOR_ID, operator = EQUAL, value = userId))
        }
    }

}