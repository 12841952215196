package supergenerous.app.core.location

import com.hipsheep.kore.model.repo.Repository
import com.hipsheep.kore.resource.Resource
import com.hipsheep.kore.resource.Resource.Success
import supergenerous.app.core.search.model.TextSearchResult

/**
 * [Repository] used to manage location data.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public class LocationRepository(

    locationApiKey: String,

    private val addressService: AddressService = AddressService(locationApiKey),
    private val ipService: IpService = IpService()

) : Repository() {

    /**
     * Searches for addresses using the [query] received, and returns the ones that match.
     *
     * If the length of the [query] is less than 6 characters then no search is performed. This is done to save money
     * on searches that will return too many results because we use a third-party system for it.
     */
    public suspend fun searchAddress(query: String): Resource<List<TextSearchResult<String>>> {
        if (query.length < 6) {
            return Success(listOf())
        }

        return getResource(getServerData = { addressService.searchAddress(query) },
                           convertRespToResult = { addressResult ->
                               addressResult.addresses.map { address ->
                                   TextSearchResult(id = address.a,
                                                    text = address.a,
                                                    textMatch = null,
                                                    data = address.a)
                               }
                           })
    }

    /**
     * Gets the IP address of the client device and returns it, or returns an error if it couldn't be obtained (e.g.,
     * if the user is using an ad-blocker).
     */
    public suspend fun getIpAddress(): Resource<String> {
        return getResource { ipService.getIpAddress() }
    }
}