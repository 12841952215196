package supergenerous.app.donor.util

/**
 * List of URLs used in the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class Url(valueStr: String) : com.supergenerous.common.network.Url {

    FIND_IRD_NUMBER("https://www.ird.govt.nz/managing-my-tax/ird-numbers/find-my-ird-number");


    override val value: String = valueStr

    /*
     * Inner types
     */

    /**
     * List of URL paths used in the app.
     */
    public enum class Path(valueStr: String) : com.supergenerous.common.network.Url.Path {

        SETUP("/setup"),
        SETUP_PERSONAL_INFO("${SETUP.value}/personal-info"),
        SETUP_CONTACT_INFO("${SETUP.value}/contact-info"),
        SETUP_TAX_ID("${SETUP.value}/tax-id"),
        SETUP_GOV_ID("${SETUP.value}/gov-id"),
        SETUP_AUTHORITY("${SETUP.value}/authority"),
        SETUP_DONEE_TYPES("${SETUP.value}/donee-types"),
        SETUP_CHARITIES("${SETUP.value}/charities"),
        SETUP_RELIGIOUS_ORGS("${SETUP.value}/religious-orgs"),
        SETUP_SCHOOLS("${SETUP.value}/schools"),
        SETUP_BANK_ACCOUNT("${SETUP.value}/bank-account"),
        SETUP_END("${SETUP.value}/end"),

        DASHBOARD("/dashboard"),
        PERSONAL_INFO("/personal-info"),
        PERSONAL_INFO_EDIT("${PERSONAL_INFO.value}/edit"),

        AML("/aml"),

        LINK("/link"),
        LINK_KINDO("${LINK.value}/kindo");


        override val value: String = valueStr

    }

}