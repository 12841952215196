package com.hipsheep.kore.util

internal actual val Any.logTag: String
    // Remove the "$Companion" suffix to make the tag clearer
    get() = this::class.js.name.removeSuffix("\$Companion")

internal actual fun log(logger: ILogger, message: Any?, cause: Throwable?, level: LogLevel) {
    var printMessage = createLogMessage(logger, message, level)

    // TODO: Add stacktrace to log message
    cause?.let { printMessage += "\n${it.message}" }

    console.log(printMessage)
}

public actual inline fun log(logger: ILogger, message: () -> Any, level: LogLevel) {
    console.log(createLogMessage(logger, message(), level))
}

/**
 * Creates a log message with the [logger], [message] and [level] received and returns it.
 */
public fun createLogMessage(logger: ILogger, message: Any?, level: LogLevel): String {
    // TODO: logger.tag makes the app crash sometimes with error "Cannot read property 'tag' of undefined"
//    return "[$level] ${logger.tag}: $message"
    return "[$level] $message"
}