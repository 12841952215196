package supergenerous.app.donor.analytics

import com.supergenerous.common.donor.DonorProvider
import com.supergenerous.common.user.User
import kotlinx.browser.window
import supergenerous.app.core.analytics.AnalyticsEvent
import supergenerous.app.core.analytics.AnalyticsService

/**
 * Analytics service used to send events to Hubspot.
 *
 * See [https://developers.hubspot.com/docs/api/events/tracking-code]
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class HubspotAnalyticsService : AnalyticsService {

    /**
     * The `window` property tracked by Hubspot analytics. When an event is pushed into it, Hubspot analytics then pops
     * that event off the queue and processes it.
     *
     * Labelled as `_hsq` in their docs.
     */
    private val eventQueue: Array<Array<*>>
        get() {
            if (window.asDynamic()._hsq == null) {
                window.asDynamic()._hsq = js("[]")
            }
            return window.asDynamic()._hsq as Array<Array<*>>
        }

    /**
     * Key used to track a page view event.
     */
    private val trackPageViewKey = "trackPageView"


    override fun logEvent(event: AnalyticsEvent) {
        when (event) {
            is AnalyticsEvent.ScreenView -> {
                /*
                 * For single page applications we need to programmatically set the path and then tell hubspot to track
                 * the page view.
                 *
                 * JS equivalent code:
                 * const _hsq = window._hsq = window._hsq || []
                 * _hsq.push(["setPath", event.path])
                 * _hsq.push(["trackPageView"])
                 */
                pushToEventQueue(arrayOf("setPath", event.path))
                pushToEventQueue(arrayOf(trackPageViewKey))
            }
            else -> {
                // Ignore other events for now
            }
        }
    }

    override fun setUser(user: User, donorProvider: DonorProvider?) {
        val contact = js("{}")
        contact.email = user.email
        // Set the is_donor property so the "donor welcome" email is triggered by Hubspot
        contact.is_donor = true
        // Set the provider property so the correct "donor welcome" email/variation is triggered by Hubspot
        donorProvider?.let { contact.provider = donorProvider.name }

        /*
         * Identify the user that is logged in by their email.
         *
         * JS equivalent code:
         * const _hsq = window._hsq = window._hsq || []
         * _hsq.push(["identify", { email: user.email }])
         */
        pushToEventQueue(arrayOf("identify", contact))

        // Track a page view after setting the user so that tracking data gets immediately sent to Hubspot with the
        // user's details. Otherwise, the route must change before the user data gets sent.
        pushToEventQueue(arrayOf(trackPageViewKey))
    }

    /**
     * Pushes the [item] to [eventQueue].
     */
    private fun pushToEventQueue(item: Array<*>) {
        eventQueue.asDynamic().push(item)
    }

}