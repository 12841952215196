package com.supergenerous.common.kindo

import com.supergenerous.common.network.Url

/**
 * Query parameters sent from Kindo.
 */
public enum class KindoParam(

    override val value: String

) : Url.Param {

    TYPE(KindoProps.TYPE),
    REFERRER(KindoProps.REFERRER),
    REFERRAL_ID(KindoProps.REFERRAL_ID),
    DONEE_EXT_ID(KindoProps.DONEE_EXT_ID),
    DONOR_EXT_ID(KindoProps.DONOR_EXT_ID),
    DONOR_EMAIL(KindoProps.DONOR_EMAIL),
    DONOR_FIRST_NAME(KindoProps.DONOR_FIRST_NAME),
    DONOR_LAST_NAME(KindoProps.DONOR_LAST_NAME),
    CHECKSUM(KindoProps.CHECKSUM)

}