package supergenerous.app.core.component.menu

/**
 * An item to display in a [Menu] component.
 */
public data class MenuItem(

    /**
     * A unique value to reference the [MenuItem].
     */
    val value: String,

    /**
     * The text to display in the menu.
     */
    val label: String

)