package supergenerous.app.donor.setup.view

import com.hipsheep.kore.viewmodel.ViewModel
import kotlinx.css.Display
import kotlinx.css.display
import kotlinx.css.margin
import kotlinx.css.marginBottom
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import react.Props
import react.PropsWithChildren
import react.RBuilder
import react.RHandler
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.button.ButtonType
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.contentSection
import supergenerous.app.core.component.dividerHorizontal
import supergenerous.app.core.component.progress.progressBar
import supergenerous.app.core.component.subheading1
import supergenerous.app.core.util.AlignContent
import supergenerous.app.core.util.alignContent
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.viewmodel.SetupViewModel
import supergenerous.app.donor.util.component.buttonFooter

/**
 * Container used for the screens that are part of the setup process and receive input from the user.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class SetupInputContainer : LifecycleOwnerComponent<SetupInputContainerProps, SetupInputContainerState>() {

    override fun SetupInputContainerState.init() {
        setupStep = 0
    }

    /**
     * Total number of steps in the setup process.
     */
    private val numberOfSteps = SetupStep.NUM_STEPS


    override fun RBuilder.render() {
        val setupStep = SetupStep.values()[state.setupStep]

        styledDiv {
            css {
                width = 100.pct
                display = Display.inlineGrid
            }

            styledDiv {
                css {
                    marginBottom = 56.px
                }

                progressBar(
                    currentStep = setupStep.stepNumber,
                    numSteps = numberOfSteps
                )
            }

            contentSection {
                styledDiv {
                    css {
                        width = 100.pct
                        display = Display.inlineGrid
                        alignContent(AlignContent.start)
                    }

                    body1 { +"Step ${setupStep.stepName}" }

                    subheading1 {
                        css {
                            margin(top = 16.px)
                        }

                        props.title(this)
                    }

                    body1 {
                        css {
                            margin(top = 8.px)
                        }

                        props.subtitle(this)
                    }

                    dividerHorizontal()

                    styledDiv {
                        css {
                            // Show children in vertical order
                            display = Display.inlineGrid
                        }

                        props.children()
                    }
                }
            }

            // "Next" button shown at the bottom of the screen
            buttonFooter {
                props.onBackBtnClick?.let {
                    button(
                        type = ButtonType.SECONDARY,
                        label = "BACK",
                        onClick = { it.invoke() }
                    )
                }

                button(
                    label = "NEXT",
                    showLoadingIcon = state.isDataLoading,
                    onClick = props.onNextBtnClick
                )
            }
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        props.setupViewModel.isActionInProgress.observe { setState { isDataLoading = it } }
        props.setupViewModel.setupProgress.observe { setState { setupStep = it } }
    }

}

/**
 * Properties used by the [SetupInputContainer] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface SetupInputContainerProps : PropsWithChildren {

    /**
     * Function that creates the title shown in screen.
     *
     * We don't use a `String` here because the title might have highlighted parts.
     */
    var title: (RBuilder) -> Unit

    /**
     * Function that creates the subtitle shown on the screen, or `null` if no subtitle should be shown.
     */
    var subtitle: (RBuilder) -> Unit

    /**
     * [ViewModel] shared between the setup process screens.
     */
    var setupViewModel: SetupViewModel

    /**
     * Function called when the "next" button is clicked.
     */
    var onNextBtnClick: () -> Unit

    /**
     * Function called when the back button is clicked, hides the back button if `null`
     *
     * TODO: Handle this in a nicer way
     */
    var onBackBtnClick: (() -> Unit)?

}

/**
 * State of the [SetupInputContainer] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface SetupInputContainerState : State {

    /**
     * `true` if there is data currently being loaded or saved in the screen, or `false` if there isn't.
     */
    var isDataLoading: Boolean

    /**
     * The setup number of the setup screen
     */
    var setupStep: Int

}

/**
 * Renders a [SetupInputContainer] component.
 */
public fun RBuilder.setupInputContainer(title: RBuilder.() -> Unit,
                                        subtitle: RBuilder.() -> Unit,
                                        setupViewModel: SetupViewModel,
                                        onNextBtnClick: () -> Unit,
                                        onBackBtnClick: (() -> Unit)?,
                                        handler: RHandler<Props>) {
    child(SetupInputContainer::class) {
        attrs {
            this.title = title
            this.subtitle = subtitle
            this.setupViewModel = setupViewModel
            this.onNextBtnClick = onNextBtnClick
            this.onBackBtnClick = onBackBtnClick
        }

        handler()
    }
}