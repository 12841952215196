package com.supergenerous.common.firebase

import com.hipsheep.kore.model.network.HttpStatusCode.UNAUTHORIZED
import com.hipsheep.kore.model.network.Response
import com.supergenerous.common.data.DboDataModel
import com.supergenerous.common.data.DtoDataModel

/**
 * Base class for all services used to manage the app's data through a Firestore DB from client apps.
 */
public abstract class FirestoreClientService<DTO : DtoDataModel<DBO>, DBO : DboDataModel<DTO>>(

    /**
     * Collection used to manage the Firestore data.
     */
    collection: FirestoreCollection<DBO>,

    /**
     * Firebase authentication data.
     */
    private val firebaseAuth: FirebaseAuth

) : FirestoreService<DTO, DBO>(collection) {

    /**
     * Returns the data from [getData] if the user is signed in, or an [UNAUTHORIZED] error if they are not.
     */
    protected suspend fun <T> getDataAuth(getData: suspend (userId: String) -> Response<T>): Response<T> {
        val userId = firebaseAuth.user?.id

        return if (userId != null) {
            getData(userId)
        } else {
            Response(UNAUTHORIZED, errorBody = "The user is not signed in")
        }
    }

}