package supergenerous.app.core.res.image

/**
 * An [Icon] that can be used throughout apps. [Icon]s are images that can be used alongside or instead of text, and
 * should have similar emphasis as the text it is alongside or replacing. If the [Icon] is not used like this, then
 * consider whether it should be an [Image] instead.
 *
 * If there is text alongside the [Icon], removing the [Icon] should make no difference to how the user interacts with
 * the page. i.e., it is used as decoration.
 *
 * If there is no text alongside the [Icon], it should be immediately obvious to the user as to what the [Icon]
 * represents. If it doesn't then there should either be accompanying text, or other visual aids to help the user.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
public interface Icon {

    /**
     * The SVG to use as the icon.
     */
    public val svgFile: SvgFile

    /**
     * The label to add for `aria-label` HTML attribute.
     */
    public val label: String

}

/**
 * List of all the icons used across apps.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class CoreIcon(

    override val svgFile: SvgFile,
    override val label: String

) : Icon {

    /**
     * Indicates "back" navigation.
     */
    ARROW_BACK(iconArrowBack, "Back"),

    /**
     * Filled circle with a hollow 'X'. Used to indicate removing or clearing a field.
     */
    CLEAR(iconCancel, "Clear"),

    /**
     * 'X' icon. Used to indicate closing the view the user is looking at, e.g. close dialog.
     */
    CLOSE(iconClose, "Close"),

    /**
     * Filled circle with a hollow '?'.
     */
    QUESTION_CIRCLE(iconQuestionCircle, "Help"),

    /**
     * Filled triangle with a white exclamation mark inside.
     */
    WARNING(iconWarning, "Warning"),

    /**
     * The Google logo.
     */
    GOOGLE_LOGO(googleLogo, "Google"),

    /**
     * Icon used to hide an element or mask text.
     */
    HIDE(iconHide, "Hide"),

    /**
     * Icon used to show an element or unmask text.
     */
    SHOW(iconShow, "Show")

}

/*
 * Any svg added here will get added to the js bundle generated by Webpack. This means the client side code does not
 * need to make a request for the image, and we can apply styling to the svg through css. Use [RBuilder.svg(...)] to
 * render these.
 *
 * Note: Remove the `width` and `height` attributes from the imported SVG. These should be set via styling within the
 * code where the SVG is used.
 *
 * Note: Be aware that the bundler runs some optimizations on the module.
 * TODO: Copy these files to child projects.
 */

/**
 * The icon to use to indicate "back" functionality.
 */
@JsModule("images/icon_arrow_back.svg")
private external val iconArrowBack: SvgFile

/**
 * The icon to use for clearing a field, i.e. filled circle icon with transparent 'X'.
 *
 * Inherits the css 'color' attribute for its color.
 */
@JsModule("images/icon_cancel.svg")
private external val iconCancel: SvgFile

/**
 * The icon to use for closing a section of the app, i.e. 'X' icon.
 *
 * Inherits the css 'color' attribute for its color.
 */
@JsModule("images/icon_close.svg")
private external val iconClose: SvgFile

/**
 * A filled question mark circle icon.
 */
@JsModule("images/icon_question_circle.svg")
private external val iconQuestionCircle: SvgFile

/**
 * A triangle with a white exclamation mark inside.
 *
 * Inherits the css 'color' attribute for the triangle fill color.
 */
@JsModule("images/icon_warning.svg")
private external val iconWarning: SvgFile

/**
 * The Google logo.
 */
@JsModule("images/google_logo.svg")
private external val googleLogo: SvgFile

/**
 * Icon used to show an element or unmask text.
 */
@JsModule("images/icon_show.svg")
private external val iconShow: SvgFile

/**
 * Icon used to hide an element or mask text.
 */
@JsModule("images/icon_hide.svg")
private external val iconHide: SvgFile
