package com.hipsheep.kore.viewmodel

import com.hipsheep.kore.error.AppErrorCode
import com.hipsheep.kore.error.ErrorType
import com.hipsheep.kore.resource.Resource
import com.hipsheep.kore.resource.Resource.*
import com.hipsheep.kore.viewmodel.event.Event
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValueAsync
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import kotlin.coroutines.CoroutineContext

/**
 * Base [ViewModel] that should be extended by all the view models in the app.
 *
 * This [ViewModel] implements [CoroutineScope], and all coroutines created inside it will run
 * using the [Dispatchers.Main] dispatcher (i.e., will run on the main thread). This means you
 * must create new coroutines that run on the background when you have to do intensive work
 * (e.g., IO calls), or change the [CoroutineContext] to use a background dispatcher on the
 * same coroutine.
 */
public abstract class BaseViewModel : ViewModel(), CoroutineScope by MainScope() {

    /**
     * Backing property for [isActionInProgress].
     */
    private val _isActionInProgress = MutableLiveData<Boolean>()
    /**
     * Observable that receives status updates when an action is in progress and when it finishes.
     */
    public val isActionInProgress: LiveData<Boolean> = _isActionInProgress

    /**
     * Backing property for [errors].
     */
    private val _errors = MutableLiveData<Event<Set<ErrorType>>>()
    /**
     * Observable that receives error events from actions triggered in this [ViewModel].
     */
    public val errors: LiveData<Event<Set<ErrorType>>> = _errors


    /**
     * Runs [executeAction] asynchronously inside a new coroutine.
     */
    protected fun <T> executeActionAsync(updateLoadingStatus: Boolean = true, action: suspend () -> Resource<T>) {
        launch { executeAction(updateLoadingStatus, action) }
    }

    /**
     * Executes the [action] received and returns the result if the action completed successfully, or
     * `null` if an error occurs (in this case, the error is sent to the UI through the [errors] observable).
     *
     * If [updateLoadingStatus] is `true`, it sends updates to the [isActionInProgress] observable as the action is
     * being executed.
     */
    protected suspend fun <T> executeAction(updateLoadingStatus: Boolean = true,
                                            action: suspend () -> Resource<T>): T? {
        if (updateLoadingStatus) {
            // Mark data as loading while the action is being executed
            _isActionInProgress.updateValueAsync(true)
        }

        // Execute action
        val resource = action()

        if (updateLoadingStatus) {
            // Mark data as NOT loading when the action completed execution
            _isActionInProgress.updateValueAsync(false)
        }

        return if (resource is Success) {
            resource.data
        } else {
            // If an error occurred then send it to the view so it can be shown
            reportError(resource as Error)

            null
        }
    }

    /**
     * Reports the [error] received to the UI through the [errors] observable.
     */
    protected suspend fun reportError(error: Error) {
        reportErrors(setOf(error.type))
    }

    /**
     * Reports the [error] received to the UI through the [errors] observable.
     */
    protected fun reportError(error: AppErrorCode) {
        reportErrors(setOf(error))
    }

    /**
     * Reports the [errors] received to the UI through the [errors] observable.
     */
    protected fun reportErrors(errors: Set<AppErrorCode>) {
        launch { reportErrors(errors.map { ErrorType.AppError(it) }.toSet()) }
    }

    /**
     * Reports the [error] received to the UI through the [errors] observable.
     */
    protected suspend fun reportError(error: ErrorType) {
        reportErrors(setOf(error))
    }

    /**
     * Reports the [errors] received to the UI through the [errors] observable.
     */
    protected suspend fun reportErrors(errors: Set<ErrorType>) {
        // TODO: Remove setting event to a blank set. This is a work-around for not having observables able to be observed
        //       directly on child components, only at a top level screen.
        _errors.updateValueAsync(Event(setOf()))
        _errors.updateValueAsync(Event(errors))
    }

    override fun onDestroy() {
        super.onDestroy()

        // Cancel this CoroutineScope and all its children
        cancel()
    }

}