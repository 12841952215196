package com.supergenerous.common.network

/**
 * List of URLs used in more than one app.
 */
public enum class CoreUrl(override val value: String) : Url {

    SG_WEBSITE("https://www.supergenerous.co.nz"),
    SG_TOS("${SG_WEBSITE.value}/terms"),
    SG_PRIVACY_POLICY("${SG_WEBSITE.value}/terms"),
    SG_FAQS("${SG_WEBSITE.value}/faqs"),
    SG_CAMPAIGN_TOS("${SG_WEBSITE.value}/promotions-terms");

    /*
     * Inner types
     */

    /**
     * List of URL paths used in more than one app.
     */
    public enum class Path(valueStr: String) : Url.Path {

        ROOT("/"),

        SIGN_UP("/signup"),
        SIGN_IN("/login");

        override val value: String = valueStr

    }

}