package com.supergenerous.common.serialization

import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.id.IdDocument.Type.*
import kotlinx.datetime.toLocalDate
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

/**
 * Serializer used to convert [IdDocument]s to `String`s and vice-versa.
 *
 * This is used when sending/receiving data from the server or when storing/reading from the local DB.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializer(forClass = IdDocument::class)
public object IdDocumentSerializer : KSerializer<IdDocument> {

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(serialName = "sg.IdDocumentSerializer",
                                                                          kind = PrimitiveKind.STRING)

    private const val SEPARATOR = ";"


    @Suppress("PARAMETER_NAME_CHANGED_ON_OVERRIDE")
    override fun serialize(encoder: Encoder, idDoc: IdDocument) {
        encoder.encodeString(convertIdDocToString(idDoc))
    }

    /**
     * Converts the [idDoc] received to a `String` and returns it.
     */
    public fun convertIdDocToString(idDoc: IdDocument): String {
        val idDocData = when (idDoc) {
            is IdDocument.DriverLicenceNz -> listOf(idDoc.type, idDoc.number, idDoc.version)
            is IdDocument.Passport -> listOf(idDoc.type, idDoc.number, idDoc.expiryDate.toString())
        }

        return idDocData.joinToString(separator = SEPARATOR)
    }

    override fun deserialize(decoder: Decoder): IdDocument {
        return convertStringToIdDoc(idDocData = decoder.decodeString())
    }

    /**
     * Converts the [idDocData] string received to an [IdDocument] and returns it.
     */
    public fun convertStringToIdDoc(idDocData: String): IdDocument {
        val (idDocType, idDocValue1, idDocValue2) = idDocData.split(SEPARATOR)

        return when (IdDocument.Type.valueOf(idDocType)) {
            DRIVER_LICENCE_NZ -> IdDocument.DriverLicenceNz(number = idDocValue1, version = idDocValue2)
            PASSPORT -> IdDocument.Passport(number = idDocValue1, expiryDate = idDocValue2.toLocalDate())
        }
    }

}