package com.supergenerous.common.donor

/**
 * List of linking states that a donor can be in for the tax linking process.
 *
 * @author Raymond Feng (raymond@supergenerous.com)
 */
public enum class TaxLinkStatus {

    /**
     * Submitted DTC account creation form to the tax system, waiting for it to be processed.
     */
    DTC_ACCOUNT_CREATION_IN_PROGRESS,

    /**
     * Submitted link account request form to the tax system, waiting for it to be processed.
     */
    LINK_IN_PROGRESS,

    /**
     * The link request submitted on the tax system has expired.
     */
    LINK_REQUEST_EXPIRED,

    /**
     * The link request submission on the tax system has failed.
     */
    LINK_ERROR,

    /**
     * The tax account is linked with SG on the tax system.
     */
    LINKED,

    /**
     * The tax account was unlinked.
     *
     * This value is only set when the account was unlinked after being [LINKED].
     */
    UNLINKED

}