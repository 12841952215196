package supergenerous.app.core.analytics

import supergenerous.app.core.auth.model.AuthProvider

/**
 * List of events that can be logged via the [AnalyticsRepository].
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public sealed class AnalyticsEvent(

    /**
     * Name of the [AnalyticsEvent] sent to the analytics server.
     */
    public val name: String,

    /**
     * The optional parameters to add to the [AnalyticsEvent]. These will be converted to `Json` format when logging them to
     * analytics (or `null` if they are not defined).
     */
    public val params: Map<String, String>? = null

) {

    /*
     * Inner types
     */

    /**
     * [AnalyticsEvent] used to identify when a user signed the authority to act.
     */
    public object AtaSigned : AnalyticsEvent(name = "ata_signed")

    /**
     * [AnalyticsEvent] used to identify when a user performs an authentication action (i.e., sign up/in).
     */
    public data class Auth(

        val action: Action,

        val authProvider: AuthProvider?

    ) : AnalyticsEvent(name = action.toString(), params = mapOf("auth_provider" to (authProvider?.name?.lowercase() ?: ""))) {

        /**
         * Authentication action.
         */
        public enum class Action {

            SIGN_UP, SIGN_IN;

            /**
             * Returns the [Action.name] in lower case.
             */
            override fun toString(): String {
                return name.lowercase()
            }

        }

    }

    /**
     * [AnalyticsEvent] used to identify when a user views a screen.
     */
    public data class ScreenView(

        /**
         * The path section of the URL (e.g., in `app.supergenerous.com/#/setup/donor-info` the path would be
         * `/setup/donor-info`).
         */
        val path: String

    ) : AnalyticsEvent(name = "screen_view", params = mapOf("path" to path))

}

