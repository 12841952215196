package supergenerous.app.donor.setup.view

import com.hipsheep.kore.error.ErrorType
import com.hipsheep.kore.viewmodel.ViewModel
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.disbursement.DisbursementRecipient.Type.*
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.Donee.Type.*
import com.supergenerous.common.donee.DoneeBasicInfo
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.gap
import kotlinx.css.marginTop
import kotlinx.css.px
import react.Props
import react.RBuilder
import react.State
import react.dom.div
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.common.valuePlural
import supergenerous.app.core.common.valueSingular
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.body2
import supergenerous.app.core.component.chip.Chip
import supergenerous.app.core.component.chip.chipSet
import supergenerous.app.core.component.radio.RadioButton
import supergenerous.app.core.component.radio.radioGroup
import supergenerous.app.core.component.textHighlight
import supergenerous.app.core.component.textfield.doneeSearchField
import supergenerous.app.core.component.textfield.selectTextField
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.core.util.AlignContent
import supergenerous.app.core.util.alignContent
import supergenerous.app.core.util.format.TextCapitalization.CAPITALIZE
import supergenerous.app.donor.setup.model.SetupError.*
import supergenerous.app.donor.setup.viewmodel.SetupViewModel

/**
 * Container used for donees selection during the setup process.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class DoneesSelectionScreen : LifecycleOwnerComponent<DoneesSelectionScreenProps, DoneesSelectionScreenState>() {

    override fun DoneesSelectionScreenState.init() {
        doneeSearchResults = emptyList()
        doneesSelected = emptySet()
        childrenNames = emptySet()
        showDisbRecipientError = false
    }

    override fun RBuilder.render() {
        // TODO: Move these initializations to init()
        val doneeTypePlural = props.doneeType.valuePlural
        val doneeTypeSingular = props.doneeType.valueSingular

        val disbRecipientOptions = props.disbRecipientTypes.map {
            val label = when (it) {
                CHARITIES, SCHOOLS, RELIGIOUS_ORGS -> "I want to be super generous and regift it to the $doneeTypePlural I donated to"
                DONOR -> "I want to keep it for myself"
            }

            // Use the enum as the value so we can easily identify the option selected by the user
            // and convert it back to an enum
            RadioButton(label = label, value = it.name)
        }

        // Some donee types need some extra text in the text shown
        val tfLabelPostfix = if (props.doneeType == RELIGIOUS_ORG) " (including suburb)" else ""

        setupInputContainer(
            title = {
                +"Which "
                textHighlight { +doneeTypePlural }
                +" do you donate to?"
            },
            subtitle = {
                +"Please only list the $doneeTypePlural$tfLabelPostfix you know you have donated to in the past four years. This ensures $doneeTypePlural can spend more time continuing their important mahi."
            },
            setupViewModel = props.setupViewModel,
            onNextBtnClick = ::saveData,
            onBackBtnClick = props.onBackBtnClick
        ) {
            val showDisbOptions = state.doneesSelected.isNotEmpty()

            styledDiv {
                css {
                    display = Display.inlineGrid
                    alignContent(AlignContent.start)

                    // Only add the gap when we are showing all the fields below
                    if (showDisbOptions) {
                        gap = 32.px
                    }
                }

                div {
                    doneeSearchField(
                        doneeType = props.doneeType,
                        showTitle = true,
                        searchResults = state.doneeSearchResults,
                        showInputAsResult = true,
                        errorMessage = state.doneesSelectionError,
                        onTextChange = { text ->
                            // Reset error message when the user starts typing
                            setState { doneesSelectionError = null }

                            props.setupViewModel.searchDonees(namePartial = text,
                                                              doneeType = props.doneeType)
                        },
                        onDoneeSelect = { donee ->
                            setState {
                                doneesSelected += donee
                                doneesSelectionError = null
                            }
                        }
                    )

                    // Chips
                    if (state.doneesSelected.isNotEmpty()) {
                        styledDiv {
                            css {
                                marginTop = 8.px

                                display = Display.flex
                                flexDirection = FlexDirection.column
                                gap = 8.px
                            }

                            body1 { +"${doneeTypePlural.capitalize()} you donated to:" }

                            chipSet(
                                chips = state.doneesSelected.map { Chip(id = it.name, label = it.name) },
                                onChipRemove = { doneeName ->
                                    val doneeToRemove = state.doneesSelected.first { it.name == doneeName }

                                    setState {
                                        if (state.doneesSelected.size == 1) {
                                            // Reset rebate recipient selected when all the donees are removed from the list
                                            disbRecipientTypeSelected = null
                                        }
                                        doneesSelected -= doneeToRemove
                                    }
                                }
                            )
                        }
                    }
                }

                if (showDisbOptions) {
                    // Show/hide the disbursement options as the user adds/removes donees from the list
                    radioGroup(
                        title = "What would you like to do with your $doneeTypeSingular rebates?",
                        radioButtons = disbRecipientOptions,
                        valueSelected = state.disbRecipientTypeSelected?.name,
                        onSelect = { value ->
                            setState {
                                disbRecipientTypeSelected = DisbursementRecipient.Type.valueOf(value)
                                showDisbRecipientError = false
                            }
                        },
                        showError = state.showDisbRecipientError
                    )

                    // Only show the "children names" field on the schools selection screen
                    if (props.doneeType == SCHOOL) {
                        selectTextField(
                            title = "Children names (optional)",
                            subtitle = "Schools typically require a child's name to find the relevant donations.",
                            placeholder = "Enter a name and click \"Add\"",
                            textCapitalization = CAPITALIZE,
                            chips = state.childrenNames.map { Chip(id = it, label = it) },
                            onItemAdd = { name -> setState { childrenNames += name } },
                            onItemRemove = { name -> setState { childrenNames -= name } },
                        )
                    }
                } else {
                    // Only show the footer when the disbursement options are not shown so the screen is not crowded
                    body2 { +"Note: Only donations over $5 are eligible for a rebate." }
                }
            }
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // Load the values already provided by the donor (if any)
        props.setupViewModel.donor.observe { donor ->
            val doneesSelectedForType = donor.donees.filter { it.type == props.doneeType }.toSet()
            setState {
                doneesSelected = doneesSelectedForType
                childrenNames = donor.childrenNames
            }

            donor.disbursementSettings.getOrElse(props.doneeType, defaultValue = { null })?.let { disbursementSetting ->
                setState { disbRecipientTypeSelected = disbursementSetting }
            }
        }

        props.setupViewModel.doneeSearchResults.observeEvent { setState { doneeSearchResults = it } }

        props.setupViewModel.errors.observeEvent { errors ->
            errors.forEach { error ->
                when ((error as? ErrorType.AppError)?.code) {
                    DONEE_SELECTION_MISSING -> setState { doneesSelectionError = "Required" }
                    DONEE_DISB_RECIPIENT_MISSING -> setState { showDisbRecipientError = true }
                }
            }
        }
    }

    /**
     * Saves the data provided on this screen.
     */
    private fun saveData() {
        props.setupViewModel.saveDoneesAndDisbSetting(donees = state.doneesSelected,
                                                      doneeType = props.doneeType,
                                                      disbRecipientType = state.disbRecipientTypeSelected,
                                                      childrenNames = state.childrenNames)
    }

}

/**
 * Properties used by the [DoneesSelectionScreen] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface DoneesSelectionScreenProps : Props {

    /**
     * Type of [Donee]s that will be selected in the screen.
     */
    var doneeType: Donee.Type

    /**
     * Types of recipients that can receive the rebates from the [doneeType].
     */
    var disbRecipientTypes: List<DisbursementRecipient.Type>

    /**
     * [ViewModel] shared between the setup process screens.
     */
    var setupViewModel: SetupViewModel

    /**
     * Function called on "back" button click
     */
    var onBackBtnClick: () -> Unit

}

/**
 * State of the [DoneesSelectionScreen] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface DoneesSelectionScreenState : State {

    /**
     * [Donee]s selected by the user.
     */
    var doneesSelected: Set<DoneeBasicInfo>

    /**
     * Error that occurred in the [doneesSelected].
     */
    var doneesSelectionError: String?

    /**
     * Type of recipient for the donee type's rebate selected by the user.
     */
    var disbRecipientTypeSelected: DisbursementRecipient.Type?

    /**
     * `true` if an error occurred in the [disbRecipientTypeSelected], otherwise `false`.
     */
    var showDisbRecipientError: Boolean

    /**
     * Name of the user's children (separated by commas).
     *
     * This is only used when [DoneesSelectionScreenProps.doneeType] is [Donee.Type.SCHOOL]
     */
    var childrenNames: Set<String>

    /**
     * The results of the user's donee search.
     */
    var doneeSearchResults: List<TextSearchResult<DoneeBasicInfo>>

}

/**
 * Renders a [DoneesSelectionScreen] component.
 */
public fun RBuilder.doneesSelectionScreen(doneeType: Donee.Type,
                                          disbRecipientTypes: List<DisbursementRecipient.Type>,
                                          setupViewModel: SetupViewModel,
                                          onBackBtnClick: () -> Unit) {
    child(DoneesSelectionScreen::class) {
        attrs {
            this.doneeType = doneeType
            this.disbRecipientTypes = disbRecipientTypes
            this.setupViewModel = setupViewModel
            this.onBackBtnClick = onBackBtnClick
        }
    }
}