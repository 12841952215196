package com.supergenerous.common.donation.request

import com.supergenerous.common.data.DtoDataModel
import com.supergenerous.common.donor.DonorBasicInfo
import com.supergenerous.common.organisation.Organisation
import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

/**
 * A request to an [organisation] for receipts of donations made by the [donor] in the [taxYears].
 */
@Serializable
public data class DonationRequest(

    override val id: String,

    override val creationTimestamp: Timestamp = 0,
    override val lastUpdateTimestamp: Timestamp = 0,

    /**
     * Donor who sent the donations requested to the [organisation].
     */
    public val donor: DonorBasicInfo,

    /**
     * The [Organisation] that donation receipts were requested from.
     */
    public val organisation: Organisation,

    /**
     * The tax years that the donation receipts were requested for.
     */
    public val taxYears: Set<Int>,

    /**
     * Date and time when the initial request was sent to the [organisation].
     *
     * This value is `null` for "old" requests that were migrated from version 1.x of the `sg-bot` OR if the request
     * has not been sent yet.
     */
    public val timestampSent: Timestamp?,

    /**
     * Date and time when the request reminders were sent to the [organisation] (if any).
     *
     * Reminders are sent periodically until the [organisation] answers the request by returning the donation receipts
     * or indicating that no receipts were found.
     */
    public val timestampRemindersSent: List<Timestamp>,

    /**
     * Date and time when this request was closed, by either indicating that no donations were found, or that donations
     * were added to the SG DB.
     */
    public val timestampClosed: Timestamp?,

    /**
     * `true` if the initial request has been sent to the [organisation], or `false` if not.
     *
     * This is needed because any [DonationRequest] prior to `sg-bot` v2 does not have a [timestampSent].
     */
    public val isSent: Boolean,

    /**
     * `true` if the [organisation] answered the request (whether they returned donations or not), or `false` if they
     * have not replied.
     */
    public val isAnswered: Boolean = false

) : DtoDataModel<DonationRequestDbo> {

    /**
     * `true` if the request is considered "open" (i.e., the [timestampClosed] is `null`), or `false` if it's "closed".
     */
    @Transient
    public val isOpen: Boolean = timestampClosed == null

    override fun toDbo(): DonationRequestDbo {
        return DonationRequestDbo(id = id,
                                  creationTimestamp = creationTimestamp,
                                  lastUpdateTimestamp = lastUpdateTimestamp,
                                  donor = donor.toDbo(),
                                  organisation = organisation.toDbo(),
                                  orgId = organisation.orgId,
                                  taxYears = taxYears.toList(),
                                  timestampSent = timestampSent,
                                  timestampRemindersSent = timestampRemindersSent,
                                  timestampClosed = timestampClosed,
                                  open = isOpen,
                                  sent = isSent,
                                  answered = isAnswered)
    }

}