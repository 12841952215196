package supergenerous.app.donor.kindo.model

import com.hipsheep.kore.di.DIManager
import com.hipsheep.kore.json.JsonParser
import com.hipsheep.kore.model.network.HttpStatusCode.INTERNAL_SERVER_ERROR
import com.hipsheep.kore.model.network.HttpStatusCode.OK
import com.hipsheep.kore.model.network.Response
import com.supergenerous.common.email.EmailAddress
import com.supergenerous.common.kindo.KindoValues.IFRAME_LINK_MSG_TYPE
import kotlinx.browser.window
import supergenerous.app.donor.kindo.model.KindoDonorLinkMsg.LinkStatus.*

/**
 * Service used to connect to the Kindo app via `postMessage`s.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class KindoService(

    private val jsonParser: JsonParser = DIManager.jsonParser

) {

    /**
     * Informs Kindo that a donor link was performed successfully.
     */
    public fun sendDonorLinkSuccessMsg(donorId: String,
                                       isNewAccount: Boolean,
                                       kindoLinkParams: KindoDonorLinkParams): Response<Unit> {
        return sendPostMessage(KindoDonorLinkMsg(referralId = kindoLinkParams.referralId,
                                                 donorExtId = kindoLinkParams.donorExtId,
                                                 donorId = donorId,
                                                 kindoLinkStatus = if (isNewAccount) NEW_USER_LINKED else EXISTING_USER_LINKED,
                                                 commentToDonor = "Successfully linked your Kindo account to Supergenerous!"))
    }

    /**
     * Informs Kindo that a donor cancelled the link request from SG.
     */
    public fun sendDonorLinkCancelMsg(kindoLinkParams: KindoDonorLinkParams): Response<Unit> {
        return sendPostMessage(KindoDonorLinkMsg(referralId = kindoLinkParams.referralId,
                                                 donorExtId = kindoLinkParams.donorExtId,
                                                 donorId = "",
                                                 kindoLinkStatus = LINK_DECLINED,
                                                 commentToDonor = "You have chosen not to link your Kindo account to Supergenerous. If this was a mistake, please try again.",
                                                 commentToKindo = "The user declined to link their account."))
    }

    /**
     * Informs Kindo that an error occurred when trying to link the donor to SG.
     */
    public fun sendDonorLinkErrorMsg(errorCode: Int): Response<Unit> {
        return sendPostMessage(KindoDonorLinkMsg(referralId = "",
                                                 donorExtId = "",
                                                 donorId = "",
                                                 kindoLinkStatus = LINK_ERROR,
                                                 commentToDonor = "An unknown error occurred when trying to link your Kindo account to Supergenerous. Please try again or contact ${EmailAddress.SG_SUPPORT}",
                                                 commentToKindo = "Error code $errorCode: Error occurred when trying to link donor."))
    }

    /**
     * Sends a [donorLinkMsg] as a `postMessage` to the Kindo app when some action occurs.
     *
     * See https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
     */
    private fun sendPostMessage(donorLinkMsg: KindoDonorLinkMsg): Response<Unit> {
        return try {
            val jsObject = js("{}")
            jsObject.message = jsonParser.toJson(donorLinkMsg, KindoDonorLinkMsg.serializer())

            /*
             * Set a type that the parent iframe will listen for, and only forward the message if the object has this
             * property and type.
             *
             * We have do this because the parent iframe is managed returned by the SG server. This iframe is an
             * 'iframe proxy' to our own donor app, that passes along any `postMessage` call from our donor app to the
             * parent Kindo window.
             *
             * This is to get around Kindo's requirement of using the same URL for GETs and POSTs to this endpoint, and
             * then also checking the origin of any iframe `postMessage` to be from that URL also.
             */
            jsObject.type = IFRAME_LINK_MSG_TYPE

            // Target origin is * (no preference) as we do not know the Kindo URL of the parent window (Kindo's website)
            window.parent.postMessage(message = jsObject, targetOrigin = "*")

            // Set Unit as the body or the conversion from Response to Resource will fail on the Repository
            Response(OK, body = Unit)
        } catch (e: Exception) {
            Response(INTERNAL_SERVER_ERROR, errorBody = e.stackTraceToString())
        }
    }

}