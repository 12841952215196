package supergenerous.app.core.component.tabs

/**
 * An item to display in a [tabs] component.
 */
public data class Tab(

    /**
     * A unique value to reference the [Tab].
     */
    val value: String,

    /**
     * The text to display in the tab.
     */
    val label: String

)