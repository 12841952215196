package supergenerous.app.core.component.menu

import com.supergenerous.common.user.UserInfo
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledImg
import supergenerous.app.core.component.body1
import supergenerous.app.core.res.Color

/**
 * Renders a menu that displays the [user]'s profile picture, or their first name initial if they don't have one.
 *
 * When this is clicked a menu pops up with [items].
 */
public fun RBuilder.profileMenu(items: List<MenuItem>,
                                user: UserInfo,
                                profileMenuSize: LinearDimension = 40.px,
                                onSelect: (item: String) -> Unit) {
    menu(
        items = items,
        onSelect = onSelect,
        toggleComponent = {
            if (user.profileImgUrl != null) {
                styledImg(src = user.profileImgUrl, alt = "User Profile") {
                    css {
                        width = profileMenuSize
                        height = profileMenuSize
                        borderRadius = 50.pct
                    }
                }
            } else {
                styledDiv {
                    css {
                        width = profileMenuSize
                        height = profileMenuSize
                        borderRadius = 50.pct
                        backgroundColor = Color.SECONDARY.cssValue

                        display = Display.flex
                        alignItems = Align.center
                        justifyContent = JustifyContent.center
                        textAlign = TextAlign.center
                        overflowY = Overflow.hidden
                    }

                    body1 {
                        css {
                            specific {
                                fontSize = profileMenuSize / 2.5
                                color = Color.TEXT_SUPER_WHITE.cssValue
                            }
                        }

                        // If the user has no name then always return "Me"
                        +(if (user.name.isBlank()) "Me" else user.name.take(1))
                    }
                }
            }
        }
    )
}