package supergenerous.app.core.component.progress

import kotlinx.css.borderRadius
import kotlinx.css.height
import kotlinx.css.px
import materialui.progress.MuiLinearProgress
import react.RBuilder
import styled.css
import styled.styled

/**
 * Creates a Loading Bar and returns it.
 */
public fun RBuilder.loadingBar() {
    @Suppress("DEPRECATION")
    styled(MuiLinearProgress)() {
        css {
            specific {
                height = 6.px
                borderRadius = 0.px
            }
        }

        attrs.variant = "indeterminate"
        attrs.color = "primary"
    }
}