package firebase

/**
 * Configuration used to set up the connection to Firebase (see https://firebase.google.com/docs/web/setup).
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public data class FirebaseConfig(

    val apiKey: String,

    val projectId: String?,

    val appId: String,

    val authDomain: String?,

    val databaseURL: String?,

    val messagingSenderId: String?,

    val measurementId: String?

)