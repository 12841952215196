package com.supergenerous.common.util

import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.Month
import kotlinx.datetime.Month.*

/**
 * Amount of hours in a week.
 */
public const val HOURS_IN_WEEK: Int = 168

/**
 * Amount of millis in a second.
 */
public const val MILLIS_IN_SECOND: Long = 1_000

/**
 * Amount of millis in an hour.
 */
public const val MILLIS_IN_HOUR: Long = 3_600_000L

/**
 * Amount of millis in a day.
 */
public const val MILLIS_IN_DAY: Long = MILLIS_IN_HOUR * 24L

/*
 * Extension functions
 */

/**
 * Display name for the month.
 */
public val Month.displayName: String
    get() = when (this) {
        JANUARY -> "January"
        FEBRUARY -> "February"
        MARCH -> "March"
        APRIL -> "April"
        MAY -> "May"
        JUNE -> "June"
        JULY -> "July"
        AUGUST -> "August"
        SEPTEMBER -> "September"
        OCTOBER -> "October"
        NOVEMBER -> "November"
        DECEMBER -> "December"
        else -> throw RuntimeException()
    }

/**
 * Returns a `String` representation of the [LocalDateTime], separating the different parts of date and time with chars
 * accepted on a file name.
 *
 * Nanoseconds are not included on the result.
 */
public fun LocalDateTime.toFileNameFormat(): String {
    return this.toString()
            // Replace char that is not accepted on file names
            .replace(":", ".")
            // Separate date and time for better readability
            .replace("T", "_")
            // Remove nanoseconds
            .substringBeforeLast('.')
}