@file:Suppress("unused")

package react.signature.canvas

import org.w3c.dom.css.CSSStyleDeclaration

/**
 * @author Franco Sabadini (franco@supergenerous.com)
 */
internal external interface CanvasProps {

    var id: String
    var style: CSSStyleDeclaration

}

@Suppress("UnsafeCastFromDynamic", "FunctionName")
internal fun CanvasProps(): CanvasProps = js("{}")