package supergenerous.app.donor.setup.view

import com.hipsheep.kore.error.ErrorType
import com.hipsheep.kore.util.isNotNullOrBlank
import com.supergenerous.common.network.CoreUrl.SG_TOS
import kotlinx.css.Display
import kotlinx.css.display
import kotlinx.css.marginBottom
import kotlinx.css.marginTop
import kotlinx.css.px
import kotlinx.css.rowGap
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.body2
import supergenerous.app.core.component.checkbox
import supergenerous.app.core.component.signaturePad
import supergenerous.app.core.component.textLink
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.setup.model.AuthorityToActTerm
import supergenerous.app.donor.setup.model.AuthorityToActTerm.*
import supergenerous.app.donor.setup.model.SetupError.*
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.AUTHORITY_TO_ACT
import supergenerous.app.donor.setup.viewmodel.SetupViewModel

/**
 * Screen that allows donors to give SG authority to act on their behalf.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class AuthorityToActScreen : SetupScreen<SetupScreenProps, AuthorityToActScreenState>() {

    override val setupStep: SetupStep = AUTHORITY_TO_ACT

    override fun AuthorityToActScreenState.init() {
        termsAll = setOf()
        termsAccepted = setOf()
        termsNotAccepted = setOf()
    }

    override fun RBuilder.render() {
        setupInputContainer(
            title = { +"The legal stuff" },
            subtitle = {
                +"Final step and then we’re ready to go. The below authorities are just the essentials. Check out our "
                textLink(url = SG_TOS) { +"terms and conditions" }
                +" if you'd like to know more!"
            },
            setupViewModel = props.viewModel,
            onNextBtnClick = ::saveSignature,
            onBackBtnClick = ::goBack
        ) {
            // Render the terms
            styledDiv {
                css {
                    display = Display.inlineGrid
                    rowGap = 24.px

                    marginBottom = 56.px
                }

                state.termsAll.forEach {
                    ataTermCheckbox(ataTerm = it)
                }
            }

            signaturePad(
                placeholder = state.donorName,
                signature = state.signature,
                onChange = { newSignature ->
                    setState {
                        signature = newSignature
                        signatureError = null
                    }
                },
                errorMessage = state.signatureError
            )

            body1 {
                css {
                    marginTop = 8.px
                }

                +"Why do you need my signature?"
            }
            body2 {
                css {
                    marginTop = 4.px
                    marginBottom = 8.px
                }

                +"This is so the charitable organisations you have given to know we have permission to receive your donation receipts."
            }
        }
    }

    /**
     * Creates a checkbox for the [ataTerm].
     */
    private fun RBuilder.ataTermCheckbox(ataTerm: AuthorityToActTerm) {
        checkbox(
            label = ataTerm.text,
            isChecked = state.termsAccepted.contains(ataTerm),
            onChange = { isChecked ->
                setState {
                    if (isChecked) {
                        termsAccepted += ataTerm
                    } else {
                        termsAccepted -= ataTerm
                    }

                    termsNotAccepted -= ataTerm
                }
            },
            showError = state.termsNotAccepted.contains(ataTerm)
        )
    }

    override fun componentDidMount() {
        super.componentDidMount()

        setState { termsAll = props.viewModel.ataTerms }

        // Load the values already provided by the donor (if any)
        props.viewModel.donor.observe { donor ->
            setState {
                donorName = donor.legalName?.let { "${it.firstName} ${it.lastName}" }
            }

            if (donor.signature?.value.isNotNullOrBlank()) {
                setState {
                    termsAccepted = state.termsAll
                    signature = donor.signature?.value
                }
            }
        }

        props.viewModel.errors.observeEvent { errors ->
            errors.forEach { error ->
                when ((error as? ErrorType.AppError)?.code) {
                    ATA_SIGNATURE_MISSING -> setState { signatureError = "Required" }
                    ATA_SIGNATURE_INVALID -> {
                        setState { signatureError = "Please enter your full name to sign: \"$donorName\"" }
                    }
                    ATA_TERM_NOT_ACCEPTED_TAX_AGENT -> setState { termsNotAccepted += TAX_AGENT }
                    ATA_TERM_NOT_ACCEPTED_SG_OPT_OUT -> setState { termsNotAccepted += SG_OPT_OUT }
                    ATA_TERM_NOT_ACCEPTED_TAX_ACCOUNT_ACCESS -> setState { termsNotAccepted += TAX_ACCOUNT_ACCESS }
                    ATA_TERM_NOT_ACCEPTED_TAX_COMMUNICATION -> setState { termsNotAccepted += TAX_COMMUNICATION }
                    ATA_TERM_NOT_ACCEPTED_TAX_CORRESPONDENCE -> setState { termsNotAccepted += TAX_CORRESPONDENCE }
                    ATA_TERM_NOT_ACCEPTED_SG_BANK_ACCOUNT -> setState { termsNotAccepted += SG_BANK_ACCOUNT }
                    ATA_TERM_NOT_ACCEPTED_SG_FEE -> setState { termsNotAccepted += SG_FEE }
                }
            }
        }
    }

    /**
     * Saves the user's signature.
     */
    private fun saveSignature() {
        props.viewModel.saveSignature(signatureStr = state.signature,
                                      termsAccepted = state.termsAccepted)
    }

}

/**
 * State of the [AuthorityToActScreen] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface AuthorityToActScreenState : State {

    /**
     * The name of the signed in donor.
     */
    var donorName: String?

    /**
     * List of all the terms that need to be accepted by the user.
     */
    var termsAll: Set<AuthorityToActTerm>

    /**
     * List of terms accepted by the user in this screen.
     */
    var termsAccepted: Set<AuthorityToActTerm>

    /**
     * List of terms that errors occurred for.
     */
    var termsNotAccepted: Set<AuthorityToActTerm>

    /**
     * Signature entered by the user, or `null` if it was not entered yet or it was deleted.
     */
    var signature: String?

    /**
     * The error message to display for the signature.
     */
    var signatureError: String?

}

/**
 * Renders an [AuthorityToActScreen] component.
 */
public fun RBuilder.authorityToActScreen(setupViewModel: SetupViewModel) {
    withRouter(AuthorityToActScreen::class) {
        attrs.viewModel = setupViewModel
    }
}