package supergenerous.app.core.component.tooltip

import kotlinx.css.CssBuilder
import kotlinx.css.Visibility
import kotlinx.css.backgroundColor
import kotlinx.css.borderRadius
import kotlinx.css.px
import kotlinx.css.visibility
import materialui.tooltip.MuiTooltip
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.dom.div
import styled.StyleSheet
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.res.Color.PRIMARY

/**
 * Tooltip component used throughout the app.
 */
@JsExport
private class Tooltip : RComponent<TooltipProps, State>() {

    override fun RBuilder.render() {
        @Suppress("DEPRECATION")
        MuiTooltip {
            attrs {
                classes = object {
                    /**
                     * The classname to add to the main component of the popup.
                     */
                    @JsName("tooltip")
                    @Suppress("unused")
                    val tooltip: String = "${TooltipStyle.name}-${TooltipStyle::cssValue.name}"
                }
                placement = "top"
                title = react.buildElement {
                    body1(isDarkBg = true) {
                        +props.text
                    }
                }
            }

            div {
                props.anchor(this)

                // TODO: Remove this and get the stylesheet to be injected some other way
                //  This is a dirty hack to ensure that the styles gets injected into the global stylesheet
                styledDiv {
                    css {
                        +TooltipStyle.cssValue

                        visibility = Visibility.hidden
                    }
                }
            }
        }
    }
}

/**
 * Styles for the popup component.
 */
private object TooltipStyle : StyleSheet("TooltipStyle", isStatic = true) {

    val cssValue: CssBuilder.() -> Unit by css {
        specific {
            borderRadius = 0.px

            backgroundColor = PRIMARY.cssValue
        }
    }

}

/**
 * Properties used by the [Tooltip] component.
 */
private external interface TooltipProps : Props {

    /**
     * The text to show when [anchor] is hovered over.
     */
    var text: String

    /**
     * The element that shows the [text] when hovered over.
     */
    var anchor: (RBuilder) -> Unit

}

/**
 * Renders a [Tooltip] component that displays [text] when [anchor] is interacted with.
 */
internal fun RBuilder.tooltip(text: String,
                              anchor: RBuilder.() -> Unit) {
    child(Tooltip::class) {
        attrs {
            this.text = text
            this.anchor = anchor
        }
    }
}