package supergenerous.app.core.auth.view

import com.hipsheep.kore.error.ErrorType
import com.hipsheep.kore.error.ErrorType.*
import com.hipsheep.kore.util.isNotNullOrBlank
import com.supergenerous.common.email.EmailAddress
import com.supergenerous.common.network.CoreUrl.Path.*
import com.supergenerous.common.network.CoreUrl.SG_TOS
import kotlinx.css.Align
import kotlinx.css.Cursor
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.GridColumn
import kotlinx.css.JustifyContent
import kotlinx.css.LinearDimension
import kotlinx.css.Overflow
import kotlinx.css.Position
import kotlinx.css.QuotedString
import kotlinx.css.TextAlign
import kotlinx.css.VerticalAlign
import kotlinx.css.alignItems
import kotlinx.css.alignSelf
import kotlinx.css.backgroundColor
import kotlinx.css.color
import kotlinx.css.content
import kotlinx.css.cursor
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.gap
import kotlinx.css.gridColumn
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.left
import kotlinx.css.margin
import kotlinx.css.marginBottom
import kotlinx.css.marginTop
import kotlinx.css.maxWidth
import kotlinx.css.minHeight
import kotlinx.css.minWidth
import kotlinx.css.overflow
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.position
import kotlinx.css.px
import kotlinx.css.textAlign
import kotlinx.css.top
import kotlinx.css.verticalAlign
import kotlinx.css.vh
import kotlinx.css.width
import kotlinx.html.js.onSubmitFunction
import react.RBuilder
import react.State
import react.dom.span
import react.setState
import styled.css
import styled.styledDiv
import styled.styledForm
import styled.styledImg
import styled.styledSection
import supergenerous.app.core.auth.model.AuthError.*
import supergenerous.app.core.auth.model.AuthProvider.GOOGLE
import supergenerous.app.core.auth.view.AuthScreen.InputField.*
import supergenerous.app.core.auth.viewmodel.AuthViewModel
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.heading1
import supergenerous.app.core.component.layout.grid
import supergenerous.app.core.component.snackbar.SnackbarMessageQueue
import supergenerous.app.core.component.subheading1
import supergenerous.app.core.component.textBold
import supergenerous.app.core.component.textLink
import supergenerous.app.core.component.textLinkButton
import supergenerous.app.core.component.textfield.TextFieldType
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.res.Color.*
import supergenerous.app.core.res.SgLogoColor
import supergenerous.app.core.res.image.CoreImage
import supergenerous.app.core.util.JustifySelf
import supergenerous.app.core.util.RouteProps
import supergenerous.app.core.util.component.sgLogo
import supergenerous.app.core.util.extraLargeScreen
import supergenerous.app.core.util.justifySelf
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.push
import supergenerous.app.core.util.urlParams
import supergenerous.app.core.util.withRouter

/**
 * Screen that allows users to sign in/up to the app.
 *
 * This screen shows 2 views:
 *
 * 1. Allows the user to sign-in to their existing account.
 * 2. Allows the user to create a new account.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class AuthScreen : LifecycleOwnerComponent<AuthScreenProps, AuthScreenState>() {

    override fun AuthScreenState.init() {
        email = ""
        password = ""
    }

    override fun RBuilder.render() {
        grid {
            css {
                height = 100.vh
                padding(all = 0.px)

                mobileScreen {
                    specific {
                        // Make the columns wrap downwards using flex, instead of next to each other as a grid when
                        // the screen is small
                        display = Display.flex
                        flexDirection = FlexDirection.column
                    }
                }
            }

            // Left side panel
            styledDiv {
                css {
                    gridColumn = GridColumn("1 / 7")

                    display = Display.flex
                    flexDirection = FlexDirection.column
                    gap = 40.px

                    backgroundColor = PRIMARY.cssValue
                    padding(all = 40.px)

                    // Make left side panel smaller on large screens
                    extraLargeScreen {
                        gridColumn = GridColumn("1 / 6")
                    }
                }

                // SG logo
                styledDiv {
                    css {
                        // Min height is 20px according to brand guidelines
                        minHeight = 20.px
                        width = 80.pct

                        alignSelf = Align.flexStart

                        mobileScreen { alignSelf = Align.center }
                    }

                    sgLogo(color = SgLogoColor.WHITE)
                }

                // Container for text under the logo + image
                styledDiv {
                    css {
                        alignSelf = Align.flexStart
                        width = 100.pct
                        height = 100.pct
                        position = Position.relative

                        mobileScreen { display = Display.none }
                    }

                    // Image
                    styledDiv {
                        css {
                            maxWidth = 70.pct
                            position = Position.absolute
                            top = 0.px
                            left = 0.px
                        }

                        styledImg(src = CoreImage.PERSON_DONATING.path) {
                            css {
                                width = 100.pct
                                height = LinearDimension.auto
                            }
                        }
                    }

                    // Text container
                    styledDiv {
                        css {
                            maxWidth = 70.pct
                            position = Position.relative
                            top = 30.pct
                            left = 30.pct
                        }

                        heading1 {
                            css {
                                alignSelf = Align.flexStart
                                margin(bottom = 30.px)

                                specific { color = TEXT_SUPER_WHITE.cssValue }
                            }

                            +props.leftPanelTitle
                        }

                        subheading1 {
                            css {
                                media(query = "only screen and (max-width: 1024px)") {
                                    // The subheading gets cut off at certain screen heights below this width, so just
                                    // don't display it at all.
                                    display = Display.none
                                }
                                specific { color = TEXT_SUPER_WHITE.cssValue }
                            }

                            +props.leftPanelSubtitle
                        }
                    }
                }
            }

            // Right side panel
            styledDiv {
                css {
                    gridColumn = GridColumn("7 / 13")
                    padding(all = 64.px)

                    display = Display.flex
                    alignItems = Align.center
                    justifyContent = JustifyContent.spaceBetween
                    flexDirection = FlexDirection.column

                    mobileScreen { minWidth = LinearDimension.auto }

                    // Make right side panel larger on large screens
                    extraLargeScreen {
                        gridColumn = GridColumn("6 / 13")
                    }
                }

                authView()
            }
        }
    }

    /**
     * Renders the auth section of the page.
     */
    private fun RBuilder.authView() {
        styledDiv {
            css {
                display = Display.flex
                alignItems = Align.center
                justifyContent = JustifyContent.center
                flexDirection = FlexDirection.column

                minWidth = 256.px
                maxWidth = 330.px
                width = 60.pct
                height = 100.pct

                children {
                    width = 100.pct
                }
            }

            // Title
            heading1 {
                css {
                    textAlign = TextAlign.center
                    margin(bottom = 56.px)
                }

                when {
                    props.title != null -> +props.title!!
                    props.isSignIn -> +"Log In"
                    else -> +"Sign Up"
                }
            }

            if (props.enableAuthProviders) {
                // Third-party providers authentication
                styledSection {
                    css {
                        +AuthStyle.centredColumnFlex
                        minWidth = LinearDimension.maxContent
                    }

                    authProviderButton(
                        authProvider = GOOGLE,
                        onClick = { props.viewModel.authenticateWithProvider(GOOGLE, referralCode = state.referralCode) }
                    )
                }

                // Vertical divider
                body1 {
                    css {
                        // This styling makes the element appear with a dividing line to the right
                        // i.e. OR -------------------
                        overflow = Overflow.hidden
                        textAlign = TextAlign.start
                        width = 100.pct
                        margin(vertical = 40.px)

                        // This adds the line to the right
                        after {
                            backgroundColor = DIVIDER.cssValue
                            content = QuotedString("")
                            display = Display.inlineBlock
                            height = 1.px
                            position = Position.relative
                            verticalAlign = VerticalAlign.middle
                            width = 100.pct
                            left = 8.px
                            margin(right = (-50).pct)
                        }
                    }

                    +"OR"
                }
            }

            // Email authentication
            val spaceBetweenFields = 24.px
            styledForm {
                css {
                    +AuthStyle.centredColumnFlex

                    children { marginBottom = spaceBetweenFields }
                }

                /*
                 * When you are in a form and press "enter" it validates the form before allowing the
                 * form to submit. Because the email field is type=email if there is an invalid email
                 * then it adds the :invalid pseudo class to the input, shows a popup on the invalid
                 * fields, and does not allow the submit action to continue. This is a problem
                 * because we want to let firebase do the validation.
                 *
                 * Setting novalidate=true on the form stops the built-in form validation behaviour.
                 */
                attrs.novalidate = true

                // Prevent the default submit event behavior or the whole web app will be reloaded on Chrome
                // (see https://github.com/ReactTraining/react-router/issues/1933)
                attrs.onSubmitFunction = { event -> event.preventDefault() }

                textField(
                    type = TextFieldType.EMAIL,
                    title = "Email",
                    value = state.email,
                    errorMessage = state.emailError,
                    onTextChange = {
                        setState {
                            email = it
                            emailError = null
                        }
                    }
                )

                textField(
                    type = TextFieldType.PASSWORD,
                    title = "Password",
                    value = state.password,
                    errorMessage = state.passwordError,
                    onTextChange = {
                        setState {
                            password = it
                            passwordError = null
                        }
                    }
                )

                if (props.isSignIn) {
                    // Show forgot password section
                    styledDiv {
                        css {
                            margin(top = -spaceBetweenFields)
                            padding(10.px)
                            cursor = Cursor.pointer
                            alignSelf = Align.flexEnd
                        }

                        textLinkButton(onClick = { showPasswordResetDialog(true) }) {
                            +props.resetPasswordText
                        }
                    }
                } else {
                    // Show sign-up info
                    body1 {
                        css {
                            margin(top = -spaceBetweenFields)
                        }

                        +"Your password must be at least "
                        textBold { +"6 characters." }
                    }

                    if (props.enableReferralCode) {
                        textField(
                            type = TextFieldType.TEXT,
                            title = "Referral code (optional)",
                            value = state.referralCode,
                            onTextChange = { setState { referralCode = it } }
                        )
                    }

                    body1 {
                        css {
                            marginTop = 12.px
                        }

                        +"By continuing you are indicating that you accept our "
                        textLink(url = SG_TOS) { +"Terms of Service and Privacy Policy" }
                        +"."
                    }
                }

                styledDiv {
                    css {
                        alignSelf = Align.center
                        display = Display.flex
                        justifyContent = JustifyContent.center
                    }

                    button(
                        showLoadingIcon = state.isAuthInProgress || props.isUserDataLoading,
                        label = if (props.isSignIn) "Log in" else "Create account",
                        isSubmitButton = true,
                        onClick = {
                            if (props.isSignIn) {
                                props.viewModel.signIn(email = state.email, password = state.password)
                            } else {
                                props.viewModel.signUp(email = state.email,
                                                       password = state.password,
                                                       referralCode = state.referralCode)
                            }
                        }
                    )
                }
            }

            if (state.showPasswordResetDialog) {
                passwordResetDialog(
                    viewModel = props.viewModel,
                    onClose = { showPasswordResetDialog(false) }
                )
            }
        }

        if (props.enableSignUp) {
            body1 {
                css {
                    // Pin to the bottom of the screen
                    top = 100.pct - 100.px

                    justifySelf(JustifySelf.end)

                    mobileScreen {
                        top = LinearDimension.auto
                        margin(top = 10.px)
                    }
                }

                span {
                    if (props.isSignIn) +"Don't have an account?" else +"Already have an account?"
                }

                textLinkButton(onClick = ::switchAuthScreens) {
                    css {
                        margin(left = 5.px)
                    }

                    if (props.isSignIn) +"Sign up" else +"Log in"
                }
            }
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // If there is a referral code provided on the URL then add it to the "referral code" field
        val referralCode = props.location.urlParams.get("refCode")
        if (props.enableReferralCode && referralCode.isNotNullOrBlank()) {
            setState { this.referralCode = referralCode!! }
        }

        props.viewModel.isActionInProgress.observe { setState { isAuthInProgress = it } }
        props.viewModel.errors.observeEvent { processErrors(errors = it) }
    }

    /**
     * Sets the state to show or hide the reset password dialog.
     */
    private fun showPasswordResetDialog(show: Boolean) {
        setState { showPasswordResetDialog = show }
    }

    /**
     * Switches between the sign-in and sign-up screens.
     */
    private fun switchAuthScreens() {
        val path = if (props.isSignIn) SIGN_UP else SIGN_IN
        props.history.push(path)
    }

    /**
     * Processes the [errors], setting the message against the appropriate field, or adding it to the
     * [SnackbarMessageQueue].
     */
    private fun processErrors(errors: Set<ErrorType>) {
        errors.forEach { error ->
            when (error) {
                is HttpError -> SnackbarMessageQueue.add(title = null,
                                                         body = "An unknown error occurred: (Code ${error.statusCode})")
                is AppError -> {
                    when (error.code) {
                        // Common errors
                        INVALID_EMAIL -> showError(inputField = EMAIL, message = "Invalid email")

                        // Sign-in errors (see https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword)
                        USER_NOT_FOUND -> showError(inputField = EMAIL, message = "Email address not found")
                        WRONG_PASSWORD -> showError(inputField = PASSWORD, message = "Incorrect password")

                        // Sign-up errors (see https://firebase.google.com/docs/reference/js/firebase.auth.Auth#createuserwithemailandpassword)
                        EMAIL_ALREADY_IN_USE -> showError(inputField = EMAIL, message = "Email address is already in use")
                        WEAK_PASSWORD -> showError(inputField = PASSWORD, message = "Weak password")

                        // Other errors
                        NETWORK_REQUEST_FAILED -> {
                            SnackbarMessageQueue.add(title = "Network error",
                                                     body = "Check if you are connected to the internet.")
                        }
                        ACCOUNT_EXISTS_WITH_DIFFERENT_PROVIDER -> {
                            SnackbarMessageQueue.add(title = "Account already exists",
                                                     body = "There is already an account that has been registered with that email address using a different login method.")
                        }
                        USER_DISABLED -> {
                            SnackbarMessageQueue.add(title = "Account has been disabled",
                                                     body = "Please email us at ${EmailAddress.SG_SUPPORT}")
                        }
                    }
                }
            }
        }
    }

    /**
     * Shows the error [message] on the [inputField].
     */
    private fun showError(inputField: InputField, message: String) {
        when (inputField) {
            EMAIL -> setState { emailError = message }
            PASSWORD -> setState { passwordError = message }
        }
    }

    /*
     * Inner types
     */

    /**
     * List of input fields in the screen.
     */
    private enum class InputField {

        EMAIL, PASSWORD

    }

}

/**
 * Properties used by the [AuthScreen] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface AuthScreenProps : RouteProps {

    /**
     * ViewModel for auth.
     */
    var viewModel: AuthViewModel

    /**
     * `true` if the screen is used for sign-in or `false` if it's for sign-up.
     */
    var isSignIn: Boolean

    /**
     * `true` if the screen should present the option to create an account, or `false` if the user is only allowed to
     * sign in with an existing account.
     */
    var enableSignUp: Boolean

    /**
     * `true` if the user is allowed to sign in/up using third-party auth providers (e.g., Google), or `false` if only
     * email authentication is allowed.
     */
    var enableAuthProviders: Boolean

    /**
     * `true` if the "referral code" section should be shown, or `false` if it should be hidden.
     */
    var enableReferralCode: Boolean

    /**
     * `true` if the user data is being loaded, or `false` if the load ended (either successfully or an error occurred).
     */
    var isUserDataLoading: Boolean

    /**
     * Title to show above the authentication buttons.
     *
     * If not set, the default titles will be used for sign in/up.
     */
    var title: String?

    /**
     * Title to show on the left side panel.
     */
    var leftPanelTitle: String

    /**
     * Subtitle to show on the left side panel under [leftPanelTitle].
     */
    var leftPanelSubtitle: String

    /**
     * Text to show on the "reset password" button.
     */
    var resetPasswordText: String

}

/**
 * State of the [AuthScreen] component.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
private external interface AuthScreenState : State {

    /**
     * The currently entered email string in the email field.
     */
    var email: String

    /**
     * The currently entered password string in the password field.
     */
    var password: String

    /**
     * Error message to show on the email field, or `null` if no error occurred.
     */
    var emailError: String?

    /**
     * Error message to show on the password field, or `null` if no error occurred.
     */
    var passwordError: String?

    /**
     * Whether to display the password reset dialog.
     */
    var showPasswordResetDialog: Boolean

    /**
     * `true` if the user is being authenticated, or `false` if the authentication ended (either successfully or an
     * error occurred).
     */
    var isAuthInProgress: Boolean

    /**
     * Referral code entered by the donor (or automatically from the URL).
     */
    var referralCode: String?

}

/**
 * Renders a [AuthScreen] component.
 */
public fun RBuilder.authScreen(viewModel: AuthViewModel,
                               isSignIn: Boolean,
                               enableSignUp: Boolean = true,
                               enableAuthProviders: Boolean = true,
                               enableReferralCode: Boolean = false,
                               isUserDataLoading: Boolean,
                               title: String? = null,
                               leftPanelTitle: String,
                               leftPanelSubtitle: String,
                               resetPasswordText: String) {
    withRouter(AuthScreen::class) {
        attrs {
            this.viewModel = viewModel
            this.isSignIn = isSignIn
            this.enableSignUp = enableSignUp
            this.enableAuthProviders = enableAuthProviders
            this.enableReferralCode = enableReferralCode
            this.isUserDataLoading = isUserDataLoading
            this.title = title
            this.leftPanelTitle = leftPanelTitle
            this.leftPanelSubtitle = leftPanelSubtitle
            this.resetPasswordText = resetPasswordText
        }
    }
}