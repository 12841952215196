package supergenerous.app.core

import kotlinx.browser.document
import kotlinx.css.BorderStyle
import kotlinx.css.BoxSizing
import kotlinx.css.Direction
import kotlinx.css.Display
import kotlinx.css.FontStyle
import kotlinx.css.FontWeight
import kotlinx.css.TextTransform
import kotlinx.css.WhiteSpace
import kotlinx.css.WordWrap
import kotlinx.css.backgroundColor
import kotlinx.css.body
import kotlinx.css.boxSizing
import kotlinx.css.direction
import kotlinx.css.display
import kotlinx.css.fontFamily
import kotlinx.css.fontStyle
import kotlinx.css.fontWeight
import kotlinx.css.height
import kotlinx.css.lineHeight
import kotlinx.css.margin
import kotlinx.css.pct
import kotlinx.css.properties.LineHeight
import kotlinx.css.properties.border
import kotlinx.css.px
import kotlinx.css.textTransform
import kotlinx.css.vh
import kotlinx.css.whiteSpace
import kotlinx.css.wordWrap
import kotlinx.html.dom.append
import kotlinx.html.js.script
import styled.injectGlobal
import supergenerous.app.core.auth.view.AuthStyle.css
import supergenerous.app.core.res.Color.*
import supergenerous.app.core.res.Font
import supergenerous.app.core.util.EnvConfig

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

/**
 * Initializes the app using the [envConfig] received.
 *
 * IMPORTANT: This method should be called from the `main()` method that is called when the app is loaded to the
 * browser.
 */
public fun initApp(envConfig: EnvConfig, enablePerformanceMonitoring: Boolean = false) {
    // Log the app version
    println("App version: ${envConfig.appVersion}")

    // TODO: Do we need this here?
    kotlinext.js.require("firebase/firestore")
    kotlinext.js.require("firebase/analytics")

    // Initialize Firebase
    val firebaseApp = firebase.initializeApp(envConfig.firebaseConfig)

    if (enablePerformanceMonitoring) {
        // Initialize performance monitoring
        firebase.performance(firebaseApp)
    }

    if (envConfig.enableHubspotTracking) {
        document.body?.append {
            script {
                attributes["id"] = "hs-script-loader"
                async = true
                defer = true
                type = "text/javascript"
                src = "//js.hs-scripts.com/20272949.js"
            }
        }
    }

    // Fix CSS styles
    fixStyles()
}

/**
 * Fixes some issues with CSS styles.
 */
private fun fixStyles() {
    injectGlobal(css {
        body {
            // Remove default margin added to the body
            margin(all = 0.px)

            // Force full height for the body content
            height = max(100.vh, 100.pct)
            // Set default background color for all screens
            backgroundColor = SCREEN_BACKGROUND.cssValue

            // Set box-sizing for all elements to be `border-box` except for those contained within libraries that may
            // set their own box-sizing.
            boxSizing = BoxSizing.borderBox
            descendants {
                boxSizing = BoxSizing.inherit
            }
        }

        // Override material error font
        descendants(".MuiFormHelperText-root") {
            specific {
                fontFamily = Font.PROXIMA_NOVA
            }
        }
    }.css)

    // Fix issues with certain material components not using the proper styles
    injectGlobal(css {
        ".mdc-list-item--activated" {
            put("--mdc-theme-primary", PRIMARY.hexValue)
        }
    }.css)

    // Change material icons style to "outlined"
    injectGlobal(css {
        ".material-icons" {
            fontFamily = "Material Icons Outlined"
            fontWeight = FontWeight.normal
            fontStyle = FontStyle.normal
            lineHeight = LineHeight("1")
            put("letter-spacing", "normal")
            textTransform = TextTransform.none
            display = Display.inlineBlock
            whiteSpace = WhiteSpace.nowrap
            wordWrap = WordWrap.normal
            direction = Direction.ltr
            put("-webkit-font-feature-settings", "liga")
            put("-webkit-font-smoothing", "antialiased")
        }
    }.css)

    // Set popover style. It exists outside the root DOM element, and is difficult to override.
    // TODO: Finish popover or delete
    injectGlobal(css {
        ".sg-tooltip" {
            backgroundColor = WHITE.cssValue
            border(1.px, BorderStyle.solid, BLACK.cssValue)
        }
    }.css)

    // Remove extra internal border from Material UI inputs.
    // TODO: Remove this once we no longer use MUI for inputs
    injectGlobal(css {
        "fieldset.MuiOutlinedInput-notchedOutline" {
            put("border-width", "0px !important")
        }
    }.css)
}