package supergenerous.app.donor.setup.model

import com.hipsheep.kore.error.AppErrorCode
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.id.IdDocument

/**
 * The errors that can occur during setup.
 */
public enum class SetupError : AppErrorCode {

    // Personal Info
    FIRST_NAME_MISSING,
    LAST_NAME_MISSING,
    ADDRESS_MISSING,

    // Contact Info
    PHONE_NUMBER_MISSING,

    // Bank Account
    BANK_ACCOUNT_MISSING,
    BANK_ACCOUNT_INVALID,

    // Tax ID
    TAX_ID_MISSING,
    TAX_ID_INVALID,

    // ATA
    ATA_SIGNATURE_MISSING,
    ATA_SIGNATURE_INVALID,
    ATA_TERM_NOT_ACCEPTED_TAX_AGENT,
    ATA_TERM_NOT_ACCEPTED_TAX_COMMUNICATION,
    ATA_TERM_NOT_ACCEPTED_SG_BANK_ACCOUNT,
    ATA_TERM_NOT_ACCEPTED_SG_FEE,
    ATA_TERM_NOT_ACCEPTED_SG_OPT_OUT,
    ATA_TERM_NOT_ACCEPTED_TAX_CORRESPONDENCE,
    ATA_TERM_NOT_ACCEPTED_TAX_ACCOUNT_ACCESS,

    // Donee Selection
    DONEE_TYPE_MISSING,
    DONEE_SELECTION_MISSING,
    DONEE_DISB_RECIPIENT_MISSING,

    // Gov ID
    /**
     * The [Donor]'s [IdDocument.Type] was missing.
     */
    GOV_ID_TYPE_MISSING,

    /**
     * The [Donor]'s [IdDocument]'s number was missing.
     */
    GOV_ID_NUMBER_MISSING,

    /**
     * The [Donor]'s [IdDocument.DriverLicenceNz.number] was invalid.
     */
    DRIVER_LICENCE_NUMBER_INVALID,

    /**
     * The [Donor]'s [IdDocument.DriverLicenceNz.version] was missing.
     */
    DRIVER_LICENCE_VERSION_MISSING,

    /**
     * The [Donor]'s [IdDocument.DriverLicenceNz.version] was invalid.
     */
    DRIVER_LICENCE_VERSION_INVALID,

    /**
     * The [Donor]'s [IdDocument.Passport.number] was invalid.
     */
    PASSPORT_NUMBER_INVALID,

    /**
     * The [Donor]'s [IdDocument.Passport.expiryDate] was missing.
     */
    PASSPORT_EXPIRY_DATE_MISSING,

    /**
     * The [Donor]'s [IdDocument.Passport.expiryDate] was invalid.
     */
    PASSPORT_EXPIRY_DATE_INVALID,

    /**
     * The [Donor.dateOfBirth] was missing.
     */
    DATE_OF_BIRTH_MISSING,

}