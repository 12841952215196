package supergenerous.app.core.component.menu

import kotlinx.css.Cursor
import kotlinx.css.backgroundColor
import kotlinx.css.borderRadius
import kotlinx.css.color
import kotlinx.css.cursor
import kotlinx.css.marginTop
import kotlinx.css.padding
import kotlinx.css.px
import kotlinx.html.js.onClickFunction
import kotlinx.js.jso
import materialui.menu.MuiMenu
import materialui.menu.MuiMenuItem
import org.w3c.dom.HTMLElement
import react.Props
import react.RBuilder
import react.RComponent
import react.RefObject
import react.State
import react.createRef
import react.dom.div
import react.setState
import styled.css
import styled.styled
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.res.Color.*

/**
 * Pop-out menu component. Used throughout the app.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@JsExport
private class Menu : RComponent<MenuProps, MenuState>() {

    /**
     * Reference to the [MenuProps.toggleComponent] rendered.
     *
     * We use this object to "link" the [Menu] to the [MenuProps.toggleComponent] so the menu is shown/hidden the
     * toggle component is clicked.
     */
    private var toggleComponentRef: RefObject<HTMLElement> = createRef()


    override fun MenuState.init() {
        isMenuOpen = false
    }

    override fun RBuilder.render() {
        div {
            styledDiv {
                css {
                    cursor = Cursor.pointer
                }

                attrs.onClickFunction = { setState { isMenuOpen = !isMenuOpen } }

                ref = toggleComponentRef

                (props.toggleComponent)(this)
            }

            @Suppress("DEPRECATION")
            styled(MuiMenu)() {
                css {
                    marginTop = 4.px

                    specific {
                        children {
                            backgroundColor = WHITE.cssValue
                            borderRadius = 0.px
                        }
                    }
                }

                attrs {
                    anchorEl = toggleComponentRef.current
                    // This is necessary for the menu to appear under the anchor
                    // (see https://stackoverflow.com/questions/48157863/how-to-make-a-dropdown-menu-open-below-the-appbar-using-material-ui)
                    getContentAnchorEl = null

                    open = state.isMenuOpen

                    autoFocus = true
                    variant = "menu"
                    anchorOrigin = jso {
                        vertical = "bottom"
                        horizontal = "left"
                    }
                    transformOrigin = jso {
                        vertical = "top"
                        horizontal = "left"
                    }

                    onClose = { setState { isMenuOpen = false } }
                }

                for (item in props.items) {
                    styled(MuiMenuItem)() {
                        css {
                            children {
                                padding(vertical = 8.px)
                            }

                            hover {
                                specific {
                                    backgroundColor = SECONDARY.cssValue
                                }

                                descendants {
                                    // Set all descendants of this element to have white text so it looks good on the
                                    // highlighted background
                                    color = WHITE.cssValue
                                }
                            }
                        }

                        attrs.onClick = { onMenuItemSelect(item) }

                        body1 { +item.label }
                    }
                }
            }
        }
    }

    /**
     * Calls [MenuProps.onSelect] with the [item] received and closes the menu
     */
    private fun onMenuItemSelect(item: MenuItem) {
        setState { isMenuOpen = false }

        props.onSelect(item.value)
    }
}

/**
 * Properties of the [Menu] component.
 */
private external interface MenuProps : Props {

    /**
     * The items to display in the [Menu].
     */
    var items: List<MenuItem>

    /**
     * Function called when a menu item is selected.
     */
    var onSelect: (itemId: String) -> Unit

    /**
     * The component that, when clicked, toggles the menu open or closed.
     */
    var toggleComponent: (RBuilder) -> Unit

}

/**
 * The state of the [Menu] component.
 */
private external interface MenuState : State {

    /**
     * If `true` show the [Menu] as expanded. If `false` the menu is hidden.
     */
    var isMenuOpen: Boolean

}

/**
 * Renders a [Menu] component.
 */
public fun RBuilder.menu(items: List<MenuItem>,
                         onSelect: (item: String) -> Unit,
                         toggleComponent: RBuilder.() -> Unit) {
    child(Menu::class) {
        attrs.items = items
        attrs.onSelect = onSelect
        attrs.toggleComponent = toggleComponent
    }
}