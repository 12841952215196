package com.supergenerous.common.serialization

import com.supergenerous.common.util.toLocalDate
import com.supergenerous.common.util.toTimestamp
import kotlinx.datetime.LocalDate
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

/**
 * Serializer used to convert [LocalDate]s to timestamp `Long`s and vice-versa. Timestamps are used so the DB can be
 * easily queried.
 *
 * This is used when sending/receiving data from the server or when storing/reading from the local DB.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializer(forClass = LocalDate::class)
public object LocalDateSerializer : KSerializer<LocalDate> {

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(serialName = "sg.LocalDateSerializer",
                                                                          kind = PrimitiveKind.LONG)


    @Suppress("PARAMETER_NAME_CHANGED_ON_OVERRIDE")
    override fun serialize(encoder: Encoder, date: LocalDate) {
        encoder.encodeLong(date.toTimestamp())
    }

    override fun deserialize(decoder: Decoder): LocalDate {
        return decoder.decodeLong().toLocalDate()
    }

}