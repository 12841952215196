package com.hipsheep.kore.json

/**
 * [Exception] thrown when an error occurs while trying to parse a JSON object.
 */
public class JsonParseException(

    override val message: String?,
    override val cause: Throwable? = null

) : Exception()