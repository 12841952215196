package supergenerous.app.donor.rebate

import com.supergenerous.common.donation.Donation
import com.supergenerous.common.donation.request.DonationRequest
import com.supergenerous.common.organisation.Organisation
import com.supergenerous.common.rebate.Rebate
import supergenerous.app.core.progress.ProgressStatus
import supergenerous.app.core.progress.ProgressStatus.*

/**
 * Details about the rebate claim for a single [donation] and which stage it is at in the claim process.
 */
public data class RebateClaim(

    /**
     * Donee that received the [donation] or the donation platform through which it was made, or `null` for unexpected
     * rebates received that have no associated [donation].
     */
    public val organisation: Organisation?,

    /**
     * [DonationRequest] sent to the [organisation] to get the donor's donations, or `null` if [donation] is not `null`.
     *
     * This value is ignored if [donation] exists because it's not necessary in that instance.
     */
    public val donationRequest: DonationRequest?,

    /**
     * One of the [Donation]s returned by the [organisation] for the [donationRequest].
     *
     * This value is `null` if any of the following is true:
     * * The [donationRequest] was not sent yet.
     * * The [donationRequest] is still open.
     * * No donation was found for the [donationRequest].
     * * The claim refers to a [rebate] that was received by SG but claimed by someone else.
     */
    public val donation: Donation?,

    /**
     * [Rebate] that includes the [donation], or `null` if no rebate claim has been submitted yet for it.
     */
    public val rebate: Rebate?,

    /**
     * Status the [RebateClaim] is in.
     */
    public val status: Status

) {

    /*
     * Inner types
     */

    /**
     * Status of a [RebateClaim].
     */
    public enum class Status(

        /**
         * Equivalent [ProgressStatus] to the [RebateClaim.Status].
         */
        public val progressStatus: ProgressStatus

    ) {

        /**
         * The donations have been requested from the [organisation] but no response has been received yet.
         */
        DONATIONS_REQUESTED(progressStatus = IN_PROGRESS),

        /**
         * The [organisation] answered the [donationRequest] indicating that no donations were found for the donor.
         */
        NO_DONATIONS_FOUND(progressStatus = COMPLETED_FAILURE),

        /**
         * The [organisation] did not answer the [donationRequest] in the timeframe established by SG.
         */
        DONATION_REQUEST_NOT_ANSWERED(progressStatus = COMPLETED_FAILURE),

        /**
         * The [donation] received from the [organisation] is invalid.
         *
         * More information might be available in the [Donation.comments].
         */
        DONATION_INVALID(progressStatus = COMPLETED_FAILURE),

        /**
         * The [donation] was made too long ago and a rebate can't be claimed because of the time threshold imposed by
         * the tax authority.
         */
        DONATION_TOO_OLD_TO_CLAIM(progressStatus = COMPLETED_FAILURE),

        /**
         * The [donation] is ready for a rebate to be claimed for it, but no claim has been submitted yet.
         */
        DONATION_READY_TO_CLAIM(progressStatus = IN_PROGRESS),

        /**
         * The [donation] was made in the current tax year and hence it can't be claimed until the tax year ends.
         */
        DONATION_CURRENT_TAX_YEAR(progressStatus = IN_PROGRESS),

        /**
         * The [rebate] has been submitted to the tax system and it's still being processed.
         */
        REBATE_CLAIM_SUBMITTED(progressStatus = IN_PROGRESS),

        /**
         * The [rebate] claim failed.
         *
         * More information might be available in the [Rebate.comments].
         */
        REBATE_CLAIM_FAILED(progressStatus = COMPLETED_FAILURE),

        /**
         * The rebate for the [donation] was already claimed on the IRD by someone else (e.g., the donor, their
         * accountant, etc.) so it can't be claimed by SG.
         */
        DONATION_CLAIMED_BY_OTHER(progressStatus = COMPLETED_FAILURE),

        /**
         * The [rebate] has been received by SG and it's ready to be disbursed to the donor on the next disbursement
         * date.
         */
        DISBURSEMENT_READY_FOR_DONOR(progressStatus = IN_PROGRESS),

        /**
         * The [rebate] has been received by SG and it's ready to be disbursed to the donee on the next disbursement
         * date.
         */
        DISBURSEMENT_READY_FOR_DONEE(progressStatus = IN_PROGRESS),

        /**
         * The [rebate] has been received by SG and it's ready to be disbursed but the donor has not indicated who
         * should receive it.
         */
        DISBURSEMENT_RECIPIENT_MISSING(progressStatus = ON_HOLD),

        /**
         * The [rebate] has been received by SG and it's ready to be disbursed to the donor but they have not provided
         * their bank account.
         */
        DONOR_BANK_ACCOUNT_MISSING(progressStatus = ON_HOLD),

        /**
         * The [rebate] was sent to someone else (e.g., the donor, their accountant, etc.) so an invoice will be
         * created and sent to the donor (the invoice has not been sent yet).
         */
        FEE_INVOICE_PENDING(progressStatus = IN_PROGRESS),

        /**
         * The [rebate] was sent to someone else (e.g., the donor, their accountant, etc.) and an invoice has been sent
         * to the donor for the SG fee amount.
         */
        FEE_INVOICE_SENT(progressStatus = ON_HOLD),

        /**
         * The [rebate] was sent to someone else (e.g., the donor, their accountant, etc.) and the invoice sent to the
         * donor has been resolved (either paid or voided in agreement with the donor).
         */
        FEE_INVOICE_RESOLVED(progressStatus = COMPLETED_SUCCESS),

        /**
         * The [rebate] has been disbursed to the proper recipient.
         */
        REBATE_DISBURSED(progressStatus = COMPLETED_SUCCESS),

        /**
         * The [rebate] received was not claimed by SG so it'll be disbursed in full to the donor on the next
         * disbursement date.
         */
        REBATE_CLAIMED_BY_OTHER_RECEIVED(progressStatus = IN_PROGRESS),

        /**
         * The [rebate] received was not claimed by SG and it has been disbursed in full to the donor.
         */
        REBATE_CLAIMED_BY_OTHER_DISBURSED(progressStatus = COMPLETED_SUCCESS)

    }

}