package com.supergenerous.common.donor

import com.hipsheep.kore.util.isNotNullOrBlank
import com.supergenerous.common.bank.BankAccountNumber
import com.supergenerous.common.data.DtoDataModel
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.disbursement.DisbursementRecipient.Type.DONOR
import com.supergenerous.common.donation.platform.DonationPlatform
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeBasicInfo
import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.id.TaxId
import com.supergenerous.common.name.FullName
import com.supergenerous.common.serialization.IdDocumentSerializer
import com.supergenerous.common.serialization.LocalDateSerializerString
import com.supergenerous.common.signature.Signature
import com.supergenerous.common.user.UserInfo
import com.supergenerous.common.util.Timestamp
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

/**
 * Donor that signs up to the system.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class Donor(

    override val id: String,

    override val name: String,

    override val email: String,

    override val phoneNumber: String?,

    override val address: String?,

    override val profileImgUrl: String?,

    // TODO: Deprecate and replace with creationTimestamp??
    override val signUpDate: Timestamp,

    override val creationTimestamp: Timestamp = 0,

    override val lastUpdateTimestamp: Timestamp = 0,

    /**
     * Full legal name of the donor.
     */
    val legalName: FullName?,

    /**
     * Date of birth of the donor.
     */
    @Serializable(LocalDateSerializerString::class)
    val dateOfBirth: LocalDate?,

    /**
     * Other emails the donor uses.
     *
     * This helps us identify the donor in different donee DBs where they might have signed up with a different email
     * than the one they used in our system.
     */
    val otherEmails: Set<String>,

    /**
     * ID document assigned by a government (e.g., passport).
     */
    @Serializable(IdDocumentSerializer::class)
    val govId: IdDocument?,

    /**
     * ID used to identify the donor for tax purposes (e.g., IRD number in NZ).
     */
    val taxId: TaxId?,

    /**
     * Bank account where the donor will receive the rebates they decide to get back.
     */
    val bankAccountNumber: BankAccountNumber?,

    /**
     * Names of the donor's children.
     *
     * This is useful when obtaining school donations (some schools require us to provide them with the children's
     * names in order for them to find the proper donation receipts).
     */
    val childrenNames: Set<String>,

    /**
     * Donor's signature.
     */
    val signature: Signature?,

    /**
     * Timestamp when the donor completed their setup with SG.
     */
    // TODO: Move to DonorState
    val setupCompleteTimestamp: Timestamp?,

    /**
     * Status that indicates if the donor is active, inactive or they opted out of the service.
     */
    val activeStatus: ActiveStatus,

    /**
     * Organisations the donor donates to.
     */
    val donees: Set<DoneeBasicInfo>,

    /**
     * Platforms used by the [Donor] to make donations, or `null` if the [Donor] doesn't use a particular platform for
     * this.
     */
    val donationPlatforms: Set<DonationPlatform>,

    /**
     * Settings that indicate which [DisbursementRecipient.Type] will receive the rebates from a specific
     * [Donee.Type].
     */
    val disbursementSettings: MutableMap<Donee.Type, DisbursementRecipient.Type>,

    /**
     * The last known IP address of the user, or `null` if this is not known.
     *
     * This is needed because of Kindo, we can remove this once this is no longer needed.
     */
    val lastKnownIPAddress: String? = null,

    /**
     * Whether the [Donor] chose to receive marketing emails, or `null` if they have not yet chosen.
     */
    val isMarketingEmailsEnabled: Boolean? = null

) : UserInfo, DtoDataModel<DonorDbo> {

    /**
     * `true` if the [Donor] completed the setup process, or `false` otherwise.
     */
    val isSetupComplete: Boolean
        /*
         * This logic is a bit complex because of the following:
         *
         * - The setupCompleteTimestamp property did not exist since the start, so some donors don't have a value for it.
         * - The signature screen didn't use to be the last screen in the setup process, so some donors signed the ATA
         *   but then never provided a tax ID.
         */
        get() = setupCompleteTimestamp != null || (signature?.value.isNotNullOrBlank() && taxId.isNotNullOrBlank())

    /**
     * `true` if the donor needs their identity verified, or `false` otherwise.
     *
     * If the donor didn't complete the setup process yet, then `false` is returned, as they technically don't need
     * an ID verification yet, but they will need it once they complete the setup flow.
     */
    // TODO: Move to DonorState
    public val needsIdVerification: Boolean
        get() = setupCompleteTimestamp != null && setupCompleteTimestamp >= ID_VERIFICATION_START_TIMESTAMP

    /**
     * Extracts the basic info from the [Donor] and returns it.
     */
    public fun getBasicInfo(): DonorBasicInfo {
        return DonorBasicInfo(id = id, name = fullName)
    }

    override fun toDbo(): DonorDbo {
        return DonorDbo(id = id,
                        name = name,
                        email = email,
                        phoneNumber = phoneNumber,
                        address = address,
                        profileImgUrl = profileImgUrl,
                        signUpDate = signUpDate,
                        creationTimestamp = creationTimestamp,
                        lastUpdateTimestamp = lastUpdateTimestamp,
                        legalName = legalName?.toDbo(),
                        dateOfBirth = dateOfBirth?.toString(),
                        otherEmails = otherEmails.toList(),
                        govId = govId?.let { IdDocumentSerializer.convertIdDocToString(it) },
                        taxId = taxId,
                        bankAccountNumber = bankAccountNumber,
                        childrenNames = childrenNames.toList(),
                        signature = signature?.value,
                        setupCompleteTimestamp = setupCompleteTimestamp,
                        activeStatus = activeStatus.toDbo(),
                        donees = donees.map { it.toDbo() },
                        donationPlatforms = donationPlatforms.toList(),
                        disbursementSettings = disbursementSettings.mapKeys { it.key.name }.toMutableMap(),
                        lastKnownIPAddress = lastKnownIPAddress,
                        marketingEmailsEnabled = isMarketingEmailsEnabled,
                        doneeIds = donees.map { it.id })
    }

}

/**
 * Date from which SG became a tax agent (03/05/2022).
 *
 * Any donors before this date (when SG was a tax representative) do not require an ID verification.
 *
 * This is set as a timestamp instead of a date as otherwise the conversion between date and timestamp would
 * have been done using the UTC time zone and mess things up.
 */
// TODO: Move to DonorState
private const val ID_VERIFICATION_START_TIMESTAMP = 1651492800000

/*
 * The following are extension properties so they are not saved in the DB.
 */

/**
 * Legal full name, if available, or display name otherwise.
 */
public val Donor.fullName: String
    get() = legalName?.toString() ?: name

/**
 * Legal first name, if available, or first part of the display name otherwise.
 */
public val Donor.firstName: String
    get() = legalName?.firstName ?: name.split(" ").getOrNull(0) ?: ""

/**
 * Legal last name, if available, or last part of the display name otherwise.
 */
public val Donor.lastName: String
    get() = legalName?.lastName ?: run {
        val nameSplit = name.split(" ")
        val nameLastIndex = nameSplit.lastIndex
        // Return an empty string when the last name was not provided
        if (nameLastIndex > 0) nameSplit[nameLastIndex] else ""
    }

/**
 * The reference used in the bank transfer to donees and other places where we don't want to share the full [Donor.id].
 */
public val Donor.shortId: String
    get() = id.substring(range = 0..11)

/**
 * `true` if the [Donor]'s bank account is required because they are taking some/all rebates back to themselves and they
 * still haven't provide a bank account number ([Donor.bankAccountNumber]).
 *
 * `false` when the [Donor] already provided their bank account number, or they decided to donate all their rebates.
 */
public val Donor.isBankAccountRequired: Boolean
    get() = bankAccountNumber.isNullOrBlank() && disbursementSettings.values.any { disbRecipient -> disbRecipient == DONOR }

/**
 * `true` if the info required to verify the [Donor]'s identity was provided, or `false` otherwise.
 */
public val Donor.isIdVerificationInfoProvided: Boolean
    get() = govId != null && dateOfBirth != null

/**
 * `true` if the info required to run an AML check was provided by the [Donor], or `false` otherwise.
 */
public val Donor.isAmlInfoProvided: Boolean
    get() = govId != null && dateOfBirth != null

/**
 * All of the [Donor]'s emails, with their main email first on the list.
 */
public val Donor.allEmails: Set<String>
    get() = setOf(email) + otherEmails
