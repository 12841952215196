package supergenerous.app.donor.setup.model

import com.supergenerous.common.donor.Donor
import com.supergenerous.common.email.EmailAddress
import supergenerous.app.donor.setup.view.authorityToActScreen

/**
 * The terms that should be displayed on the [authorityToActScreen].
 */
public enum class AuthorityToActTerm(

    /**
     * Text shown on the UI to explain the term.
     */
    public val text: String

) {

    /**
     * SG is authorised to act as the [Donor]'s tax agent.
     */
    TAX_AGENT(text = "I authorise Supergenerous to act as my tax agent with the IRD for the sole purpose of making donation tax credit refund applications for the previous 4 and ongoing tax years."),

    /**
     * The [Donor] can opt out from the SG service.
     */
    SG_OPT_OUT(text = "This authority to act continues unless I email ${EmailAddress.SG_SUPPORT} opting out of this service which I can do at any time."),

    /**
     * SG is authorised to access the tax credit account only.
     */
    TAX_ACCOUNT_ACCESS(text = "I understand Supergenerous will have full access to my donation tax credit account only and visibility over other information held by the IRD used during their assessment of my rebate application."),

    /**
     * SG is authorised to communicate with the IRD on behalf of the donor.
     */
    TAX_COMMUNICATION(text = "Supergenerous is authorised to communicate with and get any information from the IRD directly about my donation tax credits through all channels including by electronic means."),

    /**
     * Tax system correspondence is still directed to the [Donor].
     */
    TAX_CORRESPONDENCE(text = "I understand correspondence from the IRD for my donation tax credit account will still be directed towards me, not Supergenerous."),

    /**
     * SG is authorised to change the [Donor]'s donation tax credit account.
     */
    SG_BANK_ACCOUNT(text = "I authorise my donation tax rebate to be credited to the Supergenerous Bank Account. This will be held on my behalf for the purpose of redirecting the funds as requested by me."),

    /**
     * SG is authorised to take a fee and the [Donor] understands they may not receive a rebate at all.
     */
    SG_FEE(text = "I authorise Supergenerous to deduct a 15% + GST service fee for my donation tax rebate. I understand that rebates are subject to IRD assessment and that I may not get a rebate at all. In this case, I will not be charged any service fee as no rebate was found on my behalf.")

}