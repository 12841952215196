package com.supergenerous.common.donor

import com.supergenerous.common.data.Dbo
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [DonorBasicInfo] class.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class DonorBasicInfoDbo(

    val id: String? = null,

    val name: String? = null

) : Dbo<DonorBasicInfo> {

    override fun toDto(): DonorBasicInfo {
        return DonorBasicInfo(id = id!!, name = name!!)
    }

}