package com.supergenerous.common.util

import kotlinx.datetime.LocalDate
import kotlinx.datetime.toLocalDate
import kotlin.js.Date

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

/**
 * Converts the `Date` to a `String` with ISO 8601 format and returns the result including/excluding the time
 * depending on the value of [dateOnly].
 *
 * See https://en.wikipedia.org/wiki/ISO_8601
 */
public fun Date.toIsoString(dateOnly: Boolean = false): String {
    // Add offset to date to make sure toISOString() works properly, otherwise the date will be converted to UTC and
    // hence the date string might be incorrect sometimes
    // (see https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd)
    val timezoneOffset = getTimezoneOffset()
    val dateStr = Date(getTime() - (timezoneOffset * 60_000)).toISOString()

    return if (dateOnly) dateStr.split("T")[0] else dateStr
}

/**
 * Converts the `Date` to a `LocalDate`.
 */
public fun Date.toLocalDate(): LocalDate {
    return toIsoString(dateOnly = true).toLocalDate()
}

/**
 * Converts the `LocalDate` to a `Date`.
 */
public fun LocalDate.toDate(): Date {
    return Date(this.toString())
}

/**
 * Returns `true` if the `Date` is valid, or `false` otherwise.
 */
public fun Date.isValid(): Boolean {
    return !getTime().isNaN()
}