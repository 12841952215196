package supergenerous.app.core.component.progress

import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.JustifyItems
import kotlinx.css.LinearDimension.Companion.maxContent
import kotlinx.css.alignItems
import kotlinx.css.backgroundColor
import kotlinx.css.borderRadius
import kotlinx.css.display
import kotlinx.css.gap
import kotlinx.css.height
import kotlinx.css.justifyItems
import kotlinx.css.minHeight
import kotlinx.css.minWidth
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import react.RBuilder
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.progress.ProgressStatus
import supergenerous.app.core.progress.ProgressStatus.*
import supergenerous.app.core.res.Color

/**
 * Renders the [status] received.
 */
public fun RBuilder.progressStatus(status: ProgressStatus) {
    styledDiv {
        css {
            display = Display.flex
            justifyItems = JustifyItems.start
            alignItems = Align.center

            gap = 8.px
        }

        // Draw the coloured circle
        progressIcon(status)

        // Add the text
        progressText(status)
    }
}

/**
 * Renders a circle with a color as a visual indicator of the [status].
 */
private fun RBuilder.progressIcon(status: ProgressStatus) {
    val statusColor = when (status) {
        NOT_STARTED -> Color.WHITE
        IN_PROGRESS -> Color.WARNING
        ON_HOLD -> Color.ERROR
        COMPLETED_SUCCESS -> Color.SUCCESS
        COMPLETED_FAILURE -> Color.ERROR
    }

    styledDiv {
        css {
            borderRadius = 50.pct
            minWidth = 12.px
            minHeight = 12.px
            width = 12.px
            height = 12.px

            backgroundColor = statusColor.cssValue
        }
    }
}

/**
 * Renders the [status] text.
 */
private fun RBuilder.progressText(status: ProgressStatus) {
    body1 {
        css {
            // Make the text not wrap
            width = maxContent
        }

        val text = when (status) {
            NOT_STARTED -> "Not started"
            ON_HOLD -> "On hold"
            IN_PROGRESS -> "In progress"
            COMPLETED_SUCCESS -> "Complete"
            COMPLETED_FAILURE -> "Error"
        }

        +text
    }
}