package supergenerous.app.donor.donor

import com.hipsheep.kore.model.network.HttpStatusCode.NOT_FOUND
import com.hipsheep.kore.model.network.Response
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.donor.DonorDbo
import com.supergenerous.common.firebase.DONORS
import com.supergenerous.common.firebase.FirestoreCollection
import com.supergenerous.common.firebase.FirestoreService
import kotlinx.coroutines.flow.Flow

/**
 * Service used to access [Donor] data.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public class DonorService(

    collection: FirestoreCollection<DonorDbo> = FirestoreCollection.DONORS

) : FirestoreService<Donor, DonorDbo>(collection) {

    /**
     * Gets the [Donor] that matches the [id] received and returns it, or returns a [NOT_FOUND] error if it was not
     * created yet.
     */
    public suspend fun getDonor(id: String): Response<Donor> {
        return getDoc(id = id)
    }

    /**
     * Returns a [Flow] that will receive updates as the data of the [Donor] that matches [id] is updated.
     */
    public fun loadDonor(id: String): Flow<Response<Donor>> {
        return loadDoc(id = id)
    }

    /**
     * Updates an existing [donor] in the server.
     */
    public suspend fun updateDonor(donor: Donor): Response<Donor> {
        return updateDoc(donor)
    }

}