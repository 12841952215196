package com.hipsheep.kore.viewmodel.lifecycle

public actual abstract class LiveData<T> {

    /**
     * List of [ObserverWrapper]s currently monitoring this [LiveData].
     */
    private val observers: MutableList<ObserverWrapper<T>> = mutableListOf()

    /**
     * Value that is being observed by the [observers] in this [LiveData].
     */
    internal var value: T? = null
        set(newValue) {
            field = newValue

            // Notify the observers when the value changes
            notifyObservers()
        }


    /**
     * Adds the given [observer] to the [observers] list within the lifespan of the given [owner].
     *
     * If the [LiveData] already has stored [value], it will be delivered to the [observer].
     *
     * The events are dispatched on the main thread.
     */
    public fun observe(owner: LifecycleOwner, observer: Observer<T>) {
        if (!owner.isActive) {
            // TODO: Log message
            return
        }

        observe(LifecycleBoundObserver(owner, observer))
    }

    /**
     * Adds the given [observer] to the [observers] list. This call is similar to [observe] with
     * a [LifecycleOwner], which is always active. This means that the given [observer] will
     * receive all events and will never be automatically removed. You should manually call
     * [removeObserver] to stop observing this [LiveData].
     *
     * While [LiveData] has one of such observers, it will be considered as active.
     */
    public fun observeForever(observer: Observer<T>): ObserverWrapper<T> {
        val observerWrapper = AlwaysActiveObserver(observer)

        observe(observerWrapper)

        return observerWrapper
    }

    /**
     * Adds the [observerWrapper] received to the [observers] list.
     *
     * If the [LiveData] already has a stored [value], it will be delivered to the [Observer] inside the
     * [observerWrapper].
     *
     * The events are dispatched on the main thread.
     */
    private fun observe(observerWrapper: ObserverWrapper<T>) {
        observers.add(observerWrapper)

        val lastValue = value
        if (lastValue != null) {
            // If there is a value already, send it to the observer
            observerWrapper.observer(lastValue)
        }
    }

    /**
     * Removes the given [observer] from the [observers] list.
     */
    public fun removeObserver(observer: Observer<*>) {
        observers.removeAll { it.observer == observer }
    }

    /**
     * Removes the given [observerWrapper] from the [observers] list.
     */
    public fun removeObserver(observerWrapper: ObserverWrapper<T>) {
        // TODO: Check if this works as ObserverWrapper is not a data class
        observers.remove(observerWrapper)
    }

    /**
     * Notifies the active [observers] of the current [value].
     */
    private fun notifyObservers() {
        for (observerWrapper in observers) {
            observerWrapper.observer(value as T)
        }
    }

    /*
     * Inner types
     */

    /**
     * [ObserverWrapper] that contains an [Observer] and the [LifecycleOwner] that it belongs to.
     */
    private class LifecycleBoundObserver<T>(

        /**
         * [LifecycleOwner] where the [observer] was created.
         */
        private val owner: LifecycleOwner,

        observer: Observer<T>

    ) : ObserverWrapper<T>(observer) {

        override val isActive: Boolean
            get() = owner.isActive

    }

    /**
     * [ObserverWrapper] that is always active.
     */
    private class AlwaysActiveObserver<T>(observer: Observer<T>) : ObserverWrapper<T>(observer) {

        override val isActive: Boolean
            get() = true

    }

}

public actual val <T> LiveData<T>.data: T?
    get() = value