package supergenerous.app.core.splash

import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.GridTemplateRows
import kotlinx.css.alignSelf
import kotlinx.css.display
import kotlinx.css.fr
import kotlinx.css.gridTemplateRows
import kotlinx.css.height
import kotlinx.css.marginBottom
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.vh
import kotlinx.css.vw
import kotlinx.css.width
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import styled.css
import styled.styledDiv
import styled.styledImg
import supergenerous.app.core.component.progress.loadingBar
import supergenerous.app.core.res.image.Image
import supergenerous.app.core.util.JustifyItems
import supergenerous.app.core.util.justifyItems
import supergenerous.app.core.util.mobileScreen

/**
 * Screen shown while the app is starting up.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class SplashScreen : RComponent<SplashScreenProps, State>() {

    override fun RBuilder.render() {
        styledDiv {
            css {
                width = 100.vw
                height = 100.vh

                display = Display.inlineGrid
                gridTemplateRows = GridTemplateRows(1.fr, 1.fr)
                justifyItems(JustifyItems.center)
            }

            styledImg(src = props.logo.path) {
                css {
                    width = 520.px
                    mobileScreen { width = 80.pct }
                    marginBottom = 64.px

                    alignSelf = Align.end
                }
            }

            styledDiv {
                css {
                    width = 400.px
                    mobileScreen { width = 80.pct }

                    alignSelf = Align.start
                }

                loadingBar()
            }
        }
    }

}

/**
 * Properties used by the [SplashScreen] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface SplashScreenProps : Props {

    /**
     * Logo shown in the center of the [SplashScreen].
     */
    var logo: Image

}

/**
 * Renders a [SplashScreen] component.
 */
public fun RBuilder.splashScreen(logo: Image) {
    child(SplashScreen::class) {
        attrs.logo = logo
    }
}