package supergenerous.app.core.util

import com.supergenerous.common.network.Url
import kotlinx.browser.window
import org.w3c.dom.url.URLSearchParams
import react.Component
import react.ElementType
import react.Props
import react.RBuilder
import react.RElementBuilder
import react.RHandler
import react.ReactNode
import react.State
import react.buildElement
import react.fc
import react.react
import react.router.dom.HashRouter
import react.router.dom.History
import react.router.dom.Location
import react.router.dom.Match
import react.router.dom.RPrompt
import react.router.dom.Redirect
import react.router.dom.Route
import react.router.dom.useHistory
import react.router.dom.useLocation
import react.router.dom.useRouteMatch
import kotlin.reflect.KClass

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

/**
 * Wrapper of [Route] that receives a [Url.Path] enum instead of a `String`.
 */
public fun RBuilder.route(path: Url.Path,
                          exact: Boolean = false,
                          strict: Boolean = false,
                          children: RBuilder.() -> Unit) {
    Route {
        attrs.path = arrayOf(path.value)
        attrs.exact = exact
        attrs.strict = strict

        // Use the children attribute for rendering. This takes a function that returns the child elements, but that is
        // not functionality that is exposed by the kotlin wrappers.
        attrs.children = { buildElement(handler = { children() }) }.unsafeCast<ReactNode>()
    }
}

/**
 * Wrapper of [Redirect] that receives [Url.Path] enums instead of `String`s.
 */
public fun RBuilder.redirect(to: Url.Path,
                             push: Boolean = false,
                             exact: Boolean = false,
                             strict: Boolean = false) {
    Redirect {
        attrs.to = to.value
        attrs.push = push
        attrs.exact = exact
        attrs.strict = strict
    }
}

/**
 * Provides [History], [Location] and [Match] to the [component], and initialises props using [handler].
 *
 * Mimics the functionality of react-router-dom v5 "withRouter()".
 */
public fun <P : RouteProps, S : State, C : Component<P, S>> RBuilder.withRouter(component: KClass<C>,
                                                                                handler: RHandler<P> = {}) {
    child(routeProvider) {
        attrs.component = component.react as ElementType<RouteProps>
        attrs.handler = handler as (RElementBuilder<RouteProps>) -> Unit
    }
}

/**
 * Injects the [RouteProps] to the component in [RouteProviderProps.component].
 */
private val routeProvider = fc<RouteProviderProps<RouteProps>> { props ->
    val history: History = useHistory()
    val location: Location = useLocation()
    val match: Match = useRouteMatch()

    child(props.component) {
        attrs.history = history
        attrs.location = location
        attrs.match = match

        props.handler(this)
    }
}

/**
 * Props for the [routeProvider].
 */
private external interface RouteProviderProps<P : RouteProps> : Props {

    var component: ElementType<P>

    var handler: (RElementBuilder<P>) -> Unit

}

/**
 * Props provided to the child of the wrapper component created in [withRouter].
 *
 * Reimplementation of the old `react.router.dom.RouteComponentProps` provided by the old `withRouter` HOC.
 */
public external interface RouteProps : Props {

    public var history: History
    public var location: Location
    public var match: Match

}

/**
 * Wrapper of [History.push] that receives a [Url.Path] enum instead of a `String`.
 */
public fun History.push(path: Url.Path) {
    push(path.value)
}

/**
 * Query parameters on the [Location]'s URL.
 */
public val Location.urlParams: URLSearchParams
    get() = URLSearchParams(search)

/**
 * Wrapper of [HashRouter] that sets shared functionality for the `getUserConfirmation()` argument.
 *
 * This is necessary to display [RPrompt]s properly.
 */
public fun RBuilder.appRouter(handler: RHandler<Props>) {
    HashRouter {
        attrs.getUserConfirmation = { message, callback -> callback(window.confirm(message)) }
        handler()
    }
}