package com.hipsheep.kore.json

import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.json.decodeFromDynamic
import kotlinx.serialization.json.encodeToDynamic

/**
 * Parses the [dynamic] object received to an object of type [T] using the [deserializer] and returns the result.
 *
 * @throws JsonParseException When [dynamic] can't be parsed.
 */
public fun <T> JsonParser.fromDynamic(dynamic: dynamic, deserializer: DeserializationStrategy<T>): T {
    return try {
        kxJsonParser.decodeFromDynamic(deserializer, dynamic)
    } catch (e: Exception) {
        throw JsonParseException("Failed to deserialize dynamic object. Error: ${e.message}", cause = e)
    }
}

/**
 * Parses the [value] received into a dynamic object using the [serializer] and returns the result.
 *
 * @throws JsonParseException When [value] can't be parsed.
 */
public fun <T> JsonParser.toDynamic(value: T, serializer: SerializationStrategy<T>): dynamic {
    return try {
        kxJsonParser.encodeToDynamic(serializer, value)
    } catch (e: Exception) {
        throw JsonParseException("Failed to serialize object to dynamic object. Error: ${e.message}", cause = e)
    }
}