package supergenerous.app.core.util

import com.supergenerous.common.network.Url
import org.w3c.dom.Window

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

/**
 * Opens the [url] received on the current tab or a new one (depending on the value of [useNewTab]).
 */
public fun Window.open(url: Url, useNewTab: Boolean = false) {
    open(url.value, useNewTab)
}

/**
 * Opens the [url] received on the current tab or a new one (depending on the value of [useNewTab]).
 */
public fun Window.open(url: String, useNewTab: Boolean = false) {
    if (useNewTab) {
        open(url, "_blank")
    } else {
        window.location.href = url
    }
}