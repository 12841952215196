package supergenerous.app.core.search.typesense

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * The response body of a typesense search.
 *
 * See https://typesense.org/docs/0.23.0/api/search.html#search
 */
@Serializable
public data class TypesenseRespBody(

    /**
     * Total number of results for the search.
     */
    val found: Int,

    /**
     * Total number of records before filtering.
     */
    @SerialName("out_of")
    val outOf: Int,

    /**
     * Page number of the current results of the search.
     */
    val page: Int,

    /**
     * How long the search took in milliseconds.
     */
    @SerialName("search_time_ms")
    val searchTimeMs: Long,

    /**
     * Results of the search.
     */
    val hits: List<TypesenseSearchHit>

)