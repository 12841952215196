package supergenerous.app.donor.donor

import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.id.IdDocument.Type.*
import kotlinx.datetime.LocalDate

/**
 * Consolidated class to hold government ID field inputs, to make it easier to pass this data around.
 */
public data class GovIdInput(
    val govIdType: IdDocument.Type?,
    val govIdNumber: String?,
    val passportExpiryDate: LocalDate?,
    val driverLicenceVersion: String?
) {

    /**
     * Converts the input into the [IdDocument] indicated by [govIdType].
     */
    public fun toIdDocument(): IdDocument? {
        return when (govIdType) {
            DRIVER_LICENCE_NZ -> IdDocument.DriverLicenceNz(number = govIdNumber!!, version = driverLicenceVersion!!)
            PASSPORT -> IdDocument.Passport(number = govIdNumber!!, expiryDate = passportExpiryDate!!)
            null -> null
        }
    }
}