package supergenerous.app.donor.setup.view

import com.supergenerous.common.disbursement.DisbursementRecipient.Type.DONOR
import com.supergenerous.common.disbursement.DisbursementRecipient.Type.SCHOOLS
import com.supergenerous.common.donee.Donee.Type.SCHOOL
import react.RBuilder
import react.State
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.SCHOOLS_SELECTION
import supergenerous.app.donor.setup.viewmodel.SetupViewModel

/**
 * Screen that allows donors to select which schools they donate to.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class SchoolsSelectionScreen : SetupScreen<SetupScreenProps, State>() {

    override val setupStep: SetupStep = SCHOOLS_SELECTION


    override fun RBuilder.render() {
        doneesSelectionScreen(doneeType = SCHOOL,
                              disbRecipientTypes = listOf(SCHOOLS, DONOR),
                              setupViewModel = props.viewModel,
                              onBackBtnClick = ::goBack)
    }

}

/**
 * Renders a [SchoolsSelectionScreen] component.
 */
public fun RBuilder.schoolsSelectionScreen(setupViewModel: SetupViewModel) {
    withRouter(SchoolsSelectionScreen::class) {
        attrs.viewModel = setupViewModel
    }
}