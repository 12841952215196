package com.supergenerous.common.user

import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable

/**
 * User that signs up to the system.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public open class User(

    override val id: String,

    override val name: String,

    override val email: String,

    override val phoneNumber: String? = null,

    override val address: String? = null,

    override val profileImgUrl: String? = null,

    override val signUpDate: Timestamp

) : UserInfo