package supergenerous.app.donor.setup.model

/**
 * List of steps involved in the donor setup process.
 *
 * IMPORTANT: The enum values must be in the order that the process is shown so the [position] property returns the
 * proper value.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class SetupStep(public val stepNumber: Int, public val stepName: String) {

    DONEE_TYPES_SELECTION(stepNumber = 1, stepName = "one"),
    CHARITIES_SELECTION(stepNumber = 1, stepName = "one - charities"),
    RELIGIOUS_ORGS_SELECTION(stepNumber = 1, stepName = "one - religious organisations"),
    SCHOOLS_SELECTION(stepNumber = 1, stepName = "one - schools"),
    PERSONAL_INFO(stepNumber = 2, stepName = "two"),
    CONTACT_INFO(stepNumber = 3, stepName = "three"),
    GOV_ID(stepNumber = 4, stepName = "four"),
    TAX_ID(stepNumber = 5, stepName = "five"),
    BANK_ACCOUNT(stepNumber = 6, stepName = "six"),
    AUTHORITY_TO_ACT(stepNumber = 7, stepName = "seven"),
    END(stepNumber = 8, stepName = "eight");

    /**
     * Position of the step in the overall setup process.
     */
    public val position: Int = ordinal

    /*
     * Inner types
     */

    public companion object {

        /**
         * The total number of steps in the [SetupStep]s
         */
        public const val NUM_STEPS: Int = 7

    }

}