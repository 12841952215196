package supergenerous.app.core.util.component

import com.supergenerous.common.network.CoreUrl
import com.supergenerous.common.network.Url
import kotlinx.css.Display
import kotlinx.css.display
import kotlinx.css.height
import kotlinx.css.minHeight
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import kotlinx.html.tabIndex
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import styled.css
import styled.styledA
import styled.styledImg
import supergenerous.app.core.res.SgLogoColor
import supergenerous.app.core.res.image.CoreImage
import supergenerous.app.core.util.ariaLabel

/**
 * Renders the SG Logo. Optionally allows setting the link that clicking on the logo should navigate to, or null to make
 * it not clickable.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
@JsExport
private class SgLogo : RComponent<SgLogoProps, State>() {

    override fun RBuilder.render() {
        styledA {
            css {
                height = 100.pct
                display = Display.block
            }

            // Don't allow tab focus to the logo because we don't have a way to show it is focused.
            // TODO: Show focus on logo
            attrs.tabIndex = "-1"

            props.redirectUrl?.let {
                attrs.href = it.value

                // Set the rel attribute to "noopener noreferrer" to avoid a security problem:
                // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
                attrs.rel = "noopener noreferrer"
            }

            val image = when (props.color) {
                SgLogoColor.GREEN -> CoreImage.SG_LOGO_GREEN
                SgLogoColor.WHITE -> CoreImage.SG_LOGO_WHITE
            }

            styledImg(src = image.path) {
                css {
                    // Setting 100% for width and height will mean the image fits itself within the parent element. If
                    // only one of these is set then the image could expand past the parent's edges.
                    height = 100.pct
                    width = 100.pct

                    // 20 pixels is the minimum logo height according to brand guidelines.
                    minHeight = 20.px
                }

                attrs.ariaLabel("Supergenerous")
            }
        }
    }
}

/**
 * Properties of the [SgLogo] component.
 */
private external interface SgLogoProps : Props {

    /**
     * The [Url] to navigate to on logo click.
     */
    var redirectUrl: Url?

    /**
     * The color to render the logo as.
     */
    var color: SgLogoColor

}

/**
 * Renders an [SgLogo] with [color].
 *
 * When the logo is clicked the user will be redirected to [redirectUrl], or they'll remain in the same screen if
 * [redirectUrl] is `null`.
 */
public fun RBuilder.sgLogo(color: SgLogoColor, redirectUrl: Url? = CoreUrl.SG_WEBSITE) {
    child(SgLogo::class) {
        attrs.color = color
        attrs.redirectUrl = redirectUrl
    }
}