package com.hipsheep.kore.model.di

import com.hipsheep.kore.di.DIManager
import com.hipsheep.kore.model.network.HttpClient
import com.hipsheep.kore.model.network.HttpService

/*
 * @author Franco Sabadini (franco@hipsheep.com)
 */

/**
 * [HttpClient] used in all the [HttpService]s.
 */
internal val DIManager.httpClient: HttpClient by lazy { HttpClient() }
