package com.hipsheep.kore.di

import com.hipsheep.kore.json.JsonParser
import kotlinx.serialization.json.Json

/**
 * Dependency injection manager that contains the objects that will be injected at runtime.
 */
public object DIManager {

    /**
     * [JsonParser] that can be used through the app.
     */
    public val jsonParser: JsonParser by lazy { JsonParser() }

    /**
     * [Json] parser used in different parts of the library.
     *
     * This value is defined inside [DIManager] so its configuration can be set once and used everywhere consistently.
     */
    public val kxJsonParser: Json = Json {
        ignoreUnknownKeys = true
        encodeDefaults = true
    }

}