package supergenerous.app.core.component.style

import kotlinx.css.BorderStyle
import kotlinx.css.Outline
import kotlinx.css.RuleSet
import kotlinx.css.outline
import kotlinx.css.properties.border
import kotlinx.css.properties.boxShadow
import kotlinx.css.px
import styled.StyleSheet
import supergenerous.app.core.res.Color
import supergenerous.app.core.res.Color.FOCUS_RING

/**
 * List of CSS styles used for input elements in the app.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
public object InputStyle : StyleSheet("InputStyle", isStatic = true) {

    public val focusRing: RuleSet by css {
        rule(":focus-visible, .Mui-focused") {
            outline = Outline.none
            boxShadow(color = FOCUS_RING.cssValue, blurRadius = 6.px, spreadRadius = 3.px)
        }
    }

    public val border: RuleSet by css {
        border(width = 1.px,
               style = BorderStyle.solid,
               color = Color.BLACK.cssValue,
               borderRadius = 0.px)
    }

}