package supergenerous.app.core.component.button

import kotlinx.css.BorderStyle
import kotlinx.css.FontWeight
import kotlinx.css.LinearDimension
import kotlinx.css.TextTransform
import kotlinx.css.backgroundColor
import kotlinx.css.borderColor
import kotlinx.css.borderRadius
import kotlinx.css.borderWidth
import kotlinx.css.color
import kotlinx.css.fontFamily
import kotlinx.css.fontSize
import kotlinx.css.fontWeight
import kotlinx.css.height
import kotlinx.css.letterSpacing
import kotlinx.css.lineHeight
import kotlinx.css.margin
import kotlinx.css.minWidth
import kotlinx.css.padding
import kotlinx.css.properties.LineHeight
import kotlinx.css.properties.border
import kotlinx.css.px
import kotlinx.css.textTransform
import kotlinx.css.width
import styled.StyleSheet
import supergenerous.app.core.component.style.InputStyle
import supergenerous.app.core.res.Color.*
import supergenerous.app.core.res.Font
import supergenerous.app.core.util.mobileScreen

/**
 * List of styles used by button components (e.g., [Button]).
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
internal object ButtonStyle : StyleSheet("ButtonStyle", isStatic = true) {

    /**
     * Alpha value used when the button is disabled.
     */
    private const val DISABLED_ALPHA = 0.3

    /**
     * Common text style shared between all buttons.
     */
    private val text by css {
        fontFamily = Font.DOMAINE_SANS
        fontWeight = FontWeight.bold
        textTransform = TextTransform.uppercase
    }

    /**
     * Font size for a large button.
     */
    internal val FONT_SIZE_LARGE = 17.px

    /**
     * Font size for a small button.
     */
    internal val FONT_SIZE_SMALL = 14.px

    /*
     * Button type styles
     */

    /**
     * Primary button style.
     */
    val primary by css {
        +text
        borderRadius = 0.px

        specific {
            backgroundColor = SECONDARY.cssValue
            color = WHITE.cssValue

            active {
                backgroundColor = BTN_PRIMARY_ACTIVE.cssValue
            }

            hover {
                backgroundColor = BTN_PRIMARY_HOVER.cssValue
            }

            disabled {
                backgroundColor = SECONDARY.cssValue.withAlpha(DISABLED_ALPHA)
            }

            +InputStyle.focusRing
        }
    }

    /**
     * Secondary button style.
     */
    val secondary by css {
        +text
        borderRadius = 0.px

        specific {
            backgroundColor = TRANSPARENT.cssValue
            color = BLACK.cssValue

            border(width = 1.px, style = BorderStyle.solid, color = BLACK.cssValue, borderRadius = 0.px)

            hover {
                borderColor = PRIMARY.cssValue
                color = PRIMARY.cssValue
            }

            active {
                borderColor = BLACK.cssValue
                backgroundColor = BLACK.cssValue
                color = WHITE.cssValue
            }

            disabled {
                backgroundColor = WHITE.cssValue.withAlpha(DISABLED_ALPHA)
                color = BLACK.cssValue.withAlpha(DISABLED_ALPHA)
            }

            +InputStyle.focusRing
        }
    }

    /**
     * Accent button style.
     */
    val accent by css {
        +text
        borderRadius = 0.px
        borderWidth = 0.px

        specific {
            +InputStyle.focusRing

            backgroundColor = TRANSPARENT.cssValue
            color = ACCENT.cssValue

            hover { color = ACCENT.cssValue.darken(20) }
            active { color = WHITE.cssValue }
            disabled { color = ACCENT.cssValue.withAlpha(DISABLED_ALPHA) }
        }
    }

    /**
     * Low profile button style.
     */
    val lowProfile by css {
        +secondary
        fontWeight = FontWeight.normal
        textTransform = TextTransform.none

        specific {
            borderWidth = 0.px
        }
    }

    /*
     * Button size styles
     */

    /**
     * Large button style.
     */
    val large by css {
        fontSize = FONT_SIZE_LARGE
        lineHeight = LineHeight(22.px.value)
        letterSpacing = (-0.5).px

        height = 48.px
        width = LinearDimension.maxContent
        minWidth = 200.px

        // There is an odd behaviour with buttons when we use min-width, max-content, and padding on the parent. Add the
        // desired padding to the label as a margin instead of having padding on the parent to get around this.
        // The weird behaviour is that the parent expects the button width to be min-width + padding, when instead it
        // should be the max of `min-width` and `width + padding`
        padding(all = 0.px)
        children {
            firstChild {
                margin(vertical = 12.px, horizontal = 20.px)
            }
        }
    }

    /**
     * Small button style.
     */
    val small by css {
        fontSize = FONT_SIZE_SMALL
        lineHeight = LineHeight(18.px.value)
        letterSpacing = (-0.2).px

        height = 34.px
        width = LinearDimension.maxContent
        minWidth = 130.px

        // There is an odd behaviour with buttons when we use min-width, max-content, and padding on the parent. Add the
        // desired padding to the label as a margin instead of having padding on the parent to get around this.
        // The weird behaviour is that the parent expects the button width to be min-width + padding, when instead it
        // should be the max of `min-width` and `width + padding`
        padding(all = 0.px)
        children {
            firstChild {
                margin(vertical = 8.px, horizontal = 16.px)
            }
        }

        // Remove min width for small screens to fit the screen size better
        mobileScreen {
            minWidth = 0.px
        }
    }

    /**
     * Extra small button style.
     */
    val extraSmall by css {
        +small

        minWidth = LinearDimension.minContent

        // There is an odd behaviour with buttons when we use min-width, max-content, and padding on the parent. Add the
        // desired padding to the label as a margin instead of having padding on the parent to get around this.
        // The weird behaviour is that the parent expects the button width to be min-width + padding, when instead it
        // should be the max of `min-width` and `width + padding`
        padding(all = 0.px)
        children {
            firstChild {
                margin(vertical = 4.px, horizontal = 8.px)
            }
        }
    }

}