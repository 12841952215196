package com.supergenerous.common.donor

import com.supergenerous.common.data.Dbo
import com.supergenerous.common.data.DboDataModel
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.donation.platform.DonationPlatform
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeBasicInfoDbo
import com.supergenerous.common.name.FullNameDbo
import com.supergenerous.common.serialization.IdDocumentSerializer
import com.supergenerous.common.signature.Signature
import com.supergenerous.common.util.Timestamp
import kotlinx.datetime.toLocalDate
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [Donor] class.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class DonorDbo(

    override var id: String? = null,

    val name: String? = null,

    val email: String? = null,

    val phoneNumber: String? = null,

    val address: String? = null,

    val profileImgUrl: String? = null,

    val signUpDate: Long? = null,

    override var creationTimestamp: Timestamp? = null,

    override var lastUpdateTimestamp: Timestamp? = null,

    val legalName: FullNameDbo? = null,

    val dateOfBirth: String? = null,

    val otherEmails: List<String>? = null,

    val govId: String? = null,

    val taxId: String? = null,

    val bankAccountNumber: String? = null,

    val signature: String? = null,

    val setupCompleteTimestamp: Timestamp? = null,

    val activeStatus: ActiveStatusDbo? = null,

    val donees: List<DoneeBasicInfoDbo>? = null,

    val donationPlatforms: List<DonationPlatform>? = null,

    val disbursementSettings: MutableMap<String, DisbursementRecipient.Type>? = null,

    val childrenNames: List<String>? = null,

    val lastKnownIPAddress: String? = null,

    val marketingEmailsEnabled: Boolean? = null,

    /*
     * DB props only
     */

    /**
     * List of IDs of the [Donor]'s [donees].
     *
     * This value is only used for search purposes on the DB.
     */
    val doneeIds: List<String>? = null

) : DboDataModel<Donor> {

    override fun toDto(): Donor {
        return Donor(id = id!!,
                     name = name!!,
                     email = email!!,
                     phoneNumber = phoneNumber,
                     address = address,
                     profileImgUrl = profileImgUrl,
                     signUpDate = signUpDate!!,
                     creationTimestamp = creationTimestamp!!,
                     lastUpdateTimestamp = lastUpdateTimestamp!!,
                     legalName = legalName?.toDto(),
                     dateOfBirth = dateOfBirth?.toLocalDate(),
                     otherEmails = otherEmails?.toSet() ?: emptySet(),
                     govId = govId?.let { IdDocumentSerializer.convertStringToIdDoc(idDocData = it) },
                     taxId = taxId,
                     bankAccountNumber = bankAccountNumber,
                     childrenNames = childrenNames?.toSet() ?: emptySet(),
                     signature = signature?.let { Signature(v = it) },
                     setupCompleteTimestamp = setupCompleteTimestamp,
                     activeStatus = activeStatus!!.toDto(),
                     donees = donees!!.map { it.toDto() }.toSet(),
                     donationPlatforms = donationPlatforms?.toSet() ?: emptySet(),
                     disbursementSettings = disbursementSettings!!.mapKeys { Donee.Type.valueOf(it.key) }.toMutableMap(),
                     lastKnownIPAddress = lastKnownIPAddress,
                     isMarketingEmailsEnabled = marketingEmailsEnabled)
    }

}