package supergenerous.app.core.component

import kotlinx.css.*
import kotlinx.css.properties.borderTop
import react.RBuilder
import styled.css
import styled.styledHr
import supergenerous.app.core.res.Color.DIVIDER

/**
 * The divider horizontally separates two sections of the page. This can be used to separate items such as form groups
 * to show a logical division.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
public fun RBuilder.dividerHorizontal(margin: LinearDimension = 24.px) {
    styledHr {
        css {
            width = 100.pct
            margin(vertical = margin)

            // Get rid of the default border (top and bottom) and then add a single line (just top) to make it 1px
            border = "none"
            borderTop(width = 1.px, style = BorderStyle.solid, color = DIVIDER.cssValue)
        }
    }
}