package com.supergenerous.common.rebate

import com.supergenerous.common.donation.Donation
import kotlinx.serialization.Serializable

/**
 * List of people or organisations that have claimed [Rebate]s from [Donation]s.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public enum class RebateClaimer {

    /**
     * Supergenerous.
     */
    SG,

    /**
     * The donor (or a tax agent acting on their behalf) claimed the rebate for the [Donation] in the past.
     */
    OTHER,

    /**
     * No rebate claim has yet been made for the [Donation].
     */
    NONE,

    /**
     * The rebate claimer for the [Donation] is not yet known.
     */
    UNKNOWN

}
