package com.supergenerous.common.util

import kotlin.math.round

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

/*
 * Double
 */

/**
 * Rounds the digits after the decimal point to match the [precision] and returns the result.
 */
public fun Double.round(precision: Int): Double {
    var precisionFactor = 1
    (1..precision).forEach { precisionFactor *= 10 }

    return round(this * precisionFactor) / precisionFactor
}

/**
 * Returns the `String` representation of the `Double` rounding the digits after the decimal point to match the
 * [precision].
 */
public fun Double.toString(precision: Int): String {
    return convertToString(value = this,
                           round = { it.round(precision) },
                           precision = precision)
}

/*
 * Float
 */

/**
 * Rounds the digits after the decimal point to match the [precision] and returns the result.
 */
public fun Float.round(precision: Int): Float {
    var precisionFactor = 1
    (1..precision).forEach { precisionFactor *= 10 }

    return round(this * precisionFactor) / precisionFactor
}

/**
 * Returns the `String` representation of the `Float` rounding the digits after the decimal point to match the
 * [precision].
 */
public fun Float.toString(precision: Int): String {
    return convertToString(value = this,
                           round = { it.round(precision) },
                           precision = precision)
}

/**
 * Returns the `String` representation of the [value] rounding the digits after the decimal point to match the
 * [precision] using [round].
 */
private fun <T> convertToString(value: T, round: (T) -> T, precision: Int): String {
    var roundedString = round(value).toString()

    if (precision <= 0) {
        return roundedString.split(".")[0]
    }

    // Use getOrNull() because this method will throw an exception if the number doesn't have values after the "."
    // otherwise (see https://supergenerous.atlassian.net/browse/DEV-380)
    val currentPrecision = roundedString.split('.').getOrNull(1)?.length ?: 0

    // If the float is a full number then add the "." at the end before adding the extra values
    // (see https://supergenerous.atlassian.net/browse/DEV-380)
    if (currentPrecision == 0) {
        roundedString += "."
    }

    (currentPrecision until precision).forEach { roundedString += "0" }

    return roundedString
}