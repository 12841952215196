package supergenerous.app.core.component.button

import kotlinx.css.LinearDimension
import kotlinx.css.backgroundColor
import kotlinx.css.borderRadius
import kotlinx.css.borderWidth
import kotlinx.css.color
import kotlinx.css.height
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import kotlinx.html.ButtonType.button
import kotlinx.html.js.onClickFunction
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import styled.css
import styled.styledButton
import supergenerous.app.core.component.image.icon
import supergenerous.app.core.component.style.InputStyle
import supergenerous.app.core.res.Color
import supergenerous.app.core.res.image.Icon
import supergenerous.app.core.util.mobileScreen

/**
 * Icon button component used throughout the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class IconButton : RComponent<IconButtonProps, State>() {

    override fun RBuilder.render() {
        val backgroundColor = if (props.isOnDarkBackground) Color.WHITE else Color.PRIMARY
        val iconColor = if (props.isOnDarkBackground) Color.WHITE else Color.BLACK

        styledButton {
            css {
                +InputStyle.focusRing

                height = props.size
                width = props.size
                borderWidth = 0.px
                this.backgroundColor = Color.TRANSPARENT.cssValue
                color = iconColor.cssValue

                if (props.addPadding) {
                    borderRadius = 50.pct
                    padding(all = props.size / 4)
                }

                // Set the width and height of the SVG to be 100% so it always fits the container size
                children {
                    height = props.size / 2
                    width = props.size / 2
                }

                // TODO: Check if this make sense for all uses or find a different way to do it
                // The size in mobile screens is 3/4 of the size received
                mobileScreen {
                    val mobileSize = props.size * 0.75
                    height = mobileSize
                    width = mobileSize

                    if (props.addPadding) {
                        padding(all = mobileSize / 4)
                    }

                    // fontSize changes the icon size
                    children {
                        height = mobileSize / 2
                        width = mobileSize / 2
                    }
                }

                focusWithin {
                    this.backgroundColor = backgroundColor.cssValue.withAlpha(0.3)
                }

                hover {
                    this.backgroundColor = backgroundColor.cssValue.withAlpha(0.2)
                }

                active {
                    this.backgroundColor = backgroundColor.cssValue.withAlpha(0.3)
                }
            }

            attrs.onClickFunction = { props.onClick() }

            /*
             * The default button type is "submit" which means when "enter" is pressed while in a <form> element the onclick
             * function of this button will be called. Set the type to "button" to disable this behaviour.
             *
             * See https://github.com/supergenerous/issues/issues/441
             */
            attrs.type = button

            icon(icon = props.icon, size = 100.pct)
        }
    }

}

/**
 * Properties used by the [IconButton] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface IconButtonProps : Props {

    /**
     * Icon to show as a button.
     */
    var icon: Icon

    /**
     * Size of the button.
     *
     * This is not the size of the icon but the size of the whole button, the icon
     * size will be half of this value.
     *
     * If not set, 48px will be used.
     */
    var size: LinearDimension

    /**
     * Whether the button is on a dark background or not.
     */
    var isOnDarkBackground: Boolean

    /**
     * `true` if padding should be added around the icon, or `false` if the area of the button should only be the icon
     * size.
     */
    var addPadding: Boolean

    /**
     * Function called when the button is clicked.
     */
    var onClick: () -> Unit

}

/**
 * Renders an [IconButton] component.
 */
public fun RBuilder.iconButton(icon: Icon,
                               size: LinearDimension = 48.px,
                               isOnDarkBackground: Boolean = false,
                               addPadding: Boolean = true,
                               onClick: () -> Unit) {
    child(IconButton::class) {
        attrs.icon = icon
        attrs.size = size
        attrs.isOnDarkBackground = isOnDarkBackground
        attrs.addPadding = addPadding
        attrs.onClick = onClick
    }
}