package com.supergenerous.common.donor

import com.supergenerous.common.data.Dto
import com.supergenerous.common.donation.platform.DonationPlatform
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.util.LinkStatus
import kotlinx.serialization.Serializable

/**
 * The link state between a [Donor], a [DonationPlatform] and its linked [Donee]s for that platform.
 *
 * @author Raymond Feng (raymond@supergenerous.com)
 */
@Serializable
public data class DonationPlatformLinkState(

    /**
     * External ID of the donor in the [DonationPlatform], or `null` if not yet known.
     */
    val donorExtId: String?,

    /**
     * Link status of the [Donor].
     */
    val linkStatus: LinkStatus

): Dto<DonationPlatformLinkStateDbo> {

    override fun toDbo(): DonationPlatformLinkStateDbo {
        return DonationPlatformLinkStateDbo(donorExtId = donorExtId, linkStatus = linkStatus)
    }

}