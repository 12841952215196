package supergenerous.app.core.component

import com.hipsheep.kore.util.isNotNullOrBlank
import dom.html.HTMLInputElement
import kotlinx.css.Align
import kotlinx.css.BorderStyle.solid
import kotlinx.css.Display
import kotlinx.css.GridRow
import kotlinx.css.GridTemplateColumns
import kotlinx.css.GridTemplateRows
import kotlinx.css.JustifyContent
import kotlinx.css.LinearDimension.Companion.auto
import kotlinx.css.alignSelf
import kotlinx.css.borderColor
import kotlinx.css.borderRadius
import kotlinx.css.borderStyle
import kotlinx.css.borderWidth
import kotlinx.css.display
import kotlinx.css.fontFamily
import kotlinx.css.fontSize
import kotlinx.css.gridRow
import kotlinx.css.gridTemplateColumns
import kotlinx.css.gridTemplateRows
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.marginBottom
import kotlinx.css.marginTop
import kotlinx.css.paddingLeft
import kotlinx.css.paddingRight
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.rowGap
import kotlinx.css.width
import kotlinx.html.InputType
import kotlinx.html.id
import react.Props
import react.RBuilder
import react.RComponent
import react.RefObject
import react.State
import react.createRef
import react.dom.attrs
import react.dom.br
import react.dom.div
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.signature.canvas.CanvasProps
import react.signature.canvas.RSignatureCanvas
import styled.css
import styled.styledDiv
import styled.styledInput
import supergenerous.app.core.component.button.ButtonSize.EXTRA_SMALL
import supergenerous.app.core.component.button.ButtonType.LOW_PROFILE
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.style.InputStyle
import supergenerous.app.core.res.Color.*
import supergenerous.app.core.res.Font
import supergenerous.app.core.res.image.CoreIcon.CLEAR
import supergenerous.app.core.util.JustifySelf.end
import supergenerous.app.core.util.cssStyle
import supergenerous.app.core.util.justifySelf

/**
 * Signature pad component used throughout the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class SignaturePad : RComponent<SignaturePadProps, State>() {

    /**
     * Instance of wrapped [RSignatureCanvas].
     *
     * We use this object to access the methods exposed by [RSignatureCanvas].
     */
    @Suppress("DEPRECATION")
    private var signatureCanvas: RefObject<RSignatureCanvas> = createRef()


    override fun RBuilder.render() {
        /*
         * If there is a signature and it starts with "data:" then it's an "old" signature that should be rendered
         * inside a canvas, if that's not the case then use the "new" signature field which allows people to write their
         * name using the keyboard.
         *
         * All new users should use the "new" text field signature, the "old" version is kept for backwards
         * compatibility only.
         */
        if (props.signature?.startsWith("data:") == true) {
            signatureCanvas()
        } else {
            signatureTextField()
        }
    }

    /**
     * Renders an input for entering a signature by typing their name.
     */
    private fun RBuilder.signatureTextField() {
        div {
            body1 {
                css {
                    marginBottom = 8.px
                }

                +"Type your full name below to sign"
            }

            styledInput {
                css {
                    +InputStyle.focusRing

                    height = 80.px
                    width = 100.pct
                    borderRadius = 0.px
                    borderColor = if (props.errorMessage == null) BLACK.cssValue else ERROR.cssValue
                    borderStyle = solid
                    borderWidth = 1.px

                    fontFamily = Font.QWITCHER_GRYPEN
                    fontSize = 64.px

                    paddingLeft = 24.px
                    paddingRight = 24.px
                }

                attrs {
                    id = "signature-input"
                    type = InputType.text

                    placeholder = props.placeholder ?: ""
                    value = props.signature ?: ""
                    onChange = { event ->
                        val signature = (event.unsafeCast<ChangeEvent<HTMLInputElement>>()).target.value

                        props.onChange(signature)
                    }
                }
            }

            if (props.errorMessage.isNotNullOrBlank()) {
                body2 {
                    css {
                        marginTop = 4.px
                    }

                    textError { +props.errorMessage!! }
                    br {  }
                }
            }
        }
    }

    /**
     * Renders an "old" version of a signature inside a canvas.
     */
    private fun RBuilder.signatureCanvas() {
        styledDiv {
            css {
                display = Display.grid
                gridTemplateRows = GridTemplateRows.auto
                gridTemplateColumns = GridTemplateColumns(auto, 0.pct)
                rowGap = 8.px
            }

            body1 {
                css {
                    gridRow = GridRow("1")
                    alignSelf = Align.center
                }

                +"Sign here"
            }

            styledDiv {
                css {
                    gridRow = GridRow("1")
                    justifySelf(end)

                    display = Display.flex
                    justifyContent = JustifyContent.end
                }

                button(
                    label = "Clear",
                    icon = CLEAR,
                    size = EXTRA_SMALL,
                    type = LOW_PROFILE,
                    onClick = {
                        signatureCanvas.current?.clear()

                        props.onChange(null)
                    }
                )
            }

            @Suppress("DEPRECATION")
            child(RSignatureCanvas::class) {
                attrs {
                    backgroundColor = WHITE.hexValue
                    penColor = TEXT_MAIN.hexValue

                    canvasProps = CanvasProps().apply {
                        style = cssStyle {
                            height = 160.px.value
                            width = 100.pct.value
                            borderRadius = 0.px.value
                            borderColor = if (props.errorMessage == null) BLACK.hexValue else ERROR.hexValue
                            borderStyle = solid.name
                            borderWidth = 1.px.value
                        }
                    }

                    ref = signatureCanvas

                    onEnd = { props.onChange(signatureCanvas.current?.toDataURL()) }
                }
            }

            body2 {
                css {
                    gridRow = GridRow("3")
                }

                if (props.errorMessage.isNotNullOrBlank()) {
                    textError { +props.errorMessage!! }
                    br {  }
                }

                +"Use your mouse or finger to draw your signature above"
            }
        }
    }

    override fun componentDidUpdate(prevProps: SignaturePadProps, prevState: State, snapshot: Any) {
        if (props.signature != null && prevProps.signature != props.signature) {
            signatureCanvas.current?.fromDataURL(props.signature!!)
        }
    }

}

/**
 * Properties used by the [SignaturePad] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface SignaturePadProps : Props {

    /**
     * The error message to display below the signature pad, or `null` if there is none.
     */
    var errorMessage: String?

    /**
     * Placeholder shown in the [SignaturePad] before the user starts typing.
     */
    var placeholder: String?

    /**
     * Signature to show on the [SignaturePad], or `null` if it should be empty.
     */
    var signature: String?

    /**
     * Function called when the user enters a signature or deletes it.
     */
    var onChange: (signature: String?) -> Unit

}

/**
 * Renders a [SignaturePad] component.
 */
public fun RBuilder.signaturePad(signature: String?,
                                 placeholder: String? = null,
                                 onChange: (signature: String?) -> Unit,
                                 errorMessage: String? = null) {
    child(SignaturePad::class) {
        attrs.placeholder = placeholder
        attrs.signature = signature
        attrs.onChange = onChange
        attrs.errorMessage = errorMessage
    }
}