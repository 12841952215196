package supergenerous.app.donor.kindo.view

import com.hipsheep.kore.error.ErrorType
import com.hipsheep.kore.util.isNotNullOrBlank
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.disbursement.DisbursementRecipient.Type.*
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.id.IdDocument.*
import com.supergenerous.common.id.IdDocument.Type.*
import com.supergenerous.common.kindo.KindoParam.*
import com.supergenerous.common.network.CoreUrl.SG_TOS
import kotlinx.browser.window
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.GridColumn
import kotlinx.css.GridRow
import kotlinx.css.GridTemplateColumns
import kotlinx.css.GridTemplateRows
import kotlinx.css.JustifyContent
import kotlinx.css.LinearDimension
import kotlinx.css.Overflow
import kotlinx.css.Position
import kotlinx.css.QuotedString
import kotlinx.css.TextAlign
import kotlinx.css.VerticalAlign
import kotlinx.css.alignItems
import kotlinx.css.alignSelf
import kotlinx.css.backgroundColor
import kotlinx.css.columnGap
import kotlinx.css.content
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.fr
import kotlinx.css.gap
import kotlinx.css.gridColumn
import kotlinx.css.gridRow
import kotlinx.css.gridTemplateColumns
import kotlinx.css.gridTemplateRows
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.left
import kotlinx.css.margin
import kotlinx.css.marginBottom
import kotlinx.css.marginLeft
import kotlinx.css.marginTop
import kotlinx.css.maxWidth
import kotlinx.css.minWidth
import kotlinx.css.overflow
import kotlinx.css.pct
import kotlinx.css.position
import kotlinx.css.px
import kotlinx.css.rowGap
import kotlinx.css.textAlign
import kotlinx.css.top
import kotlinx.css.verticalAlign
import kotlinx.css.width
import kotlinx.datetime.LocalDate
import kotlinx.html.js.onSubmitFunction
import react.RBuilder
import react.State
import react.dom.span
import react.setState
import styled.css
import styled.styledDiv
import styled.styledForm
import styled.styledSection
import supergenerous.app.core.auth.model.AuthError.*
import supergenerous.app.core.auth.model.AuthProvider.GOOGLE
import supergenerous.app.core.auth.view.AuthStyle
import supergenerous.app.core.auth.view.authProviderButton
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.button.ButtonSize
import supergenerous.app.core.component.button.ButtonType
import supergenerous.app.core.component.button.ButtonType.SECONDARY
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.checkbox
import supergenerous.app.core.component.contentSection
import supergenerous.app.core.component.dividerHorizontal
import supergenerous.app.core.component.heading1
import supergenerous.app.core.component.image.icon
import supergenerous.app.core.component.inputTitle
import supergenerous.app.core.component.layout.grid
import supergenerous.app.core.component.picker.datePicker
import supergenerous.app.core.component.radio.RadioButton
import supergenerous.app.core.component.radio.radioGroup
import supergenerous.app.core.component.signaturePad
import supergenerous.app.core.component.snackbar.SnackbarMessageQueue
import supergenerous.app.core.component.textBold
import supergenerous.app.core.component.textLink
import supergenerous.app.core.component.textLinkButton
import supergenerous.app.core.component.textfield.TextFieldType
import supergenerous.app.core.component.textfield.searchTextField
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.res.Color
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.core.util.AlignContent
import supergenerous.app.core.util.FormField
import supergenerous.app.core.util.JustifySelf
import supergenerous.app.core.util.RouteProps
import supergenerous.app.core.util.alignContent
import supergenerous.app.core.util.component.toolbar
import supergenerous.app.core.util.format.TextCapitalization
import supergenerous.app.core.util.get
import supergenerous.app.core.util.justifySelf
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.open
import supergenerous.app.core.util.urlParams
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.kindo.model.KindoLinkError.*
import supergenerous.app.donor.kindo.viewmodel.KindoLinkViewModel
import supergenerous.app.donor.setup.model.AuthorityToActTerm
import supergenerous.app.donor.setup.model.AuthorityToActTerm.*
import supergenerous.app.donor.util.Url
import supergenerous.app.donor.util.component.buttonFooter
import supergenerous.app.donor.util.res.Icon

/**
 * Screen that allows a donor to link their Kindo account to SG.
 *
 * This screen is shown inside an iframe in the Kindo web app.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
private class KindoLinkScreen : LifecycleOwnerComponent<KindoLinkScreenProps, KindoLinkScreenState>() {

    /**
     * The donor's full name.
     *
     * This value is only set when both the first name and last name are entered, otherwise it's blank.
     */
    private val donorFullName: String
        get() = if (state.firstName.value.isBlank() || state.lastName.value.isBlank()) {
            ""
        } else {
            "${state.firstName.value} ${state.lastName.value}"
        }


    override fun KindoLinkScreenState.init() {
        addressSearchResults = emptyList()
        isAtaSigned = false
        isDonorSignedIn = false
        isDonorSigningIn = false
        isSignIn = false
        isLinkSuccessful = false
        isLinkInProgress = false
        donorProvidedGovId = false

        email = FormField("")
        password = FormField("")

        firstName = FormField("")
        middleName = FormField("")
        lastName = FormField("")
        phoneNumber = FormField("")
        address = FormField("")
        dateOfBirth = FormField(null)
        govIdType = FormField(null)
        govIdNumber = FormField("")
        driverLicenceVersion = FormField("")
        govIdExpiryDate = FormField(null)
        taxId = FormField("")
        bankAccountNumber = FormField("")
        schoolDisbRecipient = FormField(null)
        signature = FormField(null)
        isMarketingEmailsEnabled = FormField(true)

        ataTerms = mapOf()
    }

    override fun RBuilder.render() {
        toolbar()

        grid(isCentered = true) {
            styledDiv {
                css {
                    marginTop = 32.px
                    gridColumn = GridColumn("3 / 11")
                    mobileScreen {
                        gridColumn = GridColumn("1 / 3")
                    }
                }

                contentSection {
                    styledDiv {
                        css {
                            width = 100.pct
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            alignContent(AlignContent.start)
                            gap = 16.px
                        }

                        if (state.isLinkSuccessful) {
                            linkSuccessPanel()
                        } else {
                            linkForm()
                        }
                    }
                }
            }
        }
    }

    /**
     * Shows that the user has finished the link process successfully.
     */
    private fun RBuilder.linkSuccessPanel() {
        styledDiv {
            css {
                display = Display.flex
                alignItems = Align.center
                justifyContent = JustifyContent.start
            }

            icon(
                icon = Icon.CHECK_CIRCLE,
                color = Color.PRIMARY,
                size = 96.px
            )

            heading1 {
                css {
                    marginLeft = 16.px
                }

                +"Great, you’re all done!"
            }
        }
    }

    /**
     * Displays the link form there the donor can enter their info.
     */
    private fun RBuilder.linkForm() {
        if (state.isDonorSignedIn) {
            heading1 {
                +"Welcome ${state.firstName.value}"
            }
            body1 {
                +"Fill out the form below to link your Kindo account to Supergenerous"
            }
        } else {
            authSection()
        }

        // If the donor is trying to create a new account or they signed in to an existing account already then show all
        // the form fields, otherwise only the sign-in screen will be shown
        if (!state.isSignIn || state.isDonorSignedIn) {
            sectionDivider()
            donorInfoSection()
            buttons()
        }
    }

    /**
     * Renders the auth section of the screen.
     */
    private fun RBuilder.authSection() {
        styledDiv {
            css {
                display = Display.flex
                alignItems = Align.center
                justifyContent = JustifyContent.center

                width = 100.pct
            }

            styledDiv {
                css {
                    display = Display.flex
                    alignItems = Align.center
                    justifyContent = JustifyContent.center
                    flexDirection = FlexDirection.column

                    minWidth = 256.px
                    maxWidth = 330.px
                    width = 60.pct
                    height = 100.pct

                    children {
                        width = 100.pct
                    }
                }

                // Title
                heading1 {
                    css {
                        textAlign = TextAlign.center
                        margin(bottom = 24.px)
                    }

                    if (state.isSignIn) {
                        +"Log In"
                    } else {
                        +"Sign Up"
                    }
                }

                body1 {
                    css {
                        // Pin to the bottom of the screen
                        top = 100.pct - 100.px
                        margin(bottom = 16.px)

                        justifySelf(JustifySelf.end)

                        mobileScreen {
                            top = LinearDimension.auto
                            margin(top = 10.px)
                        }
                    }

                    span {
                        if (state.isSignIn) +"Don't have an account?" else +"Already have an account?"
                    }

                    textLinkButton(onClick = { setState { isSignIn = !state.isSignIn } }) {
                        css {
                            margin(left = 5.px)
                        }

                        if (state.isSignIn) +"Sign up" else +"Log in"
                    }
                }

                // Third-party providers authentication
                styledSection {
                    css {
                        +AuthStyle.centredColumnFlex
                        minWidth = LinearDimension.maxContent
                    }

                    authProviderButton(
                        authProvider = GOOGLE,
                        onClick = { props.viewModel.authenticateWithProvider(GOOGLE) }
                    )
                }

                // Vertical divider
                body1 {
                    css {
                        // This styling makes the element appear with a dividing line to the right
                        // i.e. OR -------------------
                        overflow = Overflow.hidden
                        textAlign = TextAlign.start
                        width = 100.pct
                        margin(vertical = 24.px)

                        // This adds the line to the right
                        after {
                            backgroundColor = Color.DIVIDER.cssValue
                            content = QuotedString("")
                            display = Display.inlineBlock
                            height = 1.px
                            position = Position.relative
                            verticalAlign = VerticalAlign.middle
                            width = 100.pct
                            left = 8.px
                            margin(right = (-50).pct)
                        }
                    }

                    +"OR"
                }

                // Email authentication
                val spaceBetweenFields = 16.px
                styledForm {
                    css {
                        +AuthStyle.centredColumnFlex

                        children { marginBottom = spaceBetweenFields }
                    }

                    /*
                     * When you are in a form and press "enter" it validates the form before allowing the
                     * form to submit. Because the email field is type=email if there is an invalid email
                     * then it adds the :invalid pseudo class to the input, shows a popup on the invalid
                     * fields, and does not allow the submit action to continue. This is a problem
                     * because we want to let firebase do the validation.
                     *
                     * Setting novalidate=true on the form stops the built-in form validation behaviour.
                     */
                    attrs.novalidate = true

                    // Prevent the default submit event behavior or the whole web app will be reloaded on Chrome
                    // (see https://github.com/ReactTraining/react-router/issues/1933)
                    attrs.onSubmitFunction = { event -> event.preventDefault() }

                    textField(
                        type = TextFieldType.EMAIL,
                        title = "Email",
                        value = state.email.value,
                        errorMessage = state.email.errorMessage,
                        onTextChange = {
                            setState {
                                email = FormField(it)
                            }
                        }
                    )

                    textField(
                        type = TextFieldType.PASSWORD,
                        title = "Password",
                        value = state.password.value,
                        errorMessage = state.password.errorMessage,
                        onTextChange = {
                            setState {
                                password = FormField(it)
                            }
                        }
                    )

                    if (state.isSignIn) {
                        // Show sign-in fields
                        styledDiv {
                            css {
                                alignSelf = Align.center
                                display = Display.flex
                                justifyContent = JustifyContent.center
                            }

                            button(
                                showLoadingIcon = state.isDonorSigningIn,
                                label = "Log in",
                                isSubmitButton = true,
                                onClick = { props.viewModel.signIn(state.email.value, state.password.value) }
                            )
                        }
                    } else {
                        // Show sign-up fields
                        body1 {
                            css {
                                margin(top = -spaceBetweenFields)
                            }

                            +"Your password must be at least "
                            textBold { +"6 characters." }
                        }

                        body1 {
                            css {
                                marginTop = 12.px
                                marginBottom = (-12).px
                            }

                            +"By continuing you are indicating that you accept our "
                            textLink(url = SG_TOS) { +"Terms of Service and Privacy Policy" }
                            +"."
                        }
                    }
                }
            }
        }
    }

    /**
     * Renders the main body of the form where the donor info fields are located.
     */
    private fun RBuilder.donorInfoSection() {
        schoolDisbRecipientField()

        sectionDivider()

        nameField()
        dateOfBirthField()
        addressField()
        phoneNumberField()

        // Don't show the gov ID section if the donor provided it in the past (i.e., they are an existing donor)
        if (!state.donorProvidedGovId) {
            sectionDivider()
            govIdSection()
        }

        sectionDivider()

        taxIdField()

        // Only show the bank account field if the donor is the recipient of the school rebates
        if (state.schoolDisbRecipient.value == DONOR) {
            sectionDivider()
            bankAccountField()
        }

        sectionDivider()

        // Don't show the ATA section if the donor has already accepted and signed ATA
        if (!state.isAtaSigned) {
            ataSection()
        }

        marketingEmailsCheckbox()
    }

    /**
     * Renders the options for the donor's disbursement recipient settings.
     */
    private fun RBuilder.schoolDisbRecipientField() {
        radioGroup(
            title = "What would you like to do with your school rebates?",
            radioButtons = listOf(RadioButton(label = "I want to be super generous and regift it to the school I donated to",
                                              value = SCHOOLS.name),
                                  RadioButton(label = "I want to keep it for myself",
                                              value = DONOR.name)),
            valueSelected = state.schoolDisbRecipient.value?.name,
            onSelect = { value ->
                setState {
                    schoolDisbRecipient = FormField(value = DisbursementRecipient.Type.valueOf(value),
                                                    errorMessage = null)
                }
            },
            showError = state.schoolDisbRecipient.errorMessage != null
        )
    }

    /**
     * Renders a field with the name of the donor.
     */
    private fun RBuilder.nameField() {
        infoFieldContainer(
            title = "Full legal name",
            subtitle = "Please provide your full legal name",
            content = {
                styledDiv {
                    css {
                        display = Display.grid
                        gridTemplateRows = GridTemplateRows.auto
                        gridTemplateColumns = GridTemplateColumns(1.fr, 1.fr, 1.fr)
                        columnGap = 24.px
                        mobileScreen {
                            gridTemplateRows = GridTemplateRows(1.fr, 1.fr, 1.fr)
                            gridTemplateColumns = GridTemplateColumns.auto
                            rowGap = 8.px
                        }
                    }

                    textField(
                        placeholder = "First name",
                        textCapitalization = TextCapitalization.CAPITALIZE,
                        errorMessage = state.firstName.errorMessage,
                        value = state.firstName.value,
                        onTextChange = { setState { firstName = FormField(it) } }
                    )
                    textField(
                        placeholder = "Middle name",
                        textCapitalization = TextCapitalization.CAPITALIZE,
                        value = state.middleName.value,
                        onTextChange = { setState { middleName = FormField(it) } }
                    )
                    textField(
                        placeholder = "Last name",
                        textCapitalization = TextCapitalization.CAPITALIZE,
                        errorMessage = state.lastName.errorMessage,
                        value = state.lastName.value,
                        onTextChange = { setState { lastName = FormField(it) } }
                    )
                }
            }
        )
    }

    /**
     * Renders a field with the address of the donor.
     */
    private fun RBuilder.addressField() {
        infoFieldContainer {
            searchTextField(
                title = "Home address",
                placeholder = "Start typing to find address",
                errorMessage = state.address.errorMessage,
                value = state.address.value,
                onTextChange = {
                    props.viewModel.searchAddress(it)
                    setState { address = FormField(it) }
                },
                searchResults = state.addressSearchResults,
                onResultSelect = {
                    setState {
                        address = FormField(it.data ?: it.text)
                        addressSearchResults = emptyList()
                    }
                },
                maxResultsShown = 5
            )
        }
    }

    /**
     * Renders a field with the phone number of the donor.
     */
    private fun RBuilder.phoneNumberField() {
        infoFieldContainer {
            textField(
                title = "Phone number",
                subtitle = "We promise we won’t cold call you! A phone number is just another way the organisations you give to can identify your receipts.",
                type = TextFieldType.PHONE_NUMBER,
                placeholder = "+64 123 1234 1234",
                errorMessage = state.phoneNumber.errorMessage,
                value = state.phoneNumber.value,
                onTextChange = { setState { phoneNumber = FormField(it) } }
            )
        }
    }

    /**
     * Renders the marketing emails opt-in/out checkbox.
     */
    private fun RBuilder.marketingEmailsCheckbox() {
        infoFieldContainer(
            title = "Would you like to receive email updates about how the Supergenerous community is making a difference?",
            content = {
                styledDiv {
                    css {
                        marginTop = 16.px
                    }

                    checkbox(
                        label = "Yes, I’d like to receive occasional marketing emails from Supergenerous.",
                        isChecked = state.isMarketingEmailsEnabled.value,
                        onChange = { isChecked -> setState { isMarketingEmailsEnabled = FormField(isChecked) } }
                    )
                }
            }
        )
    }

    /**
     * Renders the date of birth field.
     */
    private fun RBuilder.dateOfBirthField() {
        styledDiv {
            css {
                gridColumn = GridColumn("1 / 2")
                gridRow = GridRow("3 / 3")
            }

            datePicker(
                title = "Date of birth",
                value = state.dateOfBirth.value,
                disableFutureDates = true,
                onDateChange = { setState { dateOfBirth = FormField(it) } },
                errorMessage = state.dateOfBirth.errorMessage
            )
        }
    }

    /**
     * Renders the gov ID fields.
     */
    private fun RBuilder.govIdSection() {
        inputTitle(
            title = "Your ID",
            subtitle = "We are legally required to verify your identity before we claim your donation tax rebates"
        )

        infoFieldContainer(
            content = {
                radioGroup(
                    title = "Select ID type",
                    radioButtons = listOf(RadioButton(label = "New Zealand Driver Licence",
                                                      value = DRIVER_LICENCE_NZ.toString()),
                                          RadioButton(label = "New Zealand Passport",
                                                      value = PASSPORT.toString())),
                    valueSelected = state.govIdType.value.toString(),
                    onSelect = { value ->
                        setState {
                            govIdType = FormField(IdDocument.Type.valueOf(value))

                            // Reset all errors when changing the selected ID type
                            govIdType = govIdType.copy(errorMessage = null)
                            govIdNumber = FormField("")
                            govIdExpiryDate = FormField(null)
                            driverLicenceVersion = FormField("")
                        }
                    },
                    showError = state.govIdType.errorMessage.isNotNullOrBlank()
                )
            }
        )

        // Show the input fields for passport/driver license if the user has selected a type from the buttons above
        state.govIdType.value?.let { govIdType ->
            infoFieldContainer {
                styledDiv {
                    css {
                        display = Display.grid
                        gridTemplateRows = GridTemplateRows.auto
                        gridTemplateColumns = GridTemplateColumns(1.fr, 1.fr)
                        gap = 24.px
                        mobileScreen {
                            gridTemplateRows = GridTemplateRows.auto
                            gridTemplateColumns = GridTemplateColumns.auto
                        }
                    }

                    govIdFields(govIdType)
                }
            }
        }
    }

    /**
     * Renders the fields necessary to provide the government ID info.
     */
    private fun RBuilder.govIdFields(govIdType: IdDocument.Type) {
        val (field1, field2) = when (govIdType) {
            DRIVER_LICENCE_NZ -> listOf(GovIdField(title = "Driver licence number",
                                                   placeholder = "Enter 8 characters",
                                                   errorMessage = state.govIdNumber.errorMessage),
                                        GovIdField(title = "Version number",
                                                   placeholder = "Enter 3 numbers",
                                                   errorMessage = state.driverLicenceVersion.errorMessage))
            PASSPORT -> listOf(GovIdField(title = "Passport number",
                                          placeholder = "Enter 7 or 8 characters",
                                          errorMessage = state.govIdNumber.errorMessage),
                               GovIdField(title = "Expiry date",
                                          placeholder = "",
                                          errorMessage = state.govIdExpiryDate.errorMessage))
        }


        styledDiv {
            css {
                gridColumn = GridColumn("1 / 2")
            }

            textField(
                title = field1.title,
                textCapitalization = TextCapitalization.UPPERCASE,
                placeholder = field1.placeholder,
                value = state.govIdNumber.value,
                onTextChange = { setState { govIdNumber = FormField(it) } },
                errorMessage = field1.errorMessage
            )
        }

        styledDiv {
            css {
                gridColumn = GridColumn("1 / 2")
                gridRow = GridRow("2 / 3")
            }

            when (govIdType) {
                DRIVER_LICENCE_NZ -> {
                    textField(
                        title = field2.title,
                        type = TextFieldType.NUMBER,
                        placeholder = field2.placeholder,
                        value = state.driverLicenceVersion.value,
                        onTextChange = { setState { driverLicenceVersion = FormField(it) } },
                        errorMessage = field2.errorMessage
                    )
                }
                PASSPORT -> {
                    datePicker(
                        title = field2.title,
                        value = state.govIdExpiryDate.value,
                        onDateChange = { setState { govIdExpiryDate = FormField(it) } },
                        errorMessage = field2.errorMessage
                    )
                }
            }
        }
    }

    /**
     * Renders the Authority to Act checkboxes and signature field.
     */
    private fun RBuilder.ataSection() {
        inputTitle(
            title = "Authority to Act",
            subtitle = "We need the following authorities to be able to act on your behalf when claiming rebates from the IRD."
        )

        // Term checkboxes
        infoFieldContainer(
            content = {
                styledDiv {
                    css {
                        display = Display.inlineGrid
                        rowGap = 24.px
                    }

                    state.ataTerms.keys.forEach {
                        ataTermCheckbox(ataTerm = it)
                    }
                }
            }
        )

        // Signature field
        infoFieldContainer(
            title = "Signature",
            content = {
                styledDiv {
                    css {
                        marginBottom = 16.px
                    }

                    signaturePad(
                        signature = state.signature.value,
                        onChange = { setState { signature = FormField(it) } },
                        placeholder = donorFullName,
                        errorMessage = state.signature.errorMessage
                    )
                }
            }
        )
    }

    /**
     * Creates a checkbox for the [ataTerm].
     */
    private fun RBuilder.ataTermCheckbox(ataTerm: AuthorityToActTerm) {
        checkbox(
            label = ataTerm.text,
            isChecked = state.ataTerms[ataTerm]!!.value,
            onChange = { isChecked ->
                setState {
                    ataTerms += ataTerm to FormField(isChecked)
                }
            },
            showError = state.ataTerms[ataTerm]?.errorMessage.isNotNullOrBlank()
        )
    }

    /**
     * Renders a field with the tax ID of the donor.
     */
    private fun RBuilder.taxIdField() {
        styledDiv {
            css {
                display = Display.grid
                gridTemplateColumns = GridTemplateColumns(1.fr, 1.fr)
                gridTemplateRows = GridTemplateRows(LinearDimension.auto, LinearDimension.auto)

                columnGap = 8.px

                mobileScreen {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
            }

            val leftCol = GridColumn("1 / span 1")
            val rightCol = GridColumn("2 / span 1")
            val fullWidth = GridColumn("1 / span 2")

            val topRow = GridRow("1 / span 1")
            val bottomRow = GridRow("2 / span 1")

            styledDiv {
                css {
                    gridColumn = leftCol
                    gridRow = topRow

                    mobileScreen {
                        gridColumn = fullWidth
                        gridRow = GridRow.auto
                    }
                }

                inputTitle(title = "IRD number")
            }

            styledDiv {
                css {
                    gridColumn = leftCol
                    gridRow = bottomRow
                    // Make the textField not fill the width of the parent
                    display = Display.flex

                    mobileScreen {
                        gridColumn = fullWidth
                        gridRow = GridRow.auto
                        display = Display.block
                    }

                    mobileScreen { width = 100.pct }
                }

                textField(
                    type = TextFieldType.IRD_NUMBER,
                    placeholder = "Enter 8 or 9 numbers",
                    errorMessage = state.taxId.errorMessage,
                    value = state.taxId.value,
                    onTextChange = { setState { taxId = FormField(it) } }
                )
            }

            styledDiv {
                css {
                    gridColumn = rightCol
                    gridRow = topRow

                    mobileScreen {
                        gridColumn = fullWidth
                        gridRow = GridRow.auto
                    }
                }

                body1 { +"Can't find it?" }
            }

            styledDiv {
                css {
                    gridColumn = rightCol
                    gridRow = bottomRow

                    marginTop = 8.px

                    mobileScreen {
                        gridColumn = fullWidth
                        gridRow = GridRow.auto
                    }
                }

                button(
                    label = "Find my IRD number",
                    size = ButtonSize.SMALL,
                    type = ButtonType.PRIMARY,
                    onClick = { window.open(url = Url.FIND_IRD_NUMBER, useNewTab = true) }
                )
            }
        }
    }

    /**
     * Renders a field with the bank account number of the donor.
     */
    private fun RBuilder.bankAccountField() {
        textField(
            title = "Bank account number",
            type = TextFieldType.BANK_ACCOUNT_NUMBER,
            value = state.bankAccountNumber.value,
            onTextChange = { setState { bankAccountNumber = FormField(it) } },
            errorMessage = state.bankAccountNumber.errorMessage
        )
    }

    /**
     * Renders a container that sets the proper styling to the [content] and adds a [title] and [subtitle] if available.
     */
    private fun RBuilder.infoFieldContainer(title: String? = null,
                                            subtitle: String? = null,
                                            content: RBuilder.() -> Unit) {
        styledDiv {
            css {
                display = Display.grid
                gridTemplateColumns = GridTemplateColumns(1.fr)
            }

            title?.let {
                inputTitle(
                    title = it,
                    subtitle = subtitle
                )
            }

            content()
        }
    }

    /**
     * Renders the cancel and submit buttons.
     */
    private fun RBuilder.buttons() {
        buttonFooter {
            css {
                marginTop = 16.px
                marginBottom = 32.px
            }

            button(
                label = "Cancel",
                type = SECONDARY,
                isDisabled = state.isLinkInProgress,
                onClick = { props.viewModel.cancelLink() }
            )

            button(
                label = "Submit",
                showLoadingIcon = state.isLinkInProgress,
                onClick = {
                    props.viewModel.linkDonor(email = state.email.value,
                                              password = state.password.value,
                                              firstName = state.firstName.value,
                                              middleName = state.middleName.value,
                                              lastName = state.lastName.value,
                                              phoneNumber = state.phoneNumber.value,
                                              address = state.address.value,
                                              dateOfBirth = state.dateOfBirth.value,
                                              govIdType = state.govIdType.value,
                                              govIdNumber = state.govIdNumber.value,
                                              driverLicenceVersion = state.driverLicenceVersion.value,
                                              govIdExpiryDate = state.govIdExpiryDate.value,
                                              bankAccountNumber = state.bankAccountNumber.value,
                                              taxId = state.taxId.value,
                                              disbRecipientType = state.schoolDisbRecipient.value,
                                              signatureStr = state.signature.value,
                                              isMarketingEmailsEnabled = state.isMarketingEmailsEnabled.value,
                                              ataTermsAccepted = state.ataTerms.filterValues { it.value }.keys)
                }
            )
        }
    }

    private fun RBuilder.sectionDivider() {
        dividerHorizontal(margin = 12.px)
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // Initialise the ATA terms as "not accepted" (this can't be done in init() because the view model property is
        // not set when that method runs)
        setState { ataTerms = props.viewModel.ataTerms.associateWith { FormField(false) } }

        // Load the user data sent from Kindo to the UI
        props.viewModel.loadUserData(operationType = props.location.urlParams.get(TYPE),
                                     referralId = props.location.urlParams.get(REFERRAL_ID),
                                     referrer = props.location.urlParams.get(REFERRER),
                                     doneeExtId = props.location.urlParams.get(DONEE_EXT_ID),
                                     donorExtId = props.location.urlParams.get(DONOR_EXT_ID),
                                     donorEmail = props.location.urlParams.get(DONOR_EMAIL),
                                     donorFirstName = props.location.urlParams.get(DONOR_FIRST_NAME),
                                     donorLastName = props.location.urlParams.get(DONOR_LAST_NAME),
                                     checksum = props.location.urlParams.get(CHECKSUM))

        props.viewModel.donorEmail.observe { setState { email = FormField(it) } }
        props.viewModel.donorName.observe { fullName ->
            setState {
                firstName = FormField(fullName.firstName)
                middleName = FormField(fullName.middleName ?: "")
                lastName = FormField(fullName.lastName)
            }
        }
        props.viewModel.donorDateOfBirth.observe { setState { dateOfBirth = FormField(it) } }
        props.viewModel.donorAddress.observe { setState { address = FormField(it) } }
        props.viewModel.donorGovId.observe { govId ->
            setState {
                donorProvidedGovId = true
                govIdType = FormField(govId.type)
                when (govId) {
                    is Passport -> {
                        govIdNumber = FormField(govId.number)
                        govIdExpiryDate = FormField(govId.expiryDate)
                    }
                    is DriverLicenceNz -> {
                        govIdNumber = FormField(govId.number)
                        driverLicenceVersion = FormField(govId.version)
                    }
                }
            }
        }
        props.viewModel.donorPhoneNumber.observe { setState { phoneNumber = FormField(it) } }
        props.viewModel.donorTaxId.observe { setState { taxId = FormField(it) } }
        props.viewModel.donorBankAccNumber.observe { setState { bankAccountNumber = FormField(it) } }
        props.viewModel.schoolDisbRecipient.observe { setState { schoolDisbRecipient = FormField(it) } }
        props.viewModel.donorSignature.observe { signature ->
            val isAtaSigned = signature.isNotNullOrBlank()
            val ataTerms = if (isAtaSigned) {
                // Update the terms to be "accepted" if the donor has already provided a signature
                state.ataTerms.mapValues { FormField(true) }
            } else {
                state.ataTerms
            }

            setState {
                this.signature = FormField(signature)
                this.isAtaSigned = isAtaSigned
                this.ataTerms = ataTerms
            }
        }
        props.viewModel.isDonorMarketingEmailsEnabled.observe { setState { isMarketingEmailsEnabled = FormField(it) } }
        props.viewModel.addressSearchResults.observeEvent { setState { addressSearchResults = it } }
        props.viewModel.isDonorSigningIn.observe { setState { isDonorSigningIn = it } }
        props.viewModel.isDonorSignedIn.observe { setState { isDonorSignedIn = it } }
        props.viewModel.isLinkSuccessful.observe { setState { isLinkSuccessful = it } }
        props.viewModel.isLinkInProgress.observe { setState { isLinkInProgress = it } }

        props.viewModel.errors.observeEvent(::processErrors)
    }

    /**
     * Handles the errors that occur on the screen.
     */
    private fun processErrors(errors: Set<ErrorType>) {
        if (errors.isNotEmpty()) {
            // We have to show a snackbar as some errors might be from the top fields that are not visible to the user
            SnackbarMessageQueue.add(body = "A problem has occurred. Please check your details.")
        }

        errors.forEach { error ->
            when ((error as? ErrorType.AppError)?.code) {
                // Form data errors
                SIGNATURE_MISSING -> setState { signature = signature.copy(errorMessage = "Required") }
                SIGNATURE_INVALID -> {
                    val errorMessage = if (donorFullName.isNotBlank()) {
                        "Please enter your full name to sign: \"$donorFullName\""
                    } else {
                        "Fill in the form and then type your full name"
                    }

                    setState { signature = signature.copy(errorMessage = errorMessage) }
                }
                FIRST_NAME_MISSING -> setState { firstName = firstName.copy(errorMessage = "Required") }
                LAST_NAME_MISSING -> setState { lastName = lastName.copy(errorMessage = "Required") }
                ADDRESS_MISSING -> setState { address = address.copy(errorMessage = "Required") }
                PHONE_NUMBER_MISSING -> setState { phoneNumber = phoneNumber.copy(errorMessage = "Required") }
                TAX_ID_MISSING -> setState { taxId = taxId.copy(errorMessage = "Required") }
                TAX_ID_INVALID -> setState { taxId = taxId.copy(errorMessage = "Invalid") }
                SCHOOL_DISB_RECIPIENT_MISSING -> setState { schoolDisbRecipient = schoolDisbRecipient.copy(errorMessage = "Required") }
                BANK_ACC_MISSING -> setState { bankAccountNumber = bankAccountNumber.copy(errorMessage = "Required") }
                BANK_ACC_INVALID -> setState { bankAccountNumber = bankAccountNumber.copy(errorMessage = "Invalid number") }
                DATE_OF_BIRTH_MISSING -> setState { dateOfBirth = dateOfBirth.copy(errorMessage = "Required") }
                GOV_ID_TYPE_MISSING -> setState { govIdType = govIdType.copy(errorMessage = "Required") }
                GOV_ID_NUMBER_MISSING -> setState { govIdNumber = govIdNumber.copy(errorMessage = "Required") }
                DRIVER_LICENCE_NUMBER_INVALID -> setState { govIdNumber = govIdNumber.copy(errorMessage = "Should be 8 characters, beginning with 2 letters then 6 numbers") }
                DRIVER_LICENCE_VERSION_MISSING -> setState { driverLicenceVersion = driverLicenceVersion.copy(errorMessage = "Required") }
                DRIVER_LICENCE_VERSION_INVALID -> setState { driverLicenceVersion = driverLicenceVersion.copy(errorMessage = "Should be 3 numbers") }
                PASSPORT_NUMBER_INVALID -> setState { govIdNumber = govIdNumber.copy(errorMessage = "Should be 7 or 8 characters") }
                PASSPORT_EXPIRY_DATE_MISSING -> setState { govIdExpiryDate = govIdExpiryDate.copy(errorMessage = "Required") }
                PASSPORT_EXPIRY_DATE_INVALID -> setState { govIdExpiryDate = govIdExpiryDate.copy(errorMessage = "Invalid") }

                ATA_TERM_NOT_ACCEPTED_TAX_AGENT -> setState { ataTerms += (TAX_AGENT to ataTerms[TAX_AGENT]!!.copy(errorMessage = "Required")) }
                ATA_TERM_NOT_ACCEPTED_SG_OPT_OUT -> setState { ataTerms += (SG_OPT_OUT to ataTerms[SG_OPT_OUT]!!.copy(errorMessage = "Required")) }
                ATA_TERM_NOT_ACCEPTED_TAX_ACCOUNT_ACCESS -> setState { ataTerms += (TAX_ACCOUNT_ACCESS to ataTerms[TAX_ACCOUNT_ACCESS]!!.copy(errorMessage = "Required")) }
                ATA_TERM_NOT_ACCEPTED_TAX_COMMUNICATION -> setState { ataTerms += (TAX_COMMUNICATION to ataTerms[TAX_COMMUNICATION]!!.copy(errorMessage = "Required")) }
                ATA_TERM_NOT_ACCEPTED_TAX_CORRESPONDENCE -> setState { ataTerms += (TAX_CORRESPONDENCE to ataTerms[TAX_CORRESPONDENCE]!!.copy(errorMessage = "Required")) }
                ATA_TERM_NOT_ACCEPTED_SG_BANK_ACCOUNT -> setState { ataTerms += (SG_BANK_ACCOUNT to ataTerms[SG_BANK_ACCOUNT]!!.copy(errorMessage = "Required")) }
                ATA_TERM_NOT_ACCEPTED_SG_FEE -> setState { ataTerms += (SG_FEE to ataTerms[SG_FEE]!!.copy(errorMessage = "Required")) }

                // Auth errors
                INVALID_EMAIL -> setState { email = email.copy(errorMessage = "Invalid email") }
                USER_NOT_FOUND -> setState { email = email.copy(errorMessage = "Email address not found") }
                WRONG_PASSWORD -> setState { password = password.copy(errorMessage = "Incorrect password") }
                EMAIL_ALREADY_IN_USE -> setState { email = email.copy(errorMessage = "Email address is already in use") }
                WEAK_PASSWORD -> setState { password = password.copy(errorMessage = "Weak password") }
            }
        }
    }


    /*
     * Inner types
     */

    /**
     * Container for info needed to display [Donor.govId] fields.
     */
    private data class GovIdField(
        val title: String,
        val placeholder: String,
        val errorMessage: String?
    )

}

/**
 * Properties of the [KindoLinkScreen] component.
 */
private external interface KindoLinkScreenProps : RouteProps {

    /**
     * The view model for the link screen.
     */
    var viewModel: KindoLinkViewModel

}

/**
 * The state of the [KindoLinkScreen] component.
 */
private external interface KindoLinkScreenState : State {

    /*
     * Screen state
     */

    var addressSearchResults: List<TextSearchResult<String>>
    var isAtaSigned: Boolean
    var isDonorSignedIn: Boolean
    var isDonorSigningIn: Boolean
    var isSignIn: Boolean
    var isLinkSuccessful: Boolean
    var isLinkInProgress: Boolean

    /**
     * `true` if the donor is an existing donor that has provided a gov ID already, or `false` if they are a new
     * donor or have not yet provided a gov ID.
     */
    var donorProvidedGovId: Boolean

    /*
     * Auth
     */

    var email: FormField<String>
    var password: FormField<String>

    /*
     * Donor form fields
     */

    var firstName: FormField<String>
    var middleName: FormField<String>
    var lastName: FormField<String>
    var address: FormField<String>
    var dateOfBirth: FormField<LocalDate?>
    var govIdType: FormField<IdDocument.Type?>
    var govIdNumber: FormField<String>
    var driverLicenceVersion: FormField<String>
    var govIdExpiryDate: FormField<LocalDate?>
    var phoneNumber: FormField<String>
    var taxId: FormField<String>
    var bankAccountNumber: FormField<String>
    var schoolDisbRecipient: FormField<DisbursementRecipient.Type?>
    var signature: FormField<String?>
    var isMarketingEmailsEnabled: FormField<Boolean>

    /**
     *  Each of the [AuthorityToActTerm]s and the state of each in the form.
     */
    var ataTerms: Map<AuthorityToActTerm, FormField<Boolean>>

}

/**
 * Renders a [KindoLinkScreen] component.
 */
public fun RBuilder.kindoLinkScreen(kindoViewModel: KindoLinkViewModel) {
    withRouter(KindoLinkScreen::class) {
        attrs.viewModel = kindoViewModel
    }
}