package supergenerous.app.core

import com.hipsheep.kore.resource.Resource.*
import com.hipsheep.kore.viewmodel.BaseViewModel
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.coroutine.asLiveData
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValueAsync
import com.supergenerous.common.user.User
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import supergenerous.app.core.auth.model.AuthRepository

/**
 * [ViewModel] that provides data to the [BaseApp] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public abstract class BaseAppViewModel(

    private val authRepo: AuthRepository

) : BaseViewModel() {

    /**
     * Backing property for [isAppDataLoaded].
     */
    @Suppress("PropertyName")
    protected val _isAppDataLoaded: MutableLiveData<Boolean> = MutableLiveData()
    /**
     * Observable that receives updates on whether the app data loaded or not.
     */
    public val isAppDataLoaded: LiveData<Boolean> = _isAppDataLoaded

    /**
     * `true` if the user is signed in, or `false` otherwise.
     */
    public val isUserSignedIn: LiveData<Boolean> = authRepo.isUserSignedIn()
        .filter { it is Success }
        .map { (it as? Success)?.data ?: false }
        .asLiveData()


    /**
     * Loads the app data from the server, updating the view through the [LiveData] objects.
     */
    public fun loadAppData() {
        launch {
            _isAppDataLoaded.updateValueAsync(false)

            val authSessionRes = authRepo.loadAuthSession()
            // If we can't load the auth session then return an error
            if (authSessionRes is Error) {
                reportError(authSessionRes)

                return@launch
            }

            val userRes = authRepo.getUser()
            // If the fetching failed then return an error
            if (userRes is Error) {
                reportError(userRes)

                return@launch
            }

            val user = (userRes as Success).data
            // If there is no signed in user then return a success
            if (user == null) {
                _isAppDataLoaded.updateValueAsync(true)

                return@launch
            }

            loadUserData(user)
        }
    }

    /**
     * Loads the app data from the server for the [user], updating the view through the [LiveData] objects.
     */
    protected abstract suspend fun loadUserData(user: User)

}