package supergenerous.app.core.auth.viewmodel

import com.hipsheep.kore.resource.Resource
import com.hipsheep.kore.viewmodel.BaseViewModel
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.event.Event
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValueAsync
import com.supergenerous.common.user.User
import kotlinx.coroutines.launch
import supergenerous.app.core.analytics.AnalyticsEvent
import supergenerous.app.core.analytics.AnalyticsEvent.Auth.Action.*
import supergenerous.app.core.analytics.AnalyticsRepository
import supergenerous.app.core.auth.model.AuthProvider
import supergenerous.app.core.auth.model.AuthRepository
import supergenerous.app.core.auth.model.AuthResult
import supergenerous.app.core.auth.model.AuthSuccess

/**
 * [ViewModel] that provides data to the screens involved in authentication.
 */
public class AuthViewModel(

    private val authRepo: AuthRepository,
    private val analyticsRepo: AnalyticsRepository

) : BaseViewModel() {

    /**
     * Backing field for [onPasswordResetSent].
     */
    private val _onPasswordResetSent = MutableLiveData<Event<Unit>>()
    /**
     * Observable called when the password reset email was sent successfully to the user.
     */
    public val onPasswordResetSent: LiveData<Event<Unit>> = _onPasswordResetSent

    /**
     * Backing field for [authSuccessEvent].
     */
    private val _authSuccessEvent = MutableLiveData<Event<AuthSuccess>>()
    /**
     * Observable called when a [User] authenticates successfully.
     */
    public val authSuccessEvent: LiveData<Event<AuthSuccess>> = _authSuccessEvent


    /**
     * Authenticates the user using a third-party [authProvider].
     */
    public fun authenticateWithProvider(authProvider: AuthProvider, referralCode: String?) {
        authenticateUser(referralCode = referralCode) { authRepo.authenticateWithProvider(authProvider) }
    }

    /**
     * Signs up the user with a new [email] and [password].
     */
    public fun signUp(email: String, password: String, referralCode: String?) {
        authenticateUser(referralCode = referralCode) { authRepo.signUp(email, password) }
    }

    /**
     * Sign in the user with the [email] and [password].
     */
    public fun signIn(email: String, password: String) {
        authenticateUser(referralCode = null) { authRepo.signIn(email, password) }
    }

    /**
     * Authenticates the user through the [authenticate] function and performs the actions necessary depending on the
     * result.
     */
    private fun authenticateUser(referralCode: String?, authenticate: suspend () -> Resource<AuthResult>) {
        launch {
            executeAction { authenticate() }?.let { authResult ->
                // Make referralCode uppercase so all codes submitted have the same casing and it's easy to search by
                // them in the server DB
                _authSuccessEvent.updateValueAsync(Event(AuthSuccess(user = authResult.user,
                                                                     referralCode = referralCode?.uppercase())))

                // Set the donor ID for analytics purposes
                analyticsRepo.setUser(authResult.user, donorProvider = null)

                analyticsRepo.logEvent(AnalyticsEvent.Auth(action = if (authResult.isNewUser) SIGN_UP else SIGN_IN,
                                                           authProvider = authResult.authProvider))
            }
        }
    }

    /**
     * Sends a password reset email to the user that matches the [email].
     */
    public fun sendPasswordResetEmail(email: String) {
        launch {
            executeAction { authRepo.sendResetPasswordEmail(email) }?.let {
                _onPasswordResetSent.updateValueAsync(Event(Unit))
            }
        }
    }

}