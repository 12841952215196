package supergenerous.app.donor.util.component

import com.supergenerous.common.donor.Donor
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.FlexWrap
import kotlinx.css.LinearDimension
import kotlinx.css.alignItems
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.flexWrap
import kotlinx.css.gap
import kotlinx.css.marginTop
import kotlinx.css.px
import kotlinx.css.width
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.chip.Chip
import supergenerous.app.core.component.chip.chipSet
import supergenerous.app.core.component.textfield.TextFieldType
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.component.tooltip.helpIcon

/**
 * A component to allow a user to manage their [Donor.otherEmails], and to view their account email address.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@JsExport
private class EmailPicker : RComponent<EmailPickerProps, State>() {

    override fun RBuilder.render() {
        textField(
            title = props.title,
            subtitle = props.subtitle,
            type = TextFieldType.EMAIL,
            placeholder = "example@gmail.com",
            onTextSelect = { props.onEmailsChange(props.otherEmails + it) }
        )

        styledDiv {
            css {
                marginTop = 16.px

                display = Display.flex
                flexWrap = FlexWrap.wrap
                gap = 24.px
            }

            // Account Email Address section
            styledDiv {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    gap = 8.px
                }

                styledDiv {
                    css {
                        display = Display.flex
                        alignItems = Align.center
                        gap = 8.px
                    }

                    body1 { +"Main email address" }

                    helpIcon("Your main email is where we will send all communications, including marketing emails if opted-in.")
                }

                chipSet(chips = listOf(Chip(id = props.accountEmail, label = props.accountEmail)))
            }

            // Additional Email Address section. Shown only if there are any additional email addresses added.
            if (props.otherEmails.isNotEmpty()) {
                styledDiv {
                    css {
                        width = LinearDimension.auto

                        display = Display.flex
                        flexDirection = FlexDirection.column
                        gap = 8.px
                    }

                    body1 { +"Additional email addresses" }

                    chipSet(
                        chips = props.otherEmails.map { Chip(id = it, label = it) },
                        onChipRemove = { props.onEmailsChange(props.otherEmails - it) }
                    )
                }
            }
        }
    }
}

/**
 * Properties of the [EmailPicker] component.
 */
private external interface EmailPickerProps : Props {

    /**
     * The title to display above the input.
     */
    var title: String?

    /**
     * The text to display below the [title].
     */
    var subtitle: String?

    /**
     * The main account email. This is not deletable.
     */
    var accountEmail: String

    /**
     * Additional email addresses. These are deletable.
     */
    var otherEmails: Set<String>

    /**
     * Function to call when an email is added or removed.
     */
    var onEmailsChange: (emails: Set<String>) -> Unit

}

/**
 * Renders an [EmailPicker] component.
 */
public fun RBuilder.emailPicker(title: String? = null,
                                subtitle: String? = null,
                                accountEmail: String,
                                otherEmails: Set<String>?,
                                onEmailsChange: (emails: Set<String>) -> Unit) {
    child(EmailPicker::class) {
        attrs.title = title
        attrs.subtitle = subtitle
        attrs.accountEmail = accountEmail
        attrs.otherEmails = otherEmails ?: setOf()
        attrs.onEmailsChange = onEmailsChange
    }
}