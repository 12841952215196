package supergenerous.app.core.search.model

import kotlinx.serialization.Serializable

/**
 * An item returned from a text search.
 */
@Serializable
public data class TextSearchResult<T>(

    /**
     * Unique ID of the result.
     */
    val id: String?,

    /**
     * Text found by searching.
     */
    val text: String,

    /**
     * The part of [text] that matched the query text.
     */
    val textMatch: String?,

    /**
     * Data result that matched the search.
     */
    val data: T

)