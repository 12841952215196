package supergenerous.app.core.component

import kotlinx.css.BorderStyle
import kotlinx.css.Display
import kotlinx.css.JustifyContent
import kotlinx.css.display
import kotlinx.css.gap
import kotlinx.css.justifyContent
import kotlinx.css.pct
import kotlinx.css.properties.borderLeft
import kotlinx.css.px
import kotlinx.css.width
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import styled.css
import styled.styledDiv
import supergenerous.app.core.res.Color.DIVIDER
import supergenerous.app.core.util.mobileScreen

/**
 * The content section contains a section of the page to show that it is grouped/related.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
@JsExport
private class ContentSection : RComponent<ContentSectionProps, State>() {

    override fun RBuilder.render() {
        styledDiv {
            css {
                width = 100.pct

                display = Display.flex
                justifyContent = JustifyContent.start
                gap = 16.px
            }

            // Vertical divider
            styledDiv {
                css {
                    // Use width 0 and a border to make it always display nicely. It seems to not stay fixed to 1px
                    // when resizing.
                    width = 0.px

                    borderLeft(width = 1.px, style = BorderStyle.solid, color = DIVIDER.cssValue)

                    mobileScreen {
                        // Hide the vertical line on mobile screens so it looks nicer
                        display = Display.none
                    }
                }
            }

            props.content(this)
        }
    }

}

/**
 * Properties used by the [ContentSection].
 */
private external interface ContentSectionProps : Props {

    /**
     * Content to display inside the section.
     */
    var content: (RBuilder) -> Unit

}

/**
 * Renders a [ContentSection].
 */
public fun RBuilder.contentSection(content: RBuilder.() -> Unit) {
    child(ContentSection::class) {
        attrs.content = content
    }
}