package supergenerous.app.core.progress

/**
 * The progress of a process.
 */
public enum class ProgressStatus {
    NOT_STARTED,
    IN_PROGRESS,
    ON_HOLD,
    COMPLETED_SUCCESS,
    COMPLETED_FAILURE
}