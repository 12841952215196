package com.supergenerous.common.firebase

/**
 * List of names of the [FirestoreCollection]s in the server.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
internal object FirestoreCollectionName {

    const val DISBURSEMENTS = "disbursements"
    const val DONATIONS = "donations"
    const val DONATION_REQUESTS = "donation_requests"
    const val DONEES = "donees"
    const val DONEE_STATS = "donee_stats"
    const val DONEE_USERS = "donee_users"
    const val DONORS = "donors"
    const val DONOR_STATES = "donor_states"
    const val MANUAL_TASKS = "manual_tasks"
    const val REBATES = "rebates"

}