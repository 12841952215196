package supergenerous.app.core.search.typesense

import com.supergenerous.common.donee.Donee
import kotlinx.serialization.Serializable

/**
 * Donee data returned from a Typesense search.
 */
@Serializable
public data class TypesenseDonee(

    val id: String,

    val name: String,

    val legalName: String? = null,

    val otherNames: List<String>? = null,

    val type: Donee.Type

)
