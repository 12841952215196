package com.supergenerous.common.donee

import com.supergenerous.common.accounting.AccountingSystem
import com.supergenerous.common.bank.BankAccount
import com.supergenerous.common.campaign.CampaignCode
import com.supergenerous.common.contact.Contact
import com.supergenerous.common.crm.Crm
import com.supergenerous.common.data.Dto
import com.supergenerous.common.data.DtoDataModel
import com.supergenerous.common.donation.Donation
import com.supergenerous.common.donation.platform.DonationPlatform
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.file.CloudFile
import com.supergenerous.common.serialization.LocalDateSerializer
import com.supergenerous.common.util.Timestamp
import com.supergenerous.common.util.toTimestamp
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

/**
 * Donee organisation that can receive [Donation]s from [Donor]s.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class Donee(

    override val id: String,

    override val creationTimestamp: Timestamp = 0,

    override val lastUpdateTimestamp: Timestamp = 0,

    /**
     * The ID of the donee on the CRM.
     */
    var crmId: String?,

    /**
     * Type of donee organisation.
     */
    var type: Type,

    /**
     * ID assigned by the government (e.g., registration number from Charities Register in NZ).
     */
    var govId: String?,

    /**
     * Usual name used by the organisation for users (it might be different from the [legalName]).
     */
    var name: String,

    /**
     * Legal name used for tax purposes and other reasons.
     */
    var legalName: String?,

    /**
     * Other names the [Donee] uses (e.g., acronyms).
     */
    var otherNames: Set<String>?,

    /**
     * URL of the organisation's website.
     */
    var websiteUrl: String,

    /**
     * The organisation's main email address used by anyone to contact them.
     */
    var email: String,

    /**
     * The organisation's email addresses where emails with information about disbursements made should be sent.
     *
     * If this is empty, disbursement emails will be sent to [receiptsHandler] or [email].
     */
    var disbursementEmails: Set<String>,

    /**
     * Main contact in the organisation that SG communicates with (usually the person that registered the [Donee] with
     * SG).
     */
    var mainContact: Contact?,

    /**
     * Person in charge of providing receipts to SG.
     */
    var receiptsHandler: Contact?,

    /**
     * Phone number of the organisation.
     */
    var phoneNumber: String?,

    /**
     * Physical address where the organisation is located.
     */
    var address: String?,

    /**
     * File for the organisation's logo image.
     */
    var logo: CloudFile?,

    /**
     * Bank account where the organisation will receive the donations sent by SG.
     */
    var bankAccount: BankAccount?,

    /**
     * Status used to identify if we can claim donation tax rebates for the donations made to this [Donee].
     *
     * If `null` then we are not sure yet whether we can claim rebates for this [Donee] or not.
     */
    var taxClaimStatus: TaxClaimStatus,

    /**
     * Platforms used by the [Donee] to receive donations (and generate receipts), or `null` if no particular platform
     * is used for this.
     */
    var donationPlatforms: Set<DonationPlatform>,

    /**
     * A list of "links" between the [Donee] and the [DonationPlatform]s it uses.
     *
     * This list is not always the same as [donationPlatforms] as it only contains the "links" to the
     * [DonationPlatform]s the SG platform is integrated into.
     */
    val donationPlatformLinks: Set<DonationPlatformLink>,

    /**
     * CRMs used by the [Donee] to keep their donor and donation data.
     */
    var crms: Set<Crm>,

    /**
     * Accounting system used by the [Donee] to keep track of their finances and tax obligations.
     */
    var accountingSystem: AccountingSystem?,

    /**
     * `true` if the [Donee] has the infrastructure necessary to create and provide donation receipts to [Donor]s, or
     * `false` if they rely on [DonationPlatform]s to do this.
     *
     * When the value is `false` we should have some [DonationPlatform] in [donationPlatforms].
     */
    var canProvideReceipts: Boolean = true,

    /**
     * `true` if a bank account request was sent to the [Donee], or `false` otherwise.
     */
    val bankAccRequestSent: Boolean = false,

    /**
     * `true` if the [Donee] is part of the SG community donees, or `false` if it's not.
     */
    // TODO: Migrate data in DB to isPartner
    @Deprecated("Use isPartner")
    var isInFafCommunity: Boolean,

    /**
     * The "value" of the [Donee] as an SG partner.
     *
     * This value is `null` when the [Donee] is not an SG partner.
     */
    val partnerValue: PartnerValue?,

    /**
     * `true` if the [Donee] was a SG community partner but have opted out now, or `false` if they were never a
     * community partner or they are still a community partner.
     */
    var optedOutOfCommunity: Boolean,

    /**
     * Date at which the [Donee] joined the SG community.
     */
    @Serializable(LocalDateSerializer::class)
    var communityJoinDate: LocalDate?,

    /**
     * Number of (estimated) active individual donors for this organisation.
     */
    var activeIndividualDonorsNum: Long?,

    /**
     * Amount of money received from individual donors in the last year.
     */
    var individualDonationsAmountLastYear: Float?,

    /**
     * Number of regularly giving individual donors for this organisation (i.e, monthly donations)
     */
    var regularGivingDonorsNum: Long?,

    /**
     * `true` if the [Donee] opted out of receiving donations from SG, or `false` otherwise.
     */
    var optedOutOfDonations: Boolean,

    /**
     * List of campaign codes associated with this [Donee].
     */
    val campaignCodes: Set<CampaignCode>,

    /**
     * List of SG competitors that are partners of the [Donee] already.
     */
    var competitorPartners: Set<Competitor>,

    /**
     * `true` if the [Donee] sends receipts automatically for repeat donors, or `false` if it provides receipts only
     * on email request.
     */
    val isRepeatDonorReceiptsAutomated: Boolean = false,

    /**
     * Whether the [Donee]'s CRM is connected to SG to share data.
     */
    val isCrmConnected: Boolean = false

) : DtoDataModel<DoneeDbo> {

    /**
     * [DoneeId] value of the [Donee].
     */
    // TODO: Remove this once Donee.id type changes to DoneeId
    public val doneeId: DoneeId = DoneeId(id)

    /**
     * `true` if the [Donee] is an SG partner, or `false` if it's not.
     */
    // TODO: Migrate data and remove isInFafCommunity
    public val isPartner: Boolean = isInFafCommunity

    /**
     * `true` if donation receipts can be requested from the [Donee] to claim rebates for them, or `false` otherwise.
     */
    public val canRequestReceipts: Boolean
        get() = this.canProvideReceipts && this.taxClaimStatus.isApproved

    /**
     * Email address used to request receipts from the [Donee].
     */
    public val receiptRequestEmail: String
        get() = if (this.receiptsHandler?.email?.isNotBlank() == true) {
            this.receiptsHandler!!.email
        } else {
            this.email
        }


    /**
     * Extracts the basic info from the [Donee] and returns it.
     */
    public fun getBasicInfo(): DoneeBasicInfo {
        return DoneeBasicInfo(id = id, type = type, name = name)
    }

    override fun toDbo(): DoneeDbo {
        return DoneeDbo(id = id,
                        creationTimestamp = creationTimestamp,
                        lastUpdateTimestamp = lastUpdateTimestamp,
                        crmId = crmId,
                        type = type,
                        govId = govId,
                        name = name,
                        legalName = legalName,
                        otherNames = otherNames?.toList(),
                        websiteUrl = websiteUrl,
                        email = email,
                        disbursementEmails = disbursementEmails.toList(),
                        mainContact = mainContact?.toDbo(),
                        receiptsHandler = receiptsHandler?.toDbo(),
                        phoneNumber = phoneNumber,
                        address = address,
                        logo = logo?.toDbo(),
                        bankAccount = bankAccount?.toDbo(),
                        taxClaimStatus = taxClaimStatus.toDbo(),
                        donationPlatforms = donationPlatforms.toList(),
                        donationPlatformLinks = donationPlatformLinks.map { it.toDbo() },
                        crms = crms.toList(),
                        accountingSystem = accountingSystem,
                        canProvideReceipts = canProvideReceipts,
                        bankAccRequestSent = bankAccRequestSent,
                        inFafCommunity = isInFafCommunity,
                        partnerValue = partnerValue,
                        optedOutOfCommunity = optedOutOfCommunity,
                        communityJoinDate = communityJoinDate?.toTimestamp(),
                        activeIndividualDonorsNum = activeIndividualDonorsNum,
                        individualDonationsAmountLastYear = individualDonationsAmountLastYear,
                        regularGivingDonorsNum = regularGivingDonorsNum,
                        optedOutOfDonations = optedOutOfDonations,
                        campaignCodes = campaignCodes.map { it.toDbo() },
                        competitorPartners = competitorPartners.toList(),
                        donationPlatformIds = donationPlatforms.map { it.id },
                        donationPlatformLinksIds = donationPlatformLinks.map { "${it.donationPlatform}:${it.doneeExtId}" },
                        campaigns = campaignCodes.map { it.campaign },
                        repeatDonorReceiptsAutomated = isRepeatDonorReceiptsAutomated,
                        crmConnected = isCrmConnected)
    }

    /*
     * Inner types
     */

    /**
     * A link between a [DonationPlatform] and a [Donee].
     */
    @Serializable
    public data class DonationPlatformLink(

        /**
         * Donation platform for this link.
         */
        val donationPlatform: DonationPlatform,

        /**
         * External IDs of the donee in the [DonationPlatform] system.
         */
        val doneeExtId: String

    ) : Dto<DoneeDbo.DonationPlatformLinkDbo> {

        override fun toDbo(): DoneeDbo.DonationPlatformLinkDbo {
            return DoneeDbo.DonationPlatformLinkDbo(donationPlatform = donationPlatform, doneeExtId = doneeExtId)
        }

    }

    /**
     * Type of donee organisations supported by the system.
     */
    public enum class Type {

        CHARITY,
        SCHOOL,
        RELIGIOUS_ORG

    }

    /**
     * Value of a partner donees supported by the system.
     */
    public enum class PartnerValue {

        LOW,
        MEDIUM,
        HIGH

    }

}