package supergenerous.app.core.component.textfield

import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.gap
import kotlinx.css.marginTop
import kotlinx.css.px
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.dom.div
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.chip.Chip
import supergenerous.app.core.component.chip.chipSet
import supergenerous.app.core.util.format.TextCapitalization
import supergenerous.app.core.util.format.TextCapitalization.NONE

/**
 * Component that displays a [textField] with a set of selected items as [Chip]s beneath.
 *
 * This component is stateless.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@JsExport
private class SelectTextField : RComponent<SelectTextFieldProps, State>() {

    override fun RBuilder.render() {
        div {
            // Input
            textField(
                title = props.title,
                subtitle = props.subtitle,
                placeholder = props.placeholder,
                textCapitalization = props.textCapitalization,
                onTextSelect = props.onItemAdd,
                errorMessage = props.errorMessage
            )

            // Chips
            if (props.chips.isNotEmpty()) {
                styledDiv {
                    css {
                        marginTop = 8.px

                        display = Display.flex
                        flexDirection = FlexDirection.column
                        gap = 8.px
                    }

                    props.chipsTitle?.let { body1 { +it } }

                    chipSet(
                        chips = props.chips,
                        onChipRemove = props.onItemRemove
                    )
                }
            }
        }
    }
}

/**
 * Properties of the [SelectTextField] component.
 */
private external interface SelectTextFieldProps : Props {

    /**
     * The title to show above the text field.
     */
    var title: String?

    /**
     * The text to show below the [title].
     */
    var subtitle: String?

    /**
     * The placeholder inside the [TextField].
     */
    var placeholder: String?

    /**
     * The title to appear above the [Chip]s.
     */
    var chipsTitle: String?

    /**
     * The items to render as [Chip]s.
     */
    var chips: List<Chip>

    /**
     * The [TextCapitalization] to use for the [TextField].
     */
    var textCapitalization: TextCapitalization

    /**
     * Function called on adding an item. Passes a user entered [String] to be added.
     */
    var onItemAdd: (itemName: String) -> Unit

    /**
     * Function called on removing an item. Passes the [Chip.id] of the item to be removed.
     */
    var onItemRemove: (itemId: String) -> Unit

    /**
     * The error that occurred.
     */
    var errorMessage: String?

}

/**
 * Renders a [SelectTextField] component.
 */
public fun RBuilder.selectTextField(title: String? = null,
                                    subtitle: String? = null,
                                    placeholder: String? = null,
                                    textCapitalization: TextCapitalization = NONE,
                                    chipsTitle: String? = null,
                                    chips: List<Chip>,
                                    onItemAdd: (itemName: String) -> Unit,
                                    onItemRemove: (itemId: String) -> Unit,
                                    errorMessage: String? = null) {
    child(SelectTextField::class) {
        attrs.title = title
        attrs.subtitle = subtitle
        attrs.placeholder = placeholder
        attrs.textCapitalization = textCapitalization
        attrs.chipsTitle = chipsTitle
        attrs.chips = chips
        attrs.onItemAdd = onItemAdd
        attrs.onItemRemove = onItemRemove
        attrs.errorMessage = errorMessage
    }
}