package com.supergenerous.common.donor

/**
 * List of providers that send donors to SG.
 */
public enum class DonorProvider {

    PAYMINTY,

    KINDO

}