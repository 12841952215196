package supergenerous.app.core.util

import com.supergenerous.common.network.Url
import org.w3c.dom.url.URLSearchParams

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

/**
 * Returns the value of the [param] received, or `null` if it's not present.
 */
public fun URLSearchParams.get(param: Url.Param): String? {
    return get(param.value)
}