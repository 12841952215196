package materialui

/**
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Suppress("EnumEntryName")
@Deprecated("Don't use this component directly, it's already used inside the proper components.")
internal enum class MuiInputVariant {

    standard,
    outlined,
    filled

}