package supergenerous.app.donor.dashboard.view

import com.hipsheep.kore.error.ErrorType
import com.supergenerous.common.donor.Donor
import kotlinx.css.GridColumn
import kotlinx.css.gridColumn
import kotlinx.css.marginBottom
import kotlinx.css.padding
import kotlinx.css.paddingBottom
import kotlinx.css.px
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.heading1
import supergenerous.app.core.component.layout.grid
import supergenerous.app.core.component.snackbar.SnackbarMessageQueue
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.core.util.RouteProps
import supergenerous.app.core.util.component.toolbar
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.push
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.dashboard.viewmodel.PersonalInfoEditViewModel
import supergenerous.app.donor.util.Url

/**
 * Screen that allows donors to edit their personal information.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private class PersonalInfoEditScreen : LifecycleOwnerComponent<PersonalInfoEditScreenProps, PersonalInfoEditScreenState>() {

    override fun PersonalInfoEditScreenState.init() {
        addressSearchResults = emptyList()
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                marginBottom = 48.px
            }

            toolbar(onBackBtnClick = ::showPersonalInfoScreen)
        }

        grid(isCentered = true) {
            css {
                paddingBottom = 24.px

                mobileScreen { paddingBottom = 32.px }
            }

            styledDiv {
                css {
                    gridColumn = GridColumn("4 / 10")
                    padding(top = 0.px, right = 24.px, bottom = 24.px, left = 24.px)

                    mobileScreen {
                        gridColumn = GridColumn("1 / 3")
                    }
                }

                heading1 { +"My details" }

                state.donor?.let { donor ->
                    personalInfoEditPanel(
                        donor = donor,
                        onDonorChange = { donorUpdated, govIdInput ->
                            props.viewModel.saveDonor(donor = donorUpdated,
                                                      govIdInput = govIdInput)
                        },
                        onAddressSearch = props.viewModel::searchAddress,
                        addressSearchResults = state.addressSearchResults,
                        onAddressSelect = { setState { addressSearchResults = emptyList() } },
                        isSavingData = state.isSavingData,
                        errors = state.errors
                    )
                }
            }
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        props.viewModel.donor.observe { setState { donor = it } }

        props.viewModel.addressSearchResults.observeEvent { setState { addressSearchResults = it } }
        props.viewModel.donorSavedEvent.observeEvent {
            SnackbarMessageQueue.add(body = "Saved successfully")
            showPersonalInfoScreen()
        }
        props.viewModel.isActionInProgress.observe { setState { isSavingData = it } }

        props.viewModel.errors.observeEvent { setState { errors = it } }
    }

    /**
     * Navigates the user back to the [PersonalInfoScreen].
     */
    private fun showPersonalInfoScreen() {
        props.history.push(Url.Path.PERSONAL_INFO)
    }

}

/**
 * Properties of the [PersonalInfoEditScreen] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface PersonalInfoEditScreenProps : RouteProps {

    /**
     * View model used to get/update data.
     */
    var viewModel: PersonalInfoEditViewModel

}

/**
 * The state of the [PersonalInfoEditScreen] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface PersonalInfoEditScreenState : State {

    /**
     * Whether data is being saved or not.
     */
    var isSavingData: Boolean

    /**
     * The latest errors that have occurred.
     */
    var errors: Set<ErrorType>?

    /**
     * The addresses that match what the user is typing in the address picker.
     */
    var addressSearchResults: List<TextSearchResult<String>>

    /**
     * The [Donor] to edit info for.
     */
    var donor: Donor?

}

/**
 * Renders a [PersonalInfoEditScreen] component.
 */
public fun RBuilder.personalInfoEditScreen(viewModel: PersonalInfoEditViewModel) {
    withRouter(PersonalInfoEditScreen::class) {
        attrs.viewModel = viewModel
    }
}