package com.supergenerous.common.bank

/**
 * A bank account number.
 *
 * @author Raymond Feng (raymond@supergenerous.com)
 */
public typealias BankAccountNumber = String

/**
 * Bank code of the bank account number (i.e., the "12" in 12-XXXX-XXXXXXX-XXX).
 */
public val BankAccountNumber.bankCode: String
    get() = this.to16DigitFormat().substring(0, 2)

/**
 * Branch code of the bank account number (i.e., the "1234" in XX-1234-XXXXXXX-XXX).
 */
public val BankAccountNumber.branchCode: String
    get() = this.to16DigitFormat().substring(2, 6)

/**
 * Account code of the bank account number (i.e., the "1234567" in XX-XXXX-1234567-XXX).
 */
public val BankAccountNumber.accountCode: String
    get() = this.to16DigitFormat().substring(6, 13)

/**
 * Suffix code of the bank account number (i.e., the "123" in XX-XXXX-XXXXXXX-123).
 */
public val BankAccountNumber.suffixCode: String
    get() = this.to16DigitFormat().substring(13, 16)

/**
 * Converts a [BankAccountNumber] to a uniform 16 digit string without dashes or spaces.
 *
 * This method assumes the [BankAccountNumber] either has 15 or 16 digits.
 */
public fun BankAccountNumber.to16DigitFormat(): String {
    val bankAccountNo = this.replace("-", "").replace(" ", "")

    // First 13 digits of the bank account number
    val prefix = bankAccountNo.substring(0, 13)

    // Last 3 digits (padded with extra 0 to make suffix 3 digits)
    val suffix = bankAccountNo.substring(13, bankAccountNo.length).padStart(3, '0')

    return prefix + suffix
}

/**
 * Converts a [BankAccountNumber] to the format `12-1234-1234567-123` and returns the result.
 */
public fun BankAccountNumber.toDashFormat(): String {
    return if (this.isNotBlank()) {
        "$bankCode-$branchCode-$accountCode-$suffixCode"
    } else {
        this
    }
}

/**
 * Returns `true` if the [BankAccountNumber] matches the NZ bank account format
 * [here](https://en.wikipedia.org/wiki/New_Zealand_bank_account_number), i.e. "BB-bbbb-AAAAAAA-SSS".
 */
public fun BankAccountNumber.isValid(): Boolean {
    val bankAccountSanitised = this.replace("-", "").replace(" ", "")

    return bankAccountSanitised.matches(Regex("^[0-9]{15,16}$")) && isValidNzBankNumber(bankAccountSanitised)
}

/*
 * Private
 */

/**
 * Verifies that the [bankAccNumber] constitutes a complete and valid bank account number. Returns `true` when complete
 * and valid, or `false` otherwise.
 *
 * Adapted from [https://github.com/jarden-digital/nz-bank-account-validator].
 */
private fun isValidNzBankNumber(bankAccNumber: String): Boolean {
    val bank = bankAccNumber.bankCode.padStart(2, '0')
    val branch = bankAccNumber.branchCode.padStart(4, '0')
    val account = bankAccNumber.accountCode.padStart(8, '0')
    val suffix = bankAccNumber.suffixCode.padStart(4, '0')
    // Bank account number with extra 0s at the start for the account and suffix
    val bankAccNumberFull = bank + branch + account + suffix

    // Account == 00000000 is a special invalid case. It would pass any modulo check but is not valid.
    if (account == "00000000" || !isValidBankAndBranch(bankId = bank.toInt(), branchId = branch.toInt())) {
        return false
    }

    val weightFactor = getBankAccWeightFactor(bankId = bank.toInt(), accountNumber = account.toInt())

    // Multiply every digit of the bank account number by the corresponding digit of the weightFactor,
    // then add them together
    val weightedSum = weightFactor.mapIndexed { index, character ->
        // The character might be 'A', this corresponds to a weight of 10.
        val weight = when {
            character.isDigit() -> character.digitToInt()
            character == 'A' -> 10
            else -> throw RuntimeException("Characters in weight factor should be only digits or 'A', but was '$character'")
        }

        weight * bankAccNumberFull[index].digitToInt()
    }.sum()

    // Get the modulus factor for the bank.
    val divisor = when (bank.toInt()) {
        25, 26, 28, 29, 33 -> 10
        31 -> 1
        else -> 11
    }

    // If there is no remainder then the bank account number is valid according to the guidelines in
    // https://github.com/jarden-digital/nz-bank-account-validator
    return weightedSum % divisor == 0
}

/**
 * Verifies that the [branchId] is valid for the bank with [bankId]. Returns `true` if valid, `false` if invalid.
 *
 * Adapted from [https://github.com/jarden-digital/nz-bank-account-validator].
 */
private fun isValidBankAndBranch(bankId: Int, branchId: Int): Boolean {
    return bankBranches.find { it.prefix == bankId }?.branchRanges?.any { it.contains(branchId) } ?: false
}

/**
 * Gets the weight of each digit in the bank account string.
 *
 * Adapted from [https://github.com/jarden-digital/nz-bank-account-validator].
 */
private fun getBankAccWeightFactor(bankId: Int, accountNumber: Int): String {
    return when (bankId) {
        8 -> "000000076543210000"
        25 -> "000000017317310000"
        5, 31 -> "000000000000000000" // Makes every number is valid. Weight factor is unknown or non-existing.
        else -> if (accountNumber < 990_000) "00637900A584210000" else "00000000A584210000"
    }
}

/**
 * All bank data + range info.
 *
 * Ranges taken from Payments NZ bank branch register, September 2021
 * (see https://www.paymentsnz.co.nz/resources/industry-registers/bank-branch-register/).
 */
private val bankBranches: List<BankBranches> = listOf(BankBranches(prefix = 1, branchRanges = listOf(1..999, 1100..1199, 1800..1899)),
                                                      BankBranches(prefix = 2, branchRanges = listOf(1..999, 1200..1299, 2000..2100)),
                                                      BankBranches(prefix = 3, branchRanges = listOf(1..999, 1300..1399, 1500..1599, 1700..1799, 1900..1999, 7350..7399)),
                                                      BankBranches(prefix = 4, branchRanges = listOf(2020..2024)),
                                                      BankBranches(prefix = 5, branchRanges = listOf(8884..8889)),
                                                      BankBranches(prefix = 6, branchRanges = listOf(1..999, 1400..1499)),
                                                      BankBranches(prefix = 8, branchRanges = listOf(6500..6599)),
                                                      BankBranches(prefix = 10, branchRanges = listOf(5165..5169)),
                                                      BankBranches(prefix = 11, branchRanges = listOf(5000..6499, 6600..8999)),
                                                      BankBranches(prefix = 12, branchRanges = listOf(3000..3299, 3400..3499, 3600..3699)),
                                                      BankBranches(prefix = 13, branchRanges = listOf(4900..4999)),
                                                      BankBranches(prefix = 14, branchRanges = listOf(4700..4799)),
                                                      BankBranches(prefix = 15, branchRanges = listOf(3900..3999)),
                                                      BankBranches(prefix = 16, branchRanges = listOf(4400..4499)),
                                                      BankBranches(prefix = 17, branchRanges = listOf(3300..3399)),
                                                      BankBranches(prefix = 18, branchRanges = listOf(3500..3599)),
                                                      BankBranches(prefix = 19, branchRanges = listOf(4600..4649)),
                                                      BankBranches(prefix = 20, branchRanges = listOf(4100..4199)),
                                                      BankBranches(prefix = 21, branchRanges = listOf(4800..4899)),
                                                      BankBranches(prefix = 22, branchRanges = listOf(4000..4049)),
                                                      BankBranches(prefix = 23, branchRanges = listOf(3700..3799)),
                                                      BankBranches(prefix = 24, branchRanges = listOf(4300..4349)),
                                                      BankBranches(prefix = 25, branchRanges = listOf(2500..2599)),
                                                      BankBranches(prefix = 27, branchRanges = listOf(3800..3849)),
                                                      BankBranches(prefix = 30, branchRanges = listOf(2900..2949)),
                                                      BankBranches(prefix = 31, branchRanges = listOf(2800..2849)),
                                                      BankBranches(prefix = 38, branchRanges = listOf(9000..9499)),
                                                      BankBranches(prefix = 88, branchRanges = listOf(8800..8805)))

/**
 * Represents the bank [prefix] (the first sections of digits in a bank account number) and the valid branch numbers for
 * that bank.
 */
private data class BankBranches(

    /**
     * The bank [prefix].
     */
    val prefix: Int,

    /**
     * Valid branches for this bank [prefix].
     */
    val branchRanges: List<IntRange>

)