package supergenerous.app.core.auth.view

import com.hipsheep.kore.error.ErrorType
import com.hipsheep.kore.error.ErrorType.*
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.JustifyContent
import kotlinx.css.alignSelf
import kotlinx.css.display
import kotlinx.css.gap
import kotlinx.css.justifyContent
import kotlinx.css.marginTop
import kotlinx.css.px
import kotlinx.html.js.onSubmitFunction
import react.Props
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import styled.styledForm
import supergenerous.app.core.auth.model.AuthError.*
import supergenerous.app.core.auth.viewmodel.AuthViewModel
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.dialog.DialogSize.SMALL
import supergenerous.app.core.component.dialog.dialog
import supergenerous.app.core.component.snackbar.SnackbarMessageQueue
import supergenerous.app.core.component.textBold
import supergenerous.app.core.component.textLinkButton
import supergenerous.app.core.component.textfield.TextFieldType.EMAIL
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.util.mobileScreen

/**
 * Dialog that allows the user to send a password reset email.
 *
 * This dialog shows 2 views:
 *
 * 1. When the user is asked to enters an email address to send the password reset request to.
 * 2. When the request is sent to the user successfully.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
private class PasswordResetDialog : LifecycleOwnerComponent<PasswordResetDialogProps, PasswordResetDialogState>() {

    /**
     * Initialises component state to defaults.
     */
    override fun PasswordResetDialogState.init() {
        showEmailSentView = false
        isSendingResetRequest = false
        email = ""
    }

    override fun RBuilder.render() {
        val dialogTitle = if (state.showEmailSentView) "Email sent" else "Reset password"

        dialog(
            title = dialogTitle,
            size = SMALL,
            content = {
                styledDiv {
                    css {
                        +AuthStyle.centredColumnFlex
                    }

                    if (state.showEmailSentView) {
                        // Show "Password reset email sent" view
                        body1 {
                            +"Instructions to reset your password have been sent to: "

                            textBold { +state.email }
                        }

                        body1 {
                            css {
                                marginTop = 48.px
                                mobileScreen {
                                    marginTop = 24.px
                                }
                            }

                            +"Didn't receive an email? Check your spam filter, or "

                            textLinkButton(onClick = { setState { showEmailSentView = false } }) {
                                +"try another email address."
                            }
                        }
                    } else {
                        // Show "Ask for password reset email" view
                        styledForm {
                            css {
                                +AuthStyle.centredColumnFlex
                                gap = 40.px

                                mobileScreen {
                                    gap = 24.px
                                }
                            }

                            /*
                             * When you are in a form and press "enter" it validates the form before allowing the
                             * form to submit. Because the email field is type=email if there is an invalid email
                             * then it adds the :invalid pseudo class to the input, shows a popup on the invalid
                             * fields, and does not allow the submit action to continue. This is a problem
                             * because we want to let Firebase do the validation.
                             *
                             * Setting novalidate=true on the form stops the built-in form validation behaviour.
                             */
                            attrs.novalidate = true

                            // Prevent the default submit event behavior or the whole web app will be reloaded on Chrome
                            // (see https://github.com/ReactTraining/react-router/issues/1933)
                            attrs.onSubmitFunction = { event -> event.preventDefault() }

                            body1 {
                                +"Enter the email associated with your account and we’ll send an email with instructions to reset your password."
                            }

                            textField(
                                title = "Email",
                                type = EMAIL,
                                value = state.email,
                                errorMessage = state.error,
                                onTextChange = {
                                    setState {
                                        email = it
                                        error = null
                                    }
                                }
                            )

                            styledDiv {
                                css {
                                    alignSelf = Align.stretch
                                    display = Display.flex
                                    justifyContent = JustifyContent.center
                                }

                                button(
                                    showLoadingIcon = state.isSendingResetRequest,
                                    label = "SEND INSTRUCTIONS",
                                    isSubmitButton = true,
                                    onClick = { props.viewModel.sendPasswordResetEmail(state.email) }
                                )
                            }
                        }
                    }
                }
            },
            onClose = { props.onClose() }
        )
    }

    override fun componentDidMount() {
        super.componentDidMount()

        props.viewModel.isActionInProgress.observe { setState { isSendingResetRequest = it } }
        props.viewModel.onPasswordResetSent.observeEvent { setState { showEmailSentView = true } }
        props.viewModel.errors.observeEvent { processErrors(errors = it) }
    }

    /**
     * Processes the [errors], setting the message against the appropriate field, or adding it to the
     * [SnackbarMessageQueue].
     */
    private fun processErrors(errors: Set<ErrorType>) {
        errors.forEach { error ->
            val errorMessage = when (error) {
                is HttpError -> {
                    SnackbarMessageQueue.add(title = "Reset password failed",
                                             body = "An unknown error occurred: (Code ${error.statusCode})")
                    null
                }
                is AppError -> when (error.code) {
                    INVALID_EMAIL -> "Invalid email"
                    USER_NOT_FOUND -> "Email address not found"
                    NETWORK_REQUEST_FAILED -> {
                        SnackbarMessageQueue.add(title = "Reset password failed",
                                                 body = "There was network issue when sending the reset password email. Try again later, or contact support if this error persists.")

                        null
                    }
                    else -> "An unknown error occurred: (Code ${error.code})"
                }
            }

            errorMessage?.let { setState { this.error = it } }
        }
    }

}

/**
 * Props used by the [PasswordResetDialog] component.
 */
private external interface PasswordResetDialogProps : Props {

    /**
     * Component ViewModel.
     */
    var viewModel: AuthViewModel

    /**
     * Callback called when the dialog closes.
     */
    var onClose: () -> Unit

}

/**
 * State of the [PasswordResetDialog] component.
 */
private external interface PasswordResetDialogState : State {

    /**
     * The email entered into the dialog.
     */
    var email: String

    /**
     * `true` when password reset request is being sent, `false` otherwise.
     */
    var isSendingResetRequest: Boolean

    /**
     * `true` if the dialog should display the view that indicates the email was sent successfully, or `false` if it
     * should show the one that asks the user to enter an email address to send the reset password request to.
     */
    var showEmailSentView: Boolean

    /**
     * The error that occurred when resetting email, if any occurred.
     */
    var error: String?

}

/**
 * Renders a [passwordResetDialog] component.
 */
public fun RBuilder.passwordResetDialog(viewModel: AuthViewModel, onClose: () -> Unit) {
    child(PasswordResetDialog::class) {
        attrs {
            this.viewModel = viewModel
            this.onClose = onClose
        }
    }
}
