package supergenerous.app.donor.setup.view

import com.hipsheep.kore.error.ErrorType
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.id.IdDocument.Type.*
import kotlinx.css.Display
import kotlinx.css.GridColumn
import kotlinx.css.GridRow
import kotlinx.css.GridTemplateColumns
import kotlinx.css.GridTemplateRows
import kotlinx.css.display
import kotlinx.css.fr
import kotlinx.css.gap
import kotlinx.css.gridColumn
import kotlinx.css.gridRow
import kotlinx.css.gridTemplateColumns
import kotlinx.css.gridTemplateRows
import kotlinx.css.px
import kotlinx.datetime.LocalDate
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.dividerHorizontal
import supergenerous.app.core.component.picker.datePicker
import supergenerous.app.core.component.radio.RadioButton
import supergenerous.app.core.component.radio.radioGroup
import supergenerous.app.core.component.textfield.TextFieldType
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.util.format.TextCapitalization
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.setup.model.SetupError.*
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.GOV_ID
import supergenerous.app.donor.setup.viewmodel.SetupViewModel

/**
 * Screen that allows donors to enter their government ID (used for ID verification).
 */
private class GovIdScreen : SetupScreen<SetupScreenProps, GovIdScreenState>() {

    override val setupStep: SetupStep = GOV_ID


    override fun GovIdScreenState.init() {
        showGovIdTypeError = false
    }

    override fun RBuilder.render() {
        setupInputContainer(
            title = { +"Your ID" },
            subtitle = { +"We are legally required to verify your identity before we can claim your donation tax rebates from the IRD." },
            setupViewModel = props.viewModel,
            onNextBtnClick = ::saveGovId,
            onBackBtnClick = ::goBack,
        ) {
            radioGroup(
                title = "Select ID type",
                radioButtons = listOf(RadioButton(label = "New Zealand Driver Licence",
                                                  value = DRIVER_LICENCE_NZ.toString()),
                                      RadioButton(label = "New Zealand Passport",
                                                  value = PASSPORT.toString())),
                valueSelected = state.govIdType.toString(),
                onSelect = { value ->
                    setState {
                        govIdType = IdDocument.Type.valueOf(value)

                        // Reset all errors when changing the selected ID type
                        showGovIdTypeError = false
                        govIdNumberError = null
                        govIdExpiryDateError = null
                        govIdVersionError = null
                    }
                },
                showError = state.showGovIdTypeError
            )

            state.govIdType?.let { govIdType ->
                dividerHorizontal()

                styledDiv {
                    css {
                        display = Display.grid
                        gridTemplateRows = GridTemplateRows.auto
                        gridTemplateColumns = GridTemplateColumns(1.fr, 1.fr)
                        gap = 24.px
                        mobileScreen {
                            gridTemplateRows = GridTemplateRows.auto
                            gridTemplateColumns = GridTemplateColumns.auto
                        }
                    }

                    govIdField(govIdType)

                    dateOfBirthField()
                }
            }
        }
    }

    /**
     * Renders the fields necessary to provide the government ID info.
     */
    private fun RBuilder.govIdField(govIdType: IdDocument.Type) {
        val (field1, field2) = when (govIdType) {
            DRIVER_LICENCE_NZ -> listOf(GovIdField(title = "Driver licence number",
                                                   placeholder = "Enter 8 characters",
                                                   errorMessage = state.govIdNumberError),
                                        GovIdField(title = "Version number",
                                                   placeholder = "Enter 3 numbers",
                                                   errorMessage = state.govIdVersionError))
            PASSPORT -> listOf(GovIdField(title = "Passport number",
                                          placeholder = "Enter 7 or 8 characters",
                                          errorMessage = state.govIdNumberError),
                               GovIdField(title = "Expiry date",
                                          placeholder = "",
                                          errorMessage = state.govIdExpiryDateError))
        }


        styledDiv {
            css {
                gridColumn = GridColumn("1 / 2")
            }

            textField(
                title = field1.title,
                textCapitalization = TextCapitalization.UPPERCASE,
                placeholder = field1.placeholder,
                value = state.govIdNumber,
                onTextChange = { text ->
                    setState {
                        govIdNumber = text
                        govIdNumberError = null
                    }
                },
                errorMessage = field1.errorMessage
            )
        }

        styledDiv {
            css {
                gridColumn = GridColumn("1 / 2")
                gridRow = GridRow("2 / 3")
            }

            when (govIdType) {
                DRIVER_LICENCE_NZ -> {
                    textField(
                        title = field2.title,
                        type = TextFieldType.NUMBER,
                        placeholder = field2.placeholder,
                        value = state.govIdVersion,
                        onTextChange = { text ->
                            setState {
                                govIdVersion = text
                                govIdVersionError = null
                            }
                        },
                        errorMessage = field2.errorMessage
                    )
                }
                PASSPORT -> {
                    datePicker(
                        title = field2.title,
                        value = state.govIdExpiryDate,
                        onDateChange = { date ->
                            setState {
                                govIdExpiryDate = date
                                govIdExpiryDateError = null
                            }
                        },
                        errorMessage = field2.errorMessage
                    )
                }
            }
        }
    }

    /**
     * Renders the date of birth field.
     */
    private fun RBuilder.dateOfBirthField() {
        styledDiv {
            css {
                gridColumn = GridColumn("1 / 2")
                gridRow = GridRow("3 / 3")
            }

            datePicker(
                title = "Date of birth",
                value = state.dateOfBirth,
                disableFutureDates = true,
                onDateChange = {
                    setState {
                        dateOfBirth = it
                        dateOfBirthError = null
                    }
                },
                errorMessage = state.dateOfBirthError
            )
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // Load the values already provided by the donor (if any)
        props.viewModel.donor.observe { donor ->
            val govId = donor.govId
            val (idDocType, idDocValue1, idDocVersion) = when (govId) {
                is IdDocument.DriverLicenceNz -> Triple(DRIVER_LICENCE_NZ, govId.number, govId.version)
                is IdDocument.Passport -> Triple(PASSPORT, govId.number, null)
                else -> Triple(null, null, null)
            }
            val idDocExpiryDate = if (govId is IdDocument.Passport) govId.expiryDate else null

            setState {
                dateOfBirth = donor.dateOfBirth
                govIdType = idDocType
                govIdNumber = idDocValue1
                govIdVersion = idDocVersion
                govIdExpiryDate = idDocExpiryDate
            }
        }

        props.viewModel.errors.observeEvent { errors ->
            errors.forEach { error ->
                when ((error as? ErrorType.AppError)?.code) {
                    DATE_OF_BIRTH_MISSING -> setState { dateOfBirthError = "Required" }
                    GOV_ID_TYPE_MISSING -> setState { showGovIdTypeError = true }
                    GOV_ID_NUMBER_MISSING -> setState { govIdNumberError = "Required" }
                    DRIVER_LICENCE_NUMBER_INVALID -> setState { govIdNumberError = "Should be 8 characters, beginning with 2 letters then 6 numbers" }
                    DRIVER_LICENCE_VERSION_MISSING -> setState { govIdVersionError = "Required" }
                    DRIVER_LICENCE_VERSION_INVALID -> setState { govIdVersionError = "Should be 3 numbers" }
                    PASSPORT_NUMBER_INVALID -> setState { govIdNumberError = "Should be 7 or 8 characters" }
                    PASSPORT_EXPIRY_DATE_MISSING -> setState { govIdExpiryDateError = "Required" }
                    PASSPORT_EXPIRY_DATE_INVALID -> setState { govIdExpiryDateError = "Invalid" }
                }
            }
        }
    }

    /**
     * Saves the government ID provided through the UI.
     */
    private fun saveGovId() {
        props.viewModel.saveGovId(dateOfBirth = state.dateOfBirth,
                                  govIdType = state.govIdType,
                                  govIdNumber = state.govIdNumber,
                                  driverLicenceVersion = state.govIdVersion,
                                  govIdExpiryDate = state.govIdExpiryDate)
    }

    /*
     * Inner types
     */

    /**
     * Container for info needed to display [Donor.govId] fields.
     */
    private data class GovIdField(
        val title: String,
        val placeholder: String,
        val errorMessage: String?
    )

}

/**
 * State of the [GovIdScreen] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface GovIdScreenState : State {

    /**
     * Type of [IdDocument] selected by the user.
     */
    var govIdType: IdDocument.Type?

    /**
     * Whether an error occurred on the [govIdType] field.
     */
    var showGovIdTypeError: Boolean

    /**
     * Date of birth provided by the user.
     */
    var dateOfBirth: LocalDate?

    /**
     * The error that occurred on the date of birth field.
     */
    var dateOfBirthError: String?

    /**
     * First value provided for the [govIdType] selected by the user.
     */
    var govIdNumber: String?

    /**
     * The error that occurred on the [govIdNumber] field.
     */
    var govIdNumberError: String?

    /**
     * Version number provided when [govIdType] is [DRIVER_LICENCE_NZ].
     */
    var govIdVersion: String?

    /**
     * The error that occurred on the [govIdVersion] field.
     */
    var govIdVersionError: String?

    /**
     * Expiry date provided when [govIdType] is [PASSPORT].
     */
    var govIdExpiryDate: LocalDate?

    /**
     * The error that occurred on the [govIdExpiryDate] field.
     */
    var govIdExpiryDateError: String?

}

/**
 * Renders an [GovIdScreen] component.
 */
public fun RBuilder.govIdScreen(viewModel: SetupViewModel) {
    withRouter(GovIdScreen::class) {
        attrs.viewModel = viewModel
    }
}