package supergenerous.app.core.auth.model

import com.supergenerous.common.user.User

/**
 * Result obtained from authenticating a [User].
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public data class AuthResult(

    /**
     * User that was authenticated.
     */
    val user: User,

    /**
     * `true` if the [user] signed up for the first time, or `false` if it's an existing user.
     */
    val isNewUser: Boolean,

    /**
     * Provider used to authenticate the [user].
     */
    val authProvider: AuthProvider

)