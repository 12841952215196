package supergenerous.app.donor.donee

import com.hipsheep.kore.model.repo.Repository
import com.hipsheep.kore.resource.Resource
import com.hipsheep.kore.resource.Resource.Success
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeBasicInfo
import supergenerous.app.core.search.DoneeSearchService
import supergenerous.app.core.search.model.TextSearchResult

/**
 * [Repository] used to manage donee data.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class DoneeRepository(

    private val doneeSearchService: DoneeSearchService

) : Repository() {

    /**
     * Searches for donees that match [namePartial] and [doneeType].
     */
    public suspend fun searchDonees(namePartial: String,
                                    doneeType: Donee.Type): Resource<List<TextSearchResult<DoneeBasicInfo>>> {
        val namePartialTrimmed = namePartial.trim()

        // Don't search for values that are too short
        if (namePartialTrimmed.length < 2) {
            return Success(emptyList())
        }

        return getResource { doneeSearchService.searchDonees(namePartial = namePartialTrimmed, type = doneeType) }
    }

}