package supergenerous.app.donor.setup.view

import com.hipsheep.kore.error.ErrorType
import kotlinx.css.Display.flex
import kotlinx.css.FlexDirection
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.gap
import kotlinx.css.marginTop
import kotlinx.css.px
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.checkbox
import supergenerous.app.core.component.inputTitle
import supergenerous.app.core.component.textfield.TextFieldType.PHONE_NUMBER
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.setup.model.SetupError.PHONE_NUMBER_MISSING
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.CONTACT_INFO
import supergenerous.app.donor.setup.viewmodel.SetupViewModel
import supergenerous.app.donor.util.component.emailPicker

/**
 * Screen that allows donors to enter some personal information.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class ContactInfoScreen : SetupScreen<SetupScreenProps, ContactInfoScreenState>() {

    override val setupStep: SetupStep = CONTACT_INFO


    override fun ContactInfoScreenState.init() {
        otherEmails = setOf()
        phoneNumber = ""
        isMarketingEmailsEnabled = true
    }

    override fun RBuilder.render() {
        setupInputContainer(
            title = { +"Contact details" },
            subtitle = {
                +"This information helps us identify your donations. Some charitable organisations can only find receipts using contact details, so we appreciate you helping them out!"
            },
            setupViewModel = props.viewModel,
            onNextBtnClick = ::saveContactInfoScreen,
            onBackBtnClick = ::goBack,
        ) {
            // Other emails
            emailPicker(
                title = "Email address",
                subtitle = "Please provide any email address you may previously have used to make a donation.",
                accountEmail = state.mainEmail,
                otherEmails = state.otherEmails,
                onEmailsChange = { setState { otherEmails = it } }
            )

            styledDiv {
                css {
                    marginTop = 24.px

                    display = flex
                    flexDirection = FlexDirection.column
                    gap = 8.px
                }

                inputTitle("Would you like to receive email updates about how the Supergenerous community is making a difference?")

                checkbox(
                    label = "Yes, I'd like to receive occasional marketing emails from Supergenerous.",
                    isChecked = state.isMarketingEmailsEnabled,
                    onChange = { isChecked -> setState { isMarketingEmailsEnabled = isChecked } }
                )
            }

            // Phone
            styledDiv {
                css {
                    marginTop = 24.px
                }

                textField(
                    title = "Phone number",
                    subtitle = "We promise we won’t cold call you! A phone number is just another way the organisations you give to can identify your receipts.",
                    type = PHONE_NUMBER,
                    placeholder = "+64",
                    value = state.phoneNumber,
                    onTextChange = { phone ->
                        setState {
                            phoneNumber = phone
                            phoneNumberError = null
                        }
                    },
                    errorMessage = state.phoneNumberError
                )
            }
        }
    }

    /**
     * Saves the contact info.
     */
    private fun saveContactInfoScreen() {
        props.viewModel.saveContactInfo(otherEmails = state.otherEmails,
                                        phoneNumber = state.phoneNumber,
                                        isMarketingEmailsEnabled = state.isMarketingEmailsEnabled)
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // Load the values already provided by the donor (if any)
        props.viewModel.donor.observe { donor ->
            setState {
                mainEmail = donor.email
                otherEmails = donor.otherEmails
                phoneNumber = donor.phoneNumber ?: ""
                donor.isMarketingEmailsEnabled?.let { isMarketingEmailsEnabled = it }
            }
        }

        // Observe errors
        props.viewModel.errors.observeEvent { errors ->
            errors.forEach { error ->
                if ((error as? ErrorType.AppError)?.code == PHONE_NUMBER_MISSING) {
                    setState { phoneNumberError = "Required" }
                }
            }
        }
    }

}

/**
 * State of the [ContactInfoScreen] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface ContactInfoScreenState : State {

    /**
     * Email addresses selected by the user.
     */
    var mainEmail: String

    /**
     * Email addresses selected by the user.
     */
    var otherEmails: Set<String>

    /**
     * Phone number entered by the user.
     */
    var phoneNumber: String

    /**
     * The error that has occurred for the phone number field.
     */
    var phoneNumberError: String?

    /**
     * Whether the user has opted-in to marketing emails.
     */
    var isMarketingEmailsEnabled: Boolean

}

/**
 * Renders a [ContactInfoScreen] component.
 */
public fun RBuilder.contactInfoScreen(setupViewModel: SetupViewModel) {
    withRouter(ContactInfoScreen::class) {
        attrs.viewModel = setupViewModel
    }
}