package supergenerous.app.core.search.model

/**
 * The collections available to search on.
 */
public enum class SearchCollection(

    public val value: String

) {

    DONEES("donees")

}