package com.supergenerous.common.firebase

internal typealias FirestoreUnsubscribeFun = () -> Unit

internal actual class FirestoreChangeListener(

    private val unsubscribe: FirestoreUnsubscribeFun

) {

    internal actual fun stop() {
        unsubscribe()
    }

}

internal fun FirestoreUnsubscribeFun.toFirestoreChangeListener(): FirestoreChangeListener {
    return FirestoreChangeListener(this)
}