package supergenerous.app.donor.setup.view

import react.State
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.donor.setup.model.SetupStep

/**
 * Base screen component that is extended by all the setup screens.
 *
 * This component updates the current step in the [SetupScreenProps.viewModel] so it can calculate the next step
 * properly.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public abstract class SetupScreen<P : SetupScreenProps, S : State> : LifecycleOwnerComponent<P, S>() {

    /**
     * [SetupStep] that represents the current screen component.
     */
    protected abstract val setupStep: SetupStep

    override fun componentDidMount() {
        super.componentDidMount()

        // Set the current step in the view model so that other calculations can happen accurately
        props.viewModel.setCurrentSetupStep(setupStep)
    }

    /**
     * Shows the previous screen according to the browser history.
     */
    protected fun goBack() {
        props.history.goBack()
    }

}