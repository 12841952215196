package materialui.textfield

import kotlinext.js.asJsObject
import kotlinx.js.Object
import react.ReactNode

/**
 * Type for [MuiTextField]'s `InputProps` prop.
 *
 * Used to override the input field inside the [MuiTextField] in various ways.
 */
@JsName("inputProps")
internal class InputProps {

    /**
     * An element to render at the start of the input.
     *
     * @default `undefined`
     */
    var startAdornment: ReactNode? = undefined

    /**
     * An element to render at the end of the input.
     *
     * @default `undefined`
     */
    var endAdornment: ReactNode? = undefined

    /**
     * Props that should be passed into the input. If using [inputComponent] then leave this as undefined.
     *
     * @default `undefined`
     */
    var inputProps: InputComponentProps? = undefined

    /**
     * The input component override. Leave as undefined to use the default material UI input.
     *
     * @default `undefined`
     */
    var inputComponent: ReactNode? = undefined

    /*
     * Inner types
     */

    companion object {

        /**
         * Builds an [InputProps] object using the [build] method and returns it as an [Object].
         */
        fun buildInputProps(build: InputProps.() -> Unit): Object {
            return InputProps().apply { build() }.asJsObject()
        }

    }

}

/**
 * Type for [MuiTextField]'s [InputProps.inputProps] prop.
 *
 * Used to override the input field inside the [MuiTextField] by adding props directly to it.
 */
internal class InputComponentProps {

    /**
     * For `number` inputs. This controls the amount the value changes when using the arrows added by the browser to
     * the input.
     */
    var step: Double? = null

    /**
     * A regex for testing if the value in the input is valid. This does not stop invalid values from being entered,
     * just marks it with the pseudo-class `:invalid`.
     */
    var pattern: String? = null

    /*
     * Inner types
     */

    companion object {

        /**
         * Builds an [InputComponentProps] object using the [build] method and returns it.
         */
        fun buildInputComponentProps(build: InputComponentProps.() -> Unit): InputComponentProps {
            return InputComponentProps().apply { build() }
        }

    }

}

/**
 * Type for [MuiTextField]'s `InputLabelProps` prop.
 *
 * Used to override the input label inside the [MuiTextField] in various ways.
 */
@JsName("inputLabelProps")
internal class InputLabelProps {

    /**
     * Override the label shrink behaviour when the user focuses into the input.
     *
     * This can be used with `shrink = false` to always use the label as a piece of small text above the input, or to
     * programmatically handle the shrink effect if it doesn't work natively for some reason.
     */
    var shrink: Boolean? = undefined

    /*
     * Inner types
     */

    companion object {

        /**
         * Builds an [InputProps] object using the [build] method and returns it as an [Object].
         */
        fun buildInputLabelProps(build: InputLabelProps.() -> Unit): Object {
            return InputLabelProps().apply { build() }.asJsObject()
        }

    }

}