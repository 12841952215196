package com.hipsheep.kore.util

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

/**
 * Creates a tuple of type [Pair] from this and `List<[that]>`.
 *
 * This can be useful for creating `Map<A, List<B>>` literals with less noise, for example: `"key" toList "value"`.
 */
public infix fun <A, B> A.toList(that: B): Pair<A, List<B>> = Pair(this, listOf(that))