package supergenerous.app.core.util

import firebase.FirebaseConfig

/**
 * Configuration of the environment the app runs on.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public object EnvConfig {

    /**
     * Firebase configuration.
     */
    internal val firebaseConfig: FirebaseConfig = FirebaseConfig(apiKey = envConfigJs.FIREBASE_API_KEY,
                                                                 projectId = envConfigJs.FIREBASE_PROJECT_ID,
                                                                 appId = envConfigJs.FIREBASE_APP_ID,
                                                                 authDomain = envConfigJs.FIREBASE_AUTH_DOMAIN,
                                                                 databaseURL = envConfigJs.FIREBASE_DATABASE_URL,
                                                                 messagingSenderId = envConfigJs.FIREBASE_MESSAGING_SENDER_ID,
                                                                 measurementId = envConfigJs.FIREBASE_MEASUREMENT_ID)

    /**
     * GCP functions base URL.
     */
    public val gcpFunctionsUrlBase: String = envConfigJs.GCP_FUNCTIONS_URL_BASE

    /**
     * The Firebase admin bucket name.
     */
    public val firebaseBucketAdmin: String = envConfigJs.FIREBASE_BUCKET_ADMIN

    /**
     * API key used on calls to the location third-party system.
     */
    public val locationApiKey: String = envConfigJs.LOCATION_API_KEY

    /**
     * Google Tag Manager (GTM) ID used for analytics purposes, or `null` if GTM shouldn't be used for this environment.
     */
    public val gtmId: String? = envConfigJs.GTM_ID

    /**
     * The current version of the app, as stated in build.gradle.kt
     */
    public val appVersion: String = envConfigJs.APP_VERSION

    /**
     * Whether to add the Hubspot tracking script to the HTML document.
     */
    public val enableHubspotTracking: Boolean = envConfigJs.ENABLE_HUBSPOT_TRACKING ?: false

    /**
     * Host URL for full-text search queries.
     */
    public val typesenseUrl: String? = envConfigJs.TYPESENSE_URL

    /**
     * API key for full-text search queries.
     */
    public val typesenseApiKey: String? = envConfigJs.TYPESENSE_API_KEY

}
