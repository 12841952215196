package supergenerous.app.donor.util.res

/**
 * List of images used in the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class Image(fName: String) : supergenerous.app.core.res.image.Image {

    DRIVER_LICENCE_FRONT("driver_licence_front.png"),
    DRIVER_LICENCE_BACK("driver_licence_back.png");

    override val fileName: String = fName

}