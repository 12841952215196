package com.hipsheep.kore.resource

import com.hipsheep.kore.error.ErrorType

/**
 * Represents a resource obtained from the backend or the local DB, wrapping the value inside one of the "states"
 * indicated by the inner classes.
 *
 * This class is based on the idea presented [here](https://developer.android.com/topic/libraries/architecture/guide.html#addendum).
 */
public sealed class Resource<out T> {

    /**
     * @property data Data returned while the [Resource] is being loaded (this is usually the
     * data saved in the local memory or DB).
     */
    public data class Loading<T>(val data: T? = null) : Resource<T>()

    /**
     * @property data Data returned when the [Resource] is obtained successfully.
     */
    public data class Success<T>(val data: T) : Resource<T>()

    /**
     * @property type Type of error.
     */
    public data class Error(val type: ErrorType) : Resource<Nothing>()

}