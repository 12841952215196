package supergenerous.app.core.util.format

/**
 * Handles formatting strings, and validating that strings are formatted correctly.
 *
 * Property [format] is used to check that a string matches the expected regex when [matchesFormat] is called
 */
public abstract class TextFormatter(

    private val format: String

) {

    /**
     * Formats the [value] received and returns the result
     */
    public abstract fun format(value: String): String

    /**
     * Checks that the [value] matches [format] and returns `true` if it does, `false` otherwise
     */
    public fun matchesFormat(value: String): Boolean {
        return value.matches(format)
    }
}