package com.supergenerous.common.donation.receipt

import com.hipsheep.kore.util.isNotNullOrBlank
import kotlinx.serialization.Serializable
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.contract
import kotlin.jvm.JvmInline

/**
 * Some donees (e.g., Auckland City Mission) restart their receipt numbers every tax year, so we add a prefix
 * to the receipt ID saved in the SG DB to make it unique.
 *
 * This value is used to separate the prefix added from the receipt ID used by the donee.
 *
 * Format: <prefix-added-by-sg>__<donee-receipt-id>
 */
private const val RECEIPT_ID_PREFIX_SEPARATOR: String = "__"

/**
 * ID of a donation receipt.
 *
 * The receipt ID can contain a [prefix] because some donees (e.g., Auckland City Mission) restart their receipt
 * numbers every tax year, so we add a prefix to the receipt ID saved in the SG DB to make it unique.
 */
@JvmInline
@Serializable
public value class ReceiptId(

    /**
     * Full value, including prefix (if any).
     */
    public val value: String

) {

    /**
     * Prefix added to the receipt ID, or `null` if no prefix was added to this receipt ID.
     */
    public val prefix: String?
        get() = if (value.contains(RECEIPT_ID_PREFIX_SEPARATOR)) {
            value.split(RECEIPT_ID_PREFIX_SEPARATOR).first()
        } else {
            null
        }

    /**
     * Receipt ID as provided by the donee or donation platform (excluding the prefix added by SG).
     */
    public val valueExt: String
        get() = if (value.contains(RECEIPT_ID_PREFIX_SEPARATOR)) {
            value.split(RECEIPT_ID_PREFIX_SEPARATOR).last()
        } else {
            value
        }

}

/**
 * Returns `true` if the [ReceiptId] is not `null` and the [ReceiptId.value] is not blank.
 */
public fun ReceiptId?.isNotNullOrBlank(): Boolean {
    return !this.isNullOrBlank()
}

/**
 * Returns `true` if the [ReceiptId] is `null` or the [ReceiptId.value] is blank.
 */
@OptIn(ExperimentalContracts::class)
public fun ReceiptId?.isNullOrBlank(): Boolean {
    contract {
        returns(false) implies (this@isNullOrBlank != null)
    }

    return this == null || this.value.isBlank()
}

/**
 * Converts the `String` to a [ReceiptId], adding the [prefix] received (if it's not `null` or blank).
 */
public fun String.toReceiptId(prefix: String? = null): ReceiptId {
    val receiptId = if (prefix.isNotNullOrBlank()) {
        "$prefix$RECEIPT_ID_PREFIX_SEPARATOR$this"
    } else {
        this
    }

    return ReceiptId(value = receiptId)
}