package supergenerous.app.donor.setup.view

import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.JustifyContent
import kotlinx.css.alignItems
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.justifyContent
import kotlinx.css.marginLeft
import kotlinx.css.marginTop
import kotlinx.css.px
import react.RBuilder
import react.State
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.heading1
import supergenerous.app.core.component.image.icon
import supergenerous.app.core.res.Color
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.push
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.END
import supergenerous.app.donor.setup.viewmodel.SetupViewModel
import supergenerous.app.donor.util.Url
import supergenerous.app.donor.util.res.Icon

/**
 * Last screen shown for the setup/on-boarding process.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class SetupEndScreen : SetupScreen<SetupScreenProps, SetupEndScreenState>() {

    override val setupStep: SetupStep = END


    override fun SetupEndScreenState.init() {
        isSigningOut = false
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                marginTop = 150.px

                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = Align.center
                justifyContent = JustifyContent.start

                mobileScreen { marginTop = 80.px }
            }

            styledDiv {
                css {
                    display = Display.flex
                    alignItems = Align.center
                    justifyContent = JustifyContent.start
                }

                icon(
                    icon = Icon.CHECK_CIRCLE,
                    color = Color.PRIMARY,
                    size = 96.px
                )

                heading1 {
                    css {
                        marginLeft = 16.px
                    }

                    +"Great, you’re all done!"
                }
            }

            body1 {
                css {
                    marginTop = 32.px
                }

                +"Thanks for joining us. Select \"Done\" to go to your dashboard."
            }

            styledDiv {
                css {
                    marginTop = 48.px
                }

                button(
                    label = "Done",
                    showLoadingIcon = state.isSigningOut,
                    onClick = { props.history.push(Url.Path.DASHBOARD) },
                )
            }
        }
    }

}

/**
 * State of the [SetupEndScreen] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private interface SetupEndScreenState : State {

    /**
     * `true` if the user is being signed out, or `false` otherwise.
     */
    var isSigningOut: Boolean

}

/**
 * Renders a [SetupEndScreen] component.
 */
public fun RBuilder.setupEndScreen(setupViewModel: SetupViewModel) {
    withRouter(SetupEndScreen::class) {
        attrs.viewModel = setupViewModel
    }
}