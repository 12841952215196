package supergenerous.app.core.component.progress

import kotlinx.css.Align
import kotlinx.css.BorderStyle
import kotlinx.css.Display
import kotlinx.css.JustifyContent
import kotlinx.css.alignItems
import kotlinx.css.backgroundColor
import kotlinx.css.borderColor
import kotlinx.css.borderStyle
import kotlinx.css.borderWidth
import kotlinx.css.display
import kotlinx.css.gap
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import styled.css
import styled.styledDiv
import supergenerous.app.core.res.Color.*

/**
 * Progress bar component used throughout the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class ProgressBar : RComponent<ProgressBarProps, State>() {

    override fun RBuilder.render() {
        styledDiv {
            css {
                width = 100.pct
                height = 10.px

                display = Display.flex
                alignItems = Align.stretch
                justifyContent = JustifyContent.spaceBetween
                gap = 5.px
            }

            (1..props.numSteps).forEach { numStep ->
                styledDiv {
                    css {
                        width = 100.pct

                        borderWidth = 1.px
                        borderStyle = BorderStyle.solid
                        borderColor = PRIMARY.cssValue

                        backgroundColor = if (numStep <= props.currentStep) {
                            PRIMARY.cssValue
                        } else {
                            TRANSPARENT.cssValue
                        }
                    }
                }
            }
        }
    }

}

/**
 * Properties used by the [ProgressBar] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface ProgressBarProps : Props {

    /**
     * Current step.
     *
     * Use `1` for the first step.
     */
    var currentStep: Int

    /**
     * Total number of steps.
     */
    var numSteps: Int

}

/**
 * Renders a [ProgressBar] component.
 */
public fun RBuilder.progressBar(currentStep: Int,
                                numSteps: Int) {
    child(ProgressBar::class) {
        attrs.currentStep = currentStep
        attrs.numSteps = numSteps
    }
}