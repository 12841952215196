package supergenerous.app.core.auth.model

import com.hipsheep.kore.error.ErrorType.AppError
import com.hipsheep.kore.model.repo.Repository
import com.hipsheep.kore.resource.Resource
import com.hipsheep.kore.resource.Resource.*
import com.hipsheep.kore.util.Logger
import com.hipsheep.kore.util.logError
import com.supergenerous.common.firebase.toSgUser
import com.supergenerous.common.user.User
import firebase.FirebaseError
import firebase.Unsubscribe
import firebase.auth.Auth
import firebase.auth.GoogleAuthProvider
import firebase.auth.UserCredential
import kotlinx.coroutines.await
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.suspendCancellableCoroutine
import supergenerous.app.core.auth.model.AuthError.*
import supergenerous.app.core.auth.model.AuthProvider.EMAIL
import kotlin.coroutines.resume

/**
 * [Repository] used to manage authentication data.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public class AuthRepository(

    /**
     * Instance of the Firebase [Auth] object used to manage user's authentication.
     */
    // TODO: Abstract this into an AuthService
    private val firebaseAuth: Auth = firebase.auth()

) : Repository() {

    /**
     * Firebase auth provider that matches the [AuthProvider].
     */
    private val AuthProvider.firebaseProvider: firebase.auth.AuthProvider
        get() = when (this) {
            AuthProvider.GOOGLE -> GoogleAuthProvider()
            else -> throw NotImplementedError("Firebase provider for \"$name\" is not implemented")
        }


    /**
     * Loads the auth session by setting the proper persistence (so the auth session expires properly).
     */
    internal suspend fun loadAuthSession(): Resource<Unit> {
        return try {
            // Set auth state persistence to sign out user after the the tab/window is closed
            // (see https://firebase.google.com/docs/auth/web/auth-state-persistence#modifying_the_auth_state_persistence)
            firebaseAuth.setPersistence("session").await()

            Success(Unit)
        } catch (e: Throwable) {
            logError("Failed to load auth session", cause = e)

            Error(AppError(SESSION_LOAD_FAILED))
        }
    }

    /**
     * Obtains the current authentication state and returns `true` is the user is signed in,
     * or `false` if they aren't.
     */
    internal fun isUserSignedIn(): Flow<Resource<Boolean>> {
        return getUserAsync().map { userRes ->
            when (userRes) {
                is Loading -> Loading()
                is Success -> Success(userRes.data != null)
                is Error -> Error(userRes.type)
            }
        }
    }

    /**
     * Obtains the currently signed in [User], or `null` if there is no user signed in at the moment.
     */
    public fun getUserAsync(): Flow<Resource<User?>> = callbackFlow {
        trySend(Loading())

        val unsubscribe = firebaseAuth.onAuthStateChanged(
            nextOrObserver = { user ->
                trySend(Success(user?.toSgUser()))
            },
            error = { error ->
                logError("Failed to get user from Firebase, code: ${error.code} / message: ${error.message}")

                trySend(Error(AppError(getAuthError(firebaseErrorCode = error.code))))

                Unit
            })

        awaitClose { unsubscribe() }
    }

    /**
     * Obtains the currently signed in [User], or `null` if there is no user signed in at the moment.
     */
    internal suspend fun getUser(): Resource<User?> = suspendCancellableCoroutine { continuation ->
        // Make sure to unsubscribe from the onAuthStateChanged() so we don't get an IllegalStateException
        // (see https://github.com/supergenerous/sg-app/issues/16)
        var unsubscribe: Unsubscribe? = null

        // The only way for the unsubscribe to be available inside the callbacks is to have them as variables
        val onSuccess = { userFirebase: firebase.User? ->
            unsubscribe?.invoke()

            continuation.resume(Success(userFirebase?.toSgUser()))
        }

        val onError = { error: firebase.auth.Error ->
            logError("Error while observing auth state changes: ${error.code} - ${error.message}")

            unsubscribe?.invoke()

            continuation.resume(Error(AppError(getAuthError(error.code))))
        }

        continuation.invokeOnCancellation { unsubscribe?.invoke() }

        unsubscribe = firebaseAuth.onAuthStateChanged(nextOrObserver = onSuccess, error = onError)
    }

    /**
     * Authenticates the user using the [authProvider].
     */
    public suspend fun authenticateWithProvider(authProvider: AuthProvider): Resource<AuthResult> {
        return authenticateUser(authProvider = authProvider,
                                authenticate = { firebaseAuth.signInWithPopup(authProvider.firebaseProvider).await() })
    }

    /**
     * Signs up the user with the [email] and [password].
     */
    public suspend fun signUp(email: String, password: String): Resource<AuthResult> {
        return authenticateUser(authProvider = EMAIL,
                                authenticate = { firebaseAuth.createUserWithEmailAndPassword(email, password).await() })
    }

    /**
     * Signs in the user with the [email] and [password].
     */
    public suspend fun signIn(email: String, password: String): Resource<AuthResult> {
        return authenticateUser(authProvider = EMAIL,
                                authenticate = { firebaseAuth.signInWithEmailAndPassword(email, password).await() })
    }

    /**
     * Authenticates the user through the [authenticate] function and returns the [User] info and whether it's a new
     * sign-up or not.
     */
    private suspend fun authenticateUser(authProvider: AuthProvider,
                                         authenticate: suspend () -> UserCredential): Resource<AuthResult> {
        return try {
            val result = authenticate()

            Success(AuthResult(user = result.user!!.toSgUser(),
                               isNewUser = result.additionalUserInfo!!.isNewUser,
                               authProvider = authProvider))
        } catch (error: Throwable) {
            handleAuthError(error)
        }
    }

    /**
     * Sends a password reset email to the user that matches the [email].
     */
    public suspend fun sendResetPasswordEmail(email: String): Resource<Unit> {
        return try {
            firebaseAuth.sendPasswordResetEmail(email).await()
            Success(Unit)
        } catch (error: Throwable) {
            handleAuthError(error)
        }
    }

    /**
     * Handles the [error] by logging it to the console, converting it into an error code and returning it wrapped in
     * a [Resource.Error].
     */
    private fun handleAuthError(error: Throwable): Error {
        logError("Failed to authenticate user", cause = error)

        /*
         * If the error has a `code` property then it probably is a FirebaseError.
         *
         * IMPORTANT: `error as? FirebaseError` will always return a FirebaseError, even when it's not, because
         * FirebaseError is an external interface.
         *
         * See https://firebase.google.com/docs/reference/js/firebase.auth.Auth
         */
        val authError = getAuthError((error as? FirebaseError)?.code)

        return Error(AppError(code = authError))
    }

    /**
     * Gets the [AuthError] equivalent to the [firebaseErrorCode] and returns it.
     */
    private fun getAuthError(firebaseErrorCode: String?): AuthError {
        return when (firebaseErrorCode) {
            "auth/user-not-found" -> USER_NOT_FOUND
            "auth/wrong-password" -> WRONG_PASSWORD
            "auth/email-already-in-use" -> EMAIL_ALREADY_IN_USE
            "auth/weak-password" -> WEAK_PASSWORD
            "auth/network-request-failed" -> NETWORK_REQUEST_FAILED
            "auth/account-exists-with-different-credential" -> ACCOUNT_EXISTS_WITH_DIFFERENT_PROVIDER
            "auth/user-disabled" -> USER_DISABLED
            "auth/invalid-email" -> INVALID_EMAIL
            else -> OTHER
        }
    }

    /**
     * Signs out the currently signed in user and returns the result of the action.
     */
    public suspend fun signOut(): Resource<Unit> {
        return try {
            firebaseAuth.signOut().await()

            Success(Unit)
        } catch (e: Throwable) {
            logError("Failed to sign out user", cause = e)

            Error(AppError(SIGN_OUT_FAILED))
        }
    }

    /*
     * Inner types
     */

    private companion object : Logger()

}