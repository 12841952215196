package com.supergenerous.common.name

import com.supergenerous.common.data.Dto
import kotlinx.serialization.Serializable

/**
 * Full name of a person.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class FullName(
    var firstName: String,
    var middleName: String? = null,
    var lastName: String
) : Dto<FullNameDbo> {

    override fun toString(): String {
        return "${firstName.trim()}${middleName?.let { " ${it.trim()} " } ?: " "}${lastName.trim()}"
    }

    override fun toDbo(): FullNameDbo {
        return FullNameDbo(firstName = firstName,
                           middleName = middleName,
                           lastName = lastName)
    }

}