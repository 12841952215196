package supergenerous.app.donor.rebate

import com.hipsheep.kore.model.network.Response
import com.supergenerous.common.firebase.DbField.Name.DONOR_ID
import com.supergenerous.common.firebase.DbOperator.EQUAL
import com.supergenerous.common.firebase.DbQuery
import com.supergenerous.common.firebase.FirebaseAuth
import com.supergenerous.common.firebase.FirestoreClientService
import com.supergenerous.common.firebase.FirestoreCollection
import com.supergenerous.common.firebase.REBATES
import com.supergenerous.common.rebate.Rebate
import com.supergenerous.common.rebate.RebateDbo
import firebase.auth

/**
 * Service used to access [Rebate] data.
 */
public class RebateService(

    collection: FirestoreCollection<RebateDbo> = FirestoreCollection.REBATES

): FirestoreClientService<Rebate, RebateDbo>(collection, firebaseAuth = FirebaseAuth(auth())) {

    /**
     * Returns the [Rebate]s of the currently signed in donor.
     */
    public suspend fun getRebates(): Response<List<Rebate>> {
        return getDataAuth { userId ->
            getDocs(query = DbQuery(field = DONOR_ID, operator = EQUAL, value = userId))
        }
    }

}