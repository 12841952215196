package com.supergenerous.common.donor

import com.supergenerous.common.aml.AmlCheckResult
import com.supergenerous.common.campaign.Campaign
import com.supergenerous.common.data.DtoDataModel
import com.supergenerous.common.donation.platform.DonationPlatform
import com.supergenerous.common.id.IdVerificationResult
import com.supergenerous.common.organisation.Organisation
import com.supergenerous.common.tax.region.TaxRegion
import com.supergenerous.common.util.Timestamp
import com.supergenerous.common.util.toTimestamp
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

/**
 * State for a [Donor] in the system.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class DonorState(

    /**
     * This is the same ID as the corresponding [Donor.id]
     */
    override val id: String,

    /**
     * The ID of the donor on the CRM.
     */
    val crmId: String?,

    override val creationTimestamp: Timestamp = 0,

    override val lastUpdateTimestamp: Timestamp = 0,

    /**
     * Last time a request to link the [Donor] to SG on tax system was performed.
     */
    val taxLinkRequestTimestamp: Timestamp?,

    /**
     * The current tax link status of the [Donor] on the tax system (e.g., IRD).
     *
     * If no tax link requests have been made, the value is `null`.
     */
    val taxLinkStatus: TaxLinkStatus?,

    /**
     * Timestamps when the donor was sent a reminder about them being unlinked from the tax system.
     */
    val taxUnlinkReminderTimestamps: List<Timestamp> = emptyList(),

    /**
     * Results of the ID verifications performed for the donor.
     */
    val idVerificationResults: List<IdVerificationResult>,

    /**
     * Results of the AML checks performed for the donor.
     */
    public val amlCheckResults: List<AmlCheckResult>,

    /**
     * Date at which the last request to get the donor's AML info was sent, or `null` if no request was sent for this
     * or the request was sent prior to this property being added to the class.
     */
    val amlInfoRequestDate: LocalDate? = null,

    /**
     * Provider that sent the [Donor] to SG, or `null` if they signed up themselves.
     */
    val provider: DonorProvider?,

    /**
     * `true` if we received at least 1 rebate for the donor that was not claimed by SG (this happens when the donor
     * or another tax agent made a claim but the rebate funds were sent to SG).
     */
    val receivedRebateNotClaimedBySg: Boolean = false,

    /**
     * Referral code the [Donor] may submit on sign-up. This code will be associated with a [Campaign].
     */
    val referralCode: String?,

    /**
     * The state of the link between the donor and any of its donation platforms.
     */
    val donationPlatformLinkStates: Map<DonationPlatform, DonationPlatformLinkState>,

    /**
     * The organisations that the donor has selected that are invalid for the purposes of requesting receipts and
     * claiming donations.
     */
    val orgsInvalid: Set<Organisation> = emptySet(),

    /**
     * The [Donor]'s info on their donees' systems.
     */
    val doneesDonorInfo: Set<DoneeDonorInfo> = emptySet(),

    /**
     * Date of the last check performed for rebate claim updates on the tax system.
     */
    val rebateClaimUpdatesLastCheckDate: LocalDate? = null,

    /**
     * Timestamp of the last time the "No donations found" email was sent to the [Donor], or `null` if it has not yet
     * been sent.
     */
    val donationsNotFoundEmailTimestamp: Timestamp? = null,

    /**
     * Timestamp of the last time the "Government ID required" email was sent to the [Donor], or `null` if it has not
     * yet been sent.
     */
    val govIdRequiredEmailTimestamp: Timestamp? = null

) : DtoDataModel<DonorStateDbo> {

    /**
     * `true` if the identity of the [Donor] has been verified successfully, or `false` if it hasn't been verified yet
     * or if all the verifications have failed.
     *
     * If a successful AML check has been performed for the [Donor] then their identity is considered verified, as AML
     * checks include identity verification.
     */
    public val isIdentityVerified: Boolean
        get() = idVerificationResultLast?.isSuccess == true || isAmlCheckSuccess

    /**
     * The [IdVerificationResult] of the last ID verification performed for the [Donor].
     */
    public val idVerificationResultLast: IdVerificationResult?
        get() = idVerificationResults.maxByOrNull { it.timestamp ?: 0 }

    /**
     * `true` if an AML check has been performed for the donor and the result was successful, or `false` if it no check
     * has been run yet or if all the checks have failed.
     */
    public val isAmlCheckSuccess: Boolean
        get() = amlCheckResultLast?.isSuccess == true

    /**
     * The [AmlCheckResult] of the last AML check performed for the [Donor].
     */
    public val amlCheckResultLast: AmlCheckResult?
        get() = amlCheckResults.maxByOrNull { it.timestamp ?: 0 }

    /**
     * The tax region of the donor.
     */
    // TODO: Store this in the database
    public val taxRegion: TaxRegion
        get() = TaxRegion.NZ

    /**
     * The path to the dir where the [Donor]'s files used for ID verification are located.
     *
     * Do not use this directly to get the files, get them through [idVerificationResults] instead.
     *
     * Note: The path does not end with a "/".
     */
    public val filesPathIdVerification: String
        get() = "donors/$id/id"

    /**
     * The path to the dir where the [Donor]'s files used for AML checks are located.
     *
     * Do not use this directly to get the files, get them through [amlCheckResults] instead.
     *
     * Note: The path does not end with a "/".
     */
    public val filesPathAml: String
        get() = "donors/$id/aml"


    override fun toDbo(): DonorStateDbo {
        return DonorStateDbo(id = id,
                             crmId = crmId,
                             creationTimestamp = creationTimestamp,
                             lastUpdateTimestamp = lastUpdateTimestamp,
                             taxLinkRequestTimestamp = taxLinkRequestTimestamp,
                             taxLinkStatus = taxLinkStatus,
                             taxUnlinkReminderTimestamps = taxUnlinkReminderTimestamps,
                             idVerificationResults = idVerificationResults.map { it.toDbo() },
                             amlCheckResults = amlCheckResults.map { it.toDbo() },
                             amlInfoRequestDate = amlInfoRequestDate?.toTimestamp(),
                             provider = provider,
                             receivedRebateNotClaimedBySg = receivedRebateNotClaimedBySg,
                             referralCode = referralCode,
                             donationPlatformLinkStates = donationPlatformLinkStates.entries.associate {
                                 it.key.toString() to it.value.toDbo()
                             },
                             orgsInvalid = orgsInvalid.map { it.toDbo() },
                             doneesDonorInfo = doneesDonorInfo.map { it.toDbo() },
                             rebateClaimUpdatesLastCheckDate = rebateClaimUpdatesLastCheckDate?.toTimestamp(),
                             donationsNotFoundEmailTimestamp = donationsNotFoundEmailTimestamp,
                             govIdRequiredEmailTimestamp = govIdRequiredEmailTimestamp)
    }

}