package com.supergenerous.common.disbursement

import com.supergenerous.common.bank.BankAccountNumber
import kotlinx.serialization.Serializable

/**
 * Recipient that will receive the funds of a specific [Disbursement].
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public sealed class DisbursementRecipient {

    /**
     * The [Disbursement] will be received by the [Disbursement.donor].
     */
    @Serializable
    public data class Donor(

        /**
         * Bank account where the donor will receive the disbursement.
         */
        val bankAccountNumber: BankAccountNumber

    ) : DisbursementRecipient()

    /**
     * The [Disbursement] will be received by the donee that matches the [id].
     */
    @Serializable
    public data class Donee(

        /**
         * Internal ID assigned by the SG system.
         */
        val id: String,

        /**
         * Type of donee organisation.
         */
        val type: com.supergenerous.common.donee.Donee.Type,

        /**
         * Usual name used by the organisation for users.
         */
        val name: String,

        /**
         * Bank account where the donee will receive the disbursement.
         */
        val bankAccountNumber: BankAccountNumber

    ) : DisbursementRecipient()

    /*
     * Inner types
     */

    /**
     * List of type of entities that can receive disbursements from our system.
     */
    public enum class Type {

        CHARITIES,
        SCHOOLS,
        RELIGIOUS_ORGS,
        DONOR

    }

}