package supergenerous.app.core.component.table

import com.supergenerous.common.util.toString
import react.RBuilder
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.progress.progressStatus
import supergenerous.app.core.component.table.TableColumnConfig.HorizontalAlignment.LEFT

/**
 * Configuration for rendering a column in a [table].
 */
public sealed class TableColumnConfig<T>(

    /**
     * Function used for rendering a cell in the column.
     */
    internal val renderCellContent: RBuilder.(rowContent: T) -> Unit

) {

    /**
     * Header of the column.
     */
    internal abstract val header: String

    /**
     * Which column the cell contents should be placed on for mobile view, or `null` to be hidden on mobile.
     */
    internal abstract val mobileColumn: MobileColumn?

    /**
     * Horizontal alignment of the content inside the table cell.
     *
     * This value is only used for desktop view, in mobile everything is left-aligned.
     */
    internal abstract val horizontalAlignment: HorizontalAlignment


    /**
     * Renders a text cell.
     */
    public class Text<T>(
        override val header: String,
        override val mobileColumn: MobileColumn?,
        override val horizontalAlignment: HorizontalAlignment = LEFT,
        getValue: (rowContent: T) -> String?
    ) : TableColumnConfig<T>(
        renderCellContent = { rowContent ->
            body1 { +(getValue(rowContent) ?: "") }
        }
    )

    /**
     * Renders a money cell (i.e. prepended with a "$" symbol and right-aligned).
     */
    public class Money<T>(
        override val header: String,
        override val mobileColumn: MobileColumn?,
        override val horizontalAlignment: HorizontalAlignment = LEFT,
        getValue: (rowContent: T) -> Float?
    ) : TableColumnConfig<T>(
        renderCellContent = { rowContent ->
            val value = getValue(rowContent)?.toString(precision = 2) ?: " -"
            body1 {
                +"$$value"
            }
        }
    )

    /**
     * Renders a number cell (i.e. right-aligned).
     */
    public class Number<T>(
        override val header: String,
        override val mobileColumn: MobileColumn?,
        override val horizontalAlignment: HorizontalAlignment = LEFT,
        getValue: (rowContent: T) -> kotlin.Number?
    ) : TableColumnConfig<T>(
        renderCellContent = { rowContent ->
            val value = getValue(rowContent)?.let { number ->
                when (number) {
                    is Float -> number.toString(precision = 2)
                    is Double -> number.toString(precision = 2)
                    else -> number.toString()
                }
            }

            body1 { +(value ?: "") }
        }
    )

    /**
     * Renders the progress status cell.
     */
    public class ProgressStatus<T>(
        override val header: String,
        override val mobileColumn: MobileColumn?,
        override val horizontalAlignment: HorizontalAlignment = LEFT,
        getValue: (rowContent: T) -> supergenerous.app.core.progress.ProgressStatus?
    ) : TableColumnConfig<T>(
        renderCellContent = { rowContent ->
            getValue(rowContent)?.let { status ->
                progressStatus(status)
            }
        }
    )

    /**
     * Renders a custom cell type.
     *
     * This should be avoided in favour of the other [TableColumnConfig] implementations if possible.
     */
    public class Custom<T>(
        override val header: String,
        override val mobileColumn: MobileColumn?,
        override val horizontalAlignment: HorizontalAlignment,
        renderCell: RBuilder.(rowContent: T) -> Unit
    ) : TableColumnConfig<T>(renderCellContent = renderCell)

    /*
     * Inner types
     */

    /**
     * Columns available for cell placement in mobile view.
     */
    public enum class MobileColumn { FIRST, SECOND }

    /**
     * Horizontal alignment of the content inside the table cell.
     */
    public enum class HorizontalAlignment { LEFT, CENTER, RIGHT }

}