package supergenerous.app.core.component.snackbar

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.launch

/**
 * Queue of messages that will be shown in a [snackbar].
 */
// TODO: Make this a normal class (instead of an object) and pass it as property where necessary
public object SnackbarMessageQueue {

    /**
     * Scope used to create coroutines to send messages between senders and receiver.
     */
    // TODO: Not sure having a coroutine scope here is the best idea as we can't cancel it because the class is not
    //  lifecycle-dependent
    private val coroutineScope = MainScope()

    /**
     * Flow of messages to show in the snackbar.
     */
    private val messages = MutableSharedFlow<SnackbarMessage>()


    /**
     * Adds a message with [title] and [body] to the queue.
     */
    public fun add(title: String? = null, body: String) {
        coroutineScope.launch {
            messages.emit(SnackbarMessage(title = title, body = body))
        }
    }

    /**
     * Receives the [messages] added to the queue.
     */
    internal fun receive(block: (SnackbarMessage) -> Unit) {
        coroutineScope.launch {
            messages.collect { message ->
                block(message)
            }
        }
    }

}