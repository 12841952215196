package materialui.style

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

@Deprecated("Don't use this component directly, it's already used inside the proper components.")
internal data class MuiThemeOptions(

    //    shape: ShapeOptions
    //    breakpoints: BreakpointsOptions
    //    direction: Direction
    //    mixins: MixinsOptions
    //    components: Components
    val palette: MuiPaletteOptions
    //    shadows: Shadows
    //    spacing: SpacingOptions
    //    transitions: TransitionsOptions
    //    typography: TypographyOptions | ((palette: Palette) => TypographyOptions)
    //    zIndex: ZIndexOptions
    //    unstable_strictMode: boolean

)

@Deprecated("Don't use this component directly, it's already used inside the proper components.")
internal data class MuiPaletteOptions(

    val primary: MuiPaletteColor,
    val secondary: MuiPaletteColor
//    error: PaletteColorOptions
//    warning: PaletteColorOptions
//    info: PaletteColorOptions
//    success: PaletteColorOptions
//    mode: PaletteMode
//    tonalOffset: PaletteTonalOffset
//    contrastThreshold: number
//    common: Partial<CommonColors>
//grey: ColorPartial
//text: Partial<TypeText>
//divider: string
//action: Partial<TypeAction>
//background: Partial<TypeBackground>
//getContrastText: (background: string) => string

)

@Deprecated("Don't use this component directly, it's already used inside the proper components.")
internal data class MuiPaletteColor(

//    light?: string;
    val main: String,
//    dark?: string;
    val contrastText: String

)