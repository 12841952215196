package com.supergenerous.common.donation.platform

import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donor.Donor

/**
 * Platform used to donate money to a [Donee] and generate receipts for those donations.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public enum class DonationPlatform(

    /**
     * Name of the platform.
     */
    public val displayName: String,

    /**
     * URL of the platform's website.
     */
    public val websiteUrl: String,

    /**
     * The platform's main email address used by SG to contact them.
     */
    public val email: String,

    /**
     * `true` if the [DonationPlatform] has the infrastructure necessary to create and provide donation receipts to
     * [Donor]s, or `false` if they do not.
     */
    public val canProvideReceipts: Boolean,

    /**
     * Status that indicates if the platform is also an approved donee (this is important to know so that rebate claims
     * are filed with the platform's name and ID instead of the underlying [Donee] that received the donation).
     */
    public val doneeStatus: DoneeStatus,

    /**
     * Whether the [DonationPlatform] has automations associated with it.
     */
    public val isAutomated: Boolean = false

) {

    BOOSTED(displayName = "Boosted",
            websiteUrl = "https://www.boosted.org.nz/",
            email = "kiaora@boosted.org.nz",
            canProvideReceipts = true,
            doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    DONORBOX(displayName = "Donorbox",
             websiteUrl = "https://donorbox.org/",
             email = "support@donorbox.org",
             canProvideReceipts = false,
             doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    EDGE_LEARNING(displayName = "Edge Learning",
                  websiteUrl = "https://www.edgelearning.co.nz",
                  email = "support@edgelearning.co.nz",
                  canProvideReceipts = true,
                  doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    EVERYDAYHERO(displayName = "Everydayhero",
                 websiteUrl = "https://www.everydayhero.com",
                 email = "help-nz@justgiving.com",
                 canProvideReceipts = false,
                 doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    GIVE_LIVELY(displayName = "Give Lively",
                websiteUrl = "https://www.givelively.org/",
                email = "support@givelively.org",
                canProvideReceipts = false,
                doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    GIVEALITTLE(displayName = "Givealittle",
                websiteUrl = "https://givealittle.co.nz",
                email = "helpdesk@givealittle.co.nz",
                canProvideReceipts = true,
                doneeStatus = DoneeStatus(isDonee = true, govId = "CC46342;CC57443")),

    GOFUNDME(displayName = "GoFundMe",
             websiteUrl = "https://www.gofundme.com",
             email = "support@gofundme.com",
             canProvideReceipts = false,
             doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    GOFUNDRAISE(displayName = "GoFundraise",
                websiteUrl = "https://www.gofundraise.co.nz",
                email = "info@gofundraise.co.nz",
                canProvideReceipts = false,
                doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    GRASSROOTZ(displayName = "Grassrootz",
               websiteUrl = "https://grassrootz.com/",
               email = "support@grassrootz.com",
               canProvideReceipts = true,
               doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    INDIEGOGO(displayName = "Indiegogo",
              websiteUrl = "https://www.indiegogo.com/",
              email = "support@indiegogo.com",
              canProvideReceipts = false,
              doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    IPAYROLL(displayName = "iPayroll",
             websiteUrl = "https://www.ipayroll.co.nz/",
             email = "sales@ipayroll.co.nz",
             canProvideReceipts = true,
             doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    JUSTGIVING(displayName = "JustGiving",
               websiteUrl = "https://www.justgiving.com/",
               email = "help-nz@justgiving.com",
               canProvideReceipts = false,
               doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    KAMAR(displayName = "Kamar",
          websiteUrl = "https://www.kamar.nz",
          email = "helpdesk@kamar.nz",
          canProvideReceipts = true,
          doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    KINDO(displayName = "Kindo",
          websiteUrl = "http://kindo.co.nz",
          email = "enquiry@tgcl.co.nz",
          canProvideReceipts = true,
          doneeStatus = DoneeStatus(isDonee = false, govId = null),
          isAutomated = true),

    ONE_PERCENT_COLLECTIVE(displayName = "One Percent Collective",
                           websiteUrl = "https://www.onepercentcollective.org/",
                           email = "sheridan@onepercentcollective.org",
                           canProvideReceipts = true,
                           doneeStatus = DoneeStatus(isDonee = true, govId = "CC45257")),

    PCSCHOOL(displayName = "PCSchool",
             websiteUrl = "https://pcschool.net",
             email = "sales@pcschool.net",
             canProvideReceipts = true,
             doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    SHARE_MY_SUPER(displayName = "Share My Super",
                   websiteUrl = "https://sharemysuper.org.nz/",
                   email = "queries@sharemysuper.org.nz",
                   canProvideReceipts = false,
                   doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    SOUTHERN_STARS(displayName = "Southern Stars",
                   websiteUrl = "https://www.southernstars.org.nz",
                   email = "queenie@charity.org.nz",
                   canProvideReceipts = true,
                   doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    TRADEME_KINDNESS_STORE(displayName = "TradeMe Kindness Store",
                           websiteUrl = "https://www.trademe.co.nz/a/marketplace/stores/kindnessstore",
                           email = "charities@trademe.co.nz",
                           canProvideReceipts = true,
                           doneeStatus = DoneeStatus(isDonee = false, govId = null)),

    VOUCHARITY(displayName = "Voucharity",
               websiteUrl = "https://voucharity.com/",
               email = "info@voucharity.com",
               canProvideReceipts = true,
               doneeStatus = DoneeStatus(isDonee = false, govId = null));

    /**
     * ID of the [DonationPlatform].
     *
     * This is the same as the default enum [name].
     */
    public val id: String = name

    /**
     * `true` if donation receipts can be requested from the [DonationPlatform] to claim rebates for them, or `false`
     * otherwise.
     */
    public val canRequestReceipts: Boolean
        get() = this.canProvideReceipts

    /*
     * Inner types
     */

    /**
     * Status that indicates if a [DonationPlatform] is also an approved donee (this is important to know so that
     * rebate claims are filed with the platform's name and ID instead of the underlying [Donee] that received the
     * donation).
     */
    public data class DoneeStatus(

        /**
         * `true` if the [DonationPlatform] is an approved [Donee], or `false` if it's not.
         */
        val isDonee: Boolean,

        /**
         * ID assigned by the government to the [DonationPlatform] as a [Donee] (e.g., registration number from
         * Charities Register in NZ).
         */
        val govId: String?

    )

}