package com.supergenerous.common.id

import com.supergenerous.common.id.IdDocument.Type.*
import kotlinx.datetime.LocalDate

/**
 * ID document that can be used to verify a person's identity.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public sealed class IdDocument(public val type: Type) {

    /**
     * NZ driver licence.
     */
    public data class DriverLicenceNz(

        /**
         * Driver licence number.
         */
        val number: String,

        /**
         * Driver licence card version.
         */
        val version: String

    ) : IdDocument(type = DRIVER_LICENCE_NZ)

    /**
     * Passport.
     */
    public data class Passport(

        /**
         * Passport number.
         */
        val number: String,

        /**
         * Passport expiry date.
         */
        val expiryDate: LocalDate

    ) : IdDocument(type = PASSPORT)

    /*
     * Inner types
     */

    /**
     * Types of [IdDocument]s supported by the system.
     */
    public enum class Type {
        DRIVER_LICENCE_NZ, PASSPORT
    }

}