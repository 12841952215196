package supergenerous.app.donor.kindo.model

import com.hipsheep.kore.error.AppErrorCode
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.id.IdDocument

/**
 * An error that can occur when trying to link a donor to Kindo.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public enum class KindoLinkError : AppErrorCode {

    /**
     * Failed to send data to Kindo.
     */
    POST_MESSAGE_ERROR,

    /**
     * Failed to link the donor on our system.
     */
    LINK_DONOR_ERROR,

    /**
     * Did not receive all the expected data to link the donor.
     */
    BAD_DATA_FROM_KINDO,

    SIGNATURE_MISSING,
    SIGNATURE_INVALID,
    FIRST_NAME_MISSING,
    LAST_NAME_MISSING,
    ADDRESS_MISSING,
    PHONE_NUMBER_MISSING,
    TAX_ID_MISSING,
    TAX_ID_INVALID,
    SCHOOL_DISB_RECIPIENT_MISSING,
    BANK_ACC_MISSING,
    BANK_ACC_INVALID,

    // ATA
    ATA_TERM_NOT_ACCEPTED_TAX_AGENT,
    ATA_TERM_NOT_ACCEPTED_TAX_COMMUNICATION,
    ATA_TERM_NOT_ACCEPTED_SG_BANK_ACCOUNT,
    ATA_TERM_NOT_ACCEPTED_SG_FEE,
    ATA_TERM_NOT_ACCEPTED_SG_OPT_OUT,
    ATA_TERM_NOT_ACCEPTED_TAX_CORRESPONDENCE,
    ATA_TERM_NOT_ACCEPTED_TAX_ACCOUNT_ACCESS,

    // Gov ID
    /**
     * The [Donor]'s [IdDocument.Type] was missing.
     */
    GOV_ID_TYPE_MISSING,

    /**
     * The [Donor]'s [IdDocument]'s number was missing.
     */
    GOV_ID_NUMBER_MISSING,

    /**
     * The [Donor]'s [IdDocument.DriverLicenceNz.number] was invalid.
     */
    DRIVER_LICENCE_NUMBER_INVALID,

    /**
     * The [Donor]'s [IdDocument.DriverLicenceNz.version] was missing.
     */
    DRIVER_LICENCE_VERSION_MISSING,

    /**
     * The [Donor]'s [IdDocument.DriverLicenceNz.version] was invalid.
     */
    DRIVER_LICENCE_VERSION_INVALID,

    /**
     * The [Donor]'s [IdDocument.Passport.number] was invalid.
     */
    PASSPORT_NUMBER_INVALID,

    /**
     * The [Donor]'s [IdDocument.Passport.expiryDate] was missing.
     */
    PASSPORT_EXPIRY_DATE_MISSING,

    /**
     * The [Donor]'s [IdDocument.Passport.expiryDate] was invalid.
     */
    PASSPORT_EXPIRY_DATE_INVALID,

    /**
     * The [Donor.dateOfBirth] was missing.
     */
    DATE_OF_BIRTH_MISSING,

}