package com.supergenerous.common.firebase

/**
 * List of operators available to use in Firestore queries.
 *
 * @see DbQuery
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class DbOperator(

    /**
     * Value of the operator used in Firestore.
     */
    internal val value: String

) {

    LOWER("<"),
    LOWER_OR_EQUAL("<="),
    EQUAL("=="),
    NOT_EQUAL("!="),
    GREATER_OR_EQUAL(">="),
    GREATER(">"),
    ARRAY_CONTAINS("array-contains"),
    IN("in"),
    NOT_IN("not-in"),
    ARRAY_CONTAINS_ANY("array-contains-any")

}