package com.supergenerous.common.firebase

/**
 * Query that can be used to find documents in Firestore.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public data class DbQuery(

    /**
     * Field in the Firestore document.
     */
    val field: DbField.Name,

    /**
     * Operator used to compare the [value] to the [field] values in the Firestore documents.
     */
    val operator: DbOperator,

    /**
     * Value to compare to the [field] values in the Firestore documents.
     */
    val value: Any?

) {

    override fun toString(): String {
        // Query as a string (used in logs)
        return "$field ${operator.value} $value"
    }

}