package com.hipsheep.kore.viewmodel.coroutine

import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValueAsync
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.launch

/**
 * Returns a new [LiveData] that receives the elements emitted by the [Flow].
 */
public fun <T> Flow<T>.asLiveData(): LiveData<T> {
    val liveData = MutableLiveData<T>()

    GlobalScope.launch {
        collect { liveData.updateValueAsync(it) }
    }

    return liveData
}