package supergenerous.app.core.component

import com.supergenerous.common.network.Url
import kotlinx.css.Cursor
import kotlinx.css.FontWeight
import kotlinx.css.Outline
import kotlinx.css.RuleSet
import kotlinx.css.backgroundColor
import kotlinx.css.borderWidth
import kotlinx.css.color
import kotlinx.css.cursor
import kotlinx.css.fontFamily
import kotlinx.css.fontSize
import kotlinx.css.fontWeight
import kotlinx.css.letterSpacing
import kotlinx.css.lineHeight
import kotlinx.css.outline
import kotlinx.css.padding
import kotlinx.css.properties.LineHeight
import kotlinx.css.properties.TextDecoration
import kotlinx.css.properties.TextDecorationLine
import kotlinx.css.properties.TextDecorationStyle
import kotlinx.css.px
import kotlinx.css.textDecoration
import kotlinx.html.A
import kotlinx.html.BUTTON
import kotlinx.html.ButtonType.button
import kotlinx.html.DIV
import kotlinx.html.SPAN
import kotlinx.html.js.onClickFunction
import react.RBuilder
import styled.StyleSheet
import styled.StyledDOMBuilder
import styled.css
import styled.styledA
import styled.styledButton
import styled.styledDiv
import styled.styledSpan
import supergenerous.app.core.component.TextStyle.*
import supergenerous.app.core.component.style.InputStyle
import supergenerous.app.core.res.Color.*
import supergenerous.app.core.res.Font
import supergenerous.app.core.util.mobileScreen

/**
 * Creates a text element using the [TextStyle.HEADING_1] style.
 */
public fun RBuilder.heading1(isDarkBg: Boolean = false, block: StyledDOMBuilder<DIV>.() -> Unit) {
    text(style = HEADING_1, isDarkBg = isDarkBg, block = block)
}

/**
 * Creates a text element using the [TextStyle.SUBHEADING_1] style.
 */
public fun RBuilder.subheading1(isDarkBg: Boolean = false, block: StyledDOMBuilder<DIV>.() -> Unit) {
    text(style = SUBHEADING_1, isDarkBg = isDarkBg, block = block)
}

/**
 * Creates a text element using the [TextStyle.SUBHEADING_2] style.
 */
public fun RBuilder.subheading2(isDarkBg: Boolean = false, block: StyledDOMBuilder<DIV>.() -> Unit) {
    text(style = SUBHEADING_2, isDarkBg = isDarkBg, block = block)
}

/**
 * Creates a text element using the [TextStyle.BODY_1] style.
 */
public fun RBuilder.body1(isDarkBg: Boolean = false, block: StyledDOMBuilder<DIV>.() -> Unit) {
    text(style = BODY_1, isDarkBg = isDarkBg, block = block)
}

/**
 * Creates a text element using the [TextStyle.BODY_2] style.
 */
public fun RBuilder.body2(isDarkBg: Boolean = false, block: StyledDOMBuilder<DIV>.() -> Unit) {
    text(style = BODY_2, isDarkBg = isDarkBg, block = block)
}

/**
 * Creates a text element.
 */
private fun RBuilder.text(style: TextStyle, isDarkBg: Boolean = false, block: StyledDOMBuilder<DIV>.() -> Unit) {
    styledDiv {
        css {
            +style.cssStyle

            // Use white text color when the background is dark
            if (isDarkBg) {
                specific { color = WHITE.cssValue }
            }
        }

        block()
    }
}

/**
 * Creates a text element using the "SG Numbers" font.
 */
public fun RBuilder.sgNumber(block: StyledDOMBuilder<SPAN>.() -> Unit) {
    styledSpan {
        css {
            fontFamily = Font.SG_NUMBERS
            fontWeight = FontWeight.normal
            letterSpacing = 1.px
        }

        block()
    }
}

/**
 * Creates a text element with bold style.
 */
public fun RBuilder.textBold(block: StyledDOMBuilder<SPAN>.() -> Unit) {
    styledSpan {
        css { fontWeight = FontWeight.bold }

        block()
    }
}

/**
 * Creates a text element with highlighted style that changes the color of the text and makes it bold.
 */
public fun RBuilder.textHighlight(block: StyledDOMBuilder<SPAN>.() -> Unit) {
    styledSpan {
        css {
            fontWeight = FontWeight.bold
            color = SECONDARY.cssValue
        }

        block()
    }
}

/**
 * Creates a text link that users can click on to go to the [url] received either on the same tab they are in or
 * in a new one, depending on the value of [openInNewTab].
 */
public fun RBuilder.textLink(url: Url,
                             openInNewTab: Boolean = true,
                             block: StyledDOMBuilder<A>.() -> Unit = { +url.value }) {
    textLink(url.value, openInNewTab, block)
}

/**
 * Creates a text link that users can click on to send an email to the [email] address.
 */
public fun RBuilder.textLinkEmail(email: String,
                                  block: StyledDOMBuilder<A>.() -> Unit = { +email }) {
    textLink(url = "mailto:$email", openInNewTab = true, block = block)
}

/**
 * Creates a text link that users can click on to go to the [url] received either on the same tab they are in or
 * in a new one, depending on the value of [openInNewTab].
 */
public fun RBuilder.textLink(url: String, openInNewTab: Boolean = true, block: StyledDOMBuilder<A>.() -> Unit) {
    val target = if (openInNewTab) "_blank" else null

    styledA(href = url, target = target) {
        css { +TextStyleCss.link }

        block()
    }
}

/**
 * Creates a text link that calls [onClick] when clicked.
 */
public fun RBuilder.textLinkButton(onClick: () -> Unit, block: StyledDOMBuilder<BUTTON>.() -> Unit) {
    // Use a button rather than a link because this component is actually a button styled like a link.
    styledButton {
        css {
            /*
             * Text Styles
             */
            // Use body1 as a base
            +TextStyleCss.body1

            // Overwrite with link styling
            +TextStyleCss.link

            /*
             * Button styles
             */
            backgroundColor = TRANSPARENT.cssValue
            padding(all = 0.px)
            borderWidth = 0.px

            rule(":focus-visible") {
                outline = Outline.none
            }
        }

        attrs.onClickFunction = { onClick() }

        /*
         * The default button type is "submit" which means when "enter" is pressed while in a <form> element the onclick
         * function of this button will be called. Set the type to "button" to disable this behaviour.
         *
         * See https://github.com/supergenerous/issues/issues/441
         */
        attrs.type = button

        block()
    }
}

/**
 * Creates a text element with error or warning style depending on the value of [isWarning].
 */
public fun RBuilder.textError(isWarning: Boolean = false, block: StyledDOMBuilder<SPAN>.() -> Unit) {
    styledSpan {
        css { color = if (isWarning) WARNING.cssValue else ERROR.cssValue }

        block()
    }
}

/*
 * Types
 */

/**
 * List of text styles used in the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
internal enum class TextStyle(

    /**
     * CSS values used to render the text style.
     */
    val cssStyle: RuleSet

) {

    HEADING_1(TextStyleCss.heading1),

    SUBHEADING_1(TextStyleCss.subheading1),
    SUBHEADING_2(TextStyleCss.subheading2),

    BODY_1(TextStyleCss.body1),
    BODY_2(TextStyleCss.body2),

    INPUT_TEXT(TextStyleCss.inputText)

}

/**
 * List of CSS styles used for text elements in the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private object TextStyleCss : StyleSheet("TextStyle", isStatic = true) {

    val heading1 by css {
        fontFamily = Font.DOMAINE_SANS
        fontWeight = FontWeight.normal
        fontSize = 46.px

        lineHeight = LineHeight(55.px.value)
        letterSpacing = (-0.9).px

        color = TEXT_MAIN.cssValue

        mobileScreen {
            fontSize = 26.px

            lineHeight = LineHeight(32.px.value)
            letterSpacing = (-0.5).px
        }

        //        extraLargeScreen {
        //            fontWeight = FontWeight.bold
        //            fontSize = 72.px
        //
        //            lineHeight = LineHeight(78.px.value)
        //            letterSpacing = (-0.5).px
        //        }
    }

    val subheading1 by css {
        fontFamily = Font.DOMAINE_SANS
        fontWeight = FontWeight.normal

        fontSize = 33.px
        lineHeight = LineHeight(37.px.value)
        letterSpacing = (-0.7).px

        color = TEXT_MAIN.cssValue

        mobileScreen {
            fontSize = 20.px
            lineHeight = LineHeight(24.px.value)
            letterSpacing = (-0.5).px
        }

        //        extraLargeScreen {
        //            fontWeight = FontWeight.bold
        //
        //            fontSize = 46.px
        //            lineHeight = LineHeight(55.px.value)
        //            letterSpacing = (-0.5).px
        //        }
    }

    val subheading2 by css {
        fontFamily = Font.DOMAINE_SANS
        fontWeight = FontWeight.bold

        fontSize = 17.px
        lineHeight = LineHeight(22.px.value)
        letterSpacing = (-0.5).px

        color = TEXT_MAIN.cssValue

        mobileScreen {
            fontSize = 14.px
            lineHeight = LineHeight(18.px.value)
            letterSpacing = (-0.2).px
        }

        //        extraLargeScreen {
        //            fontSize = 20.px
        //            lineHeight = LineHeight(26.px.value)
        //            letterSpacing = (-0.5).px
        //        }
    }

    val body1 by css {
        fontFamily = Font.DOMAINE_SANS
        fontWeight = FontWeight.normal

        fontSize = 17.px
        lineHeight = LineHeight(22.px.value)
        letterSpacing = (-0.5).px

        color = TEXT_MAIN.cssValue

        mobileScreen {
            fontSize = 14.px
            lineHeight = LineHeight(18.px.value)
            letterSpacing = (-0.2).px
        }

        //        extraLargeScreen {
        //            fontSize = 20.px
        //            lineHeight = LineHeight(26.px.value)
        //            letterSpacing = (-0.5).px
        //        }
    }

    val body2 by css {
        +inputText

        color = TEXT_MAIN.cssValue.withAlpha(0.6)

        placeholder {
            color = TEXT_PLACEHOLDER.cssValue.withAlpha(0.6)
        }

        mobileScreen {
            fontSize = 14.px
            lineHeight = LineHeight(18.px.value)
        }
    }

    val inputText by css {
        fontFamily = Font.PROXIMA_NOVA
        fontWeight = FontWeight.normal

        fontSize = 16.px
        lineHeight = LineHeight(20.px.value)
        letterSpacing = 0.px

        color = TEXT_MAIN.cssValue

        placeholder {
            color = TEXT_PLACEHOLDER.cssValue
        }

        //        extraLargeScreen {
        //            fontSize = 20.px
        //            lineHeight = LineHeight(24.px.value)
        //        }
    }

    val link by css {
        fontWeight = FontWeight.bold

        val linkColor = SECONDARY
        color = linkColor.cssValue

        /*
         * Set the cursor and decoration so a "hand" cursor is shown when hovering over links and the text looks as a
         * link (i.e., underlined, etc.).
         *
         * This is necessary so that all links look the same on the UI, even though some of them will be buttons in
         * disguise (e.g., the "forgot password" link on the sign-in screen).
         */
        cursor = Cursor.pointer
        textDecoration = TextDecoration(lines = setOf(TextDecorationLine.underline),
                                        style = TextDecorationStyle.solid,
                                        color = linkColor.cssValue)

        +InputStyle.focusRing
    }

}