package supergenerous.app.core.util.format

/**
 * Formatter used to parse phone numbers
 *
 * The only restriction on user input is that it starts with a '+' and then any combination of numbers, hyphens or
 * spaces can follow. Hyphens will be replaces with spaces.
 */
public class PhoneNumberFormatter : TextFormatter(format = """^\+{0,1}[-\s0-9]*$""") {

    /**
     * Formats the [value] of any length into Phone Number format.
     *
     * @return a string in the format `+#...` with numbers, and spaces
     */
    override fun format(value: String): String {
        return value.replace("-", " ").replace("  ", " ")
    }
}