package supergenerous.app.core.analytics

import com.supergenerous.common.donor.DonorProvider
import com.supergenerous.common.user.User

/**
 * Repository used to manage the analytics data.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public class AnalyticsRepository(

    /**
     * Analytics services used to log events.
     */
    private val analyticsServices: List<AnalyticsService>

) {

    /**
     * Events logged during this session from the [logEventOnce] method.
     */
    private val eventsLoggedOnce = mutableSetOf<AnalyticsEvent>()


    /**
     * Sets the user that the logged events belong to.
     *
     * @param donorProvider [DonorProvider] that referred the donor to SG.
     */
    // TODO: find a better way so we don't expose DonorProvider by this API
    public fun setUser(user: User, donorProvider: DonorProvider?) {
        analyticsServices.forEach { it.setUser(user = user, donorProvider = donorProvider) }
    }

    /**
     * Logs the [event] once per session. If the event was already logged during this session then the action is
     * skipped.
     */
    public fun logEventOnce(event: AnalyticsEvent) {
        if (eventsLoggedOnce.contains(event)) {
            return
        }

        logEvent(event)

        eventsLoggedOnce.add(event)
    }

    /**
     * Logs the [event] received.
     */
    public fun logEvent(event: AnalyticsEvent) {
        analyticsServices.forEach { it.logEvent(event) }
    }

}