package com.supergenerous.common.firebase

import com.hipsheep.kore.json.JsonParser
import com.hipsheep.kore.json.fromDynamic
import firebase.firestore.DocumentData
import firebase.firestore.FirestoreDataConverter
import firebase.firestore.QueryDocumentSnapshot
import firebase.firestore.SnapshotOptions
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationStrategy

/**
 * Converter of Kotlin objects of type [T] to [JsObject] objects and vice-versa.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public class DbDataConverter<T>(

    /**
     * Parser used to convert Kotlin objects to JSON strings.
     */
    private val jsonParser: JsonParser,

    private val serializer: SerializationStrategy<T>,
    private val deserializer: DeserializationStrategy<T>

) : FirestoreDataConverter<T> {

    override fun toFirestore(modelObject: T): DocumentData {
        val dataJson = jsonParser.toJson(modelObject, serializer)

        return JSON.parse(dataJson)
    }

    override fun fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): T {
        val dataJs = snapshot.data(options)

        return jsonParser.fromDynamic(dataJs, deserializer)
    }

}