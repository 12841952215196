package supergenerous.app.core.res

/*
 * Brand colors
 */
private const val SUPER_WHITE = "#FFF6F1"
private const val SUPER_GREEN = "#004247"
private const val SUPER_PURPLE = "#AB7DCC"
private const val SUPER_YELLOW = "#EBFF80"

/*
 * Standard colors
 */
private const val BLACK_HEX = "#000000"

/**
 * Object that contains the colors that are used in different parts of the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class Color(

    /**
     * Hexadecimal value for the color.
     */
    public val hexValue: String

) {

    PRIMARY(SUPER_GREEN),
    SECONDARY(SUPER_PURPLE),
    ACCENT(SUPER_YELLOW),

    WHITE("#FFFFFF"),
    BLACK(BLACK_HEX),
    TRANSPARENT("#00000000"),

    SCREEN_BACKGROUND(SUPER_WHITE),

    BTN_PRIMARY_ACTIVE("#976AB8"),
    BTN_PRIMARY_HOVER("#976AB8"),
    FOCUS_RING("#71B5FF"),

    ERROR("#FF5421"),
    WARNING("#FFB83C"),
    SUCCESS("#8BEC36"),

    DIVIDER(BLACK_HEX),

    TEXT_MAIN(BLACK_HEX),
    TEXT_PLACEHOLDER("#5F5F5F"),
    TEXT_SUPER_WHITE(SUPER_WHITE);

    /**
     * CSS value used to style elements with this color.
     */
    public val cssValue: kotlinx.css.Color = kotlinx.css.Color(hexValue)

}