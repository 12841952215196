package supergenerous.app.core.component.button

/**
 * Size of [Button]s that can be used in the app.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
public enum class ButtonSize {

    EXTRA_SMALL,
    SMALL,
    LARGE

}