package supergenerous.app.core.location.addy

import kotlinx.serialization.Serializable

/**
 * Address search result as returned from the Addy service.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class AddressSearchResultAddy(

    /**
     * Number of addresses that matched the text sent for the search.
     */
    val matched: Int,

    /**
     * Addresses that matched the text sent for the search.
     *
     * Only a small number of results are returned, not all of them.
     */
    val addresses: List<AddressAddy>

)