package supergenerous.app.core.component.chip

import kotlinx.css.Display
import kotlinx.css.FlexWrap
import kotlinx.css.backgroundColor
import kotlinx.css.borderRadius
import kotlinx.css.color
import kotlinx.css.display
import kotlinx.css.em
import kotlinx.css.flexWrap
import kotlinx.css.gap
import kotlinx.css.height
import kotlinx.css.px
import kotlinx.css.width
import materialui.chip.MuiChip
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.buildElement
import styled.css
import styled.styled
import styled.styledDiv
import supergenerous.app.core.component.TextStyle
import supergenerous.app.core.component.image.icon
import supergenerous.app.core.component.style.InputStyle
import supergenerous.app.core.res.Color.*
import supergenerous.app.core.res.image.CoreIcon

/**
 * Chip set component used throughout the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class ChipSet : RComponent<ChipSetProps, State>() {

    override fun RBuilder.render() {
        styledDiv {
            css {
                display = Display.flex
                flexWrap = FlexWrap.wrap
                gap = 4.px
            }

            for (chip in props.chips) {
                @Suppress("DEPRECATION")
                styled(MuiChip)() {
                    css {
                        specific {
                            +TextStyle.SUBHEADING_2.cssStyle

                            backgroundColor = ACCENT.cssValue
                            borderRadius = 0.px
                            focusWithin {
                                backgroundColor = ACCENT.cssValue
                            }
                        }

                        +InputStyle.focusRing
                    }

                    attrs {
                        label = chip.label

                        props.onChipRemove?.let { onChipRemove ->
                            deleteIcon = buildElement { deleteIcon() }

                            onDelete = { onChipRemove(chip.id) }
                        }
                    }
                }
            }
        }
    }

    /**
     * Renders the [Chip] delete icon.
     */
    private fun RBuilder.deleteIcon() {
        styledDiv {
            css {
                specific {
                    // use 1em here to set the close icon size to be equal to the text size. MUI sets it to be 22px.
                    width = 1.em
                    height = 1.em
                    color = BLACK.cssValue
                }

                hover {
                    specific { color = BLACK.cssValue.withAlpha(0.4) }
                }
            }

            icon(CoreIcon.CLEAR)
        }
    }

}

/**
 * Properties used by the [ChipSet] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface ChipSetProps : Props {

    /**
     * [Chip]s to show inside the [ChipSet].
     */
    var chips: List<Chip>

    /**
     * Function called when a chip is removed from the [ChipSet].
     */
    var onChipRemove: ((id: String) -> Unit)?

}

/**
 * Renders a [ChipSet] component.
 */
public fun RBuilder.chipSet(chips: List<Chip>,
                            onChipRemove: ((id: String) -> Unit)? = null) {
    child(ChipSet::class) {
        attrs.chips = chips
        attrs.onChipRemove = onChipRemove
    }
}