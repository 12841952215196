package com.supergenerous.common.util

import kotlinx.serialization.Serializable

/**
 * The status of a link.
 *
 * @author Raymond Feng (raymond@supergenerous.com)
 */
@Serializable
public enum class LinkStatus {

    /**
     * A link has been requested.
     */
    REQUESTED,

    /**
     * The items are linked.
     */
    LINKED,

    /**
     * There is no longer a link (but there used to be).
     */
    UNLINKED

}