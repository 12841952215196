package supergenerous.app.donor.rebate

import com.hipsheep.kore.model.repo.Repository
import com.hipsheep.kore.resource.Resource
import com.supergenerous.common.rebate.Rebate

/**
 * [Repository] used to manage the [Rebate]s data.
 */
public class RebateRepository(

    private val rebateService: RebateService

): Repository() {

    /**
     * Returns the [Rebate]s of the currently signed in donor.
     */
    public suspend fun getRebates(): Resource<List<Rebate>> {
        return getResource { rebateService.getRebates() }
    }

}