package com.supergenerous.common.kindo

/**
 * Constants used as properties, e.g. JSON keys.
 */
public object KindoProps {

    /**
     * The affiliate of Kindo, should always be "Supergenerous".
     */
    public const val AFFILIATE: String = "affiliate"

    /**
     * The checksum for verifying a Kindo customer.
     */
    public const val CHECKSUM: String = "tgcl_cust_checksum"

    /**
     * The donee's ID on Kindo.
     */
    public const val DONEE_EXT_ID: String = "tgcl_referral_org"

    /**
     * The donor's email on Kindo.
     */
    public const val DONOR_EMAIL: String = "tgcl_cust_email"

    /**
     * The donor's ID on Kindo.
     */
    public const val DONOR_EXT_ID: String = "tgcl_cust_ref"

    /**
     * The donor's first name on Kindo.
     */
    public const val DONOR_FIRST_NAME: String = "tgcl_cust_firstname"

    /**
     * The donor's ID on Supergenerous.
     *
     * This is the short form that uses "cust".
     */
    public const val DONOR_ID: String = "affiliate_cust_ref"

    /**
     * The donor's ID on Supergenerous.
     *
     * This is the long form that uses "customer".
     */
    public const val DONOR_ID_LONG: String = "affiliate_customer_ref"

    /**
     * The donor's last name on Kindo.
     */
    public const val DONOR_LAST_NAME: String = "tgcl_cust_lastname"

    /**
     * Whether the operation was a success.
     */
    public const val IS_SUCCESS: String = "success"

    /**
     * A comment that is _not_ meant to be shown to the end user.
     */
    public const val PRIVATE_COMMENT: String = "affiliate_comment_private"

    /**
     * A comment that _is_ meant to be shown to the end user.
     */
    public const val PUBLIC_COMMENT: String = "affiliate_comment_public"

    /**
     * Contains info about a referral.
     */
    public const val REFERRAL: String = "referral"

    /**
     * A unique code referencing this referral on Kindo.
     */
    public const val REFERRAL_ID: String = "tgcl_referral_id"

    /**
     * The TGCL brand performing the request? Should always be Kindo.
     */
    public const val REFERRER: String = "tgcl_referral_brand"

    /**
     * The result that occurred on Supergenerous.
     */
    public const val RESULT: String = "affiliate_result"

    /**
     * The type of operation that has occurred?
     */
    public const val RETURN_TYPE: String = "rtype"

    /**
     * A unique code referencing this reverse join on Kindo.
     */
    public const val REVERSE_JOIN_ID: String = "reverse_join_id"

    /**
     * The type of operation being performed by Kindo.
     */
    public const val TYPE: String = "tgcl_type"

}