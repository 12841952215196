package com.hipsheep.kore.model.network

import com.hipsheep.kore.di.DIManager
import com.hipsheep.kore.model.di.httpClient

/**
 * Base class that should be extended by all app services to communicate with servers
 * via an [HttpClient].
 */
public abstract class HttpService(httpClient: HttpClient? = null) {

    @Suppress("MemberVisibilityCanBePrivate")
    protected val httpClient: HttpClient = httpClient ?: DIManager.httpClient

}