package supergenerous.app.core.component.textfield

import com.hipsheep.kore.util.isNotNullOrBlank
import kotlinx.css.backgroundColor
import kotlinx.css.borderRadius
import kotlinx.css.color
import kotlinx.css.padding
import kotlinx.css.px
import kotlinx.css.width
import kotlinx.css.zIndex
import kotlinx.js.jso
import materialui.common.MuiClickAwayListener
import materialui.menu.MuiMenuItem
import materialui.menu.MuiMenuList
import materialui.menu.MuiPopper
import materialui.paper.MuiPaper
import org.w3c.dom.HTMLElement
import react.Component
import react.RBuilder
import react.RComponent
import react.RefObject
import react.State
import react.createRef
import react.dom.div
import react.setState
import styled.css
import styled.styled
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.body2
import supergenerous.app.core.component.dividerHorizontal
import supergenerous.app.core.component.textBold
import supergenerous.app.core.res.Color.*
import supergenerous.app.core.search.model.TextSearchResult
import kotlin.reflect.KClass

/**
 * Text field component that shows search results on a menu below it.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class SearchTextField<T> : RComponent<SearchTextFieldProps<T>, SearchTextFieldState>() {

    /**
     * Reference to the text field where the user writes to search.
     *
     * We use this reference to "link" the search results menu to the text field so the menu is shown below the text
     * field.
     */
    private var textFieldRef: RefObject<HTMLElement> = createRef()

    /**
     * Reference to the container that wraps the text field and search results menu.
     *
     * We use this reference to prevent the search results menu from moving outside the boundaries of the container
     * when scrolling.
     */
    private var containerRef: RefObject<HTMLElement> = createRef()


    override fun SearchTextFieldState.init() {
        showResults = false
    }

    override fun RBuilder.render() {
        div {
            ref = containerRef

            div {
                ref = textFieldRef

                textField(
                    type = props.type,
                    title = props.title,
                    subtitle = props.subtitle,
                    isClearable = true,
                    placeholder = props.placeholder,
                    // If there has been no interaction with the text field yet then show the value sent from the parent
                    // component (if any)
                    value = state.textEntered ?: props.value ?: "",
                    errorMessage = props.errorMessage,
                    warningMessage = props.warningMessage,
                    onTextChange = ::onTextChange,
                    onFocus = { setState { showResults = true } }
                )
            }

            val searchResults = getSearchResults()

            searchResultsList(searchResults = searchResults)
        }
    }

    /**
     * Renders a list that displays the [searchResults].
     */
    private fun RBuilder.searchResultsList(searchResults: List<TextSearchResult<out T?>>) {
        @Suppress("DEPRECATION")
        styled(MuiPopper)() {
            css {
                // Make the menu match the width of the text field
                textFieldRef.current?.clientWidth?.let {
                    width = it.px
                }

                // Add a z-index above 1300 for when the menu has to be shown above a dialog (the default z-index on
                // dialogs is 1300)
                zIndex = 1500
            }

            attrs.anchorEl = textFieldRef.current
            attrs.container  = containerRef.current
            attrs.open = state.showResults && searchResults.isNotEmpty()
            attrs.transition = false
            attrs.disablePortal = false
            attrs.placement = "bottom-start"
            attrs.modifiers = jso {
                // Force the popper menu to follow the anchor outside the visible screen when scrolling (if not set,
                // the popper will remain at the top of the screen even after the anchor is way past it)
                preventOverflow = jso {
                    boundariesElement = "viewport"
                    escapeWithReference = true
                }
                // Force the popper menu to appear at the "placement" set above (if not set, the popper will be
                // displayed at the opposite placement when there is not enough space on the one set)
                flip = jso {
                    enabled = false
                }
            }

            styled(MuiPaper)() {
                css {
                    backgroundColor = WHITE.cssValue
                }

                // Remove default rounded corners
                attrs.square = true
                // Add shadow so the list appears on top of the elements behind it
                attrs.elevation = 4

                MuiClickAwayListener {
                    // Close menu when the user clicks outside it
                    attrs.onClickAway = { setState { showResults = false } }

                    styled(MuiMenuList)() {
                        css {
                            specific {
                                children {
                                    backgroundColor = WHITE.cssValue
                                    borderRadius = 0.px
                                }
                            }
                        }

                        attrs.variant = "menu"

                        for (result in searchResults) {
                            searchResult(result)
                        }
                    }
                }
            }
        }
    }

    /**
     * Returns the list of results to display, up to a maximum of [SearchTextFieldProps.maxResultsShown], including
     * the input if [SearchTextFieldProps.showInputAsResult] is `true`.
     */
    private fun getSearchResults(): List<TextSearchResult<out T?>> {
        val resultsMax = props.searchResults.take(props.maxResultsShown)

        // Show input as result if it was selected and the input doesn't match an existing result
        val showInputAsResult = props.showInputAsResult
                && state.textEntered.isNotNullOrBlank()
                && resultsMax.none { it.text.equals(state.textEntered?.trim(), ignoreCase = true) }

        return if (showInputAsResult) {
            resultsMax.take(props.maxResultsShown - 1) + TextSearchResult(text = state.textEntered!!,
                                                                          id = null,
                                                                          data = null,
                                                                          textMatch = null)
        } else {
            resultsMax
        }
    }

    /**
     * Renders a search result.
     */
    private fun RBuilder.searchResult(result: TextSearchResult<out T?>) {
        val isInputResult = result.text == state.textEntered && result.data == null

        // If this is the input text and there are other results then separate the input option from the rest of the
        // items
        if (isInputResult && props.searchResults.isNotEmpty()) {
            dividerHorizontal(margin = 0.px)
        }

        // Render the menu item
        @Suppress("DEPRECATION")
        styled(MuiMenuItem)() {
            css {
                children {
                    padding(vertical = 8.px)
                }

                hover {
                    specific {
                        backgroundColor = SECONDARY.cssValue
                    }

                    descendants {
                        // Set all descendants of this element to have white text so it looks good on the highlighted
                        // background
                        color = WHITE.cssValue
                    }
                }
            }

            attrs.onClick = { onSearchResultSelect(result) }

            div {
                body1 {
                    if (isInputResult) {
                        +"Add \""
                    }

                    if (result.textMatch == null) {
                        +result.text
                    } else {
                        // Show result highlighting search query
                        +result.text.substringBefore(result.textMatch)
                        textBold { +result.textMatch }
                        +result.text.substringAfter(result.textMatch)
                    }

                    if (isInputResult) {
                        +"\""
                    }
                }

                // Show subtext if provided
                props.resultSubtext?.invoke(result)?.let {
                    body2 { +it }
                }
            }
        }
    }

    /**
     * Called when the user edits the text in the [SearchTextField].
     */
    private fun onTextChange(text: String) {
        setState {
            textEntered = text
            showResults = true
        }

        props.onTextChange?.invoke(text)
    }

    /**
     * Called when the user selects a search result from the menu. Sends the selected value to the parent component via
     * [SearchTextFieldProps.onResultSelect].
     */
    private fun onSearchResultSelect(result: TextSearchResult<out T?>) {
        setState {
            textEntered = null
            showResults = false
        }

        props.onTextChange?.invoke("")
        props.onResultSelect(result)
    }

}

/**
 * Properties used by the [SearchTextField] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface SearchTextFieldProps<T> : TextFieldProps {

    /**
     * Results to show on the menu that appears under the text field.
     */
    var searchResults: List<TextSearchResult<T>>

    /**
     * Function that returns the subtext to show for each [TextSearchResult].
     */
    var resultSubtext: ((TextSearchResult<out T?>) -> String?)?

    /**
     * Function called when one of the [searchResults] is selected by the user from the menu shown.
     */
    var onResultSelect: (TextSearchResult<out T?>) -> Unit

    /**
     * The maximum number of results to display in the dropdown.
     *
     * If [showInputAsResult] is `true`, the input will count towards the maximum.
     */
    var maxResultsShown: Int

    /**
     * If `true` the user-entered text in the search box will be a selectable option. If `false` the user must select an
     * option from [searchResults].
     */
    var showInputAsResult: Boolean

}

/**
 * State of the [SearchTextField] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface SearchTextFieldState : State {

    /**
     * Text entered by the user into the [SearchTextField].
     */
    var textEntered: String?

    /**
     * `true` if the search results dropdown should be displayed, or `false` if it should be hidden.
     */
    var showResults: Boolean

}

/**
 * Renders a [SearchTextField] component.
 */
public fun <T> RBuilder.searchTextField(title: String? = null,
                                        subtitle: String? = null,
                                        placeholder: String? = null,
                                        errorMessage: String? = null,
                                        warningMessage: String? = null,
                                        value: String? = null,
                                        searchResults: List<TextSearchResult<T>>,
                                        maxResultsShown: Int,
                                        showInputAsResult: Boolean = false,
                                        resultSubtext: ((TextSearchResult<out T?>) -> String?)? = null,
                                        onTextChange: ((String) -> Unit)? = null,
                                        onResultSelect: (TextSearchResult<out T?>) -> Unit) {
    child(SearchTextField::class as KClass<out Component<SearchTextFieldProps<T>, *>>) {
        attrs.title = title
        attrs.subtitle = subtitle
        attrs.placeholder = placeholder
        attrs.errorMessage = errorMessage
        attrs.warningMessage = warningMessage
        attrs.value = value
        attrs.searchResults = searchResults
        attrs.resultSubtext = resultSubtext
        attrs.maxResultsShown = maxResultsShown
        attrs.showInputAsResult = showInputAsResult
        attrs.onTextChange = onTextChange
        attrs.onResultSelect = onResultSelect
    }
}