package com.hipsheep.kore.viewmodel.event

/**
 * Used as a wrapper for data that is exposed via a LiveData that represents an event.
 *
 * This code is based on this implementation: https://github.com/android/architecture-samples/blob/master/app/src/main/java/com/example/android/architecture/blueprints/todoapp/Event.kt
 */
public open class Event<out T>(

    private val content: T

) {

    /**
     * `true` if the [Event] was handled already, or `false` otherwise.
     */
    private var wasHandled = false


    /**
     * Returns the content and prevents its use again. If the [Event] was handled already then `null` ir returned.
     */
    public fun getContentIfNotHandled(): T? {
        return if (wasHandled) {
            null
        } else {
            wasHandled = true
            content
        }
    }

}