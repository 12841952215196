package supergenerous.app.core.component.picker

import com.supergenerous.common.util.isValid
import com.supergenerous.common.util.toDate
import com.supergenerous.common.util.toLocalDate
import dateio.DateFnsUtils
import kotlinx.css.Display
import kotlinx.css.borderColor
import kotlinx.css.display
import kotlinx.css.em
import kotlinx.css.margin
import kotlinx.css.minWidth
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import kotlinx.datetime.LocalDate
import materialui.MuiInputVariant
import materialui.picker.MuiKeyboardDatePicker
import materialui.picker.MuiPickersUtilsProvider
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import styled.css
import styled.styled
import styled.styledDiv
import supergenerous.app.core.component.TextStyle
import supergenerous.app.core.component.body2
import supergenerous.app.core.component.inputTitle
import supergenerous.app.core.component.picker.DatePickerType.*
import supergenerous.app.core.component.style.InputStyle
import supergenerous.app.core.component.textError
import supergenerous.app.core.component.toInputFieldId
import supergenerous.app.core.res.Color
import kotlin.js.Date

/**
 * Date picker component used throughout the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class DatePicker : RComponent<DatePickerProps, State>() {

    override fun RBuilder.render() {
        // We only need an ID if we are setting a label, so they are "linked" for accessibility
        val textFieldId = props.title?.toInputFieldId()

        styledDiv {
            props.title?.let { label ->
                inputTitle(
                    title = label,
                    inputFieldId = textFieldId
                )
            }

            @Suppress("DEPRECATION")
            MuiPickersUtilsProvider {
                attrs.utils = DateFnsUtils

                styled(MuiKeyboardDatePicker)() {
                    css {
                        // Use specific to override default MUI styling
                        specific {
                            margin(top = 8.px, bottom = 4.px)
                            width = 100.pct
                        }

                        // Override the input styles
                        descendants("input.MuiInputBase-input.MuiOutlinedInput-input") {
                            +TextStyle.INPUT_TEXT.cssStyle
                            +InputStyle.focusRing
                            +InputStyle.border

                            // Avoid cutting off any of the date in the input. Use em because it should scale relative to
                            // the font size.
                            minWidth = 6.em

                            // Equivalent to margin="dense" in the MuiInput
                            padding(vertical = 10.5.px)

                            // If there is a warning then add warning styling
                            props.warningMessage?.let {
                                borderColor = Color.WARNING.cssValue
                            }

                            // If there is an error then add error styling (this overwrites the warning styling)
                            props.errorMessage?.let {
                                borderColor = Color.ERROR.cssValue
                            }
                        }

                        // Remove the gap between the input and the calendar icon
                        descendants(".MuiInputAdornment-positionEnd") {
                            margin(left = 0.px)
                        }

                        // Remove the gap between the input and the calendar icon
                        descendants(".MuiOutlinedInput-adornedEnd") {
                            padding(right = 0.px)
                        }

                        // Add focus style to the calendar icon
                        descendants("button.MuiIconButton-root") {
                            +InputStyle.focusRing
                        }

                        // Remove ripple from calendar icon when active
                        descendants("span.MuiTouchRipple-root") {
                            display = Display.none
                        }
                    }

                    attrs {
                        id = textFieldId

                        inputVariant = MuiInputVariant.outlined.name
                        allowKeyboardControl = true
                        clearable = props.isClearable

                        disablePast = props.disablePastDates
                        disableFuture = props.disableFutureDates

                        when (props.type) {
                            DATE -> {
                                format = "dd/MM/yyyy"
                                placeholder = "DD/MM/YYYY"
                            }
                            MONTH -> {
                                format = "MMM yyyy"
                                placeholder = ""
                                views = arrayOf("year", "month")
                            }
                        }

                        value = props.value?.toDate()
                        onChange = ::onDateChange
                    }
                }
            }

            // Show a footer if there is and error or warning, and if there are both show only the error
            (props.errorMessage ?: props.warningMessage)?.let { footer ->
                body2 {
                    textError(isWarning = props.errorMessage == null) { +footer }
                }
            }
        }
    }

    private fun onDateChange(date: Date?) {
        // Only continue if the date is valid or the app will crash when we try to parse the Date to String in the
        // parent component
        if (date == null || date.isValid()) {
            props.onDateChange(date?.toLocalDate())
        }
    }

}

/**
 * Properties used by the [DatePicker] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Suppress("NonNullableBooleanPropertyInExternalInterface")
private external interface DatePickerProps : Props {

    /**
     * The title to show above the date picker.
     */
    var title: String?

    /**
     * Value shown in the [DatePicker], or `null` if no value should be shown.
     */
    var value: LocalDate?

    /**
     * `true` to disable past dates in the [DatePicker], or `false` to enable them.
     */
    var disablePastDates: Boolean

    /**
     * `true` to disable future dates in the [DatePicker], or `false` to enable them.
     */
    var disableFutureDates: Boolean

    /**
     * `true` if the date can be cleared, or `false` if not.
     */
    var isClearable: Boolean

    /**
     * The error message to display.
     */
    var errorMessage: String?

    /**
     * The warning message to display.
     *
     * If an [errorMessage] is set then this message won't be displayed.
     */
    var warningMessage: String?

    /**
     * Type of [DatePicker] to display.
     */
    var type: DatePickerType

    /**
     * Function called when the user changes the date in the [DatePicker].
     */
    var onDateChange: (LocalDate?) -> Unit

}

/**
 * Renders a [DatePicker] component.
 */
public fun RBuilder.datePicker(title: String? = null,
                               value: LocalDate?,
                               type: DatePickerType = DATE,
                               disablePastDates: Boolean = false,
                               disableFutureDates: Boolean = false,
                               isClearable: Boolean = true,
                               errorMessage: String? = null,
                               warningMessage: String? = null,
                               onDateChange: (LocalDate?) -> Unit) {
    child(DatePicker::class) {
        attrs.title = title
        attrs.value = value
        attrs.type = type
        attrs.disablePastDates = disablePastDates
        attrs.disableFutureDates = disableFutureDates
        attrs.isClearable = isClearable
        attrs.errorMessage = errorMessage
        attrs.warningMessage = warningMessage
        attrs.onDateChange = onDateChange
    }
}