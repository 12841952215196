package com.supergenerous.common.firebase

import com.hipsheep.kore.di.DIManager
import com.supergenerous.common.disbursement.DisbursementDbo
import com.supergenerous.common.donation.DonationDbo
import com.supergenerous.common.donation.request.DonationRequestDbo
import com.supergenerous.common.donee.DoneeDbo
import com.supergenerous.common.donee.DoneeStatsDbo
import com.supergenerous.common.donee.user.DoneeUserDbo
import com.supergenerous.common.donor.DonorDbo
import com.supergenerous.common.donor.DonorStateDbo
import com.supergenerous.common.rebate.RebateDbo
import com.supergenerous.common.task.ManualTaskDbo
import firebase.firestore.CollectionReference
import firebase.firestore.DocumentData
import kotlinx.coroutines.await

public actual class FirestoreCollection<DBO>(

    collection: CollectionReference<DocumentData>,

    /**
     * Converter used to parse data to/from the [collection].
     */
    dbDataConverter: DbDataConverter<DBO>

) {

    private val collection: CollectionReference<DBO> = collection.withConverter(dbDataConverter)

    internal actual val name: String
        get() = collection.path


    internal actual fun createQuery(): FirestoreQuery<DBO> {
        return collection.toFirestoreQuery()
    }

    internal actual suspend fun observeChanges(docId: String,
                                               observer: (doc: FirestoreDocument<DBO>?, error: Throwable?) -> Unit): FirestoreChangeListener {
        return collection.doc(docId).onSnapshot(onNext = { doc -> observer(doc, null) },
                                                onError = { error -> observer(null, error) })
                .toFirestoreChangeListener()
    }

    internal actual suspend fun getDoc(id: String): FirestoreDocument<DBO> {
        return collection.doc(id).get().await()
    }

    public actual fun getData(doc: FirestoreDocument<DBO>): DBO? {
        return doc.data()
    }

    internal actual suspend fun createDoc(): String {
        return collection.doc().id
    }

    internal actual suspend fun deleteDoc(id: String) {
        collection.doc(id).delete().await()
    }

    internal actual suspend fun updateDoc(id: String, data: DBO): FirestoreDocument<DBO> {
        val doc = collection.doc(id).apply { set(data).await() }

        return doc.get().await()
    }

    internal actual suspend fun updateFields(docId: String, fields: List<DbField>): FirestoreDocument<DBO> {
        // The first field has to be sent separately because of a limitation on the SDK APIs
        val firstField = fields.first()
        // Remove the first field to send the other ones separately
        val otherFields = fields.filter { it != firstField }

        collection.doc(docId).update(firstField.name.value,
                                     firstField.value,
                                     otherFields.flatMap { listOf(it.name, it.value) }.toTypedArray()).await()

        return collection.doc(docId).get().await()
    }

    internal actual suspend fun updateField(docId: String, field: DbField): FirestoreDocument<DBO> {
        collection.doc(docId).update(field.name.value, field.value).await()

        return collection.doc(docId).get().await()
    }

    /*
     * Inner types
     */

    public actual companion object

}

public actual val FirestoreCollection.Companion.DISBURSEMENTS: FirestoreCollection<DisbursementDbo>
    get() = FirestoreCollection(collection = firebase.firestore().collection(FirestoreCollectionName.DISBURSEMENTS),
                                dbDataConverter = DbDataConverter(DIManager.jsonParser,
                                                                  serializer = DisbursementDbo.serializer(),
                                                                  deserializer = DisbursementDbo.serializer()))

public actual val FirestoreCollection.Companion.DONATIONS: FirestoreCollection<DonationDbo>
    get() = FirestoreCollection(collection = firebase.firestore().collection(FirestoreCollectionName.DONATIONS),
                                dbDataConverter = DbDataConverter(DIManager.jsonParser,
                                                                  serializer = DonationDbo.serializer(),
                                                                  deserializer = DonationDbo.serializer()))

public actual val FirestoreCollection.Companion.DONATION_REQUESTS: FirestoreCollection<DonationRequestDbo>
    get() = FirestoreCollection(collection = firebase.firestore().collection(FirestoreCollectionName.DONATION_REQUESTS),
                                dbDataConverter = DbDataConverter(DIManager.jsonParser,
                                                                  serializer = DonationRequestDbo.serializer(),
                                                                  deserializer = DonationRequestDbo.serializer()))

public actual val FirestoreCollection.Companion.DONEES: FirestoreCollection<DoneeDbo>
    get() = FirestoreCollection(collection = firebase.firestore().collection(FirestoreCollectionName.DONEES),
                                dbDataConverter = DbDataConverter(DIManager.jsonParser,
                                                                  serializer = DoneeDbo.serializer(),
                                                                  deserializer = DoneeDbo.serializer()))

public actual val FirestoreCollection.Companion.DONEE_STATS: FirestoreCollection<DoneeStatsDbo>
    get() = FirestoreCollection(collection = firebase.firestore().collection(FirestoreCollectionName.DONEE_STATS),
                                dbDataConverter = DbDataConverter(DIManager.jsonParser,
                                                                  serializer = DoneeStatsDbo.serializer(),
                                                                  deserializer = DoneeStatsDbo.serializer()))

public actual val FirestoreCollection.Companion.DONEE_USERS: FirestoreCollection<DoneeUserDbo>
    get() = FirestoreCollection(collection = firebase.firestore().collection(FirestoreCollectionName.DONEE_USERS),
                                dbDataConverter = DbDataConverter(DIManager.jsonParser,
                                                                  serializer = DoneeUserDbo.serializer(),
                                                                  deserializer = DoneeUserDbo.serializer()))

public actual val FirestoreCollection.Companion.DONORS: FirestoreCollection<DonorDbo>
    get() = FirestoreCollection(collection = firebase.firestore().collection(FirestoreCollectionName.DONORS),
                                dbDataConverter = DbDataConverter(DIManager.jsonParser,
                                                                  serializer = DonorDbo.serializer(),
                                                                  deserializer = DonorDbo.serializer()))

public actual val FirestoreCollection.Companion.DONOR_STATES: FirestoreCollection<DonorStateDbo>
    get() = FirestoreCollection(collection = firebase.firestore().collection(FirestoreCollectionName.DONOR_STATES),
                                dbDataConverter = DbDataConverter(DIManager.jsonParser,
                                                                  serializer = DonorStateDbo.serializer(),
                                                                  deserializer = DonorStateDbo.serializer()))

public actual val FirestoreCollection.Companion.MANUAL_TASKS: FirestoreCollection<ManualTaskDbo>
    get() = FirestoreCollection(collection = firebase.firestore().collection(FirestoreCollectionName.MANUAL_TASKS),
                                dbDataConverter = DbDataConverter(DIManager.jsonParser,
                                                                  serializer = ManualTaskDbo.serializer(),
                                                                  deserializer = ManualTaskDbo.serializer()))

public actual val FirestoreCollection.Companion.REBATES: FirestoreCollection<RebateDbo>
    get() = FirestoreCollection(collection = firebase.firestore().collection(FirestoreCollectionName.REBATES),
                                dbDataConverter = DbDataConverter(DIManager.jsonParser,
                                                                  serializer = RebateDbo.serializer(),
                                                                  deserializer = RebateDbo.serializer()))