package com.supergenerous.common.firebase

import com.hipsheep.kore.model.network.HttpStatusCode.CONFLICT
import com.hipsheep.kore.model.network.HttpStatusCode.INTERNAL_SERVER_ERROR
import com.hipsheep.kore.model.network.HttpStatusCode.NOT_FOUND
import com.hipsheep.kore.model.network.HttpStatusCode.OK
import com.hipsheep.kore.model.network.Response
import com.hipsheep.kore.util.Logger
import com.hipsheep.kore.util.logError
import com.hipsheep.kore.util.logWarn
import com.supergenerous.common.data.DboDataModel
import com.supergenerous.common.data.DtoDataModel
import com.supergenerous.common.firebase.DbField.Name.LAST_UPDATE_TIMESTAMP
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.datetime.Clock

/**
 * Base class for all services used to manage the app's data through a Firestore DB.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public abstract class FirestoreService<DTO : DtoDataModel<DBO>, DBO : DboDataModel<DTO>>(

    /**
     * Collection used to manage the Firestore data.
     */
    private val collection: FirestoreCollection<DBO>

) {

    /**
     * Loads all the documents that match the [query] (or all the documents inside the DB collection if [query] is
     * `null`) into the [Flow] returned and sends updates as the documents are updated.
     */
    protected fun loadDocs(query: DbQuery? = null): Flow<Response<List<DTO>>> = callbackFlow {
        val firestoreQuery = collection.createQuery()

        val searchQuery = query?.let { firestoreQuery.where(dbQuery = it) } ?: firestoreQuery

        val listener = searchQuery.observeChanges { docs, error ->
            if (docs != null) {
                trySend(Response(OK, body = docs.mapNotNull { collection.getData(doc = it)?.toDto() }))
            } else {
                trySend(logAndCreateError<List<DTO>>("Failed to load data updates from collection \"${collection.name}\"",
                                                     cause = error))
            }

            Unit
        }

        // Unsubscribe from DB updates when the Flow is closed
        awaitClose { listener.stop() }
    }

    /**
     * Gets all the documents inside the DB collection and returns them.
     */
    protected suspend fun getDocs(): Response<List<DTO>> {
        return try {
            val data = collection.createQuery().getDocs()
                    .mapNotNull { collection.getData(doc = it)?.toDto() }

            Response(OK, body = data)
        } catch (e: Throwable) {
            logAndCreateError("Failed to get data from collection \"${collection.name}\"", cause = e)
        }
    }

    /**
     * Searches for the documents that match the [query] received and returns them.
     */
    protected suspend fun getDocs(query: DbQuery): Response<List<DTO>> {
        return try {
            val data = collection.createQuery().where(query).getDocs()
                    .mapNotNull { collection.getData(doc = it)?.toDto() }

            Response(OK, body = data)
        } catch (e: Throwable) {
            logAndCreateError("Failed to get data for query \"$query\"", cause = e)
        }
    }

    /**
     * Searches for the documents that match ALL the [queries] received and returns them, or returns all documents in
     * the collection if [queries] is empty.
     */
    protected suspend fun getDocsAll(queries: List<DbQuery>): Response<List<DTO>> {
        return try {
            // If no queries were received then get all documents or the query search below will fail
            if (queries.isEmpty()) {
                return getDocs()
            }

            var dbQuery = collection.createQuery()
            for (query in queries) {
                dbQuery = dbQuery.where(query)
            }

            val data = dbQuery.getDocs().mapNotNull { collection.getData(doc = it)?.toDto() }

            Response(OK, body = data)
        } catch (e: Throwable) {
            logAndCreateError("Failed to get data for query \"$queries\"", cause = e)
        }
    }

    /**
     * Searches for the documents that match ANY of the [queries] received and returns
     * them.
     */
    protected suspend fun getDocsAny(queries: List<DbQuery>): Response<Set<DTO>> {
        return try {
            // Convert result to Set to remove possible duplicates
            val data = queries.flatMap { query ->
                collection.createQuery().where(query).getDocs()
                        .mapNotNull { collection.getData(doc = it)?.toDto() }
            }.toSet()

            Response(OK, body = data)
        } catch (e: Throwable) {
            logAndCreateError("Failed to get data for query \"$queries\"", cause = e)
        }
    }

    /**
     * Loads a Firestore document with [id] into the [Flow] returned and sends updates as the document is updated.
     */
    protected fun loadDoc(id: String): Flow<Response<DTO>> = callbackFlow {
        val listener = collection.observeChanges(docId = id) { doc, error ->
            if (doc != null) {
                trySend(Response(OK, body = collection.getData(doc)?.toDto()))
            } else {
                trySend(logAndCreateError<DTO>("Failed to load data update from document with ID: $id",
                                               cause = error))
            }
        }

        // Unsubscribe from DB updates when the Flow is closed
        awaitClose { listener.stop() }
    }

    /**
     * Obtains the Firestore document with [id] and returns it.
     */
    protected suspend fun getDoc(id: String): Response<DTO> {
        return try {
            val data = collection.getData(collection.getDoc(id))

            if (data != null) {
                Response(OK, body = data.toDto())
            } else {
                logWarn("No data found for document with ID: \"$id\"")

                Response(NOT_FOUND)
            }
        } catch (e: Throwable) {
            logAndCreateError(message = "Failed to get data from document with ID: $id", cause = e)
        }
    }

    /**
     * Searches for all documents that match the [query] and performs the following checks:
     *
     * 1. If only 1 document is found, then it's returned.
     * 2. If no documents are found, then a [NOT_FOUND] error is returned.
     * 3. If more than 1 document is found, then a [CONFLICT] error is returned.
     */
    protected suspend fun getDoc(query: DbQuery): Response<DTO> {
        val resp = getDocs(query = query)

        if (!resp.isSuccessful) {
            return Response(statusCode = resp.statusCode, errorBody = resp.errorBody)
        }

        val docs = resp.body!!

        return when (docs.size) {
            0 -> Response(NOT_FOUND)
            1 -> Response(OK, body = docs.first())
            else -> Response(CONFLICT,
                             errorBody = "More than 1 doc found that matches the query \"$query\": ${docs.joinToString(separator = ", ") { it.id }}")
        }
    }

    /**
     * Creates a new Firestore document with the [data] received, setting the document ID to the [data].
     */
    protected suspend fun createDoc(data: DTO): Response<DTO> {
        // Prevent the creation of a document that has an ID as it's probably a doc that already exists in the DB
        if (data.id.isNotBlank()) {
            return Response(CONFLICT, errorBody = "Trying to create document with existing ID")
        }

        return try {
            val docId = collection.createDoc()

            val dbo = data.toDbo().apply {
                // Set document ID from the created doc
                id = docId

                // Set creation and last update timestamps
                val timestamp = Clock.System.now().toEpochMilliseconds()
                creationTimestamp = timestamp
                lastUpdateTimestamp = timestamp
            }

            // Save data
            val doc = collection.updateDoc(id = docId, dbo)

            Response(OK, body = collection.getData(doc)!!.toDto())
        } catch (e: Throwable) {
            logAndCreateError(message = "Failed to create document with data: $data", cause = e)
        }
    }

    /**
     * Deletes the Firestore document that matches the [id] received.
     */
    protected suspend fun deleteDoc(id: String): Response<Unit> {
        return try {
            collection.deleteDoc(id)

            Response(OK, body = Unit)
        } catch (e: Throwable) {
            logAndCreateError(message = "Failed to delete document with ID: \"$id\"",
                              cause = e)
        }
    }

    /**
     * Updates the Firestore document with the [data] received. If there is a document with that ID it will be updated,
     * if there is no document with the data's ID in the collection it will create a new document using that ID.
     */
    protected suspend fun updateDoc(data: DTO): Response<DTO> {
        val docId = data.id

        return try {
            val dbo = data.toDbo().apply {
                // Set creation and last update timestamps
                val timestamp = Clock.System.now().toEpochMilliseconds()
                lastUpdateTimestamp = timestamp

                if (creationTimestamp == null || creationTimestamp == 0L) {
                    // Set creation timestamp if it is not already set. This might happen if [data] is a new object.
                    creationTimestamp = timestamp
                }
            }

            val doc = collection.updateDoc(id = docId, data = dbo)

            Response(OK, body = collection.getData(doc)!!.toDto())
        } catch (e: Throwable) {
            logAndCreateError(message = "Failed to update data for document. Doc ID: \"$docId\" / Data: $data",
                              cause = e)
        }
    }

    /**
     * Updates the [fields] in the Firestore document that matches the [docId].
     */
    protected suspend fun updateFields(docId: String, fields: List<DbField>): Response<DTO> {
        return try {
            val fieldsToUpdate = fields + createLastUpdateTimestampField()

            val doc = collection.updateFields(docId = docId, fields = fieldsToUpdate)

            Response(OK, body = collection.getData(doc)!!.toDto())
        } catch (e: Throwable) {
            logAndCreateError(message = "Failed to update data for document. Doc ID: \"$docId\" / Fields: $fields",
                              cause = e)
        }
    }

    /**
     * Updates the [field] in the Firestore document that matches the [docId].
     */
    protected suspend fun updateField(docId: String, field: DbField): Response<DTO> {
        return try {
            val fieldsToUpdate = listOf(field, createLastUpdateTimestampField())

            val doc = collection.updateFields(docId = docId, fields = fieldsToUpdate)

            Response(OK, body = collection.getData(doc)!!.toDto())
        } catch (e: Throwable) {
            logAndCreateError(message = "Failed to update data for document. Doc ID: \"$docId\" / Field: $field",
                              cause = e)
        }
    }

    /**
     * Returns a new [DbField] for the [LAST_UPDATE_TIMESTAMP] with the current timestamp in millis as value.
     */
    private fun createLastUpdateTimestampField(): DbField {
        return DbField(name = LAST_UPDATE_TIMESTAMP,
                       value = Clock.System.now().toEpochMilliseconds())
    }

    /**
     * Logs the [message] and [cause] to the console as an error and returns a [Response] with status code
     * [INTERNAL_SERVER_ERROR].
     */
    private fun <RT> logAndCreateError(message: String, cause: Throwable? = null): Response<RT> {
        logError(message, cause)

        return Response(INTERNAL_SERVER_ERROR, errorBody = cause?.stackTraceToString())
    }

    /*
     * Inner types
     */

    private companion object : Logger()

}
