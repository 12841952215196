package supergenerous.app.core.auth.model

/**
 * List of auth providers available to use.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class AuthProvider {

    GOOGLE,
    EMAIL

}