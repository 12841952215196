package supergenerous.app.core.component

import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.JustifyContent
import kotlinx.css.alignItems
import kotlinx.css.display
import kotlinx.css.justifyContent
import kotlinx.css.margin
import kotlinx.css.marginTop
import kotlinx.css.px
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.dom.label
import styled.css
import styled.styledDiv
import styled.styledSpan
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.component.tooltip.helpIcon
import supergenerous.app.core.util.htmlFor

/**
 * Title component used to display a title and subtitle above input fields (e.g., [textField]).
 */
@JsExport
private class InputTitle : RComponent<InputTitleProps, State>() {

    override fun RBuilder.render() {
        styledDiv {
            styledDiv {
                css {
                    display = Display.flex
                    alignItems = Align.center
                    justifyContent = JustifyContent.start
                }

                label {
                    props.inputFieldId?.let { attrs.htmlFor(it) }

                    subheading2 { +props.title }
                }

                props.helpInfo?.let {
                    styledSpan {
                        css {
                            margin(left = 8.px)
                        }

                        helpIcon(helpInfo = it)
                    }
                }
            }

            props.subtitle?.let { label ->
                styledDiv {
                    css {
                        marginTop = 8.px
                    }

                    label {
                        props.inputFieldId?.let { attrs.htmlFor(it) }

                        body1 { +label }
                    }
                }
            }
        }
    }

}

/**
 * Properties used by the [InputTitle] component.
 */
private external interface InputTitleProps : Props {

    /**
     * The title to display.
     */
    var title: String

    /**
     * The subtitle to display below the [title].
     */
    var subtitle: String?

    /**
     * The text to display when the help icon is hovered over/active. If `null` then no help icon is displayed.
     */
    var helpInfo: String?

    /**
     * The ID of the input field this title is related to.
     */
    var inputFieldId: String?

}

/**
 * Renders an [InputTitle] component.
 */
public fun RBuilder.inputTitle(title: String,
                               subtitle: String? = null,
                               helpInfo: String? = null,
                               inputFieldId: String? = null) {
    child(InputTitle::class) {
        attrs.title = title
        attrs.subtitle = subtitle
        attrs.helpInfo = helpInfo
        attrs.inputFieldId = inputFieldId
    }
}

/**
 * Converts the `String` to an ID compatible with HTML elements.
 */
internal fun String.toInputFieldId(): String {
    return this.replace(" ", "-").lowercase()
}