package supergenerous.app.core.search.typesense

import kotlinx.serialization.Serializable

/**
 * Information about an individual match in a Typesense search.
 *
 * See https://typesense.org/docs/0.23.0/api/search.html#search
 */
@Serializable
public data class TypesenseSearchHit(

    /**
     * The text that caused the document to match.
     */
    val highlights: List<Highlight>,

    /**
     * The whole document that matched the search.
     */
    // TODO: Make this generic so we can search by other types of data
    val document: TypesenseDonee

) {

    /*
     * Inner types
     */

    /**
     * Information about the matching text.
     *
     * See https://typesense.org/docs/0.23.0/api/search.html#search
     */
    @Serializable
    public data class Highlight(

        /**
         * The name of the matching field on the document.
         */
        val field: String,

        /**
         * The whole text entry that matched, with the matching pieces of text highlighted in HTML `<mark>` elements.
         *
         * e.g.
         * Search term = `"test"`
         * Matched value = `"Some text for testing"`
         * [snippet] = `"Some text for <mark>test</mark>ing"`
         *
         * Only present if the [field] is not an array type on Typesense.
         */
        val snippet: String? = null,

        /**
         * The whole text entry(s) that matched if the highlight is for an array field of the document, with the
         * matching pieces of text highlighted in HTML `<mark>` elements.
         *
         * Only present if the [field] is an array type on Typesense.
         */
        val snippets: List<String>? = null

        //        /**
        //         * The start index of each match in [snippets].
        //         *
        //         * Only present if the [field] is an array type on Typesense.
        //         */
        //        val indices: List<Int>? = null,

        //        /**
        //         * The matched pieces of strings.
        //         */
        //        @SerialName("matched_tokens")
        //        val matchedTokens: List<String> || List<List<String>>

    )

}