package supergenerous.app.core.component.dialog

/**
 * Sizes available for [Dialog]s on the app.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public enum class DialogSize {

    /**
     * Small size.
     */
    SMALL,

    /**
     * Medium size.
     */
    MEDIUM,

    /**
     * Large size.
     */
    LARGE

}