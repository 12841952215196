package supergenerous.app.donor.kindo.model

import com.supergenerous.common.kindo.KindoProps.AFFILIATE
import com.supergenerous.common.kindo.KindoProps.DONOR_EXT_ID
import com.supergenerous.common.kindo.KindoProps.DONOR_ID
import com.supergenerous.common.kindo.KindoProps.PRIVATE_COMMENT
import com.supergenerous.common.kindo.KindoProps.PUBLIC_COMMENT
import com.supergenerous.common.kindo.KindoProps.REFERRAL_ID
import com.supergenerous.common.kindo.KindoProps.RESULT
import com.supergenerous.common.kindo.KindoProps.RETURN_TYPE
import com.supergenerous.common.kindo.KindoValues
import com.supergenerous.common.kindo.KindoValues.SG_ID
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Message sent to Kindo after a donor link is performed (via `postMessage`).
 */
@Serializable
public data class KindoDonorLinkMsg(

    /**
     * The ID of the referral itself.
     */
    @SerialName(REFERRAL_ID)
    val referralId: String,

    /**
     * ID of the donor on Kindo's system.
     */
    @SerialName(DONOR_EXT_ID)
    val donorExtId: String,

    /**
     * ID of the donor on SG's system.
     */
    @SerialName(DONOR_ID)
    val donorId: String,

    /**
     * The result of the customer referral.
     */
    @SerialName(RESULT)
    val kindoLinkStatus: LinkStatus,

    /**
     * Text comment returned to Kindo and potentially displayed to end user.
     */
    @SerialName(PUBLIC_COMMENT)
    val commentToDonor: String,

    /**
     * Text comment returned to Kindo for use by Kindo only.
     *
     * This can be kept empty by default.
     */
    @SerialName(PRIVATE_COMMENT)
    val commentToKindo: String = ""

) {

    @SerialName(RETURN_TYPE)
    val returnType: String = KindoValues.FORWARD_JOIN_RESULT

    @SerialName(AFFILIATE)
    val affiliate: String = SG_ID

    /**
     * Possible results from a [KindoDonorLinkParams] that Kindo is able to receive.
     *
     * There are other possible results (i.e. affiliate_decline) but these should never occur.
     */
    @Serializable
    public enum class LinkStatus {

        @SerialName("user_select")
        EXISTING_USER_LINKED,

        @SerialName("user_join")
        NEW_USER_LINKED,

        @SerialName("affiliate_error")
        LINK_ERROR,

        @SerialName("user_decline")
        LINK_DECLINED

    }

}