package com.supergenerous.common.firebase

/**
 * Field that can be used to update values in a DB document.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public data class DbField(

    /**
     * Name of the field in the document.
     */
    val name: Name,

    /**
     * Value to set for the field [name].
     */
    val value: Any?

) {

    /**
     * List of field names for all DB documents.
     */
    public enum class Name(

        /**
         * Value of the field name in the DB document.
         */
        internal val value: String

    ) {

        ID("id"),
        DONOR_ID("donor.id"),
        DONEE_ID("donee.id"),
        DONEE_ID_2("doneeId"),
        ORG_ID("orgId"),
        CRM_ID("crmId"),
        GOV_ID("govId"),
        TAX_ID("taxId"),
        DONEE_IDS("doneeIds"),
        DONEES_DONOR_IDS("doneesDonorIds"),
        DONEE_RECIPIENT_IDS("doneeRecipientIds"),

        NAME("name"),
        LEGAL_NAME("legalName"),
        OTHER_NAMES("otherNames"),

        EMAIL("email"),
        OTHER_EMAILS("otherEmails"),

        DATE("date"),
        DATE_SUBMITTED("dateSubmitted"),
        DATE_SENT("dateSent"),

        TAX_YEAR("taxYear"),

        ACTIVE_STATUS("activeStatus"),
        PROVIDER("provider"),

        BANK_ACCOUNT_NUMBER("bankAccountNumber"),
        IS_BANK_ACCOUNT_VERIFIED("bankAccount.verified"),

        AMOUNT("amount"),
        AMOUNT_EXPECTED("amountExpected"),

        ACCESS_TOKEN("accessToken"),

        TAX_LINK_STATUS("taxLinkStatus"),
        STATUS("status"),
        TYPE("type"),

        // Firebase fields cannot start with "is"
        IS_VERIFIED("verified"),

        IS_VALID("valid"),
        REBATE_CLAIMER("rebateClaimer"),

        IN_FAF_COMMUNITY("inFafCommunity"),
        PARTNER_VALUE("partnerValue"),
        WEBSITE_URL("websiteUrl"),

        IS_CRM_CONNECTED("crmConnected"),

        IS_MARKETING_EMAILS_ENABLED("marketingEmailsEnabled"),

        DONATIONS("donations"),
        REBATES("rebates"),
        DONATION_PLATFORM("donationPlatform"),
        DONATION_PLATFORMS("donationPlatforms"),
        DONATION_PLATFORM_LINKS("donationPlatformLinks"),
        DONATION_PLATFORM_LINKS_IDS("donationPlatformLinksIds"),

        IS_OPEN("open"),
        IS_SENT("sent"),
        IS_ANSWERED("answered"),
        TIMESTAMP_CLOSED("timestampClosed"),

        LAST_UPDATE_TIMESTAMP("lastUpdateTimestamp"),

        RECEIPT_ID("receiptId"),
        RECEIPT_PATH("receipt.path"),
        RECEIPT_BUCKET("receipt.bucket"),

    }

}