package supergenerous.app.core.res

/**
 * Object that contains all the fonts used in the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
internal object Font {

    // Main font
    const val DOMAINE_SANS = "Domaine Sans, Helvetica, sans-serif"

    // Secondary font
    const val PROXIMA_NOVA = "Proxima Nova, Verdana, sans-serif"

    // SG Number font
    const val SG_NUMBERS = "SG Numbers, Helvetica, sans-serif"

    // Signature field font
    const val QWITCHER_GRYPEN = "Qwitcher Grypen, cursive, sans-serif"

}