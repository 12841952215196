package com.supergenerous.common.rebate

import com.supergenerous.common.data.DtoDataModel
import com.supergenerous.common.donation.Donation
import com.supergenerous.common.donor.DonorBasicInfo
import com.supergenerous.common.invoice.Invoice
import com.supergenerous.common.rebate.Rebate.Status.SUBMITTED
import com.supergenerous.common.serialization.LocalDateSerializer
import com.supergenerous.common.tax.region.TaxRegion
import com.supergenerous.common.util.FeeCalculator
import com.supergenerous.common.util.Timestamp
import com.supergenerous.common.util.round
import com.supergenerous.common.util.sumByFloat
import com.supergenerous.common.util.toLocalDate
import com.supergenerous.common.util.toTimestamp
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.plus
import kotlinx.serialization.Serializable

/**
 * Rebate received from the government for a group of [Donation]s made by the [donor] on a specific [taxYear].
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class Rebate(

    override val id: String,

    override val creationTimestamp: Timestamp = 0,

    override val lastUpdateTimestamp: Timestamp = 0,

    /**
     * Donor the rebate belongs to.
     */
    val donor: DonorBasicInfo,

    /**
     * [Donation]s included on the rebate claim sent to the government.
     */
    val donations: Set<Donation>,

    /**
     * Tax year for which the rebate qualifies.
     */
    var taxYear: Int,

    /**
     * Date at which the rebate claim was sent to the government.
     *
     * This value is `null` in any the these scenarios:
     *
     * 1. When the [Rebate] was not claimed by SG and hence there is no info on when the rebate was submitted.
     * 2. When the [Rebate] is "old", i.e., it was claimed manually and the submitted date was not available when they
     * were added to the DB.
     */
    @Serializable(LocalDateSerializer::class)
    val dateSubmitted: LocalDate?,

    /**
     * Date at which the rebate was received from the government.
     */
    @Serializable(LocalDateSerializer::class)
    var dateReceived: LocalDate?,

    /**
     * Amount of money expected for this rebate, calculated from the [donations] amount.
     *
     * This value is `null` when the [Rebate] was not claimed by SG and hence we were not expecting any amount.
     */
    val amountExpected: Float?,

    /**
     * Amount of money received for this rebate.
     *
     * This value might differ from [amountExpected] when some of the donations were already claimed or something else
     * happened on the government side. When this happens, a comment should be added to [comments].
     */
    var amountReceived: Float?,

    /**
     * Status the rebate is in.
     */
    var status: Status,

    /**
     * Whether the [Rebate] was verified by someone in the SG team or not.
     *
     * Rebates only need to be verified when they were edited manually (this field is not used when the rebate was only
     * edited by the bot).
     *
     * Values:
     * - `true` if the rebate needed to be verified, and it was done by someone in the SG team.
     * - `false` if the rebate needs to be verified, but it was not done yet.
     * - `null` if the rebate doesn't need to be verified, or it's an old rebate that was added before this field was
     * introduced.
     */
    val isVerified: Boolean?,

    /**
     * Invoice sent to the [donor] when they receive the [Rebate] and not SG.
     *
     * Multiple [Rebate]s can have the same invoice as only one invoice is sent to the [donor] at a time.
     */
    val invoice: Invoice? = null,

    /**
     * Optional comments added by a SG team member in case something went wrong (e.g., [amountExpected] and
     * [amountReceived] are different).
     */
    var comments: String? = null

) : DtoDataModel<RebateDbo> {

    /**
     * Fee charged by SG for claiming the [Rebate].
     *
     * If the [Rebate.amountExpected] is `null` then the fee will be `0` because it means we didn't claim this [Rebate] but
     * we received the funds for it because our bank account was set on the donor's tax account.
     */
    public val serviceFee: Float
        get() = FeeCalculator.calculateServiceFee(rebate = this)

    /**
     * `true` if the [Rebate] was claimed by SG, or `false` if it wasn't.
     *
     * The reason for this property is the limitation on the IRD side, as they only accept one bank account per DTC
     * account on their system so sometimes we receive rebates that were claimed by someone else but sent to us because our
     * bank account was the last one set on the IRD.
     */
    public val claimedBySg: Boolean
        get() = amountExpected != null

    /**
     * `true` if the [Rebate] was submitted but not received yet, or `false` if it is finished processing or declined.
     */
    public val isInProgress: Boolean
        get() = status == SUBMITTED


    /**
     * Checks if the [Rebate] was submitted more than [TaxRegion.rebateClaimTimeoutDays] ago and returns `true` if so,
     * or `false` if it has not been [TaxRegion.rebateClaimTimeoutDays] days or its processing is complete.
     */
    public fun isTimedOut(taxRegion: TaxRegion): Boolean {
        val dateNow = Clock.System.now().toEpochMilliseconds().toLocalDate()

        return isInProgress
                && dateSubmitted != null
                && dateSubmitted.plus(value = taxRegion.rebateClaimTimeoutDays, unit = DateTimeUnit.DAY) < dateNow
    }

    /**
     * Calculates the amount to disburse for the [donation] that is part of the [Rebate].
     *
     * If the [donation] is not part of the [Rebate] then `0` is returned.
     *
     * Note that this value is rounded to 2 decimal places, but when calculating disbursements the rounding is performed
     * *after* summing up the donations, so the numbers might be off by a few cents when adding each donation individually
     * from this calculation.
     */
    public fun calculateDisbForDonation(donation: Donation): Float {
        // If there was no amount received yet then we can't calculate the disbursement amount
        if (amountReceived == null) {
            return 0f
        }

        // Calculate the amount that will be disbursed to the recipient/s for this rebate
        val disbAmount = amountReceived!! - serviceFee

        // Return the rebate amount that will be disbursed for the donation received
        return (disbAmount * calculateRebatePercentForDonation(donation)).round(precision = 2)
    }

    /**
     * Calculates the service fee charged for the [donation] that is part of the [Rebate].
     *
     * If the [donation] is not part of the [Rebate] then `0` is returned.
     */
    public fun calculateServiceFeeForDonation(donation: Donation): Float {
        return (serviceFee * calculateRebatePercentForDonation(donation)).round(precision = 2)
    }

    /**
     * Calculates the percent of the rebate expected for the [donation] that is part of the [Rebate].
     *
     * If the [donation] is not part of the [Rebate] then `0` is returned.
     */
    private fun calculateRebatePercentForDonation(donation: Donation): Float {
        // The donation is not part of the rebate
        if (donation.id !in donations.map { it.id }
            // The rebate was not claimed by SG
            || !claimedBySg) {
            return 0f
        }

        val donationsAmountTotal = donations.sumByFloat { it.amount }

        // Get the percent of the rebate amount that is from this donation
        return donation.amount / donationsAmountTotal
    }

    override fun toDbo(): RebateDbo {
        return RebateDbo(id = id,
                         creationTimestamp = creationTimestamp,
                         lastUpdateTimestamp = lastUpdateTimestamp,
                         donor = donor.toDbo(),
                         donations = donations.map { it.toDbo() },
                         taxYear = taxYear,
                         dateSubmitted = dateSubmitted?.toTimestamp(),
                         dateReceived = dateReceived?.toTimestamp(),
                         amountExpected = amountExpected,
                         amountReceived = amountReceived,
                         status = status,
                         verified = isVerified,
                         invoice = invoice?.toDbo(),
                         comments = comments)
    }

    /*
     * Inner types
     */

    /**
     * List of statuses a [Rebate] can be in.
     */
    public enum class Status(

        /**
         * Value used to display the [Status] on the UI.
         */
        public val displayValue: String

    ) {

        /**
         * The rebate claim was submitted to the government.
         */
        SUBMITTED("Submitted"),

        /**
         * The rebate funds were received by SG.
         */
        FUNDS_RECEIVED("Funds Received"),

        /**
         * The rebate funds were sent from the government to the [donor] instead of to SG.
         */
        FUNDS_SENT_TO_DONOR("Funds Sent to Donor"),

        /**
         * An invoice for the SG fee amount corresponding to the rebate [amountReceived] was sent to the [donor].
         *
         * This is only used when [FUNDS_SENT_TO_DONOR] was set previously.
         */
        FEE_INVOICE_SENT_TO_DONOR("Fee Invoice Sent to Donor"),

        /**
         * The [invoice] was paid by the [donor].
         *
         * This is only used when [FEE_INVOICE_SENT_TO_DONOR] was set previously.
         */
        FEE_INVOICE_PAID("Fee Invoice Paid"),

        /**
         * The [invoice] was voided and it doesn't need to be paid by the [donor].
         *
         * This is only used when [FEE_INVOICE_SENT_TO_DONOR] was set previously.
         */
        FEE_INVOICE_VOIDED("Fee Invoice Voided"),

        /**
         * The rebate funds were disbursed as per the [donor]'s selection.
         */
        FUNDS_DISBURSED("Funds Disbursed"),

        /**
         * The rebate was declined by the government.
         */
        DECLINED("Declined")

    }

}

/**
 * The total net amount of the [Rebate]s in the list.
 */
public val Collection<Rebate>.totalAmountNet: Float
    get() = sumByFloat { it.amountReceived!! - it.serviceFee }.round(precision = 2)
