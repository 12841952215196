package supergenerous.app.core.network

import com.hipsheep.kore.model.network.Header.Key.AUTHORIZATION
import com.hipsheep.kore.model.network.Header.Value.BEARER
import com.hipsheep.kore.model.network.HttpClient
import com.hipsheep.kore.model.network.HttpMethod
import com.hipsheep.kore.model.network.HttpService
import com.hipsheep.kore.model.network.Response
import com.hipsheep.kore.model.network.sendRequest
import com.hipsheep.kore.util.toList
import firebase.auth.Auth
import kotlinx.coroutines.await
import supergenerous.app.core.di.DIManager
import supergenerous.app.core.util.EnvConfig

/**
 * Base class that should be extended by all services that call GCP cloud functions.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public abstract class CloudFunctionService(

    httpClient: HttpClient = DIManager.httpClient,

    /**
     * Base URL used to call all cloud functions.
     */
    protected val gcpBaseUrl: String = EnvConfig.gcpFunctionsUrlBase,

    /**
     * [CloudFunction] that will be called from this service.
     */
    protected val cloudFunction: CloudFunction,

    protected val firebaseAuth: Auth = firebase.auth()

) : HttpService(httpClient) {

    /**
     * Sends a request to [cloudFunction], authenticating it for the currently signed-in user using [firebaseAuth].
     *
     * @param httpMethod [HttpMethod] of the request.
     * @param path Path to add to the [cloudFunction] base URL (without a leading `/`).
     * @param headers Headers of the request.
     * @param queryParams Query params to add to the request URL.
     * @param httpBody HTTP body to add to the request.
     */
    protected suspend inline fun <reified T> sendRequest(httpMethod: HttpMethod,
                                                         path: String? = null,
                                                         headers: Map<String, List<String>>? = null,
                                                         queryParams: Map<String, String>? = null,
                                                         httpBody: Any? = null): Response<T> {
        val firebaseAuthToken = firebaseAuth.currentUser?.getIdToken()?.await()
        val urlPath = path?.let { "/$it" } ?: ""

        return httpClient.sendRequest(httpMethod = httpMethod,
                                      url = "$gcpBaseUrl/${cloudFunction.urlName}$urlPath",
                                      headers = (headers ?: mapOf()) + (AUTHORIZATION toList "$BEARER $firebaseAuthToken"),
                                      queryParams = queryParams,
                                      httpBody = httpBody)
    }
}