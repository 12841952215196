package supergenerous.app.core.analytics

import react.Props
import react.RBuilder
import react.fc
import react.router.dom.useLocation
import react.useEffect
import supergenerous.app.core.analytics.AnalyticsEvent.ScreenView

/*
 * @author Cameron Probert (cameron@supergenerous.com)
 */

/**
 * Tracks the location of the local route in `react-router`. Whenever location changes, it logs an [AnalyticsEvent.ScreenView]
 * using the [AnalyticsRepository] (e.g., if URL is `app.supergenerous.com/#/auth` then [screenTracker]
 * would log path as being `/auth`).
 *
 * This component returns nothing, so it does not render into the DOM at all. It is just a function that observes
 * location.
 */
private val screenTracker = fc("ScreenTracker") { props: ScreenTrackerProps ->
    val location = useLocation()
    useEffect(listOf(location, props.analyticsRepo)) {
        props.analyticsRepo.logEvent(ScreenView(location.pathname))
    }
}

/**
 * Props for [screenTracker].
 */
private external interface ScreenTrackerProps : Props {

    /**
     * [AnalyticsRepository] used to send screen view events when a screen is rendered.
     */
    var analyticsRepo: AnalyticsRepository

}

/**
 * Renders a [screenTracker] component.
 */
public fun RBuilder.screenTracker(analyticsRepo: AnalyticsRepository) {
    child(screenTracker) {
        attrs.analyticsRepo = analyticsRepo
    }
}