package supergenerous.app.core.component.snackbar

import kotlinx.css.backgroundColor
import kotlinx.css.borderRadius
import kotlinx.css.px
import materialui.snackbar.MuiSnackbar
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.buildElement
import react.dom.div
import react.setState
import styled.css
import styled.styled
import supergenerous.app.core.component.body2
import supergenerous.app.core.res.Color.BLACK

/**
 * Snackbar component used throughout the app.
 */
@JsExport
private class Snackbar : RComponent<SnackbarProps, SnackbarState>() {

    override fun RBuilder.render() {
        @Suppress("DEPRECATION")
        styled(MuiSnackbar)() {
            css {
                backgroundColor = BLACK.cssValue
                // Remove rounded corners
                borderRadius = 0.px
            }

            state.message?.let { message ->
                attrs.message = buildElement {
                    div {
                        message.title?.let { title ->
                            body2(isDarkBg = true) { +title }
                        }

                        body2(isDarkBg = true) { +message.body }
                    }
                }
            }
            // Set the key as otherwise any existing snackbar won't be hidden when a new messages are shown
            // (see https://v4.mui.com/api/snackbar/)
            attrs.key = "${state.message?.title}${state.message?.body}"

            attrs.autoHideDuration = 5_000

            // Only open snackbar when there is a message to show
            attrs.open = state.message != null

            attrs.onClose = {
                // Remove message (i.e., hide snackbar) when it's closed
                setState { message = null }
            }
        }
    }

    override fun componentDidMount() {
        props.messageQueue.receive { setState { message = it } }
    }

}

/**
 * Properties used by the [Snackbar] component.
 */
private external interface SnackbarProps : Props {

    /**
     * Queue used to receive messages and show them in the snackbar.
     */
    var messageQueue: SnackbarMessageQueue

}

/**
 * State of the [Snackbar] component.
 */
private external interface SnackbarState : State {

    /**
     * Message currently being shown in the snackbar, or `null` if the snackbar hidden.
     */
    var message: SnackbarMessage?

}

/**
 * Renders a [Snackbar] component when messages are received via the [messageQueue].
 */
internal fun RBuilder.snackbar(messageQueue: SnackbarMessageQueue) {
    child(Snackbar::class) {
        attrs.messageQueue = messageQueue
    }
}