package com.supergenerous.common.firebase

import firebase.firestore.Query
import kotlinx.coroutines.await

internal actual class FirestoreQuery<DBO>(

    private val query: Query<DBO>

) {

    internal actual suspend fun where(dbQuery: DbQuery): FirestoreQuery<DBO> {
        return query.where(dbQuery.field.value, dbQuery.operator.value, dbQuery.value).toFirestoreQuery()
    }

    internal actual suspend fun observeChanges(observer: (docs: List<FirestoreDocument<DBO>>?, error: Throwable?) -> Unit): FirestoreChangeListener {
        return query.onSnapshot(onNext = { value -> observer(value.docs.toList(), null) },
                                onError = { error -> observer(null, error) })
                .toFirestoreChangeListener()
    }

    internal actual suspend fun getDocs(): List<FirestoreDocument<DBO>> {
        return query.get().await().docs.toList()
    }

}

internal fun <DBO> Query<DBO>.toFirestoreQuery(): FirestoreQuery<DBO> {
    return FirestoreQuery(this)
}