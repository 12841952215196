package supergenerous.app.core

import com.supergenerous.common.network.CoreUrl.Path.ROOT
import kotlinx.css.*
import react.RBuilder
import react.dom.div
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.contentSection
import supergenerous.app.core.component.heading1
import supergenerous.app.core.component.layout.grid
import supergenerous.app.core.component.textLink
import supergenerous.app.core.util.component.toolbar
import supergenerous.app.core.util.mobileScreen

/**
 * Renders a screen that tells the user they have navigated to a non-existent route and links them back to [ROOT].
 */
public fun RBuilder.pageNotFoundScreen() {
    toolbar()

    grid {
        styledDiv {
            css {
                display = Display.flex
                justifyContent = JustifyContent.center
                marginTop = 100.px
                gridColumn = GridColumn("4 / span 6")
                mobileScreen {
                    marginTop = 60.px
                    padding(horizontal = 16.px)
                    gridColumn = GridColumn("1 / span 2")
                }
            }
            styledDiv {
                css {
                    mobileScreen {
                        width = 60.pct
                    }
                }

                contentSection {
                    div {
                        heading1 {
                            +"Page not found :("
                        }
                        body1 {
                            css {
                                marginTop = 16.px
                            }

                            +"Click "
                            textLink(url = ROOT.value) {
                                +"here"
                            }
                            +" to go home."
                        }
                    }
                }
            }
        }
    }
}