package com.supergenerous.common.donor

import com.supergenerous.common.data.Dbo
import com.supergenerous.common.donee.DoneeBasicInfoDbo
import kotlinx.serialization.Serializable

/**
 * [Dbo] of [DoneeDonorInfo].
 */
@Serializable
public data class DoneeDonorInfoDbo(

    val donee: DoneeBasicInfoDbo? = null,

    val donorExtId: String? = null

) : Dbo<DoneeDonorInfo> {

    override fun toDto(): DoneeDonorInfo {
        return DoneeDonorInfo(donee = donee!!.toDto(), donorExtId = donorExtId!!)
    }

}