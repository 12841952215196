package com.supergenerous.common.kindo

/**
 * Constants used as values, e.g. JSON values.
 */
public object KindoValues {

    /**
     * Returned with the result of a Kindo forward join. (i.e. Initiated by Kindo, through the donor app)
     */
    public const val FORWARD_JOIN_RESULT: String = "tgcl_customer_referral_result"

    /**
     * Supergenerous ID on Kindo.
     */
    public const val SG_ID: String = "sgen"

    /**
     * Message type used to communicate between the SG app and Kindo app.
     */
    public const val IFRAME_LINK_MSG_TYPE: String = "linkDonorKindo"

}