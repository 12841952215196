package supergenerous.app.donor.dashboard.view

import com.hipsheep.kore.error.ErrorType
import com.hipsheep.kore.error.ErrorType.*
import com.supergenerous.common.disbursement.DisbursementRecipient.Type.DONOR
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.id.IdDocument.*
import com.supergenerous.common.id.IdDocument.Type.*
import com.supergenerous.common.name.FullName
import com.supergenerous.common.util.toLocaleString
import kotlinx.browser.window
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.GridColumn
import kotlinx.css.GridRow
import kotlinx.css.GridTemplateColumns
import kotlinx.css.GridTemplateRows
import kotlinx.css.JustifyContent
import kotlinx.css.LinearDimension
import kotlinx.css.alignItems
import kotlinx.css.columnGap
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.fr
import kotlinx.css.gap
import kotlinx.css.gridColumn
import kotlinx.css.gridRow
import kotlinx.css.gridTemplateColumns
import kotlinx.css.gridTemplateRows
import kotlinx.css.justifyContent
import kotlinx.css.marginTop
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.rowGap
import kotlinx.css.width
import kotlinx.datetime.LocalDate
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.dom.div
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.button.ButtonSize
import supergenerous.app.core.component.button.ButtonType
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.dividerHorizontal
import supergenerous.app.core.component.inputTitle
import supergenerous.app.core.component.picker.datePicker
import supergenerous.app.core.component.radio.RadioButton
import supergenerous.app.core.component.radio.radioGroup
import supergenerous.app.core.component.snackbar.SnackbarMessageQueue
import supergenerous.app.core.component.textfield.TextFieldType
import supergenerous.app.core.component.textfield.searchTextField
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.core.util.format.TextCapitalization
import supergenerous.app.core.util.format.TextCapitalization.CAPITALIZE
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.open
import supergenerous.app.donor.dashboard.model.DashboardError.*
import supergenerous.app.donor.dashboard.view.DonorField.*
import supergenerous.app.donor.donor.GovIdInput
import supergenerous.app.donor.util.Url
import supergenerous.app.donor.util.component.emailPicker

/**
 * Component that allows the user to edit their account information.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
@JsExport
private class PersonalInfoEditPanel : RComponent<PersonalInfoEditPanelProps, PersonalInfoEditPanelState>() {

    /**
     * Spacing between [infoField]s.
     */
    private val infoFieldSpacing = 24.px


    override fun PersonalInfoEditPanelState.init() {
        errors = mapOf()
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                gap = infoFieldSpacing
            }

            state.donorUpdated?.let { donor ->
                sectionDivider()

                nameField(donor)

                dateOfBirthField(donor)

                addressField(donor)

                sectionDivider()

                emailField(donor)

                phoneNumberField(donor)

                govIdField(donor)

                sectionDivider()

                taxIdField(donor)

                sectionDivider()

                bankAccountField(donor)

                styledDiv {
                    css {
                        // Make a gap of 48px to the previous field
                        marginTop = 48.px - infoFieldSpacing
                        width = 100.pct
                        display = Display.flex
                        justifyContent = JustifyContent.center
                    }

                    button(
                        label = "Save",
                        type = ButtonType.PRIMARY,
                        showLoadingIcon = props.isSavingData,
                        onClick = ::saveDonor
                    )
                }
            }
        }
    }

    /**
     * Renders a horizontal divider to separate different sections.
     */
    private fun RBuilder.sectionDivider() {
        dividerHorizontal(8.px)
    }

    /**
     * Renders a field with the name of the [donor].
     */
    private fun RBuilder.nameField(donor: Donor) {
        val legalName = donor.legalName ?: FullName(firstName = "", middleName = null, lastName = "")

        // If the first or last names were not provided yet, then the name field is editable so the donor can
        // provide this info
        val isNameEditable = legalName.firstName.isBlank() || legalName.lastName.isBlank()

        infoField(
            title = "Full legal name",
            subtitle = if (isNameEditable) "Please provide your full legal name" else null,
            isEditable = isNameEditable,
            readOnlyValue = legalName.toString(),
            editableContent = {
                styledDiv {
                    css {
                        display = Display.grid
                        gridTemplateRows = GridTemplateRows.auto
                        gridTemplateColumns = GridTemplateColumns(1.fr, 1.fr, 1.fr)
                        columnGap = 24.px
                        mobileScreen {
                            gridTemplateRows = GridTemplateRows(1.fr, 1.fr, 1.fr)
                            gridTemplateColumns = GridTemplateColumns.auto
                            rowGap = 8.px
                        }
                    }

                    textField(
                        placeholder = "First name",
                        textCapitalization = CAPITALIZE,
                        errorMessage = state.errors[FIRST_NAME],
                        value = legalName.firstName,
                        onTextChange = { firstName ->
                            setFieldState(FIRST_NAME) {
                                donorUpdated = donor.copy(legalName = legalName.copy(firstName = firstName))
                            }
                        }
                    )
                    textField(
                        placeholder = "Middle name",
                        textCapitalization = CAPITALIZE,
                        value = legalName.middleName,
                        onTextChange = { middleName ->
                            val middleNameOrNull = middleName.ifBlank { null }
                            setState {
                                donorUpdated = donor.copy(legalName = legalName.copy(middleName = middleNameOrNull))
                            }
                        }
                    )
                    textField(
                        placeholder = "Last name",
                        textCapitalization = CAPITALIZE,
                        errorMessage = state.errors[LAST_NAME],
                        value = legalName.lastName,
                        onTextChange = { lastName ->
                            setFieldState(LAST_NAME) {
                                donorUpdated = donor.copy(legalName = legalName.copy(lastName = lastName))
                            }
                        }
                    )
                }
            }
        )
    }

    /**
     * Renders a field with the date of birth of the [donor].
     */
    private fun RBuilder.dateOfBirthField(donor: Donor) {
        // Don't render the DOB field when it's not needed for the donor and they don't already have one saved
        if (donor.dateOfBirth == null && !donor.needsIdVerification) {
            return
        }

        styledDiv {
            css {
                display = Display.grid
                gridTemplateColumns = GridTemplateColumns(1.fr, 1.fr, 1.fr)
                columnGap = 16.px
                mobileScreen {
                    gridTemplateColumns = GridTemplateColumns.auto
                }
            }

            infoField(
                title = "Date of birth",
                isEditable = props.donor.dateOfBirth == null && donor.needsIdVerification,
                readOnlyValue = donor.dateOfBirth?.toLocaleString(),
                editableContent = {
                    datePicker(
                        value = donor.dateOfBirth,
                        disableFutureDates = true,
                        isClearable = false,
                        errorMessage = state.errors[DATE_OF_BIRTH],
                        onDateChange = {
                            setFieldState(DATE_OF_BIRTH) { donorUpdated = donor.copy(dateOfBirth = it) }
                        }
                    )
                }
            )
        }
    }

    /**
     * Renders a field with the address of the [donor].
     */
    private fun RBuilder.addressField(donor: Donor) {
        infoField(
            title = "Home address",
            editableContent = {
                div {
                    searchTextField(
                        placeholder = "Start typing to find address",
                        errorMessage = state.errors[HOME_ADDRESS],
                        value = donor.address,
                        onTextChange = ::onAddressTextChange,
                        searchResults = props.addressSearchResults,
                        onResultSelect = { addressSelected ->
                            props.onAddressSelect()
                            setFieldState(HOME_ADDRESS) {
                                donorUpdated = state.donorUpdated!!.copy(address = addressSelected.data)
                            }
                        },
                        maxResultsShown = 5
                    )
                }
            }
        )
    }

    /**
     * Processes the address text entered by the user.
     */
    private fun onAddressTextChange(text: String) {
        props.onAddressSearch(text)

        // Reset home address value when the text in the field changes but no address was selected yet
        setFieldState(HOME_ADDRESS) {
            donorUpdated = donorUpdated?.copy(address = null)
        }
    }

    /**
     * Renders a field with the email of the [donor].
     */
    private fun RBuilder.emailField(donor: Donor) {
        infoField(
            title = "Email address",
            subtitle = "Please provide any email address you may previously have used to make a donation.",
            editableContent = {
                emailPicker(
                    accountEmail = donor.email,
                    otherEmails = donor.otherEmails,
                    onEmailsChange = { setState { donorUpdated = donor.copy(otherEmails = it) } }
                )
            }
        )
    }

    /**
     * Renders a field with the phone number of the [donor].
     */
    private fun RBuilder.phoneNumberField(donor: Donor) {
        infoField(
            title = "Phone number",
            subtitle = "We promise we won’t cold call you! A phone number is just another way the organisations you give to can identify your receipts.",
            editableContent = {
                textField(
                    type = TextFieldType.PHONE_NUMBER,
                    placeholder = "+64 123 1234 1234",
                    errorMessage = state.errors[PHONE_NUMBER],
                    value = donor.phoneNumber,
                    onTextChange = { phoneNumber ->
                        setFieldState(PHONE_NUMBER) { donorUpdated = donor.copy(phoneNumber = phoneNumber) }
                    }
                )
            }
        )
    }

    /**
     * Renders a field with the government ID of the [Donor].
     */
    private fun RBuilder.govIdField(donor: Donor) {
        // Don't render the government ID field when it's not needed for the donor and they don't already have one saved
        if (donor.govId == null && !donor.needsIdVerification) {
            return
        }

        sectionDivider()

        val isGovIdEditable = props.donor.govId == null

        if (isGovIdEditable) {
            radioGroup(
                title = "Select ID type",
                radioButtons = listOf(RadioButton(label = "New Zealand Driver Licence",
                                                  value = DRIVER_LICENCE_NZ.toString()),
                                      RadioButton(label = "New Zealand Passport",
                                                  value = PASSPORT.toString())),
                valueSelected = state.govIdType.toString(),
                onSelect = { value ->
                    setState {
                        govIdType = IdDocument.Type.valueOf(value)

                        // Reset all government ID errors when changing the selected ID type
                        errors = errors - setOf(GOV_ID_TYPE,
                                                GOV_ID_NUMBER,
                                                GOV_ID_PASSPORT_EXPIRY_DATE,
                                                GOV_ID_DRIVER_LICENCE_VERSION)
                    }
                },
                showError = state.errors[GOV_ID_TYPE] != null
            )
        }

        state.govIdType?.let { govIdType ->
            val (govIdField1, govIdField2) = when (govIdType) {
                DRIVER_LICENCE_NZ -> listOf(GovIdField(title = "Driver licence number",
                                                       placeholder = "Enter 8 characters"),
                                            GovIdField(title = "Version number",
                                                       placeholder ="Enter 3 numbers"))
                PASSPORT -> listOf(GovIdField(title = "Passport number",
                                              placeholder = "Enter 7 or 8 characters"),
                                   GovIdField(title = "Expiry date",
                                              placeholder = ""))
            }

            styledDiv {
                css {
                    display = Display.grid
                    gridTemplateRows = GridTemplateRows.auto
                    gridTemplateColumns = GridTemplateColumns(1.fr, 1.fr)
                    alignItems = Align.start
                    gap = 24.px
                    mobileScreen {
                        gridTemplateRows = GridTemplateRows.auto
                        gridTemplateColumns = GridTemplateColumns.auto
                    }
                }

                infoField(
                    title = govIdField1.title,
                    isEditable = isGovIdEditable,
                    readOnlyValue = when (val govId = donor.govId) {
                        is DriverLicenceNz -> govId.number
                        is Passport -> govId.number
                        null -> ""
                    },
                    editableContent = {
                        textField(
                            textCapitalization = TextCapitalization.UPPERCASE,
                            placeholder = govIdField1.placeholder,
                            value = state.govIdNumber,
                            onTextChange = { text ->
                                setFieldState(GOV_ID_NUMBER) {
                                    govIdNumber = text
                                }
                            },
                            errorMessage = state.errors[GOV_ID_NUMBER]
                        )
                    }
                )

                infoField(
                    title = govIdField2.title,
                    isEditable = isGovIdEditable,
                    readOnlyValue = when (val govId = donor.govId) {
                        is DriverLicenceNz -> govId.version
                        is Passport -> govId.expiryDate.toLocaleString()
                        null -> ""
                    },
                    editableContent = {
                        when (govIdType) {
                            DRIVER_LICENCE_NZ -> {
                                textField(
                                    type = TextFieldType.NUMBER,
                                    placeholder = govIdField2.placeholder,
                                    value = state.driverLicenceVersion,
                                    onTextChange = { text ->
                                        setFieldState(GOV_ID_DRIVER_LICENCE_VERSION) {
                                            driverLicenceVersion = text
                                        }
                                    },
                                    errorMessage = state.errors[GOV_ID_DRIVER_LICENCE_VERSION]
                                )
                            }
                            PASSPORT -> {
                                datePicker(
                                    value = state.passportExpiryDate,
                                    onDateChange = { date ->
                                        setFieldState(GOV_ID_PASSPORT_EXPIRY_DATE) {
                                            passportExpiryDate = date
                                        }
                                    },
                                    errorMessage = state.errors[GOV_ID_PASSPORT_EXPIRY_DATE]
                                )
                            }
                        }
                    }
                )
            }
        }
    }

    /**
     * Renders a field with the tax ID of the [donor].
     */
    private fun RBuilder.taxIdField(donor: Donor) {
        if (props.donor.taxId.isNullOrBlank()) {
            // Can't use infoField() to render the tax ID because the structure is more complex. It needs to render the
            // link to IRD for finding tax number.
            styledDiv {
                css {
                    display = Display.grid
                    gridTemplateColumns = GridTemplateColumns(1.fr, 1.fr)
                    gridTemplateRows = GridTemplateRows(LinearDimension.auto, LinearDimension.auto)

                    gap = 8.px

                    mobileScreen {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                    }
                }

                val leftCol = GridColumn("1 / span 1")
                val rightCol = GridColumn("2 / span 1")
                val fullWidth = GridColumn("1 / span 2")

                val topRow = GridRow("1 / span 1")
                val bottomRow = GridRow("2 / span 1")

                styledDiv {
                    css {
                        gridColumn = leftCol
                        gridRow = topRow

                        mobileScreen {
                            gridColumn = fullWidth
                            gridRow = GridRow.auto
                        }
                    }

                    inputTitle(title = "IRD number")
                }

                styledDiv {
                    css {
                        gridColumn = leftCol
                        gridRow = bottomRow
                        // Make the textField not fill the width of the parent
                        display = Display.flex

                        mobileScreen {
                            gridColumn = fullWidth
                            gridRow = GridRow.auto
                            display = Display.block
                        }

                        mobileScreen { width = 100.pct }
                    }

                    textField(
                        type = TextFieldType.IRD_NUMBER,
                        placeholder = "Enter 8 or 9 numbers",
                        errorMessage = state.errors[TAX_ID],
                        value = donor.taxId,
                        onTextChange = { setFieldState(TAX_ID) { donorUpdated = (donor.copy(taxId = it)) } }
                    )
                }

                styledDiv {
                    css {
                        gridColumn = rightCol
                        gridRow = topRow

                        mobileScreen {
                            gridColumn = fullWidth
                            gridRow = GridRow.auto
                        }
                    }

                    body1 { +"Can't find it?" }
                }

                styledDiv {
                    css {
                        gridColumn = rightCol
                        gridRow = bottomRow

                        marginTop = 8.px

                        mobileScreen {
                            gridColumn = fullWidth
                            gridRow = GridRow.auto
                        }
                    }

                    button(
                        label = "Find my IRD number",
                        size = ButtonSize.SMALL,
                        type = ButtonType.PRIMARY,
                        onClick = { window.open(url = Url.FIND_IRD_NUMBER, useNewTab = true) }
                    )
                }
            }
        } else {
            infoField(
                title = "IRD number",
                isEditable = false,
                readOnlyValue = donor.taxId,
                editableContent = { }
            )
        }
    }

    /**
     * Renders a field with the bank account number of the [donor].
     */
    private fun RBuilder.bankAccountField(donor: Donor) {
        val subtitle = if (donor.disbursementSettings.any { (_, disbRecipient) -> disbRecipient == DONOR }) {
            null
        } else {
            // If the donor selected to donate all their rebates then explain why their bank account is required
            "We'll use this bank account to send you any rebates that we were not able to send to the charitable organisations you donated to."
        }

        textField(
            title = "Bank account number",
            subtitle = subtitle,
            type = TextFieldType.BANK_ACCOUNT_NUMBER,
            value = donor.bankAccountNumber,
            onTextChange = { setFieldState(BANK_ACCOUNT) { donorUpdated = donor.copy(bankAccountNumber = it) } },
            errorMessage = state.errors[BANK_ACCOUNT]
        )
    }

    /**
     * Renders a field that contains info about a value, as well as edit and read states for them. Displays
     * [editableContent] when [isEditable] is `true` or [readOnlyValue] if [isEditable] is `false`.
     */
    private fun RBuilder.infoField(title: String = "",
                                   subtitle: String? = null,
                                   isEditable: Boolean = true,
                                   readOnlyValue: String? = null,
                                   editableContent: RBuilder.() -> Unit) {
        styledDiv {
            css {
                display = Display.grid
                gridTemplateColumns = GridTemplateColumns(1.fr)
                gap = 12.px
            }

            inputTitle(
                title = title,
                subtitle = subtitle
            )

            if (isEditable) {
                editableContent()
            } else {
                body1 { +(readOnlyValue ?: "") }
            }
        }
    }

    override fun componentDidMount() {
        updateDonorFields()
    }

    // TODO: remove this when we add a view model with error observable into the component
    override fun componentDidUpdate(prevProps: PersonalInfoEditPanelProps,
                                    prevState: PersonalInfoEditPanelState,
                                    snapshot: Any) {
        val errors = props.errors
        if (errors != null && errors != prevProps.errors) {
            handleErrors(errors = errors)
        }
    }

    /**
     * Updates the [PersonalInfoEditPanel.state] to match [PersonalInfoEditPanelProps.donor]'s values.
     */
    private fun updateDonorFields() {
        setState {
            donorUpdated = props.donor.copy()
            govIdType = props.donor.govId?.type
            when (val govId = props.donor.govId) {
                is DriverLicenceNz -> {
                    govIdNumber = govId.number
                    driverLicenceVersion = govId.version
                }
                is Passport -> {
                    govIdNumber = govId.number
                    passportExpiryDate = govId.expiryDate
                }
                null -> {}
            }
        }
    }

    /**
     * Sets a state through [stateSetter] and removes any errors that match the [donorField] from
     * [PersonalInfoEditPanelState.errors].
     */
    private fun setFieldState(donorField: DonorField, stateSetter: PersonalInfoEditPanelState.() -> Unit) {
        setState {
            errors = state.errors - donorField
            stateSetter()
        }
    }

    /**
     * Updates the donor with the [PersonalInfoEditPanelState.donorUpdated] data.
     */
    private fun saveDonor() {
        props.onDonorChange(state.donorUpdated!!,
                            GovIdInput(govIdType = state.govIdType,
                                       govIdNumber = state.govIdNumber,
                                       passportExpiryDate = state.passportExpiryDate,
                                       driverLicenceVersion = state.driverLicenceVersion))
    }

    /**
     * Handles the errors passed in.
     */
    private fun handleErrors(errors: Set<ErrorType>) {
        val invalidText = "Invalid"
        val requiredText = "Required"
        errors.forEach { error ->
            when (error) {
                is HttpError -> SnackbarMessageQueue.add(title = null,
                                                         body = "An unknown error occurred: (Code ${error.statusCode})")
                is AppError -> {
                    when (error.code) {
                        // Personal Info
                        FIRST_NAME_MISSING -> setFieldError(donorField = FIRST_NAME, errorMessage = requiredText)
                        LAST_NAME_MISSING -> setFieldError(donorField = LAST_NAME, errorMessage = requiredText)
                        HOME_ADDRESS_MISSING -> setFieldError(donorField = HOME_ADDRESS, errorMessage = requiredText)
                        PHONE_NUMBER_MISSING -> setFieldError(donorField = PHONE_NUMBER, errorMessage = requiredText)
                        DATE_OF_BIRTH_MISSING -> setFieldError(donorField = DATE_OF_BIRTH, errorMessage = requiredText)

                        // Tax ID
                        TAX_ID_MISSING -> setFieldError(donorField = TAX_ID, errorMessage = requiredText)
                        TAX_ID_INVALID -> setFieldError(donorField = TAX_ID, errorMessage = invalidText)

                        // Bank Account
                        BANK_ACCOUNT_MISSING -> setFieldError(donorField = BANK_ACCOUNT, errorMessage = requiredText)
                        BANK_ACCOUNT_INVALID -> setFieldError(donorField = BANK_ACCOUNT, errorMessage = "Invalid number")

                        // Government ID
                        GOV_ID_TYPE_MISSING -> setFieldError(donorField = GOV_ID_TYPE, errorMessage = requiredText)
                        GOV_ID_NUMBER_MISSING -> setFieldError(donorField = GOV_ID_NUMBER, errorMessage = requiredText)
                        PASSPORT_NUMBER_INVALID -> setFieldError(donorField = GOV_ID_NUMBER, errorMessage = invalidText)
                        PASSPORT_EXPIRY_DATE_MISSING -> setFieldError(donorField = GOV_ID_PASSPORT_EXPIRY_DATE, errorMessage = requiredText)
                        PASSPORT_EXPIRY_DATE_INVALID -> setFieldError(donorField = GOV_ID_PASSPORT_EXPIRY_DATE, errorMessage = invalidText)
                        DRIVER_LICENCE_NUMBER_INVALID -> setFieldError(donorField = GOV_ID_NUMBER, errorMessage = invalidText)
                        DRIVER_LICENCE_VERSION_MISSING -> setFieldError(donorField = GOV_ID_DRIVER_LICENCE_VERSION, errorMessage = requiredText)
                        DRIVER_LICENCE_VERSION_INVALID -> setFieldError(donorField = GOV_ID_DRIVER_LICENCE_VERSION, errorMessage = invalidText)
                    }
                }
            }
        }
    }

    /**
     * Sets the [errorMessage] onto [PersonalInfoEditPanelState.errors] at key [donorField].
     */
    private fun setFieldError(donorField: DonorField, errorMessage: String) {
        setState { errors += (donorField to errorMessage) }
    }

    /*
     * Inner types
     */

    /**
     * Container for info needed to display [Donor.govId] fields.
     */
    data class GovIdField(
        val title: String,
        val placeholder: String
    )

}

/**
 * Properties of the [PersonalInfoEditPanel] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface PersonalInfoEditPanelProps : Props {

    /**
     * The latest error to occur.
     */
    var errors: Set<ErrorType>?

    /**
     * `true` if saving is in progress, `false` otherwise.
     */
    var isSavingData: Boolean

    /**
     * The function to call to get autocomplete results for the address.
     */
    var onAddressSearch: (String) -> Unit

    /**
     * The addresses results to show in the address dropdown.
     */
    var addressSearchResults: List<TextSearchResult<String>>

    /**
     * The function to call when an address is selected. TODO: Remove this when we add a viewmodel for the panel.
     */
    var onAddressSelect: () -> Unit

    /**
     * The [Donor] to edit details for.
     */
    var donor: Donor

    /**
     * Function called when the [donor] data changes.
     */
    var onDonorChange: (donor: Donor, govIdInput: GovIdInput) -> Unit

}

/**
 * The state of the [PersonalInfoEditPanel] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface PersonalInfoEditPanelState : State {

    /**
     * The [Donor] to edit details for.
     */
    var donorUpdated: Donor?

    /**
     * The errors to display for each [DonorField].
     *
     * If a [DonorField] does not exist as a key, then there is no error.
     */
    var errors: Map<DonorField, String>

    /**
     * The [IdDocument.Type] selected by the user.
     *
     * This is separate from the [donorUpdated] field because the panel needs to be able to hold partial state of an
     * [IdDocument] when the donor is editing the field.
     */
    var govIdType: IdDocument.Type?

    /**
     * The [Passport.number] or [DriverLicenceNz.number] entered by the user.
     *
     * This is separate from the [donorUpdated] field because the panel needs to be able to hold partial state of an
     * [IdDocument] when the donor is editing the field.
     */
    var govIdNumber: String?

    /**
     * The [Passport.expiryDate] entered by the user.
     *
     * This is separate from the [donorUpdated] field because the panel needs to be able to hold partial state of an
     * [IdDocument] when the donor is editing the field.
     */
    var passportExpiryDate: LocalDate?

    /**
     * The [DriverLicenceNz.version] entered by the user.
     *
     * This is separate from the [donorUpdated] field because the panel needs to be able to hold partial state of an
     * [IdDocument] when the donor is editing the field.
     */
    var driverLicenceVersion: String?

}

/**
 * A reference to a field on the screen.
 */
private enum class DonorField {

    /**
     * The [Donor.bankAccountNumber].
     */
    BANK_ACCOUNT,

    /**
     * The [Donor.taxId].
     */
    TAX_ID,

    /**
     * The [Donor]'s [FullName.firstName].
     */
    FIRST_NAME,

    /**
     * The [Donor]'s [FullName.lastName].
     */
    LAST_NAME,

    /**
     * The [Donor.dateOfBirth].
     */
    DATE_OF_BIRTH,

    /**
     * The [Donor.address].
     */
    HOME_ADDRESS,

    /**
     * The [Donor.phoneNumber].
     */
    PHONE_NUMBER,

    /**
     * The [IdDocument.Type].
     */
    GOV_ID_TYPE,

    /**
     * The [IdDocument.Passport.number].
     */
    GOV_ID_NUMBER,

    /**
     * The [IdDocument.Passport.expiryDate].
     */
    GOV_ID_PASSPORT_EXPIRY_DATE,

    /**
     * The [IdDocument.DriverLicenceNz.version].
     */
    GOV_ID_DRIVER_LICENCE_VERSION

}

/**
 * Renders a [PersonalInfoEditPanel] component.
 */
public fun RBuilder.personalInfoEditPanel(donor: Donor,
                                          onDonorChange: (Donor, GovIdInput) -> Unit,
                                          onAddressSearch: (String) -> Unit,
                                          addressSearchResults: List<TextSearchResult<String>>,
                                          onAddressSelect: () -> Unit,
                                          isSavingData: Boolean,
                                          errors: Set<ErrorType>?) {
    child(PersonalInfoEditPanel::class) {
        attrs.donor = donor
        attrs.onDonorChange = onDonorChange
        attrs.addressSearchResults = addressSearchResults
        attrs.onAddressSearch = onAddressSearch
        attrs.onAddressSelect = onAddressSelect
        attrs.isSavingData = isSavingData
        attrs.errors = errors
    }
}