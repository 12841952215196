package supergenerous.app.donor.setup.view

import com.supergenerous.common.disbursement.DisbursementRecipient.Type.CHARITIES
import com.supergenerous.common.disbursement.DisbursementRecipient.Type.DONOR
import com.supergenerous.common.donee.Donee.Type.CHARITY
import react.RBuilder
import react.State
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.CHARITIES_SELECTION
import supergenerous.app.donor.setup.viewmodel.SetupViewModel

/**
 * Screen that allows donors to select which charities they donate to.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class CharitiesSelectionScreen : SetupScreen<SetupScreenProps, State>() {

    override val setupStep: SetupStep = CHARITIES_SELECTION


    override fun RBuilder.render() {
        doneesSelectionScreen(doneeType = CHARITY,
                              disbRecipientTypes = listOf(CHARITIES, DONOR),
                              setupViewModel = props.viewModel,
                              onBackBtnClick = ::goBack)
    }

}

/**
 * Renders a [CharitiesSelectionScreen] component.
 */
public fun RBuilder.charitiesSelectionScreen(setupViewModel: SetupViewModel) {
    withRouter(CharitiesSelectionScreen::class) {
        attrs.viewModel = setupViewModel
    }
}