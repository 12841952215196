package supergenerous.app.core.component

import kotlinx.css.Align
import kotlinx.css.BorderStyle
import kotlinx.css.Display
import kotlinx.css.alignItems
import kotlinx.css.borderRadius
import kotlinx.css.display
import kotlinx.css.gap
import kotlinx.css.padding
import kotlinx.css.paddingLeft
import kotlinx.css.properties.borderLeft
import kotlinx.css.px
import materialui.checkbox.MuiCheckbox
import react.Props
import react.RBuilder
import react.RComponent
import react.ReactNode
import react.State
import react.buildElement
import react.dom.div
import styled.css
import styled.styled
import styled.styledLabel
import supergenerous.app.core.component.image.svg
import supergenerous.app.core.component.style.InputStyle
import supergenerous.app.core.res.Color
import supergenerous.app.core.res.image.SvgFile

/**
 * Checkbox component used throughout the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class Checkbox : RComponent<CheckboxProps, State>() {

    override fun RBuilder.render() {
        // Wrap checkbox and text inside a label so the checkbox changes state when the text is clicked
        styledLabel {
            css {
                display = Display.flex
                alignItems = Align.flexStart

                gap = 16.px

                if (props.showError && !props.isChecked) {
                    // 8px padding total (7px padding + 1px border)
                    paddingLeft = 7.px
                    borderLeft(width = 1.px, color = Color.ERROR.cssValue, style = BorderStyle.solid)

                    // Unselected checkbox border only
                    descendants("path.checkbox_unselected_svg__box") {
                        put("stroke", Color.ERROR.hexValue)
                    }
                }
            }

            // The checkbox icon
            @Suppress("DEPRECATION")
            styled(MuiCheckbox)() {
                css {
                    +InputStyle.focusRing

                    specific {
                        // Remove padding so the checkbox is aligned to the top of the text
                        padding(all = 0.px)

                        // Make the element square, otherwise the focus ring is a circle shape
                        borderRadius = 0.px
                    }
                }

                attrs {
                    disableRipple = true

                    icon = checkboxIcon(isChecked = false)
                    checkedIcon = checkboxIcon(isChecked = true)

                    checked = props.isChecked
                    onChange = { event -> props.onChange(event.currentTarget.checked) }
                }
            }

            // Group label and error message in "div" to show them vertically
            div {
                props.label?.let { body1 { +it } }

                if (props.showError) {
                    body2 {
                        textError { +"Required" }
                    }
                }
            }
        }
    }

    /**
     * Creates a checkbox icon and returns it.
     *
     * If [isChecked] is `true` then it creates a checked icon, otherwise an unchecked icon.
     */
    private fun checkboxIcon(isChecked: Boolean): ReactNode {
        return buildElement {
            svg(svgFile = if (isChecked) iconCheckboxSelected else iconCheckboxUnselected, size = 22.px)
        }
    }

}

/**
 * Properties used by the [Checkbox] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface CheckboxProps : Props {

    /**
     * `true` if the checkbox should render as an error state when the [Checkbox] is unselected, or `false` otherwise.
     */
    var showError: Boolean

    /**
     * Label shown to the right of the checkbox. If `null` then the checkbox will be displayed on its own.
     */
    var label: String?

    /**
     * `true` if the initial state of the [Checkbox] should be checked, or `false` otherwise.
     */
    var isChecked: Boolean

    /**
     * Function called when the [Checkbox] is checked/unchecked.
     */
    var onChange: (isChecked: Boolean) -> Unit

}

/**
 * The selected checkbox icon.
 */
@JsModule("images/checkbox_selected.svg")
private external val iconCheckboxSelected: SvgFile

/**
 * The unselected checkbox icon.
 */
@JsModule("images/checkbox_unselected.svg")
private external val iconCheckboxUnselected: SvgFile

/**
 * Renders a [Checkbox] component.
 */
public fun RBuilder.checkbox(label: String? = null,
                             showError: Boolean = false,
                             isChecked: Boolean,
                             onChange: (isChecked: Boolean) -> Unit) {
    child(Checkbox::class) {
        attrs.label = label
        attrs.showError = showError
        attrs.isChecked = isChecked
        attrs.onChange = onChange
    }
}