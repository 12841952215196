package supergenerous.app.core.util.format

/**
 * Types of text capitalization accepted in formatters.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class TextCapitalization {

    NONE,
    CAPITALIZE,
    UPPERCASE,
    LOWERCASE

}