package supergenerous.app.core.component.chip

/**
 * Chip shown inside [ChipSet] components.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public data class Chip(

    /**
     * ID of the [Chip] (must be different from all other IDs on the same [chipSet]).
     */
    val id: String,

    /**
     * Label shown in the [Chip].
     */
    val label: String

)