package com.supergenerous.common.invoice

import com.supergenerous.common.data.Dbo
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [Invoice] class.
 *
 * @author Murray Kinsman (murray@supergenerous.com)
 */
@Serializable
public data class InvoiceDbo(

    val extId: String? = null,

    val url: String? = null

): Dbo<Invoice> {

    override fun toDto(): Invoice {
        return Invoice(extId = extId!!,
                       url = url!!)
    }

}