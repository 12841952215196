package supergenerous.app.donor.util.res

import supergenerous.app.core.res.image.SvgFile

/**
 * List of icons used in the app.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public enum class Icon(

    override val svgFile: SvgFile,
    override val label: String

) : supergenerous.app.core.res.image.Icon {

    CHECK_CIRCLE(iconCheckCircle, "Done")

}

/**
 * Check mark inside a circle.
 */
@JsModule("images/icon_check_circle.svg")
private external val iconCheckCircle: SvgFile