package supergenerous.app.core.analytics

import com.supergenerous.common.donor.DonorProvider
import com.supergenerous.common.user.User
import firebase.analytics.Analytics
import react.gtm.TagManager
import react.gtm.TagManagerArgs
import kotlin.js.json

/**
 * Analytics service used to send events to Firebase analytics.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class FirebaseAnalyticsService(

    /**
     * Analytics engine used in the background to log events.
     */
    private val firebaseAnalytics: Analytics = firebase.analytics()

) : AnalyticsService {

    override fun logEvent(event: AnalyticsEvent) {
        val eventParams = event.params?.let { params -> json(*params.map { Pair(it.key, it.value) }.toTypedArray()) }
        firebaseAnalytics.logEvent(event.name, eventParams)
    }

    override fun setUser(user: User, donorProvider: DonorProvider?) {
        firebaseAnalytics.setUserId(user.id)
    }

    /*
     * Inner types
     */

    public companion object {

        /**
         * Initializes analytics tracking using the [gtmId] (Google Tag Manager ID) received.
         */
        public fun init(gtmId: String) {
            TagManager.initialize(TagManagerArgs(gtmId))
        }

    }

}