package com.supergenerous.common.rebate

import com.supergenerous.common.data.Dbo
import com.supergenerous.common.data.DboDataModel
import com.supergenerous.common.disbursement.Disbursement
import com.supergenerous.common.donation.DonationDbo
import com.supergenerous.common.donor.DonorBasicInfoDbo
import com.supergenerous.common.invoice.InvoiceDbo
import com.supergenerous.common.util.Timestamp
import com.supergenerous.common.util.toLocalDate
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [Rebate] class.
 *
 * @author Raymond Feng (raymond@supergenerous.com)
 */
@Serializable
public data class RebateDbo(

    override var id: String? = null,

    override var creationTimestamp: Timestamp? = null,

    override var lastUpdateTimestamp: Timestamp? = null,

    val donor: DonorBasicInfoDbo? = null,

    val donations: List<DonationDbo>? = null,

    val taxYear: Int? = null,

    val dateSubmitted: Timestamp? = null,

    val dateReceived: Timestamp? = null,

    val amountExpected: Float? = null,

    val amountReceived: Float? = null,

    val status: Rebate.Status? = null,

    // Property name doesn't start with "is" because of Firebase issue: https://github.com/firebase/firebase-admin-java/issues/472
    // TODO: Change when the issue is fixed on Firebase
    val verified: Boolean? = null,

    val invoice: InvoiceDbo? = null,

    val comments: String? = null

): DboDataModel<Rebate> {

    override fun toDto(): Rebate {
        return Rebate(id = id!!,
                      creationTimestamp = creationTimestamp!!,
                      lastUpdateTimestamp = lastUpdateTimestamp!!,
                      donor = donor!!.toDto(),
                      donations = donations!!.map { donation -> donation.toDtoInternal() }.toSet(),
                      taxYear = taxYear!!,
                      dateSubmitted = dateSubmitted?.toLocalDate(),
                      dateReceived = dateReceived?.toLocalDate(),
                      amountExpected = amountExpected,
                      amountReceived = amountReceived,
                      status = status!!,
                      isVerified = verified,
                      invoice = invoice?.toDto(),
                      comments = comments)
    }

    /**
     * Converts the [RebateDbo] to a [Rebate] ignoring the timestamps.
     *
     * This method should be used when parsing the data for the purpose of saving/reading to/from another data model,
     * e.g., [Disbursement.rebates].
     */
    internal fun toDtoInternal(): Rebate {
        return Rebate(id = id!!,
                      donor = donor!!.toDto(),
                      donations = donations!!.map { donation -> donation.toDtoInternal() }.toSet(),
                      taxYear = taxYear!!,
                      dateSubmitted = dateSubmitted?.toLocalDate(),
                      dateReceived = dateReceived?.toLocalDate(),
                      amountExpected = amountExpected,
                      amountReceived = amountReceived,
                      status = status!!,
                      isVerified = verified,
                      invoice = invoice?.toDto(),
                      comments = comments)
    }

}