package supergenerous.app.core

import com.hipsheep.kore.viewmodel.ViewModel
import com.supergenerous.common.network.Url
import materialui.style.MuiPaletteColor
import materialui.style.MuiPaletteOptions
import materialui.style.MuiThemeOptions
import materialui.style.MuiThemeProvider
import materialui.style.createMuiTheme
import react.Props
import react.RBuilder
import react.RHandler
import react.State
import react.setState
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.snackbar.SnackbarMessageQueue
import supergenerous.app.core.component.snackbar.snackbar
import supergenerous.app.core.res.Color
import supergenerous.app.core.res.image.Image
import supergenerous.app.core.splash.splashScreen
import supergenerous.app.core.util.RouteProps
import supergenerous.app.core.util.redirect

/**
 * Base class that should be extended by the main App class in the app project.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public abstract class BaseApp<P : RouteProps, S : BaseAppState, VM : BaseAppViewModel> : LifecycleOwnerComponent<P, S>() {

    /**
     * Logo to show on the [splashScreen].
     */
    protected abstract val splashScreenLogo: Image

    /**
     * URL path to the root endpoint.
     */
    protected abstract val rootPath: Url.Path

    /**
     * URL paths to the auth endpoints.
     *
     * The first [Url.Path] on the list should be the one the user redirects to when they are not signed in and try to
     * access an auth-protected screen.
     */
    protected abstract val authPaths: Set<Url.Path>

    /**
     * URL path to redirect to after the user is authenticated
     */
    protected abstract var afterAuthPath: String?

    /**
     * [ViewModel] that provides data to the [BaseApp] component.
     */
    protected abstract val appViewModel: VM

    /**
     * `true` if the user is signed in, or `false` otherwise.
     */
    private var isUserSignedIn: Boolean = false


    /*
     * Set `final` modifier so child classes cannot override this. Child classes should instead call [S.initAppState].
     * If a child class overrides this then `isAppLoading` is not set to `true`, causing the app page to flicker before
     * the loading screen is displayed.
     */
    final override fun S.init() {
        isAppLoading = true
        initAppState()
    }

    /**
     * Initialises the app state.
     */
    protected open fun S.initAppState() {
        // This method should be overridden from the child classes when necessary
    }

    override fun RBuilder.render() {
        appTheme {
            if (state.isAppLoading) {
                // Show a splash screen while the app is loading
                splashScreen(logo = splashScreenLogo)
            } else {
                // Once the app finishes loading show the screen requested
                onAppLoaded()
            }

            // Snackbar that will appear when a message is added to the snackbarMessageQueue anywhere in the app
            snackbar(messageQueue = SnackbarMessageQueue)
        }
    }

    /**
     * Called when the app loading finishes.
     */
    protected abstract fun RBuilder.onAppLoaded()

    override fun componentDidMount() {
        super.componentDidMount()

        // Get updates on whether the app data is being loaded so we can show the splash screen accordingly
        appViewModel.isAppDataLoaded.observe { isAppDataLoaded -> setState { isAppLoading = !isAppDataLoaded } }

        // Get updates of the auth status of the user so we can redirect to the auth screen if necessary
        appViewModel.isUserSignedIn.observe { isSignedIn -> isUserSignedIn = isSignedIn }

        appViewModel.errors.observeEvent {
            // TODO: Show this error indefinitely to make sure the user sees it
            // TODO: Show different errors depending on the error code received
            SnackbarMessageQueue.add(body = "Failed to load app, please refresh the page")
        }

        // Load app data from the server
        appViewModel.loadAppData()
    }

    /**
     * Creates the theme used to style the whole app.
     */
    private fun RBuilder.appTheme(handler: RHandler<Props>) {
        @Suppress("DEPRECATION")
        MuiThemeProvider {
            val contrastTextColor = Color.WHITE.hexValue

            attrs.theme = createMuiTheme(MuiThemeOptions(
                MuiPaletteOptions(primary = MuiPaletteColor(main = Color.PRIMARY.hexValue,
                                                            contrastText = contrastTextColor),
                                  secondary = MuiPaletteColor(main = Color.SECONDARY.hexValue,
                                                              contrastText = contrastTextColor))
            ))

            handler()
        }
    }

    /**
     * Redirects to the authentication screen if the user is not signed in, otherwise [render]s the child components.
     */
    protected fun RBuilder.requireAuth(render: RBuilder.() -> Unit) {
        // Get the path ignoring any query params
        val currentPath = props.location.pathname

        // This method is called with the "root" and/or "auth" paths during auth so ignore those values (it doesn't
        // make sense to redirect to the auth screen once the user is authenticated)
        if (currentPath != rootPath.value && currentPath !in authPaths.map { it.value }) {
            // Add query params to path so they are included when redirecting after auth
            afterAuthPath = currentPath + props.location.search
        }

        if (isUserSignedIn) {
            render()
        } else {
            redirect(to = authPaths.first())
        }
    }

}

/**
 * State of the [BaseAppState] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public external interface BaseAppState : State {

    /**
     * `true` is the app is loading (i.e., fetching initial data from the server),
     * or `false` if it's finished loading.
     */
    public var isAppLoading: Boolean

}