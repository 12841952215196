package supergenerous.app.core.util

/**
 * Contains data for a field on a form. This is used to group the properties that relate to a field together.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public data class FormField<T>(

    /**
     * The value to display in the field.
     */
    val value: T,

    /**
     * The error message to display on the field, if there is one.
     */
    val errorMessage: String? = null

)