package supergenerous.app.core.component.radio

/**
 * Radio button shown inside [RadioGroup] components.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public data class RadioButton(

    /**
     * Label shown in the [RadioButton].
     */
    var label: String,

    /**
     * Value assigned to the [RadioButton].
     */
    var value: String

)