package supergenerous.app.core.component.radio

import kotlinx.css.BorderStyle
import kotlinx.css.Display
import kotlinx.css.display
import kotlinx.css.gap
import kotlinx.css.marginTop
import kotlinx.css.padding
import kotlinx.css.paddingLeft
import kotlinx.css.properties.borderLeft
import kotlinx.css.px
import materialui.radio.MuiRadio
import materialui.radio.MuiRadioGroup
import react.Props
import react.RBuilder
import react.RComponent
import react.ReactNode
import react.State
import react.buildElement
import styled.css
import styled.styled
import styled.styledLabel
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.body2
import supergenerous.app.core.component.image.svg
import supergenerous.app.core.component.inputTitle
import supergenerous.app.core.component.style.InputStyle
import supergenerous.app.core.component.textError
import supergenerous.app.core.res.Color
import supergenerous.app.core.res.image.SvgFile
import supergenerous.app.core.util.AlignItems
import supergenerous.app.core.util.alignItems

/**
 * Radio group component used throughout the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class RadioGroup : RComponent<RadioGroupProps, State>() {

    override fun RBuilder.render() {
        @Suppress("DEPRECATION")
        styled(MuiRadioGroup)() {
            css {
                if (props.showError) {
                    // 8px padding total (7px padding + 1px border)
                    paddingLeft = 7.px
                    borderLeft(width = 1.px, color = Color.ERROR.cssValue, style = BorderStyle.solid)
                }
            }

            props.title?.let {
                inputTitle(
                    title = it,
                    subtitle = props.subtitle
                )
            }

            for (radioButton in props.radioButtons) {
                // Wrap radio button and text inside a label so the radio changes state when the text is clicked
                styledLabel {
                    css {
                        marginTop = 16.px
                        display = Display.flex
                        alignItems(AlignItems.center)

                        gap = 16.px
                    }

                    // The radio icon
                    styled(MuiRadio)() {
                        css {
                            +InputStyle.focusRing

                            specific {
                                // Remove padding so the radio button is aligned to the top of the text
                                padding(all = 0.px)
                            }

                            // If there is an error message set the radio icon borders to red
                            if (props.showError) {
                                descendants(selector = arrayOf("svg > circle")) {
                                    this.put("stroke", Color.ERROR.hexValue)
                                }
                            }
                        }

                        attrs {
                            icon = radioIcon(isChecked = false)
                            checkedIcon = radioIcon(isChecked = true)

                            value = radioButton.value
                            checked = value == props.valueSelected

                            onChange = { event ->
                                val eventTarget = event.currentTarget

                                if (eventTarget.checked) {
                                    props.onSelect(eventTarget.value)
                                }
                            }
                        }
                    }

                    body1 { +radioButton.label }
                }
            }

            if (props.showError) {
                body2 {
                    css {
                        marginTop = 8.px
                    }

                    textError { +"Required" }
                }
            }
        }
    }

    /**
     * Creates a radio icon, either filled or not.
     */
    private fun radioIcon(isChecked: Boolean): ReactNode {
        return buildElement {
            svg(svgFile = if (isChecked) iconRadioButtonSelected else iconRadioButtonUnselected, size = 22.px)
        }
    }

}

/**
 * Properties used by the [RadioGroup] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface RadioGroupProps : Props {

    /**
     * The title of the radio group.
     */
    var title: String?

    /**
     * The help text below the [title].
     */
    var subtitle: String?

    /**
     * [RadioButton]s that belong to the [RadioGroup].
     */
    var radioButtons: List<RadioButton>

    /**
     * Value of the [RadioButton] that is selected.
     *
     * If not set, none of the [RadioButton]s will be selected.
     */
    var valueSelected: String?

    /**
     * Function called when a [RadioButton] is selected by the user.
     */
    var onSelect: (value: String) -> Unit

    /**
     * `true` to display the [RadioGroup] in an error state, or `false` otherwise.
     */
    var showError: Boolean

}

/**
 * The selected radio icon.
 */
@JsModule("images/radio_button_selected.svg")
private external val iconRadioButtonSelected: SvgFile

/**
 * The unselected radio icon.
 */
@JsModule("images/radio_button_unselected.svg")
private external val iconRadioButtonUnselected: SvgFile

/**
 * Renders a [RadioGroup] component.
 */
public fun RBuilder.radioGroup(title: String? = null,
                               subtitle: String? = null,
                               showError: Boolean = false,
                               radioButtons: List<RadioButton>,
                               valueSelected: String?,
                               onSelect: (value: String) -> Unit) {
    child(RadioGroup::class) {
        attrs.title = title
        attrs.subtitle = subtitle
        attrs.radioButtons = radioButtons
        attrs.valueSelected = valueSelected
        attrs.showError = showError
        attrs.onSelect = onSelect
    }
}