package supergenerous.app.donor.dashboard.view

import com.supergenerous.common.donor.Donor
import com.supergenerous.common.donor.fullName
import com.supergenerous.common.id.IdDocument.*
import com.supergenerous.common.util.mask
import com.supergenerous.common.util.toLocaleString
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.Float
import kotlinx.css.GridColumn
import kotlinx.css.GridTemplateColumns
import kotlinx.css.GridTemplateRows
import kotlinx.css.LinearDimension
import kotlinx.css.Overflow
import kotlinx.css.Position
import kotlinx.css.TextAlign
import kotlinx.css.TextOverflow
import kotlinx.css.alignItems
import kotlinx.css.display
import kotlinx.css.float
import kotlinx.css.fr
import kotlinx.css.gap
import kotlinx.css.gridColumn
import kotlinx.css.gridTemplateColumns
import kotlinx.css.gridTemplateRows
import kotlinx.css.margin
import kotlinx.css.marginLeft
import kotlinx.css.overflow
import kotlinx.css.pct
import kotlinx.css.position
import kotlinx.css.px
import kotlinx.css.textAlign
import kotlinx.css.textOverflow
import kotlinx.css.width
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.button.ButtonSize
import supergenerous.app.core.component.button.ButtonType
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.dividerHorizontal
import supergenerous.app.core.component.image.icon
import supergenerous.app.core.component.subheading1
import supergenerous.app.core.component.subheading2
import supergenerous.app.core.component.textError
import supergenerous.app.core.res.Color
import supergenerous.app.core.res.image.CoreIcon
import supergenerous.app.core.util.mobileScreen

/**
 * Component that allows the user to view their personal information.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
@JsExport
private class PersonalInfoPanel : RComponent<PersonalInfoPanelProps, State>() {

    override fun RBuilder.render() {
        styledDiv {
            css {
                width = 100.pct
                display = Display.grid
                gridTemplateColumns = GridTemplateColumns(2.fr, 3.fr)
                gridTemplateRows = GridTemplateRows.auto
                gap = 20.px
            }

            val gridColumnFullWidth = GridColumn("1 / 3")

            styledDiv {
                css {
                    gridColumn = gridColumnFullWidth
                    display = Display.flex
                    alignItems = Align.center
                    gap = 8.px
                }

                subheading1 { +"My details" }

                styledDiv {
                    css {
                        marginLeft = LinearDimension.auto
                    }

                    button(
                        label = "Edit",
                        type = ButtonType.SECONDARY,
                        size = ButtonSize.EXTRA_SMALL,
                        onClick = props.onEditInfoClick
                    )
                }
            }

            styledDiv {
                css {
                    gridColumn = gridColumnFullWidth
                }

                sectionDivider()
            }

            infoField(
                label = "Name",
                value = props.donor.fullName,
                isValueRequired = true
            )

            // Only show DOB when it was provided by the donor already or if the donor needs to provide it for ID
            // verification
            if (props.donor.dateOfBirth != null || props.donor.needsIdVerification) {
                infoField(
                    label = "Date of birth",
                    value = props.donor.dateOfBirth?.toLocaleString(),
                    isValueRequired = props.donor.needsIdVerification
                )
            }

            infoField(
                label = "Home address",
                value = props.donor.address,
                isValueRequired = true
            )

            sectionDivider()

            infoField(
                label = "Email address",
                values = props.donor.otherEmails + props.donor.email
            )
            infoField(
                label = "Phone number",
                value = props.donor.phoneNumber,
                isValueRequired = true
            )

            sectionDivider()

            when (val govId = props.donor.govId) {
                is DriverLicenceNz -> {
                    infoField(
                        label = "Driver license number",
                        value = govId.number.mask(),
                        isValueRequired = true
                    )
                    infoField(
                        label = "Version number",
                        value = govId.version.mask(),
                        isValueRequired = true
                    )

                    sectionDivider()
                }
                is Passport -> {
                    infoField(
                        label = "Passport number",
                        value = govId.number.mask(),
                        isValueRequired = true
                    )
                    infoField(
                        label = "Expiry date",
                        value = govId.expiryDate.toLocaleString().mask(),
                        isValueRequired = true
                    )

                    sectionDivider()
                }
                null -> {
                    // When the gov ID is missing but the donor needs ID verification, add this pseudo-field to display
                    // a warning to the donor
                    if (props.donor.needsIdVerification) {
                        infoField(
                            label = "Government ID",
                            value = null,
                            isValueRequired = true
                        )
                    }
                }
            }

            infoField(
                label = "IRD number",
                value = props.donor.taxId?.mask(),
                isValueRequired = true
            )

            sectionDivider()

            infoField(
                label = "Bank account",
                value = props.donor.bankAccountNumber?.mask(),
                isValueRequired = true
            )
        }
    }

    /**
     * Renders an info field that has only one item.
     */
    private fun RBuilder.infoField(label: String,
                                   value: String?,
                                   isValueRequired: Boolean = false) {
        infoField(
            label = label,
            isValueRequired = isValueRequired,
            values = value?.let { listOf(it) } ?: listOf()
        )
    }

    /**
     * Renders an info field with a list of items.
     */
    private fun RBuilder.infoField(label: String,
                                   values: Collection<String>,
                                   isValueRequired: Boolean = false) {
        styledDiv {
            css {
                gridColumn = GridColumn("1 / 2")
            }

            subheading2 { +label }
        }

        styledDiv {
            css {
                gridColumn = GridColumn("2 / 3")

                /*
                 * `overflow: hidden` + `position: relative` stops the elements being scrollable. Without the `relative`
                 * the element is scrollable a little bit. This is especially noticeable on Macs because scrolling is a
                 * little bit "elastic".
                 */
                overflow = Overflow.hidden
                position = Position.relative

                mobileScreen {
                    textAlign = TextAlign.right
                }
            }

            if (isValueRequired && (values.isEmpty() || values.all { it.isBlank() })) {
                // "Required" error message
                body1 {
                    css {
                        display = Display.flex
                        alignItems = Align.center
                        gap = 8.px

                        mobileScreen {
                            float = Float.right
                        }
                    }

                    icon(
                        icon = CoreIcon.WARNING,
                        color = Color.PRIMARY
                    )

                    textError { +"Required" }
                }
            } else {
                values.forEach {
                    body1 {
                        css {
                            /*
                             * `overflow: hidden` + `position: relative` stops the elements being scrollable. Without
                             * the `relative` the element is scrollable a little bit. This is especially noticeable on
                             * Macs because scrolling is a little bit "elastic".
                             */
                            overflow = Overflow.hidden
                            position = Position.relative
                            textOverflow = TextOverflow.ellipsis
                        }

                        +it
                    }
                }
            }
        }
    }

    /**
     * Renders a [dividerHorizontal] spanning the full width of the parent view.
     */
    private fun RBuilder.sectionDivider() {
        styledDiv {
            css {
                gridColumn = GridColumn("1 / 3")

                // override the margin on the divider because the panel uses Gap for spacing
                specific { children { margin(vertical = 0.px) } }
            }

            dividerHorizontal()
        }
    }

}

/**
 * Properties of the [PersonalInfoPanel] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface PersonalInfoPanelProps : Props {

    /**
     * The donor to display info for.
     */
    var donor: Donor

    /**
     * Function called when edit info button is clicked.
     */
    var onEditInfoClick: () -> Unit

}

/**
 * Renders a [PersonalInfoPanel] component.
 */
public fun RBuilder.personalInfoPanel(donor: Donor,
                                      onEditInfoClick: () -> Unit) {
    child(PersonalInfoPanel::class) {
        attrs.donor = donor
        attrs.onEditInfoClick = onEditInfoClick
    }
}