package com.hipsheep.kore.viewmodel.lifecycle

/**
 * Callback used to observe changes in [LiveData] objects.
 */
public typealias Observer<T> = (T) -> Unit

/**
 * Wrapper of an [Observer].
 */
public open class ObserverWrapper<in T>(

    /**
     * [Observer] that will be notified when the [LiveData]'s value changes.
     */
    internal val observer: Observer<T>

) {

    /**
     * `true` if the [Observer] is active, or `false` otherwise.
     *
     * This value should be overridden in the classes that extend this one.
     */
    internal open val isActive = false

}