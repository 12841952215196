package com.hipsheep.kore.model.network

/**
 * Enum that lists the HTTP methods supported in [Request].
 */
public enum class HttpMethod {

    GET,
    POST,
    PUT,
    PATCH,
    DELETE,
    HEAD,
    OPTIONS

}