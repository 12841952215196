package supergenerous.app.core.search.typesense

import com.hipsheep.kore.model.network.HttpMethod.GET
import com.hipsheep.kore.model.network.HttpService
import com.hipsheep.kore.model.network.HttpStatusCode.INTERNAL_SERVER_ERROR
import com.hipsheep.kore.model.network.Response
import com.hipsheep.kore.model.network.sendRequest
import com.hipsheep.kore.util.toList
import supergenerous.app.core.search.model.SearchCollection
import supergenerous.app.core.search.model.SearchField
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.core.util.EnvConfig

/**
 * Service used to perform full-text searches.
 */
public abstract class TypesenseService<T>(

    /**
     * The base URL to query.
     */
    urlBase: String = EnvConfig.typesenseUrl!!,

    /**
     * The API key to use in queries.
     */
    private val apiKey: String = EnvConfig.typesenseApiKey!!,

    /**
     * The collection to be queried.
     */
    collection: SearchCollection

) : HttpService() {

    /**
     * The URL of the collection to be queried.
     */
    private val urlCollection: String = "$urlBase/collections/${collection.value}"

    /**
     * Searches the server for documents that contain [query] in any of the [queryFields]. Optionally [filters] can be
     * provided to narrow the search further.
     */
    protected suspend fun search(queryFields: Set<SearchField>,
                                 query: String,
                                 filters: Map<SearchField, String> = emptyMap()): Response<List<TextSearchResult<T>>> {
        return try {
            val queryParams = buildMap {
                put("q", query)
                put("query_by", queryFields.joinToString(separator = ",") { it.value })

                if (filters.isNotEmpty()) {
                    // Can only filter on "faceted" fields, so add the field name as a facet
                    put("facet_by", filters.keys.joinToString(separator = ",") { it.value })
                    // Filter by strictly equals operator (":=")
                    put("filter_by", filters.entries.joinToString(separator = ",") { "${it.key.value}:=${it.value}" })
                }

                // Typesense will by default omit the last words of a search if it narrows the result too much, adding this
                // parameter disables that (see https://typesense.org/docs/0.23.0/api/search.html#typo-tolerance-parameters)
                put("drop_tokens_threshold", "0")
            }

            val response = httpClient.sendRequest<TypesenseRespBody>(httpMethod = GET,
                                                                     url = "$urlCollection/documents/search",
                                                                     queryParams = queryParams,
                                                                     headers = mapOf(HEADER_KEY_API_KEY toList apiKey))

            val searchResults = response.body?.hits?.map { hit ->
                val highlightFirst = hit.highlights.first()

                // Only one of snippet or snippets should be populated, so use whichever is not null
                val snippet = highlightFirst.snippet
                        ?: highlightFirst.snippets!!.first()

                // Remove the "mark" tags from the text
                val textWhole = snippet
                        .replace("<mark>", "")
                        .replace("</mark>", "")

                // If there is a space in the search term it looks like:
                // "result <mark>with</mark> <mark>two</mark> matches"
                // So get the text between the first <mark> and the last </mark> and remove the rest
                val textMatched = snippet
                        .substringAfter("<mark>")
                        .substringBeforeLast("</mark>")
                        .replace("<mark>", "")
                        .replace("</mark>", "")

                TextSearchResult(id = hit.document.id,
                                 text = textWhole,
                                 textMatch = textMatched,
                                 data = convertDocToData(hit.document))
            }

            Response(statusCode = response.statusCode,
                     message = response.message,
                     headers = response.headers,
                     body = searchResults,
                     errorBody = response.errorBody)
        } catch (e: Throwable) {
            Response(statusCode = INTERNAL_SERVER_ERROR,
                     errorBody = e.stackTraceToString())
        }
    }

    /**
     * Converts the typesense document to an internal data type.
     */
    protected abstract fun convertDocToData(doc: TypesenseDonee): T

    /*
     * Inner types
     */

    private companion object {

        /**
         * The key in the request header for the API key.
         */
        private const val HEADER_KEY_API_KEY: String = "X-TYPESENSE-API-KEY"

    }

}