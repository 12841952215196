package com.supergenerous.common.donor

import com.supergenerous.common.data.Dto
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeBasicInfo
import kotlinx.serialization.Serializable

/**
 * Information about a [Donor]'s profile inside a [Donee] system.
 */
@Serializable
public data class DoneeDonorInfo(

    /**
     * The [Donee] the [Donor] donates to.
     */
    val donee: DoneeBasicInfo,

    /**
     * The donor's ID in the [donee]'s system.
     */
    val donorExtId: String

) : Dto<DoneeDonorInfoDbo> {

    override fun toDbo(): DoneeDonorInfoDbo {
        return DoneeDonorInfoDbo(donee = donee.toDbo(), donorExtId = donorExtId)
    }

}