package supergenerous.app.core.auth.model

import com.supergenerous.common.user.User

/**
 * Data passed between components when a successful authentication occurs.
 *
 * @author Murray kinsman (murray@supergenerous.com)
 */
public data class AuthSuccess(

    /**
     * [User] that signed-in/up into the app.
     */
    val user: User,

    /**
     * Referral code provided by the [user] if they are signing-up, otherwise `null`.
     */
    val referralCode: String?

)