package supergenerous.app.core.util.format

/**
 * Formatter used to parse NZ IRD numbers
 *
 * This might be used in a place where the user is inputting an IRD number, because as they are typing it in
 * it is not a complete NZ IRD number. This is opinionated and it will add hyphens in the format '##-###-###'
 * until 9 digits is reached, then it will format it to '###-###-###'.
 */
public class IrdNumberFormatter : TextFormatter(format = "^[0-9]{0,3}-?[0-9]{0,3}-?[0-9]{0,3}$") {

    /**
     * Formats the [value] of any length into IRD Number format.
     *
     * Any hyphens (`-`) and spaces (` `) are stripped out.
     * Truncates to a max of 9 digits
     * If the number is less than 8 digits long it will return a string formatted as best as possible
     * * formatIrdNumber("12") -> "12"
     * * formatIrdNumber("12-3-") -> "12-3-" (hmmm.. maybe not so good) // TODO improve formatting in this case
     * * formatIrdNumber("12-345-") -> "12-345-" (hyphens on the end are left where they are)
     * * formatIrdNumber("12-345 ") -> "12-345-" (spaces on the end are converted to hyphens)
     * * formatIrdNumber("12345678") -> "12-345-678"
     * * formatIrdNumber("123456789") -> "123-456-789"
     * * formatIrdNumber("123456789123") -> "123-456-789"
     *
     * @return a string in the format ##-###-### if less than 9 digits,
     * otherwise a string in the format ###-###-###.
     */
    override fun format(value: String): String {
        val shouldEndWithHyphen = value.endsWith("-") || value.endsWith(" ")
        val cleanedNumber = value.replace("-", "").replace(" ", "")
        // if less than 9 digits then the first hyphen should be after the second digit
        val firstHyphenIndex = if (cleanedNumber.length < 9) 2 else 3
        val secondHyphenIndex = firstHyphenIndex + 3
        // split the given string up into the 3 sections of an IRD number
        return listOf(cleanedNumber.substring(0, firstHyphenIndex),
                      cleanedNumber.substring(firstHyphenIndex, secondHyphenIndex),
                      // truncates end to 3 characters long
                      cleanedNumber.substring(secondHyphenIndex, secondHyphenIndex + 3))
                // filter out any substrings that are empty so we don't add extra hyphens
                .filter { it.isNotBlank() }
                .joinToString("-", postfix = if (shouldEndWithHyphen) "-" else "")
    }
}