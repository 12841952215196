package com.supergenerous.common.util

import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

/**
 * Returns the tax year that the [LocalDate] belongs to.
 *
 * If the date is between January 1st and March 31st then the tax year is the same year as the date, otherwise it's the
 * following year.
 */
public val LocalDate.taxYear: Int
    get() = if (monthNumber <= 3) year else year + 1

/**
 * `true` if the date is yesterday or earlier, or `false` if it is today or later.
 */
public val LocalDate.isExpired: Boolean
    get() = this < LocalDate.now()

/**
 * Converts the [LocalDate] to a `String` using the current locale format and returns it.
 */
// TODO: Change format according to locale
public fun LocalDate.toLocaleString(): String {
    val isoDate = toString().split("-")
    return "${isoDate[2]}/${isoDate[1]}/${isoDate[0]}"
}

/**
 * Returns the next [LocalDate] for the [dayOfMonth].
 *
 * If the [dayOfMonth] is equal to the day in the [LocalDate] then the same date is returned.
 *
 * E.g., if [dayOfMonth] is 20 and the [LocalDate] is 10/06/2021 then it'll return 20/06/2021, but if the [LocalDate] is
 * 22/06/2021 then it'll return 20/07/2021.
 */
public fun LocalDate.next(dayOfMonth: Int): LocalDate {
    val (month, year) = when {
        // Reset month to January and add 1 year if the date is on December
        this.dayOfMonth > dayOfMonth && this.monthNumber == 12 -> Pair(1, this.year + 1)
        this.dayOfMonth > dayOfMonth -> Pair(this.monthNumber + 1, this.year)
        else -> Pair(this.monthNumber, this.year)
    }

    return LocalDate(year = year, monthNumber = month, dayOfMonth = dayOfMonth)
}

/**
 * Creates a [LocalDate] for the current date and returns it.
 */
// TODO: Check if we need to send a TimeZone to this function
public fun LocalDate.Companion.now(): LocalDate {
    return Clock.System.now().toLocalDateTime(TimeZone.of(zoneId = "NZ")).date
}