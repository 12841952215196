package com.supergenerous.common.donation.request

import com.supergenerous.common.data.Dbo
import com.supergenerous.common.data.DboDataModel
import com.supergenerous.common.donor.DonorBasicInfoDbo
import com.supergenerous.common.organisation.Organisation
import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [DonationRequest] class.
 */
@Serializable
public data class DonationRequestDbo(

    override var id: String? = null,
    override var creationTimestamp: Timestamp? = null,
    override var lastUpdateTimestamp: Timestamp? = null,

    public val donor: DonorBasicInfoDbo? = null,

    public val organisation: String? = null,

    public val taxYears: List<Int>? = null,

    public val timestampSent: Timestamp? = null,

    public val timestampRemindersSent: List<Timestamp>? = null,

    public val timestampClosed: Timestamp? = null,

    public val sent: Boolean? = null,

    public val answered: Boolean? = null,

    /*
     * Database-only fields
     */

    /**
     * Organisation ID (ID if donee, or the name of the donation platform) for searching on the DB.
     */
    public val orgId: String? = null,

    /**
     * `true` if the request is considered "open" (i.e., the [timestampClosed] is `null`), or `false` if it's "closed".
     *
     * This value is only used for searching on the DB.
     */
    public val open: Boolean? = null

) : DboDataModel<DonationRequest> {

    override fun toDto(): DonationRequest {
        return DonationRequest(id = id!!,
                               creationTimestamp = creationTimestamp!!,
                               lastUpdateTimestamp = lastUpdateTimestamp!!,
                               donor = donor!!.toDto(),
                               organisation = Organisation.fromDbo(organisation!!),
                               taxYears = taxYears!!.toSet(),
                               timestampSent = timestampSent,
                               timestampRemindersSent = timestampRemindersSent ?: emptyList(),
                               timestampClosed = timestampClosed,
                               isSent = sent!!,
                               isAnswered = answered!!)
    }

}