package com.supergenerous.common.donee

import com.supergenerous.common.data.Dbo
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [DoneeBasicInfo] class.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class DoneeBasicInfoDbo(

    val id: String? = null,

    val type: Donee.Type? = null,

    val name: String? = null

) : Dbo<DoneeBasicInfo> {

    override fun toDto(): DoneeBasicInfo {
        return DoneeBasicInfo(id = id ?: "", type = type!!, name = name!!)
    }

}