package com.supergenerous.common.firebase

import com.supergenerous.common.user.User
import firebase.auth.Auth

// This class can't be a typealias because firebase.auth.Auth is an interface and FirebaseAuth is a class
public actual class FirebaseAuth(

    public val auth: Auth

)

internal actual val FirebaseAuth.user: User?
    get() = auth.currentUser?.toSgUser()