package com.supergenerous.common.donation

import com.supergenerous.common.data.DtoDataModel
import com.supergenerous.common.donation.platform.DonationPlatform
import com.supergenerous.common.donation.receipt.ReceiptId
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeBasicInfo
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.donor.DonorBasicInfo
import com.supergenerous.common.file.CloudFile
import com.supergenerous.common.rebate.RebateClaimer
import com.supergenerous.common.rebate.RebateClaimer.*
import com.supergenerous.common.serialization.LocalDateSerializerString
import com.supergenerous.common.tax.region.TaxRegion
import com.supergenerous.common.util.Timestamp
import com.supergenerous.common.util.round
import com.supergenerous.common.util.sumByFloat
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

/**
 * Donation made from a [Donor] to a [Donee].
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class Donation(

    override val id: String,

    override val creationTimestamp: Timestamp = 0,

    override val lastUpdateTimestamp: Timestamp = 0,

    /**
     * Donor that made the donation.
     */
    var donor: DonorBasicInfo,

    /**
     * Donee organisation that received the donation.
     */
    var donee: DoneeBasicInfo,

    /**
     * [DonationPlatform] through which the [Donation] was made, or `null` if it was made directly to the [donee].
     */
    var donationPlatform: DonationPlatform?,

    /**
     * Amount of money donated.
     */
    var amount: Float,

    /**
     * Date at which the donation was received by the [donee].
     */
    @Serializable(LocalDateSerializerString::class)
    var date: LocalDate,

    /**
     * `true` if the donation is recurring (e.g., the donor donates every month), or `false` if it's a one-off donation.
     *
     * This value is `null` when it's not known whether the donation is recurring or not.
     */
    val isRecurring: Boolean?,

    /**
     * Receipt file created by the [donee] for this donation.
     *
     * Can be `null` when the [Donation] is obtained via a method that does not provide a receipt (e.g., directly from
     * the donee's CRM), or for [Donation]s added before we stored receipts as [CloudFile]s (i.e., any donation before
     * Jan 2021).
     */
    val receipt: CloudFile?,

    /**
     * ID of the [receipt].
     *
     * This value is `null` when the receipt was added manually and hence no receipt ID was set.
     */
    val receiptId: ReceiptId?,

    /**
     * `true` if the [Donation] details were verified by someone in the SG team, or `false` otherwise.
     */
    var isVerified: Boolean,

    /**
     * `true` if the [Donation] is "valid" and hence a rebate can be claimed for it, or `false` if it's "invalid" and a
     * rebate should not be claimed for it.
     *
     * Donations can be invalid for different reasons, e.g., if they were refunded to the [donor] for some reason.
     */
    val isValid: Boolean = true,

    /**
     * Comments added by SG Team to the [Donation], or `null` if there are none.
     *
     * Comments can be added for different reasons, e.g., to explain why a donation is invalid.
     */
    val comments: String? = null,

    /**
     * Person or organisation that claimed the rebate for this [Donation].
     */
    var rebateClaimer: RebateClaimer,

    /**
     * Whether the [Donation] was obtained directly from the [donee] CRM and hence no [receipt] is necessary.
     */
    val isImportedFromCrm: Boolean = false

) : DtoDataModel<DonationDbo> {

    override fun toDbo(): DonationDbo {
        return DonationDbo(id = id,
                           creationTimestamp = creationTimestamp,
                           lastUpdateTimestamp = lastUpdateTimestamp,
                           donor = donor.toDbo(),
                           donee = donee.toDbo(),
                           amount = amount,
                           date = date.toString(),
                           recurring = isRecurring,
                           receipt = receipt?.toDbo(),
                           receiptId = receiptId?.value,
                           donationPlatform = donationPlatform,
                           verified = isVerified,
                           valid = isValid,
                           comments = comments,
                           rebateClaimer = rebateClaimer,
                           importedFromCrm = isImportedFromCrm)
    }

}

/**
 * Expected rebate amount that can be claimed for the [Donation].
 */
public val Donation.rebateAmountExp: Float
    // TODO: Get the rebate claim percent from the donor state
    get() = (amount * TaxRegion.NZ.rebateClaimPercent).round(precision = 2)

/**
 * `true` when the [Donation] can be deleted from the server, or `false` otherwise.
 */
public val Donation.isDeletable: Boolean
    get() = this.rebateClaimer == UNKNOWN || this.rebateClaimer == NONE

/**
 * The total amount of the [Donation]s in the list.
 */
public val Collection<Donation>.totalAmount: Float
    get() = sumByFloat { it.amount }.round(precision = 2)