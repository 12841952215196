package com.hipsheep.kore.model.network

/**
 * Response returned from a backend when a [Request] is sent.
 *
 * This class is used to abstract our code from the specifics of whatever network library we are using.
 */
public data class Response<T>(

    /**
     * Status code returned from the backend (e.g., 200).
     */
    val statusCode: Int,

    /**
     * Message returned from the backend (e.g., "OK").
     */
    val message: String? = null,

    /**
     * Headers of the response.
     */
    val headers: Map<String, List<String>> = emptyMap(),

    /**
     * Body of the response.
     */
    val body: T? = null,

    /**
     * Error body returned when the [Request] failed.
     */
    val errorBody: String? = null

) {

    /**
     * `true` if the request could be processed successfully (i.e., the status code is 2xx),
     * or `false` otherwise.
     */
    val isSuccessful: Boolean = statusCode in 200..299

}