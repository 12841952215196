package supergenerous.app.core.component.progress

import kotlinx.css.LinearDimension
import kotlinx.css.color
import kotlinx.css.px
import materialui.progress.MuiCircularProgress
import react.RBuilder
import styled.css
import styled.styled
import supergenerous.app.core.res.Color

/**
 * Creates a Loading Circle component.
 */
public fun RBuilder.loadingCircle(color: Color? = null,
                                  size: LinearDimension = 24.px) {
    @Suppress("DEPRECATION")
    styled(MuiCircularProgress)() {
        css {
            specific {
                this.color = color?.cssValue ?: kotlinx.css.Color.inherit
            }
        }

        attrs.size = size.value
    }
}