package com.supergenerous.common.file

import com.supergenerous.common.data.Dto
import com.supergenerous.common.util.FileBucket
import kotlinx.serialization.Serializable

/**
 * A file that is stored on the cloud.
 */
@Serializable
public data class CloudFile(

    /**
     * The display name of the [CloudFile].
     *
     * This does not have to match the filename that exists in [path].
     */
    val name: String,

    /**
     * Bucket where the file is stored.
     */
    // TODO: Remove default value once all files in the DB have a bucket value
    val bucket: FileBucket = FileBucket.ADMIN,

    /**
     * The path of the [CloudFile], including the file name.
     */
    val path: String

) : Dto<CloudFileDbo> {

    override fun toDbo(): CloudFileDbo {
        return CloudFileDbo(name = name,
                            bucket = bucket,
                            path = path)
    }

}