package supergenerous.app.core.util

/**
 * List of keyboard keys used in the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class KeyboardKey(

    /**
     * Value that represents the key in the DOM.
     */
    internal val value: String

) {

    ENTER("Enter")

}