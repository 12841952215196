package com.supergenerous.common.donor

import com.supergenerous.common.data.Dbo
import com.supergenerous.common.util.LinkStatus
import kotlinx.serialization.Serializable

/**
 * [Dbo] associated with [DonationPlatformLinkState].
 *
 * @author Raymond Feng (raymond@supergenerous.com)
 */
@Serializable
public data class DonationPlatformLinkStateDbo(

    val donorExtId: String? = null,
    val linkStatus: LinkStatus? = null

) : Dbo<DonationPlatformLinkState> {

    override fun toDto(): DonationPlatformLinkState {
        return DonationPlatformLinkState(donorExtId = donorExtId, linkStatus = linkStatus!!)
    }

}
