package com.supergenerous.common.kindo

import com.hipsheep.kore.model.network.HttpMethod.POST
import com.supergenerous.common.kindo.KindoProps.CHECKSUM
import com.supergenerous.common.kindo.KindoProps.DONEE_EXT_ID
import com.supergenerous.common.kindo.KindoProps.DONOR_EMAIL
import com.supergenerous.common.kindo.KindoProps.DONOR_EXT_ID
import com.supergenerous.common.kindo.KindoProps.DONOR_FIRST_NAME
import com.supergenerous.common.kindo.KindoProps.DONOR_ID_LONG
import com.supergenerous.common.kindo.KindoProps.DONOR_LAST_NAME
import com.supergenerous.common.kindo.KindoProps.IS_SUCCESS
import com.supergenerous.common.kindo.KindoProps.REFERRAL
import com.supergenerous.common.kindo.KindoProps.REFERRAL_ID
import com.supergenerous.common.kindo.KindoProps.REFERRER
import com.supergenerous.common.kindo.KindoProps.RETURN_TYPE
import com.supergenerous.common.kindo.KindoProps.REVERSE_JOIN_ID
import com.supergenerous.common.kindo.KindoProps.TYPE
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * An action sent from Kindo to SG to link a donor between the platforms.
 *
 * This is the body of a [POST] to SG's `/kindo` endpoint to link a donor from Kindo.
 */
@Serializable
public data class KindoDonorLink(

    /**
     * The donor ID on our platform.
     */
    @SerialName(DONOR_ID_LONG)
    val donorId: String,

    /**
     * The details for this referral.
     */
    @SerialName(REFERRAL)
    val referralInfo: List<List<String?>>,

    /**
     * Whether the operation was a success.
     */
    @SerialName(IS_SUCCESS)
    val isSuccess: Boolean,

    /**
     * The unique ID for this operation. Used for troubleshooting with Kindo.
     *
     * This can be `null` when coming from the donor app.
     */
    @SerialName(REVERSE_JOIN_ID)
    val operationId: String?,

    /**
     * The type of operation.
     *
     * This can be `null` when coming from the donor app.
     */
    @SerialName(RETURN_TYPE)
    val operationType: String?

) {

    public constructor(donorId: String,
                       isSuccess: Boolean,
                       type: String,
                       referrer: String,
                       referralId: String,
                       doneeExtId: String,
                       donorExtId: String,
                       donorEmail: String,
                       donorFirstName: String,
                       donorLastName: String,
                       checksum: String) : this(donorId = donorId,
                                                operationId = null,
                                                operationType = null,
                                                isSuccess = isSuccess,
                                                referralInfo = listOf(listOf(TYPE, type),
                                                                      listOf(REFERRER, referrer),
                                                                      listOf(REFERRAL_ID, referralId),
                                                                      listOf(DONEE_EXT_ID, doneeExtId),
                                                                      listOf(DONOR_EXT_ID, donorExtId),
                                                                      listOf(DONOR_EMAIL, donorEmail),
                                                                      listOf(DONOR_FIRST_NAME, donorFirstName),
                                                                      listOf(DONOR_LAST_NAME, donorLastName),
                                                                      listOf(CHECKSUM, checksum)))

    public val type: String?
        get() = getReferralValue(TYPE)

    public val referrer: String?
        get() = getReferralValue(REFERRER)

    public val referralId: String?
        get() = getReferralValue(REFERRAL_ID)

    public val doneeExtId: String?
        get() = getReferralValue(DONEE_EXT_ID)

    public val donorExtId: String?
        get() = getReferralValue(DONOR_EXT_ID)

    public val donorEmail: String?
        get() = getReferralValue(DONOR_EMAIL)

    public val donorFirstName: String?
        get() = getReferralValue(DONOR_FIRST_NAME)

    public val donorLastName: String?
        get() = getReferralValue(DONOR_LAST_NAME)

    public val checksum: String?
        get() = getReferralValue(CHECKSUM)

    /**
     * Finds the value associated with [key] in [referralInfo].
     */
    private fun getReferralValue(key: String): String? {
        // Each "pair" is actually stored as a list of 2 values (we have to do this as it's how Kindo formats this data
        // and modeling as a Pair doesn't work)
        return referralInfo.firstOrNull { pair -> pair[0] == key }?.get(1)
    }

}