package supergenerous.app.donor.dashboard.view

import com.hipsheep.kore.error.ErrorType
import com.hipsheep.kore.util.capitalizeWords
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeBasicInfo
import com.supergenerous.common.donor.Donor
import kotlinx.css.Display
import kotlinx.css.FlexBasis
import kotlinx.css.FlexDirection
import kotlinx.css.FlexWrap
import kotlinx.css.LinearDimension
import kotlinx.css.display
import kotlinx.css.flexBasis
import kotlinx.css.flexDirection
import kotlinx.css.flexGrow
import kotlinx.css.flexShrink
import kotlinx.css.flexWrap
import kotlinx.css.gap
import kotlinx.css.height
import kotlinx.css.marginTop
import kotlinx.css.minWidth
import kotlinx.css.paddingLeft
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.dom.key
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.common.valuePlural
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.button.ButtonType.*
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.contentSection
import supergenerous.app.core.component.heading1
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.core.util.mobileScreen

/**
 * Component that allows the user to select the [Donee]s they donated to.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
@JsExport
private class OrgSelectionPanel : RComponent<OrgSelectionPanelProps, OrgSelectionPanelState>() {

    /**
     * List of all the [Donee.Type]s shown on the screen.
     */
    private val doneeTypes = Donee.Type.values()


    override fun RBuilder.render() {
        contentSection {
            styledDiv {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    gap = 24.px
                }

                heading1 { +"My organisations" }
                body1 { +"You can add organisations you are currently donating to and those that you’ve donated to in the last 4 years." }

                // Org type selection buttons
                orgTypeTabs()
            }
        }

        state.doneeTypeExpanded?.let { doneeType ->
            styledDiv {
                css {
                    paddingLeft = 90.px
                    marginTop = 40.px

                    mobileScreen {
                        paddingLeft = 0.px
                        marginTop = 16.px
                    }
                }

                // Override the attrs.key every time because otherwise the doneeSelectionPanel() will retain its current
                // state when switching between donee types.
                attrs.key = doneeType.name

                doneeSelectionPanel(
                    donees = props.donor.donees.filter { it.type == doneeType }.toSet(),
                    doneeType = doneeType,
                    disbursementRecipientType = props.donor.disbursementSettings[doneeType],
                    childrenNames = props.donor.childrenNames,
                    isSavingData = props.isSavingData,
                    errors = props.errors,
                    onDoneesChange = { donees, disbRecipientType, childrenNames ->
                        props.onDoneesChange(donees, doneeType, disbRecipientType, childrenNames)
                    },
                    doneeSearchResults = props.doneeSearchResults,
                    onDoneeSearch = props.onDoneeSearch
                )
            }
        }
    }

    /**
     * Renders the organisation type tabs that can be selected by the user.
     */
    private fun RBuilder.orgTypeTabs() {
        styledDiv {
            css {
                width = 100.pct

                display = Display.flex
                flexWrap = FlexWrap.wrap
                gap = 24.px

                specific {
                    children("button") {
                        minWidth = LinearDimension.minContent
                        width = 100.pct
                        height = 84.px

                        flexGrow = 1.0
                        flexShrink = 1.0
                        flexBasis = FlexBasis(30.pct.value)

                        media(query = "only screen and (max-width: 1179px)") {
                            // Make buttons try to take up all the available width. This means that we won't have 2
                            // buttons taking half width and one button taking full width when it wraps.
                            flexBasis = FlexBasis(100.pct.value)
                        }
                    }
                }

                mobileScreen {
                    flexDirection = FlexDirection.column
                    flexWrap = FlexWrap.nowrap

                    specific {
                        children("button") {
                            width = 100.pct
                            height = 44.px
                        }
                    }
                }
            }

            doneeTypes.forEach { doneeType ->
                button(
                    label = doneeType.valuePlural.capitalizeWords(),
                    type = if (state.doneeTypeExpanded == doneeType) PRIMARY else SECONDARY,
                    onClick = {
                        if (doneeType != state.doneeTypeExpanded) {
                            // Reset search results when switching between org types
                            props.onDoneeSearch("", doneeType)

                            setState { doneeTypeExpanded = doneeType }
                        }
                    }
                )
            }
        }
    }

}

/**
 * Properties of the [OrgSelectionPanel] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface OrgSelectionPanelProps : Props {

    /**
     * The donor that contains the info that will be displayed.
     */
    var donor: Donor

    /**
     * Function called when the donees are changed.
     */
    var onDoneesChange: (Set<DoneeBasicInfo>, Donee.Type, DisbursementRecipient.Type?, childrenNames: Set<String>) -> Unit

    /**
     * The latest errors to have occurred.
     */
    var errors: Set<ErrorType>?

    /**
     * `true` if the saving action is occurring. `false` otherwise.
     */
    var isSavingData: Boolean

    /**
     * List of donees returned from the donor's search.
     */
    var doneeSearchResults: List<TextSearchResult<DoneeBasicInfo>>

    /**
     * Function performed when the donor searches for a donee.
     */
    var onDoneeSearch: (namePartial: String, type: Donee.Type) -> Unit

}

/**
 * State of the [OrgSelectionPanel] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface OrgSelectionPanelState : State {

    /**
     * The donee type panel that is currently being shown.
     */
    var doneeTypeExpanded: Donee.Type?

}

/**
 * Renders a [OrgSelectionPanel] component.
 */
public fun RBuilder.orgSelectionPanel(donor: Donor,
                                      isSavingData: Boolean,
                                      errors: Set<ErrorType>?,
                                      onDoneesChange: (Set<DoneeBasicInfo>, Donee.Type, DisbursementRecipient.Type?, childrenNames: Set<String>) -> Unit,
                                      doneeSearchResults: List<TextSearchResult<DoneeBasicInfo>>,
                                      onDoneeSearch: (namePartial: String, doneeType: Donee.Type) -> Unit) {
    child(OrgSelectionPanel::class) {
        attrs.donor = donor
        attrs.isSavingData = isSavingData
        attrs.errors = errors
        attrs.onDoneesChange = onDoneesChange
        attrs.doneeSearchResults = doneeSearchResults
        attrs.onDoneeSearch = onDoneeSearch
    }
}