package com.supergenerous.common.firebase

import com.supergenerous.common.user.User
import kotlin.js.Date

/**
 * Converts a Firebase user object to an instance of the [User] we use in the SG system and returns it.
 */
public fun firebase.User.toSgUser(): User {
    // Convert date received as string from Firebase (format: Tue, 07 Apr 2020 02:26:04 GMT) to ms since epoch
    val signUpDateMs = this.metadata.creationTime?.let { Date(it).getTime().toLong() } ?: 0

    return User(id = uid,
                email = email!!,
                name = displayName ?: "",
                profileImgUrl = photoURL,
                signUpDate = signUpDateMs)
}