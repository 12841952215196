package com.hipsheep.kore.model.repo

import com.hipsheep.kore.coroutine.CoreIO
import com.hipsheep.kore.error.ErrorType.HttpError
import com.hipsheep.kore.model.network.Response
import com.hipsheep.kore.resource.Resource
import com.hipsheep.kore.resource.Resource.*
import com.hipsheep.kore.util.Logger
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.InternalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map

/**
 * Base class for all repositories used to manage the app's data.
 */
@Suppress("unused")
public abstract class Repository {

    /*
     * Backend access only
     */

    /**
     * Same as the [getData] method below, but for backend requests that return the same type of object that will be
     * returned by the method and the default error is thrown.
     *
     * @throws Exception if the response from the server is not successful.
     */
    @Throws(Exception::class)
    @Suppress("MemberVisibilityCanBePrivate")
    protected suspend fun <ResultType> getData(getServerData: suspend () -> Response<ResultType>): ResultType {
        return getData(getServerData, convertResponseToResult = { it }, getError = null)
    }

    /**
     * Same as the [getData] method below, but for backend requests that return the same type of object that will be
     * returned by the method.
     *
     * @throws Exception if the response from the server is not successful.
     */
    @Throws(Exception::class)
    @Suppress("MemberVisibilityCanBePrivate")
    protected suspend fun <ResultType> getData(getServerData: suspend () -> Response<ResultType>,
                                               getError: ((Response<ResultType>) -> Exception)? = null): ResultType {
        return getData(getServerData, convertResponseToResult = { it }, getError = getError)
    }

    /**
     * Executes [getServerData], converts the [Response] body received using [convertResponseToResult] and returns it,
     * or throws the [Exception] returned from [getError] if [Response.isSuccessful] is `false`.
     *
     * @param ResultType Type of data that will be returned.
     * @param ResponseType Type of the object returned from the backend.
     *
     * @param getServerData Function called to get the data from a server.
     * @param convertResponseToResult Function called to convert the response object to a result one (if they are
     * different).
     * @param getError Function called to get the [Exception] to thrown when the [Response] returned from
     * [getServerData] is not successful.
     *
     * @throws Exception if the response from the server is not successful.
     */
    @Throws(Exception::class)
    @Suppress("MemberVisibilityCanBePrivate")
    protected suspend fun <ResultType, ResponseType> getData(getServerData: suspend () -> Response<ResponseType>,
                                                             convertResponseToResult: (ResponseType) -> ResultType,
                                                             getError: ((Response<ResponseType>) -> Exception)? = null): ResultType {
        val response = getServerData()

        if (!response.isSuccessful) {
            throw getError?.invoke(response) ?: RuntimeException("Error: $response")
        }

        return convertResponseToResult(response.body!!)
    }

    /**
     * Same as the [getResource] method below, but for backend requests that return the same type of object
     * that will be returned by the method.
     */
    @Suppress("MemberVisibilityCanBePrivate")
    protected suspend fun <ResultType> getResource(getServerData: suspend () -> Response<ResultType>): Resource<ResultType> {
        return getResource(getServerData = getServerData,
                           convertRespToResult = { it })
    }

    /**
     * Executes [getServerData], converts the [Response] body received using [convertRespToResult],
     * wraps it inside a [Resource] and returns it.
     *
     * @param ResultType Type of the [Resource] data that will be returned.
     * @param ResponseType Type of the object returned from the backend.
     *
     * @param getServerData Function called to get the data from a server.
     * @param convertRespToResult Function called to convert the response object to a result one (if they are
     * different).
     */
    @Suppress("MemberVisibilityCanBePrivate")
    protected suspend fun <ResultType, ResponseType> getResource(getServerData: suspend () -> Response<ResponseType>,
                                                                 convertRespToResult: (ResponseType) -> ResultType): Resource<ResultType> {
        return getServerData().toResource(convertRespToResult)
    }

    /**
     * Same as the [loadResource] method below, but for backend requests that return the same type of object
     * that will be loaded to the [Flow] returned.
     */
    @Suppress("MemberVisibilityCanBePrivate")
    protected fun <ResultType> loadResource(getServerData: suspend () -> Response<ResultType>): Flow<Resource<ResultType>> {
        return loadResource(getServerData = getServerData,
                            convertRespToResult = { it })
    }

    /**
     * Loads a backend resource into the [Flow] returned.
     *
     * @param ResultType Type of the [Resource] data that will be loaded into the [Flow].
     * @param ResponseType Type of the object returned from the backend.
     *
     * @param getServerData Function called to get the data from a server.
     * @param convertRespToResult Function called to convert the response object to a result one (if they are
     * different).
     *
     * @return [Flow] where the backend resource will be loaded.
     */
    @Suppress("MemberVisibilityCanBePrivate")
    protected fun <ResultType, ResponseType> loadResource(getServerData: suspend () -> Response<ResponseType>,
                                                          convertRespToResult: (ResponseType) -> ResultType): Flow<Resource<ResultType>> = flow<Resource<ResultType>> {
        // Set the resource as "loading"
        emit(Loading())

        val result = getServerData().toResource(convertRespToResult)

        emit(result)
    }.flowOn(Dispatchers.CoreIO)

    /**
     * Same as the [loadResource] method below, but for backend requests that return the same type of object that will
     * be loaded to the [Flow] returned.
     */
    @Suppress("MemberVisibilityCanBePrivate")
    protected fun <ResultType> loadResource(loadServerData: () -> Flow<Response<ResultType>>): Flow<Resource<ResultType>> {
        return loadResource(loadServerData = loadServerData,
                            convertRespToResult = { it })
    }

    /**
     * Loads a backend resource into the [Flow] returned and receives any updates to it until the flow is closed.
     *
     * The difference between this method and the `loadResource(getServerData)` one above is that this one keeps getting
     * updates to the data from the [Flow] returned by [loadServerData], while the other one only receives one value
     * from `getServerData` and then stops.
     *
     * This method is supposed to be used with continuous data transfer protocols like `WebSocket`s or using libraries
     * that allow this type of transfer like the Firestore SDK.
     *
     * @param ResultType Type of the [Resource] data that will be loaded into the [Flow].
     * @param ResponseType Type of the object returned from the backend.
     *
     * @param loadServerData Function called to load the data from a server.
     * @param convertRespToResult Function called to convert the response object to a result one (if they are
     * different).
     *
     * @return [Flow] where the backend resource will be loaded.
     */
    @Suppress("MemberVisibilityCanBePrivate")
    protected fun <ResultType, ResponseType> loadResource(loadServerData: () -> Flow<Response<ResponseType>>,
                                                          convertRespToResult: (ResponseType) -> ResultType): Flow<Resource<ResultType>> {
        return loadServerData().map { resp -> resp.toResource(convertRespToResult) }
    }

    /*
     * Backend and DB access
     */

    /**
     * Loads a backend resource into the local DB, which in turn updates the [Flow] returned.
     *
     * This method will usually be called for `GET` requests.
     *
     * @param ResultType Type of the [Resource] data that will be loaded into the [Flow].
     * @param ResponseType Type of the object returned from the backend.
     *
     * @param loadFromDB Function that loads the DB data to a [Flow].
     * @param shouldUpdateFromBackend Function that returns `true` if the data should be updated from the backend, or
     * `false` if it shouldn't.
     * @param getServerData Function called to get the data from a server.
     * @param updateDB Function that saves the data returned from the backend to the local DB.
     *
     * @return [Flow] where the backend resource that is saved to the local DB will be loaded.
     */
    @InternalCoroutinesApi
    protected fun <ResultType, ResponseType> loadResourceToDB(loadFromDB: () -> Flow<ResultType>,
                                                              shouldUpdateFromBackend: (ResultType) -> Boolean,
                                                              getServerData: suspend () -> Response<ResponseType>,
                                                              updateDB: suspend (ResponseType) -> Unit): Flow<Resource<ResultType>> = flow<Resource<ResultType>> {
        // Set the resource as "loading"
        emit(Loading())

        // Get data from the DB (if any)
        // TODO: Should this be firstOrNull()?
        val dbData = loadFromDB().first()

        if (shouldUpdateFromBackend(dbData)) {
            // Set the status as "loading" and the DB data as interim data to show while the updated data from the
            // backend is fetched
            emit(Loading(dbData))

            // If the data should be updated, fetch it from the backend and update the DB
            val response = getServerData()

            if (response.isSuccessful) {
                response.body?.let { updateDB(it) }
            } else {
                // If the fetch failed then notify that an error occurred
                emit(createErrorResource(response))
            }
        }

        // Observe changes in the DB data and propagate them to the returned flow
        loadFromDB().collect { newData ->
            emit(Success(newData))
        }
    }.flowOn(Dispatchers.CoreIO)

    /**
     * Saves or deletes data to/from the backend and updates the local DB if the action was successful, or returns an
     * error through the [Flow] if it failed.
     *
     * This method will usually be called for `POST` and `PUT` requests.
     *
     * @param ResultType Type of the [Resource] data that will be loaded into the [Flow].
     * @param ResponseType Type of the object returned from the backend.
     *
     * @param getServerData Function called to get the data from a server.
     * @param updateDB Function that saves the data returned from the backend to the local DB.
     *
     * @return [Flow] where the backend resource that is saved to the local DB will be loaded.
     */
    protected fun <ResultType, ResponseType> updateResourceToDB(getServerData: suspend () -> Response<ResponseType>,
                                                                updateDB: suspend (ResponseType) -> Unit): Flow<Resource<Unit>> = flow<Resource<Unit>> {
        // Set the resource as "loading"
        emit(Loading())

        val response = getServerData()

        if (response.isSuccessful) {
            response.body?.let { updateDB(it) }

            // TODO: Reconsider sending the data back for when we are not saving this on the SQL DB
            // When updating a resource all that matters is whether the action was successful or not, that is
            // why we don't return any data inside the Resource object. The proper way to update the UI is to
            // already be monitoring changes in the DB data before updating it
            emit(Success(Unit))
        } else {
            emit(createErrorResource(response))
        }
    }.flowOn(Dispatchers.CoreIO)

    /**
     * Creates a [Resource] from the [Response] and returns it, converting the data in it using [convertRespToResult].
     */
    private fun <ResultType, ResponseType> Response<ResponseType>.toResource(convertRespToResult: (ResponseType) -> ResultType): Resource<ResultType> {
        return if (this.isSuccessful) {
            Success(data = convertRespToResult(this.body!!))
        } else {
            createErrorResource(this)
        }
    }

    /**
     * Creates a [Resource.Error] using the information in the [response] received and returns it.
     */
    private fun <ResultType, ResponseType> createErrorResource(response: Response<ResponseType>): Resource<ResultType> {
        return Error(HttpError(response.statusCode))
    }

    /*
     * Inner types
     */

    protected companion object : Logger()

}