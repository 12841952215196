package supergenerous.app.donor

import com.hipsheep.kore.util.isNotNullOrBlank
import com.supergenerous.common.network.CoreUrl.Path.*
import react.RBuilder
import react.router.dom.Switch
import react.setState
import supergenerous.app.core.BaseApp
import supergenerous.app.core.BaseAppState
import supergenerous.app.core.analytics.AnalyticsRepository
import supergenerous.app.core.analytics.FirebaseAnalyticsService
import supergenerous.app.core.analytics.screenTracker
import supergenerous.app.core.auth.model.AuthRepository
import supergenerous.app.core.auth.viewmodel.AuthViewModel
import supergenerous.app.core.location.LocationRepository
import supergenerous.app.core.pageNotFoundScreen
import supergenerous.app.core.res.image.CoreImage
import supergenerous.app.core.search.DoneeSearchService
import supergenerous.app.core.util.RouteProps
import supergenerous.app.core.util.redirect
import supergenerous.app.core.util.route
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.aml.view.amlInfoScreen
import supergenerous.app.donor.aml.viewmodel.AmlInfoViewModel
import supergenerous.app.donor.analytics.HubspotAnalyticsService
import supergenerous.app.donor.dashboard.view.dashboardScreen
import supergenerous.app.donor.dashboard.view.personalInfoEditScreen
import supergenerous.app.donor.dashboard.view.personalInfoScreen
import supergenerous.app.donor.dashboard.viewmodel.DashboardViewModel
import supergenerous.app.donor.dashboard.viewmodel.PersonalInfoEditViewModel
import supergenerous.app.donor.dashboard.viewmodel.PersonalInfoViewModel
import supergenerous.app.donor.dashboard.viewmodel.RebateClaimsViewModel
import supergenerous.app.donor.donation.DonationRepository
import supergenerous.app.donor.donation.DonationService
import supergenerous.app.donor.donation.platform.DonationPlatformRepository
import supergenerous.app.donor.donation.request.DonationRequestRepository
import supergenerous.app.donor.donation.request.DonationRequestService
import supergenerous.app.donor.donee.DoneeRepository
import supergenerous.app.donor.donor.DonorLinkService
import supergenerous.app.donor.donor.DonorRepository
import supergenerous.app.donor.donor.DonorService
import supergenerous.app.donor.donor.DonorStateService
import supergenerous.app.donor.kindo.model.KindoService
import supergenerous.app.donor.kindo.view.kindoLinkScreen
import supergenerous.app.donor.kindo.viewmodel.KindoLinkViewModel
import supergenerous.app.donor.rebate.RebateRepository
import supergenerous.app.donor.rebate.RebateService
import supergenerous.app.donor.setup.view.setupContainer
import supergenerous.app.donor.setup.viewmodel.SetupViewModel
import supergenerous.app.donor.util.Url.Path.*
import supergenerous.app.core.auth.view.authScreen as coreAuthScreen

/**
 * Main component that contains the whole app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class App : BaseApp<AppProps, AppState, AppViewModel>() {

    override val splashScreenLogo = CoreImage.SG_LOGO_GREEN

    override val rootPath = ROOT
    override val authPaths = setOf(SIGN_IN, SIGN_UP)

    override var afterAuthPath: String? = null

    private val authRepo = AuthRepository()
    private val donorRepo = DonorRepository(donorService = DonorService(),
                                            donorStateService = DonorStateService(),
                                            donorLinkService = DonorLinkService())
    private val doneeRepo = DoneeRepository(doneeSearchService = DoneeSearchService())
    private val donationPlatformRepo = DonationPlatformRepository(kindoService = KindoService())
    private val donationRepo = DonationRepository(donationService = DonationService())
    private val donationRequestRepo = DonationRequestRepository(donationRequestService = DonationRequestService())
    private val rebateRepo = RebateRepository(rebateService = RebateService())
    private val locationRepo by lazy { LocationRepository(locationApiKey = props.locationApiKey) }
    private val analyticsRepo = AnalyticsRepository(analyticsServices = listOf(FirebaseAnalyticsService(),
                                                                               HubspotAnalyticsService()))

    override val appViewModel by lazy { AppViewModel(authRepo, donorRepo, locationRepo) }
    private val setupViewModel by lazy { SetupViewModel(donorRepo, doneeRepo, locationRepo, analyticsRepo) }
    private val amlInfoViewModel by lazy { AmlInfoViewModel(donorRepo) }
    private val authViewModel by lazy { AuthViewModel(authRepo, analyticsRepo) }


    override fun AppState.initAppState() {
        isDonorDataLoading = false
    }

    override fun RBuilder.onAppLoaded() {
        // Start tracking screens views for analytics
        screenTracker(analyticsRepo)

        Switch {
            route(ROOT, exact = true) { redirect(to = SIGN_IN) }

            route(SIGN_UP) { authScreen(isSignIn = false) }
            route(SIGN_IN) { authScreen(isSignIn = true) }

            route(SETUP) { requireAuth { setupContainer(setupViewModel, props.history) } }

            route(DASHBOARD, exact = true) {
                requireAuth {
                    dashboardScreen(dashboardViewModel = DashboardViewModel(donorRepo,
                                                                            authRepo,
                                                                            doneeRepo,
                                                                            locationRepo),
                                    rebateClaimsViewModel = RebateClaimsViewModel(donorRepo,
                                                                                  donationRepo,
                                                                                  donationRequestRepo,
                                                                                  rebateRepo))
                }
            }
            route(PERSONAL_INFO, exact = true) { requireAuth { personalInfoScreen(PersonalInfoViewModel(donorRepo)) } }
            route(PERSONAL_INFO_EDIT, exact = true) {
                requireAuth {
                    personalInfoEditScreen(PersonalInfoEditViewModel(donorRepo,
                                                                     locationRepo))
                }
            }

            route(AML, exact = true) { requireAuth { amlInfoScreen(viewModel = amlInfoViewModel) } }

            route(LINK_KINDO, exact = true) {
                kindoLinkScreen(KindoLinkViewModel(donorRepo,
                                                   authRepo,
                                                   analyticsRepo,
                                                   donationPlatformRepo,
                                                   locationRepo))
            }

            // Catch-all route to display 404 screen
            route(ROOT, exact = false) { pageNotFoundScreen() }
        }
    }

    /**
     * Renders the auth screen in sign-in/up mode depending on [isSignIn].
     */
    private fun RBuilder.authScreen(isSignIn: Boolean) {
        coreAuthScreen(
            viewModel = authViewModel,
            isSignIn = isSignIn,
            isUserDataLoading = state.isDonorDataLoading,
            leftPanelTitle = "We make claiming donation rebates easy.",
            leftPanelSubtitle = "And regifting them to the charitable organisations you care about? Even easier.",
            resetPasswordText = "Forgot password?",
            enableReferralCode = true
        )
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // Load donor data once the user authenticates successfully
        authViewModel.authSuccessEvent.observeEvent {
            appViewModel.loadOrCreateDonor(user = it.user, referralCode = it.referralCode)
        }

        // Show loading icon on auth button when the donor data is being loaded (this is done to avoid the button in
        // AuthScreen from appearing not loading for a second after the user authenticates)
        appViewModel.isActionInProgress.observe { setState { isDonorDataLoading = it } }

        // When the donor data is loaded then move to the next screen
        appViewModel.donor.observe { donor ->
            // If the user is just trying to navigate to a specific page then send them there
            val urlPath = afterAuthPath
                    ?: if (donor.signature?.value.isNotNullOrBlank()) {
                        /*
                         * If we have the user signed the ATA then we can take them straight to the dashboard.
                         *
                         * We don't use Donor.isSetupComplete here because the tax ID can be provided in the dashboard,
                         * but the signature is only editable via the setup flow.
                         */
                        DASHBOARD.value
                    } else {
                        SETUP.value
                    }

            props.history.push(path = urlPath)
        }
    }

}

/**
 * Properties used by the [App] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface AppProps : RouteProps {

    /**
     * API key used on calls to the location third-party system.
     */
    var locationApiKey: String

}

/**
 * State of the [App] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface AppState : BaseAppState {

    /**
     * `true` if the donor's data is being loaded, or `false` if the loading ended (either successfully or an error
     * occurred).
     */
    var isDonorDataLoading: Boolean

}

/**
 * Renders an [App] component.
 */
public fun RBuilder.app(locationApiKey: String) {
    withRouter(App::class) {
        attrs.locationApiKey = locationApiKey
    }
}