package supergenerous.app.core.util.component

import kotlinx.css.Align
import kotlinx.css.CssBuilder
import kotlinx.css.Display
import kotlinx.css.JustifyContent
import kotlinx.css.Position
import kotlinx.css.alignItems
import kotlinx.css.backgroundImage
import kotlinx.css.backgroundSize
import kotlinx.css.display
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.left
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.position
import kotlinx.css.px
import kotlinx.css.right
import kotlinx.css.top
import kotlinx.css.width
import kotlinx.css.zIndex
import react.RBuilder
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.button.iconButton
import supergenerous.app.core.res.SgLogoColor
import supergenerous.app.core.res.image.CoreIcon
import supergenerous.app.core.res.image.CoreImage
import supergenerous.app.core.util.mobileScreen

/**
 * Creates a toolbar for the top of the screen with the SG logo on it, a back button on the left side (if
 * [onBackBtnClick] is not `null`) and a set of actions on the right side ([actionsRight]).
 *
 * TODO: Make back button work for not-mobile screen sizes
 */
public fun RBuilder.toolbar(onBackBtnClick: (() -> Unit)? = null,
                            actionsRight: (RBuilder.() -> Unit)? = null) {
    styledDiv {
        css {
            height = 150.px
            width = 100.pct

            display = Display.flex
            justifyContent = JustifyContent.flexStart

            // Set the header image and make it stretch to the available width and height
            backgroundImage = CoreImage.SG_HEADER_BAR.cssValue
            backgroundSize = "${100.pct.value} ${100.pct.value}"

            mobileScreen {
                height = 80.px
            }
        }

        // Header content
        styledDiv {
            css {
                verticalPadding()

                padding(horizontal = 24.px)

                height = 100.pct
                width = 100.pct
                position = Position.relative

                mobileScreen {
                    // Set logo on the middle of the header for mobile screens
                    justifyContent = JustifyContent.center
                }
            }

            // Back button
            onBackBtnClick?.let { onBackBtnClick ->
                styledDiv {
                    css {
                        verticalPadding()

                        position = Position.absolute
                        height = 100.pct

                        // Needs higher z-index so it appears above the sgLogo link
                        zIndex = 2

                        display = Display.flex
                        justifyContent = JustifyContent.flexStart
                        alignItems = Align.center

                        top = 0.px
                        left = 16.px
                    }

                    iconButton(
                        icon = CoreIcon.ARROW_BACK,
                        size = 64.px,
                        isOnDarkBackground = true,
                        onClick = onBackBtnClick
                    )
                }
            }

            // SG logo
            styledDiv {
                css {
                    position = Position.relative
                    height = 53.px
                    width = 100.pct

                    display = Display.flex
                    justifyContent = JustifyContent.flexStart

                    mobileScreen {
                        height = 24.px
                        justifyContent = JustifyContent.center
                    }
                }

                styledDiv {
                    css {
                        width = 295.px

                        mobileScreen {
                            width = 134.px
                        }
                    }

                    sgLogo(color = SgLogoColor.WHITE)
                }
            }

            // Right side actions
            actionsRight?.let {
                styledDiv {
                    css {
                        verticalPadding()

                        position = Position.absolute
                        height = 100.pct

                        // Needs higher z-index so it appears above the sgLogo link
                        zIndex = 2

                        display = Display.flex
                        justifyContent = JustifyContent.flexEnd
                        alignItems = Align.center

                        top = 0.px
                        right = 16.px
                    }

                    it()
                }
            }
        }
    }
}

private fun CssBuilder.verticalPadding() {
    padding(top = 40.px, bottom = 57.px)

    mobileScreen {
        padding(top = 24.px, bottom = 32.px)
    }
}