package supergenerous.app.donor.aml.view

import com.hipsheep.kore.error.ErrorType
import com.hipsheep.kore.viewmodel.ViewModel
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.id.IdDocument.*
import com.supergenerous.common.id.IdDocument.Type.*
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.FlexWrap
import kotlinx.css.GridColumn
import kotlinx.css.GridTemplateColumns
import kotlinx.css.GridTemplateRows
import kotlinx.css.JustifyContent
import kotlinx.css.alignItems
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.flexWrap
import kotlinx.css.fr
import kotlinx.css.gap
import kotlinx.css.gridColumn
import kotlinx.css.gridTemplateColumns
import kotlinx.css.gridTemplateRows
import kotlinx.css.justifyContent
import kotlinx.css.margin
import kotlinx.css.marginBottom
import kotlinx.css.marginTop
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import kotlinx.datetime.LocalDate
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.button.ButtonType.PRIMARY
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.contentSection
import supergenerous.app.core.component.dividerHorizontal
import supergenerous.app.core.component.layout.grid
import supergenerous.app.core.component.picker.datePicker
import supergenerous.app.core.component.radio.RadioButton
import supergenerous.app.core.component.radio.radioGroup
import supergenerous.app.core.component.subheading1
import supergenerous.app.core.component.textfield.TextFieldType
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.util.AlignContent
import supergenerous.app.core.util.JustifySelf
import supergenerous.app.core.util.RouteProps
import supergenerous.app.core.util.alignContent
import supergenerous.app.core.util.component.toolbar
import supergenerous.app.core.util.format.TextCapitalization
import supergenerous.app.core.util.justifySelf
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.push
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.aml.viewmodel.AmlInfoViewModel
import supergenerous.app.donor.setup.model.SetupError.*
import supergenerous.app.donor.util.Url.Path.DASHBOARD

/**
 * Screen that allows the donor to enter the info necessary to perform an AML check.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
// TODO: Remove this once the ID verification is working for all donors and everyone that signed up before releasing that went through the process already
private class AmlInfoScreen : LifecycleOwnerComponent<AmlInfoScreenProps, AmlInfoScreenState>() {

    override fun AmlInfoScreenState.init() {
        isSaveInProgress = false
    }

    override fun RBuilder.render() {
        toolbar()

        grid(isCentered = true) {
            styledDiv {
                css {
                    marginTop = 32.px
                    gridColumn = GridColumn("3 / 11")
                    mobileScreen {
                        gridColumn = GridColumn("1 / 3")
                    }
                }

                contentSection {
                    styledDiv {
                        css {
                            width = 100.pct
                            display = Display.inlineGrid
                            alignContent(AlignContent.start)
                        }

                        subheading1 {
                            css {
                                margin(top = 16.px)
                            }

                            +"AML Check"
                        }

                        body1 {
                            css {
                                margin(top = 8.px)
                            }

                            +"Well done on being one of our most generous donors! Your security is our priority, so we now need to verify your identification. Just select one of the below options and fill out the required information."
                        }

                        dividerHorizontal()

                        styledDiv {
                            css {
                                // Show children in vertical order
                                display = Display.inlineGrid
                            }

                            inputFields()
                        }
                    }
                }

                submitButton()
            }
        }
    }

    private fun RBuilder.inputFields() {
        radioGroup(
            title = "Select ID type",
            radioButtons = listOf(RadioButton(label = "New Zealand Driver Licence",
                                              value = DRIVER_LICENCE_NZ.toString()),
                                  RadioButton(label = "New Zealand Passport",
                                              value = PASSPORT.toString())),
            valueSelected = state.govIdType?.toString(),
            onSelect = { value ->
                setState {
                    govIdType = IdDocument.Type.valueOf(value)
                    // Reset all errors when changing the selected ID type
                    showGovIdTypeError = false
                    govIdNumberError = null
                    govIdExpiryDateError = null
                    govIdVersionError = null
                }
            },
            showError = state.showGovIdTypeError
        )

        state.govIdType?.let { govIdType ->
            styledDiv {
                css {
                    display = Display.grid
                    gridTemplateRows = GridTemplateRows.auto
                    gridTemplateColumns = GridTemplateColumns(1.fr, 1.fr)
                    gap = 16.px

                    marginTop = 24.px

                    mobileScreen {
                        gridTemplateRows = GridTemplateRows.auto
                        gridTemplateColumns = GridTemplateColumns.auto
                    }
                }

                govIdField(govIdType)

                dateOfBirthField()
            }
        }
    }

    private fun RBuilder.dateOfBirthField() {
        styledDiv {
            css {
                gridColumn = GridColumn("1 / 2")
            }

            datePicker(
                title = "Date of birth",
                value = state.dateOfBirth,
                disableFutureDates = true,
                onDateChange = {
                    setState {
                        dateOfBirth = it
                        dateOfBirthError = null
                    }
                },
                errorMessage = state.dateOfBirthError
            )
        }
    }

    private fun RBuilder.govIdField(govIdType: IdDocument.Type) {
        val (field1, field2) = when (govIdType) {
            DRIVER_LICENCE_NZ -> listOf(GovIdField(title = "Driver licence number",
                                                   placeholder = "Enter 8 characters",
                                                   errorMessage = state.govIdNumberError),
                                        GovIdField(title = "Version number",
                                                   placeholder = "Enter 3 numbers",
                                                   errorMessage = state.govIdVersionError))
            PASSPORT -> listOf(GovIdField(title = "Passport number",
                                          placeholder = "Enter 7 or 8 characters",
                                          errorMessage = state.govIdNumberError),
                               GovIdField(title = "Expiry date",
                                          placeholder = "",
                                          errorMessage = state.govIdExpiryDateError))
        }

        styledDiv {
            css {
                gridColumn = GridColumn("1 / 2")
            }

            textField(
                title = field1.title,
                textCapitalization = TextCapitalization.UPPERCASE,
                placeholder = field1.placeholder,
                value = state.govIdNumber,
                onTextChange = { text ->
                    setState {
                        govIdNumber = text
                        govIdNumberError = null
                    }
                },
                errorMessage = field1.errorMessage
            )
        }

        styledDiv {
            css {
                gridColumn = GridColumn("1 / 2")
            }

            when (govIdType) {
                DRIVER_LICENCE_NZ -> {
                    textField(
                        title = field2.title,
                        type = TextFieldType.NUMBER,
                        placeholder = field2.placeholder,
                        value = state.govIdVersion,
                        onTextChange = { text ->
                            setState {
                                govIdVersion = text
                                govIdVersionError = null
                            }
                        },
                        errorMessage = field2.errorMessage
                    )
                }
                PASSPORT -> {
                    datePicker(
                        title = field2.title,
                        value = state.govIdExpiryDate,
                        onDateChange = { date ->
                            setState {
                                govIdExpiryDate = date
                                govIdExpiryDateError = null
                            }
                        },
                        errorMessage = field2.errorMessage
                    )
                }
            }
        }
    }

    private fun RBuilder.submitButton() {
        styledDiv {
            css {
                marginTop = 64.px
                marginBottom = 64.px

                display = Display.flex
                alignContent(AlignContent.end)
                justifyContent = JustifyContent.end
                justifySelf(JustifySelf.end)
                alignItems = Align.center

                gap = 24.px
                flexWrap = FlexWrap.wrap

                // Show "next" button above "back" one on mobile screens
                mobileScreen {
                    width = 100.pct
                    flexDirection = FlexDirection.columnReverse
                    flexWrap = FlexWrap.nowrap
                }
            }

            button(
                label = "Submit",
                type = PRIMARY,
                showLoadingIcon = state.isSaveInProgress,
                onClick = ::saveAmlInfo
            )
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        props.viewModel.donor.observe { donor ->
            val govId = donor.govId
            val (idDocType, idDocValue1, idDocVersion) = when (govId) {
                is DriverLicenceNz -> Triple(DRIVER_LICENCE_NZ, govId.number, govId.version)
                is Passport -> Triple(PASSPORT, govId.number, null)
                else -> Triple(null, null, null)
            }
            val idDocExpiryDate = if (govId is Passport) govId.expiryDate else null

            setState {
                dateOfBirth = donor.dateOfBirth
                govIdType = idDocType
                govIdNumber = idDocValue1
                govIdVersion = idDocVersion
                govIdExpiryDate = idDocExpiryDate
            }
        }

        props.viewModel.isActionInProgress.observe { setState { isSaveInProgress = it } }

        // Redirect the user to the dashboard screen when the data is saved
        props.viewModel.saveSuccessEvent.observeEvent { props.history.push(path = DASHBOARD) }

        props.viewModel.errors.observeEvent { showErrors(errors = it) }
    }

    private fun saveAmlInfo() {
        props.viewModel.saveAmlInfo(dateOfBirth = state.dateOfBirth,
                                    govIdType = state.govIdType,
                                    govIdNumber = state.govIdNumber,
                                    driverLicenceVersion = state.govIdVersion,
                                    govIdExpiryDate = state.govIdExpiryDate)
    }

    private fun showErrors(errors: Set<ErrorType>) {
        errors.forEach { error ->
            when ((error as? ErrorType.AppError)?.code) {
                DATE_OF_BIRTH_MISSING -> setState { dateOfBirthError = "Required" }

                GOV_ID_TYPE_MISSING -> setState { showGovIdTypeError = true }
                GOV_ID_NUMBER_MISSING -> setState { govIdNumberError = "Required" }

                DRIVER_LICENCE_NUMBER_INVALID -> setState { govIdNumberError = "Should be 8 characters, beginning with 2 letters then 6 numbers" }
                DRIVER_LICENCE_VERSION_MISSING -> setState { govIdVersionError = "Required" }
                DRIVER_LICENCE_VERSION_INVALID -> setState { govIdVersionError = "Should be 3 numbers" }

                PASSPORT_NUMBER_INVALID -> setState { govIdNumberError = "Should be 7 or 8 characters" }
                PASSPORT_EXPIRY_DATE_MISSING -> setState { govIdExpiryDateError = "Required" }
                PASSPORT_EXPIRY_DATE_INVALID -> setState { govIdExpiryDateError = "Invalid" }
            }
        }
    }

    /*
     * Inner types
     */

    /**
     * Container for info needed to display [Donor.govId] fields.
     */
    private data class GovIdField(
        val title: String,
        val placeholder: String,
        val errorMessage: String?
    )

}

/**
 * Properties used by the [AmlInfoScreen] component.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
private external interface AmlInfoScreenProps : RouteProps {

    /**
     * [ViewModel] shared between the setup process screens.
     */
    var viewModel: AmlInfoViewModel

}

/**
 * State of the [AmlInfoScreen] component.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
private external interface AmlInfoScreenState : State {

    /**
     * Type of [IdDocument] selected by the user.
     */
    var govIdType: Type?

    /**
     * Whether an error occurred on the [govIdType] field.
     */
    var showGovIdTypeError: Boolean

    /**
     * Date of birth provided by the user.
     */
    var dateOfBirth: LocalDate?

    /**
     * The error that occurred on the date of birth field.
     */
    var dateOfBirthError: String?

    /**
     * First value provided for the [govIdType] selected by the user.
     */
    var govIdNumber: String?

    /**
     * The error that occurred on the [govIdNumber] field.
     */
    var govIdNumberError: String?

    /**
     * Version number provided when [govIdType] is [DRIVER_LICENCE_NZ].
     */
    var govIdVersion: String?

    /**
     * The error that occurred on the [govIdVersion] field.
     */
    var govIdVersionError: String?

    /**
     * Expiry date provided when [govIdType] is [PASSPORT].
     */
    var govIdExpiryDate: LocalDate?

    /**
     * The error that occurred on the [govIdExpiryDate] field.
     */
    var govIdExpiryDateError: String?

    /**
     * `true` if the data saving action is in progress, or `false` otherwise.
     */
    var isSaveInProgress: Boolean

}

/**
 * Renders an [AmlInfoScreen] component.
 */
public fun RBuilder.amlInfoScreen(viewModel: AmlInfoViewModel) {
    withRouter(AmlInfoScreen::class) {
        attrs.viewModel = viewModel
    }
}