package supergenerous.app.donor.donation

import com.hipsheep.kore.model.repo.Repository
import com.hipsheep.kore.resource.Resource
import com.supergenerous.common.donation.Donation

/**
 * [Repository] used to manage the [Donation]s data.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class DonationRepository(

    private val donationService: DonationService

) : Repository() {

    /**
     * Returns the [Donation]s of the currently signed in donor.
     */
    public suspend fun getDonations(): Resource<List<Donation>> {
        return getResource { donationService.getDonations() }
    }

}