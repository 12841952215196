package supergenerous.app.core.auth.view

import kotlinx.css.Align
import kotlinx.css.Cursor
import kotlinx.css.Direction
import kotlinx.css.Display
import kotlinx.css.FontWeight
import kotlinx.css.JustifyContent
import kotlinx.css.LinearDimension
import kotlinx.css.TextAlign
import kotlinx.css.alignItems
import kotlinx.css.backgroundColor
import kotlinx.css.cursor
import kotlinx.css.direction
import kotlinx.css.display
import kotlinx.css.fontWeight
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.lineHeight
import kotlinx.css.margin
import kotlinx.css.marginBottom
import kotlinx.css.minHeight
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.properties.LineHeight
import kotlinx.css.px
import kotlinx.css.textAlign
import kotlinx.css.width
import kotlinx.html.ButtonType.button
import kotlinx.html.js.onClickFunction
import react.RBuilder
import styled.css
import styled.styledButton
import styled.styledDiv
import supergenerous.app.core.auth.model.AuthProvider
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.image.icon
import supergenerous.app.core.component.style.InputStyle
import supergenerous.app.core.res.Color
import supergenerous.app.core.res.image.CoreIcon

/**
 * Renders a sign in/up button for the [authProvider], calling [onClick] when the button is clicked.
 */
public fun RBuilder.authProviderButton(authProvider: AuthProvider, onClick: () -> Unit) {
    val (image, providerName) = when (authProvider) {
        AuthProvider.GOOGLE -> Pair(CoreIcon.GOOGLE_LOGO, "Google")
        else -> Pair(null, null)
    }

    styledButton {
        css {
            +InputStyle.focusRing
            +InputStyle.border

            direction = Direction.ltr
            fontWeight = FontWeight.w500
            textAlign = TextAlign.left

            width = 100.pct
            height = LinearDimension.auto
            lineHeight = LineHeight.normal
            minHeight = 40.px

            marginBottom = 24.px
            padding(vertical = 8.px, horizontal = 16.px)

            backgroundColor = Color.TRANSPARENT.cssValue

            display = Display.flex
            alignItems = Align.center
            justifyContent = JustifyContent.center

            hover {
                cursor = Cursor.pointer
            }

            lastOfType {
                margin(bottom = 0.px)
            }
        }

        attrs.onClickFunction = { onClick() }

        /*
         * The default button type is "submit" which means when "enter" is pressed while in a <form> element the onclick
         * function of this button will be called. Set the type to "button" to disable this behaviour.
         *
         * See https://github.com/supergenerous/issues/issues/441
         */
        attrs.type = button

        image?.let {
            styledDiv {
                val iconHeight = 18.px
                css {
                    margin(right = 8.px)
                    height = iconHeight
                }

                icon(it, size = iconHeight)
            }
        }

        body1 { +"Continue with $providerName" }
    }
}