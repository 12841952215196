package supergenerous.app.core.component.image

import kotlinx.css.LinearDimension
import kotlinx.css.em
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import supergenerous.app.core.res.Color

/**
 * An SVG Icon Element. This should be used for SVG elements that appear alongside or instead of text. They should be
 * similar size to the text. Non-icon SVG elements should instead be rendered using [Svg].
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@JsExport
private class Icon : RComponent<IconProps, State>() {

    override fun RBuilder.render() {
        val svgProps: dynamic = object{}
        val svgStyle: dynamic = object{}

        // Set width and height
        svgProps["width"] = props.size.value
        svgProps["height"] = props.size.value

        // Set the svg font-color if a color is supplied
        props.color?.let { svgStyle["color"] = it.hexValue }
        svgProps["fill"] = "currentColor"
        svgProps["stroke"] = "currentColor"

        // Accessibility
        svgProps["focusable"] = "false"
        svgProps["aria-label"] = props.icon.label

        // Add CSS styling to the SVG element
        svgProps["style"] = svgStyle

        child(props.icon.svgFile.default(svgProps))
    }

}

/**
 * Properties of the [Icon] component.
 */
private external interface IconProps : Props {

    /**
     * The icon to render.
     */
    var icon: supergenerous.app.core.res.image.Icon

    /**
     * The size to render the icon.
     */
    var size: LinearDimension

    /**
     * The color to render the icon (if supported by the icon).
     */
    var color: Color?

}

/**
 * Renders an [Icon].
 *
 * @param icon the icon to display.
 * @param color the color to render the icon (if supported by the icon).
 * @param size the size the icon should display at. Defaults to `1em` to inherit the current font-size so that it can
 * be used in-line.
 */
public fun RBuilder.icon(icon: supergenerous.app.core.res.image.Icon,
                         color: Color? = null,
                         size: LinearDimension = 1.em) {
    child(Icon::class) {
        attrs.icon = icon
        attrs.color = color
        attrs.size = size
    }
}