package supergenerous.app.donor.donor

import com.hipsheep.kore.model.network.Response
import com.supergenerous.common.donor.DonorState
import com.supergenerous.common.donor.DonorStateDbo
import com.supergenerous.common.firebase.DONOR_STATES
import com.supergenerous.common.firebase.FirestoreCollection
import com.supergenerous.common.firebase.FirestoreService
import kotlinx.coroutines.flow.Flow

/**
 * Service used to access [DonorState] data.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class DonorStateService(

    collection: FirestoreCollection<DonorStateDbo> = FirestoreCollection.DONOR_STATES

) : FirestoreService<DonorState, DonorStateDbo>(collection) {

    /**
     * Returns a [Flow] that will receive updates as the data of the [DonorState] that matches [id] is updated.
     */
    public fun loadDonorState(id: String): Flow<Response<DonorState>> {
        return loadDoc(id = id)
    }

    /**
     * Updates an existing [donorState] in the server.
     */
    public suspend fun updateDonorState(donorState: DonorState): Response<DonorState> {
        return updateDoc(donorState)
    }

}