package com.hipsheep.kore.json

import com.hipsheep.kore.di.DIManager
import com.hipsheep.kore.util.Logger
import com.hipsheep.kore.util.logError
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationException
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.json.Json

/**
 * Parser used to convert objects from/to JSON.
 *
 * This is a wrapper used to hide the specifics of the actual JSON parsing library used.
 */
public class JsonParser(

    /**
     * Parser that will perform the actual encoding/decoding of JSON objects.
     *
     * IMPORTANT: Don't use this property outside this class. Its visibility is set as `internal` only so that it can
     * be used from the extension methods on the `jsMain` module to encode/decode dynamic objects.
     */
    internal val kxJsonParser: Json

) {

    /**
     * Helper constructor necessary in iOS to avoid having to pass all the parameters
     * (iOS interop doesn't include the default values set in functions).
     */
    public constructor() : this(kxJsonParser = DIManager.kxJsonParser)

    /**
     * Parses the [json] string received to an object of type [T] using the [deserializer] and returns the result.
     *
     * @throws JsonParseException When [json] is `null` or it can't be parsed.
     */
    public fun <T> fromJson(json: String?, deserializer: DeserializationStrategy<T>): T {
        return try {
            json?.let { kxJsonParser.decodeFromString(deserializer, it) }
            // If json is null then throw an exception that will trigger the
            // throwing of the JsonParseException in the "catch" block
                ?: throw SerializationException("JSON string to parse is null")
        } catch (e: Exception) {
            val errorMsg = "Failed to deserialize JSON"
            logError(errorMsg, cause = e)

            throw JsonParseException(errorMsg, cause = e)
        }
    }

    /**
     * Parses the [value] received into a JSON string using the [serializer] and returns the result.
     *
     * @throws JsonParseException When [value] can't be parsed.
     */
    public fun <T> toJson(value: T, serializer: SerializationStrategy<T>): String {
        return try {
            kxJsonParser.encodeToString(serializer, value)
        } catch (e: Exception) {
            val errorMsg = "Failed to serialize JSON"
            logError(errorMsg, cause = e)

            throw JsonParseException(errorMsg, cause = e)
        }
    }

    /*
     * Inner types
     */

    private companion object : Logger()

}