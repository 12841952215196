package com.supergenerous.common.util

/**
 * List of buckets where files are stored in the server.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class FileBucket {

    /**
     * Bucket that stores the files only accessible to SG admins.
     */
    ADMIN

}