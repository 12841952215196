package com.supergenerous.common.id

import com.supergenerous.common.data.Dto
import com.supergenerous.common.file.CloudFile
import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable

/**
 * Result from an identity verification check.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class IdVerificationResult(

    /**
     * `true` if the result of the check is successful (i.e., the person's info was verified), or `false` otherwise.
     */
    val isSuccess: Boolean,

    /**
     * `true` if the ID verification check was done manually because the automated one failed, or `false` otherwise.
     */
    val isManualCheck: Boolean,

    /**
     * Files used to verify the identity of a user.
     */
    val files: List<CloudFile>,

    /**
     * Date and time when the ID verification check was performed.
     *
     * This is `null` for results stored in the DB before the migration in
     * https://github.com/supergenerous/issues/issues/490.
     */
    val timestamp: Timestamp?

) : Dto<IdVerificationResultDbo> {

    override fun toDbo(): IdVerificationResultDbo {
        return IdVerificationResultDbo(success = isSuccess,
                                       manualCheck = isManualCheck,
                                       files = files.map { it.toDbo() },
                                       timestamp = timestamp)
    }

}
