package supergenerous.app.donor.donation.platform

import com.hipsheep.kore.model.network.HttpStatusCode.INTERNAL_SERVER_ERROR
import com.hipsheep.kore.model.repo.Repository
import com.hipsheep.kore.resource.Resource
import supergenerous.app.donor.kindo.model.KindoDonorLinkParams
import supergenerous.app.donor.kindo.model.KindoService

/**
 * Repository used to manage data from external donation platforms (e.g., Kindo).
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class DonationPlatformRepository(

    private val kindoService: KindoService

) : Repository() {

    /**
     * Sends an error response of [errorCode] to Kindo.
     */
    public suspend fun sendDonorLinkErrorMsgToKindo(errorCode: Int = INTERNAL_SERVER_ERROR): Resource<Unit> {
        return getResource { kindoService.sendDonorLinkErrorMsg(errorCode) }
    }

    /**
     * Sends a confirmation of link success to Kindo.
     */
    public suspend fun sendDonorLinkSuccessMsgToKindo(donorId: String,
                                                      isNewAccount: Boolean,
                                                      kindoLinkParams: KindoDonorLinkParams): Resource<Unit> {
        return getResource { kindoService.sendDonorLinkSuccessMsg(donorId, isNewAccount, kindoLinkParams) }
    }

    /**
     * Sends a cancel link response to Kindo.
     */
    public suspend fun sendDonorLinkCancelMsgToKindo(kindoLinkParams: KindoDonorLinkParams): Resource<Unit> {
        return getResource { kindoService.sendDonorLinkCancelMsg(kindoLinkParams) }
    }

}