package com.hipsheep.kore.model.network

import com.hipsheep.kore.di.DIManager
import com.hipsheep.kore.model.network.Header.Key.CONTENT_TYPE
import com.hipsheep.kore.model.network.Header.Value.MULTIPART_FORM_DATA
import com.hipsheep.kore.model.network.HttpMethod.*
import com.hipsheep.kore.model.network.HttpStatusCode.INTERNAL_SERVER_ERROR
import io.ktor.client.call.*
import io.ktor.client.engine.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.http.HttpMethod.Companion.Delete
import io.ktor.http.HttpMethod.Companion.Get
import io.ktor.http.HttpMethod.Companion.Head
import io.ktor.http.HttpMethod.Companion.Options
import io.ktor.http.HttpMethod.Companion.Patch
import io.ktor.http.HttpMethod.Companion.Post
import io.ktor.http.HttpMethod.Companion.Put
import io.ktor.http.content.*
import io.ktor.serialization.kotlinx.json.*
import io.ktor.util.*
import io.ktor.util.reflect.*

/**
 * Platform-specific HTTP engine that will be used in [HttpClient.ktorHttpClient].
 */
internal expect val KTOR_HTTP_CLIENT_ENGINE: HttpClientEngine

/**
 * HTTP client used to send [Request]s to the backend.
 *
 * This is a wrapper used to hide the specifics of the actual HTTP client library used.
 */
public class HttpClient(

    /**
     * Actual HTTP client that will send the [Request]s.
     *
     * The APIs of this client should not be exposed to the rest of the system
     * so we can easily change it for another client if we need to.
     */
    private val ktorHttpClient: io.ktor.client.HttpClient

) {

    /**
     * @param timeoutMs Timeout (in milliseconds) used for the requests sent.
     * @param enableLogging `true` to enable the logging of requests/responses, or `false` to disable it.
     */
    public constructor(timeoutMs: Long = 20_000, enableLogging: Boolean = false)
            : this(ktorHttpClient = createKtorHttpClient(KTOR_HTTP_CLIENT_ENGINE, timeoutMs, enableLogging))

    /**
     * Creates a [Request] using the data received, sends it to a server and returns
     * the [Response] obtained from it.
     */
    @Suppress("UNCHECKED_CAST")
    public suspend fun <T> sendRequest(httpMethod: HttpMethod,
                                       url: String,
                                       headers: Map<String, List<String>>? = null,
                                       queryParams: Map<String, String>? = null,
                                       body: Any? = null,
                                       respBodyType: TypeInfo): Response<T> {
        return try {
            val contentType = headers?.get(CONTENT_TYPE)

            val httpResponse = if (contentType?.contains(MULTIPART_FORM_DATA) == true) {
                /*
                 * The client handles setting the ContentType header when using the submitFormWithBinaryData method
                 * so remove any headers which match.
                 *
                 * An error is thrown if we don't remove this.
                 */
                val headersNoContentType = headers.filter { header -> header.key != CONTENT_TYPE }

                // Remove the "multipart form data" value from the content types and what remains is the content type
                // of the file
                val contentTypeFile = contentType.filter { it != MULTIPART_FORM_DATA }

                val partData: List<PartData> = formData {
                    append(key = "file",
                           value = body as ByteArray,
                           headers = Headers.build {
                               append(HttpHeaders.ContentType, contentTypeFile.joinToString(separator = HEADER_SEPARATOR))
                               // Use a generic name as it's not important
                               append(HttpHeaders.ContentDisposition, "filename=file_uploaded")
                           })
                }

                ktorHttpClient.submitFormWithBinaryData(url = url, formData = partData) {
                    method = getKtorHttpMethod(httpMethod)

                    headersNoContentType.forEach { header(it.key, it.value.joinToString(separator = HEADER_SEPARATOR)) }
                    queryParams?.forEach { parameter(it.key, it.value) }
                }
            } else {
                ktorHttpClient.request(urlString = url) {
                    method = getKtorHttpMethod(httpMethod)

                    headers?.forEach {
                        header(key = it.key,
                               value = it.value.joinToString(separator = HEADER_SEPARATOR))
                    }
                    queryParams?.forEach { parameter(key = it.key, value = it.value) }
                    body?.let { setBody(body = it) }
                }
            }

            val respStatusCode = httpResponse.status
            val respHeaders = httpResponse.headers.toMap()

            if (respStatusCode.isSuccess()) {
                Response(statusCode = respStatusCode.value,
                         message = respStatusCode.description,
                         headers = respHeaders,
                    // body() can throw exceptions so execute it inside a try-catch
                         body = httpResponse.body(typeInfo = respBodyType))
            } else {
                Response(statusCode = respStatusCode.value,
                         message = respStatusCode.description,
                         headers = respHeaders,
                         errorBody = httpResponse.bodyAsText())
            }
            // Catch Throwable so that Kotlin Errors are also caught
            // (see https://github.com/supergenerous/issues/issues/46)
        } catch (e: Throwable) {
            // Avoid sending exceptions upstream from the HttpClient
            Response(INTERNAL_SERVER_ERROR, errorBody = e.stackTraceToString())
        }
    }

    /**
     * Maps the [httpMethod] received to the equivalent value from [io.ktor.http.HttpMethod]
     * and returns it.
     */
    private fun getKtorHttpMethod(httpMethod: HttpMethod): io.ktor.http.HttpMethod {
        return when (httpMethod) {
            GET -> Get
            POST -> Post
            PUT -> Put
            PATCH -> Patch
            DELETE -> Delete
            HEAD -> Head
            OPTIONS -> Options
        }
    }

    /*
     * Inner types
     */

    private companion object {

        /**
         * String used to separate header values in Ktor.
         */
        private const val HEADER_SEPARATOR = ", "


        /**
         * Creates an [io.ktor.client.HttpClient] instance with the [httpClientEngine] and the [timeoutMs] received,
         * enabling logging according to the value of [enableLogging], and returns it.
         */
        private fun createKtorHttpClient(httpClientEngine: HttpClientEngine,
                                         timeoutMs: Long,
                                         enableLogging: Boolean): io.ktor.client.HttpClient {
            return io.ktor.client.HttpClient(engine = httpClientEngine) {
                // Disable this feature or error responses won't be parsed properly
                // (see https://github.com/ktorio/ktor/issues/1217)
                expectSuccess = false

                // Parse JSON in request/response bodies automatically into class objects
                install(ContentNegotiation) {
                    json(json = DIManager.kxJsonParser)
                }

                install(HttpTimeout) {
                    requestTimeoutMillis = timeoutMs
                    socketTimeoutMillis = timeoutMs
                }

                if (enableLogging) {
                    install(Logging) {
                        logger = Logger.SIMPLE
                        level = LogLevel.ALL
                    }
                }
            }
        }

    }

}

/**
 * Sends the [request] received to a server and returns the [Response] obtained
 * from it.
 */
@Suppress("unused")
public suspend inline fun <ReqBody, reified RespBody> HttpClient.sendRequest(request: Request<ReqBody>): Response<RespBody> {
    return sendRequest(request.method, request.url, request.headers, request.queryParams, request.body)
}

/**
 * Creates a [Request] using the data received, sends it to a server and returns
 * the [Response] obtained from it.
 */
public suspend inline fun <reified T> HttpClient.sendRequest(httpMethod: HttpMethod,
                                                             url: String,
                                                             headers: Map<String, List<String>>? = null,
                                                             queryParams: Map<String, String>? = null,
                                                             httpBody: Any? = null): Response<T> {
    return sendRequest(httpMethod, url, headers, queryParams, httpBody, respBodyType = typeInfo<T>())
}