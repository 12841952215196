package com.hipsheep.kore.error

/**
 * Type of error that occurred.
 */
public sealed class ErrorType {

    /**
     * HTTP error that was returned by the server.
     *
     * @property statusCode HTTP status code returned by the server.
     */
    public data class HttpError(val statusCode: Int) : ErrorType()

    /**
     * Error that occurred inside the app.
     *
     * @property code Code that contains details about the error that occurred.
     */
    public data class AppError(val code: AppErrorCode) : ErrorType()

}