package supergenerous.app.core.component.button

/**
 * Type of [Button]s that can be used in the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class ButtonType {

    PRIMARY,
    SECONDARY,
    ACCENT,
    LOW_PROFILE,

}