package com.supergenerous.common.donee

import com.supergenerous.common.data.Dto
import com.supergenerous.common.donation.Donation
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

/**
 * Basic info from a [Donee] that is stored inside other classes (e.g., [Donation.donee]).
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class DoneeBasicInfo(

    /**
     * Internal ID assigned by the SG system.
     */
    val id: String,

    /**
     * Type of donee organisation.
     */
    val type: Donee.Type,

    /**
     * Usual name used by the organisation for users.
     */
    val name: String

) : Dto<DoneeBasicInfoDbo> {

    /**
     * [DoneeId] value of the [Donee].
     */
    // TODO: Remove this once DoneeBasicInfo.id type changes to DoneeId
    @Transient
    public val doneeId: DoneeId = DoneeId(id)


    override fun toDbo(): DoneeBasicInfoDbo {
        return DoneeBasicInfoDbo(id = id, type = type, name = name)
    }

}