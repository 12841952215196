package com.supergenerous.common.util

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atStartOfDayIn
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime

/**
 * Timestamp in millis since epoch.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public typealias Timestamp = Long

/**
 * Time zone used to convert [LocalDate]s to a timestamp `Long`s and vice-versa.
 *
 * A static time zone (UTC) is used rather than the local one so the parsing works properly on any machine (this
 * is possible because we only care about the date and not the time).
 */
private val utcTimeZone = TimeZone.UTC

/*
 * Extension functions
 */

/**
 * Converts the [Timestamp] to a [LocalDateTime] and returns the result.
 */
// TODO: Remove default time zone value??
public fun Timestamp.toLocalDateTime(timeZone: TimeZone = utcTimeZone): LocalDateTime {
    return Instant.fromEpochMilliseconds(this).toLocalDateTime(timeZone)
}

/**
 * Converts the [Timestamp] to a [LocalDate] and returns the result.
 */
// TODO: Receive time zone as param
public fun Timestamp.toLocalDate(): LocalDate {
    return this.toLocalDateTime().date
}

/**
 * Converts the [LocalDate] to a [Timestamp] and returns the result.
 */
// TODO: Receive time zone as param
public fun LocalDate.toTimestamp(): Timestamp {
    return this.atStartOfDayIn(utcTimeZone).toEpochMilliseconds()
}

/**
 * Converts the [LocalDateTime] to a [Timestamp] and returns the result.
 */
// TODO: Receive time zone as param
public fun LocalDateTime.toTimestamp(): Timestamp {
    return this.toInstant(utcTimeZone).toEpochMilliseconds()
}

/**
 * Converts the `String` to a [Timestamp] and returns the result.
 */
public fun String.toTimestamp(): Timestamp {
    return this.toLong()
}

/**
 * Formats the [Timestamp] using format "dd/MM/yyyy" if [dateOnly] is `true` or "dd/MM/yyyy, HH:mm:ss" if it's `false`
 * and returns the result.
 */
public expect fun Timestamp.toLocaleString(dateOnly: Boolean = false): String