package com.supergenerous.common.serialization

import kotlinx.datetime.LocalDate
import kotlinx.datetime.toLocalDate
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

/**
 * Serializer used to convert [LocalDate]s to `String`s and vice-versa.
 *
 * This is used when sending/receiving data from the server or when storing/reading from the local DB.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
// TODO: Migrate all properties using this to LocalDateSerializer
@Deprecated("Use LocalDateSerializer instead")
public object LocalDateSerializerString : KSerializer<LocalDate> {

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(serialName = "sg.LocalDateSerializerString",
                                                                          kind = PrimitiveKind.STRING)


    @Suppress("PARAMETER_NAME_CHANGED_ON_OVERRIDE")
    override fun serialize(encoder: Encoder, date: LocalDate) {
        encoder.encodeString(date.toString())
    }

    override fun deserialize(decoder: Decoder): LocalDate {
        return decoder.decodeString().toLocalDate()
    }

}