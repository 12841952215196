package supergenerous.app.core.component.textfield

import com.hipsheep.kore.util.capitalizeWords
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeBasicInfo
import react.RBuilder
import supergenerous.app.core.common.valueSingular
import supergenerous.app.core.search.model.TextSearchResult

/**
 * Renders a [searchTextField] used to search for donees by name.
 */
public fun RBuilder.doneeSearchField(doneeType: Donee.Type? = null,
                                     showTitle: Boolean = false,
                                     value: String? = null,
                                     errorMessage: String? = null,
                                     warningMessage: String? = null,
                                     searchResults: List<TextSearchResult<DoneeBasicInfo>>,
                                     showInputAsResult: Boolean = false,
                                     onTextChange: ((String) -> Unit),
                                     onDoneeSelect: (DoneeBasicInfo) -> Unit) {
    val doneeTypeSingular = doneeType?.valueSingular ?: "organisation"

    val title = if (showTitle) "${doneeTypeSingular.capitalizeWords()} name" else null
    val orgTypeArticle = if (doneeType != null) "a" else "an"

    searchTextField(
        title = title,
        placeholder = "Start typing $orgTypeArticle $doneeTypeSingular name",
        value = value,
        errorMessage = errorMessage,
        warningMessage = warningMessage,
        searchResults = searchResults,
        maxResultsShown = 5,
        showInputAsResult = showInputAsResult,
        onTextChange = onTextChange,
        onResultSelect = { searchResult ->
            val doneeSelected = searchResult.data ?: DoneeBasicInfo(id = "",
                                                                    name = searchResult.text,
                                                                    type = doneeType!!)

            onDoneeSelect(doneeSelected)
        },
        resultSubtext = { searchResult ->
            if (!searchResult.text.equals(searchResult.data?.name, ignoreCase = true)) {
                searchResult.data?.name
            } else {
                null
            }
        }
    )
}