package supergenerous.app.donor

import com.hipsheep.kore.error.ErrorType.HttpError
import com.hipsheep.kore.model.network.HttpStatusCode.NOT_FOUND
import com.hipsheep.kore.resource.Resource
import com.hipsheep.kore.resource.Resource.*
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValueAsync
import com.supergenerous.common.donor.ActiveStatus.Active
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.donor.DonorState
import com.supergenerous.common.user.User
import supergenerous.app.core.BaseAppViewModel
import supergenerous.app.core.auth.model.AuthRepository
import supergenerous.app.core.location.LocationRepository
import supergenerous.app.donor.donor.DonorRepository
import kotlin.js.Date

/**
 * [ViewModel] that provides data to the [App] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public class AppViewModel(

    authRepo: AuthRepository,
    private val donorRepo: DonorRepository,
    private val locationRepo: LocationRepository

) : BaseAppViewModel(authRepo) {

    /**
     * Backing property for [donor].
     */
    private val _donor = MutableLiveData<Donor>()
    /**
     * Observable that is called when the data for the signed-in [Donor] is loaded.
     */
    public val donor: LiveData<Donor> = _donor


    /**
     * Loads the [user]'s [Donor] data.
     */
    override suspend fun loadUserData(user: User) {
        processDonorRes(donorRes = donorRepo.getDonor(user))
    }

    /**
     * Attempts to load the [Donor] associated with the signed in [user] from the server DB, if no [Donor] is found,
     * then it creates a new one.
     *
     * If it's a new donor sign-up and a [referralCode] is provided then it'll be added to the [DonorState] of the
     * [user].
     */
    public fun loadOrCreateDonor(user: User, referralCode: String? = null) {
        executeActionAsync {
            val ipAddress = (locationRepo.getIpAddress() as? Success)?.data

            val existingDonorRes = donorRepo.getDonor(user)

            val donorRes = when {
                // If the donor couldn't be found then it's a new donor sign-up so add the new donor data
                ((existingDonorRes as? Error)?.type as? HttpError)?.statusCode == NOT_FOUND -> {
                    val timestampNow = Date().getTime().toLong()

                    val donor = Donor(id = user.id,
                                      name = user.name,
                                      email = user.email,
                                      profileImgUrl = user.profileImgUrl,
                                      signUpDate = timestampNow,
                                      phoneNumber = null,
                                      otherEmails = setOf(),
                                      address = null,
                                      legalName = null,
                                      dateOfBirth = null,
                                      govId = null,
                                      taxId = null,
                                      bankAccountNumber = null,
                                      childrenNames = setOf(),
                                      signature = null,
                                      donees = setOf(),
                                      donationPlatforms = setOf(),
                                      disbursementSettings = mutableMapOf(),
                                      activeStatus = Active(lastUpdateTimestamp = timestampNow),
                                      setupCompleteTimestamp = null,
                                      lastKnownIPAddress = ipAddress)

                    donorRepo.createDonor(donor, referralCode = referralCode)
                }
                // If the donor exists and an IP address was fetched then save it on the server
                ipAddress != null -> donorRepo.saveIpAddress(ipAddress)
                else -> existingDonorRes
            }

            processDonorRes(donorRes)

            donorRes
        }
    }

    /**
     * Processes the [donorRes] received, calling the proper observables according to its state.
     */
    private suspend fun processDonorRes(donorRes: Resource<Donor>) {
        when (donorRes) {
            is Loading -> {
                // Nothing to do while loading
            }
            is Success -> {
                _isAppDataLoaded.updateValueAsync(true)

                _donor.updateValueAsync(donorRes.data)
            }
            is Error -> reportError(donorRes)
        }
    }

}