package com.supergenerous.common.id

import com.supergenerous.common.data.Dbo
import com.supergenerous.common.file.CloudFileDbo
import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [IdVerificationResult] class.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class IdVerificationResultDbo(

    val success: Boolean? = null,

    val manualCheck: Boolean? = null,

    val files: List<CloudFileDbo>? = null,

    val timestamp: Timestamp? = null

) : Dbo<IdVerificationResult> {

    override fun toDto(): IdVerificationResult {
        return IdVerificationResult(isSuccess = success!!,
                                    isManualCheck = manualCheck!!,
                                    files = files?.map { it.toDto() } ?: emptyList(),
                                    timestamp = timestamp)
    }

}