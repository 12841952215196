package supergenerous.app.donor.setup.view

import com.hipsheep.kore.error.ErrorType
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.disbursement.DisbursementRecipient.Type.DONOR
import com.supergenerous.common.donee.Donee
import kotlinx.css.Display
import kotlinx.css.display
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.textfield.TextFieldType.BANK_ACCOUNT_NUMBER
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.setup.model.SetupError.*
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.BANK_ACCOUNT
import supergenerous.app.donor.setup.viewmodel.SetupViewModel

/**
 * Screen that allows donors to enter their bank account number.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
private class BankAccountScreen : SetupScreen<SetupScreenProps, BankAccountScreenState>() {

    override val setupStep: SetupStep = BANK_ACCOUNT


    override fun RBuilder.render() {
        if (state.disbursementSettings == null) {
            return
        }

        val subtitle = if (state.disbursementSettings!!.any { (_, disbRecipient) -> disbRecipient == DONOR }) {
            "This is where we'll deposit the rebates we help you to claim."
        } else {
            // If the donor selected to donate all their rebates then explain why their bank account is required
            "We'll use this bank account to send you any rebates that we were not able to send to the charitable organisations you donated to."
        }

        setupInputContainer(
            title = { +"Bank account number" },
            subtitle = { +subtitle },
            setupViewModel = props.viewModel,
            onNextBtnClick = ::saveBankAccountNumber,
            onBackBtnClick = ::goBack
        ) {
            styledDiv {
                css {
                    // Make the textField not fill the width of the parent
                    display = Display.flex
                }

                textField(
                    title = "Bank account number",
                    type = BANK_ACCOUNT_NUMBER,
                    value = state.bankAccountNumber,
                    onTextChange = {
                        setState {
                            bankAccountNumber = it
                            bankAccountNumberError = null
                        }
                    },
                    errorMessage = state.bankAccountNumberError
                )
            }
        }
    }

    /**
     * Saves the bank account number.
     */
    private fun saveBankAccountNumber() {
        props.viewModel.saveBankAccountNumber(bankAccountNumber = state.bankAccountNumber)
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // Load the values already provided by the donor (if any)
        props.viewModel.donor.observe { donor ->
            setState {
                bankAccountNumber = donor.bankAccountNumber
                disbursementSettings = donor.disbursementSettings
            }
        }

        props.viewModel.errors.observeEvent { errors ->
            errors.forEach { error ->
                when ((error as? ErrorType.AppError)?.code) {
                    BANK_ACCOUNT_MISSING -> setState { bankAccountNumberError = "Required" }
                    BANK_ACCOUNT_INVALID -> setState { bankAccountNumberError = "Invalid number" }
                }
            }
        }
    }

}

/**
 * State of the [BankAccountScreen] component.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
private external interface BankAccountScreenState : State {

    /**
     * Bank account number provided by the user.
     */
    var bankAccountNumber: String?

    /**
     * Error that occurred in the [bankAccountNumber] field.
     */
    var bankAccountNumberError: String?

    /**
     * Disbursement settings selected by the user on previous screens.
     */
    var disbursementSettings: Map<Donee.Type, DisbursementRecipient.Type>?

}

/**
 * Renders a [BankAccountScreen] component.
 */
public fun RBuilder.bankAccountScreen(setupViewModel: SetupViewModel) {
    withRouter(BankAccountScreen::class) {
        attrs.viewModel = setupViewModel
    }
}