package supergenerous.app.donor.kindo.viewmodel

import com.hipsheep.kore.error.AppErrorCode
import com.hipsheep.kore.model.network.HttpStatusCode.BAD_REQUEST
import com.hipsheep.kore.model.network.HttpStatusCode.INTERNAL_SERVER_ERROR
import com.hipsheep.kore.resource.Resource
import com.hipsheep.kore.resource.Resource.*
import com.hipsheep.kore.viewmodel.BaseViewModel
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.event.Event
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValue
import com.hipsheep.kore.viewmodel.lifecycle.updateValueAsync
import com.supergenerous.common.bank.isValid
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.donee.Donee.Type.SCHOOL
import com.supergenerous.common.donor.ActiveStatus.Active
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.donor.DonorProvider.KINDO
import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.id.IdDocument.Type.*
import com.supergenerous.common.id.TaxId
import com.supergenerous.common.name.FullName
import com.supergenerous.common.signature.Signature
import com.supergenerous.common.user.User
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate
import supergenerous.app.core.analytics.AnalyticsEvent
import supergenerous.app.core.analytics.AnalyticsEvent.Auth.Action.*
import supergenerous.app.core.analytics.AnalyticsRepository
import supergenerous.app.core.auth.model.AuthError.INVALID_EMAIL
import supergenerous.app.core.auth.model.AuthProvider
import supergenerous.app.core.auth.model.AuthRepository
import supergenerous.app.core.auth.model.AuthResult
import supergenerous.app.core.location.LocationRepository
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.donor.donation.platform.DonationPlatformRepository
import supergenerous.app.donor.donor.DonorRepository
import supergenerous.app.donor.kindo.model.KindoDonorLinkParams
import supergenerous.app.donor.kindo.model.KindoLinkError
import supergenerous.app.donor.kindo.model.KindoLinkError.*
import supergenerous.app.donor.kindo.view.kindoLinkScreen
import supergenerous.app.donor.setup.model.AuthorityToActTerm
import supergenerous.app.donor.setup.model.AuthorityToActTerm.*
import kotlin.js.Date

/**
 * [ViewModel] that provides data to the [kindoLinkScreen].
 *
 * @author Raymond Feng (raymond@supergenerous.com)
 */
public class KindoLinkViewModel(

    private val donorRepo: DonorRepository,
    private val authRepo: AuthRepository,
    private val analyticsRepo: AnalyticsRepository,
    private val donationPlatformRepo: DonationPlatformRepository,
    private val locationRepo: LocationRepository

) : BaseViewModel() {

    /**
     * The [Donor] that signed in to the app before submitting the link data, or `null` if the donor is creating a new
     * account.
     */
    private var donorSignedIn: Donor? = null

    /**
     * List of terms that need to be accepted by the user.
     */
    public val ataTerms: Set<AuthorityToActTerm> = AuthorityToActTerm.values().toSet()

    /*
     * Donor property data
     */

    private val _donorEmail = MutableLiveData<String>()
    public val donorEmail: LiveData<String> = _donorEmail

    private val _donorName = MutableLiveData<FullName>()
    public val donorName: LiveData<FullName> = _donorName

    private val _donorDateOfBirth = MutableLiveData<LocalDate>()
    public val donorDateOfBirth: LiveData<LocalDate> = _donorDateOfBirth

    private val _donorAddress = MutableLiveData<String>()
    public val donorAddress: LiveData<String> = _donorAddress

    private val _donorGovId = MutableLiveData<IdDocument>()
    public val donorGovId: LiveData<IdDocument> = _donorGovId

    private val _donorPhoneNumber = MutableLiveData<String>()
    public val donorPhoneNumber: LiveData<String> = _donorPhoneNumber

    private val _donorTaxId = MutableLiveData<String>()
    public val donorTaxId: LiveData<String> = _donorTaxId

    private val _donorBankAccNumber = MutableLiveData<String>()
    public val donorBankAccNumber: LiveData<String> = _donorBankAccNumber

    private val _schoolDisbRecipient = MutableLiveData<DisbursementRecipient.Type>()
    public val schoolDisbRecipient: LiveData<DisbursementRecipient.Type> = _schoolDisbRecipient

    private val _donorSignature = MutableLiveData<String>()
    public val donorSignature: LiveData<String> = _donorSignature

    private val _isDonorMarketingEmailsEnabled = MutableLiveData<Boolean>()
    public val isDonorMarketingEmailsEnabled: LiveData<Boolean> = _isDonorMarketingEmailsEnabled

    /*
     * Form state data.
     */

    private val _addressSearchResults = MutableLiveData<Event<List<TextSearchResult<String>>>>()
    public val addressSearchResults: LiveData<Event<List<TextSearchResult<String>>>> = _addressSearchResults

    private val _isDonorSignedIn = MutableLiveData<Boolean>()
    /**
     * `true` if the donor signed in to their existing account or they created a new account via Google Sign-in.
     */
    public val isDonorSignedIn: LiveData<Boolean> = _isDonorSignedIn

    private val _isDonorSigningIn = MutableLiveData<Boolean>()
    /**
     * `true` if the donor is currently signing into their existing account or creating a new account via Google
     * Sign-in.
     */
    public val isDonorSigningIn: LiveData<Boolean> = _isDonorSigningIn

    private val _isLinkSuccessful = MutableLiveData<Boolean>()
    /**
     * `true` if the donor link between SG and Kindo completed successfully.
     */
    public val isLinkSuccessful: LiveData<Boolean> = _isLinkSuccessful

    private val _isLinkInProgress = MutableLiveData<Boolean>()
    public val isLinkInProgress: LiveData<Boolean> = _isLinkInProgress

    /**
     * `true` if the donor is new to SG, or `false` if they already have a SG account and they signed in to it.
     */
    private var isNewDonor: Boolean = true

    /**
     * The data received from Kindo about the donor.
     */
    private var kindoLinkParams: KindoDonorLinkParams? = null


    /**
     * Loads the user data received from Kindo into the UI.
     */
    public fun loadUserData(operationType: String?,
                            referralId: String?,
                            referrer: String?,
                            doneeExtId: String?,
                            donorExtId: String?,
                            donorEmail: String?,
                            donorFirstName: String?,
                            donorLastName: String?,
                            checksum: String?) {
        kindoLinkParams = try {
            KindoDonorLinkParams(operationType = operationType!!,
                                 referralId = referralId!!,
                                 referrer = referrer!!,
                                 doneeExtId = doneeExtId!!,
                                 donorExtId = donorExtId!!,
                                 donorEmail = donorEmail!!,
                                 donorName = FullName(firstName = donorFirstName!!, lastName = donorLastName!!),
                                 checksum = checksum!!)
        } catch (e: Exception) {
            // An unknown error occurred when parsing the fields so report an error (some required query params might
            // have been missing)
            reportError(BAD_DATA_FROM_KINDO)
            launch { sendErrorToKindo(BAD_REQUEST) }
            return
        }

        // Initialise form fields from the query parameters
        _donorEmail.updateValue(donorEmail)
        _donorName.updateValue(FullName(firstName = donorFirstName, lastName = donorLastName))
    }

    /**
     * Sign in the user with the [email] and [password].
     */
    public fun signIn(email: String, password: String) {
        authenticateUser { authRepo.signIn(email, password) }
    }

    /**
     * Signs in, or signs up the user using [authProvider].
     */
    public fun authenticateWithProvider(authProvider: AuthProvider) {
        authenticateUser { authRepo.authenticateWithProvider(authProvider) }
    }

    /**
     * Authenticates the user through the [authenticate] function and performs the actions necessary depending on the
     * result.
     */
    private fun authenticateUser(authenticate: suspend () -> Resource<AuthResult>) {
        launch {
            _isDonorSigningIn.updateValueAsync(true)

            executeAction { authenticate() }?.let { authResult ->
                loadOrCreateDonor(isNewUser = authResult.isNewUser, user = authResult.user)

                logAuthEvent(authResult)
            }

            _isDonorSigningIn.updateValueAsync(false)
        }
    }

    /**
     * Attempts to load the [Donor] associated with the signed in [user] from the server DB, if no [Donor] is found,
     * then it creates a new one.
     */
    private suspend fun loadOrCreateDonor(isNewUser: Boolean, user: User) {
        isNewDonor = isNewUser

        executeActionAsync {
            val donorRes = if (isNewUser) {
                createDonor(user)
            } else {
                donorRepo.getDonor(user)
            }

            if (donorRes is Success) {
                loadDonorData(donor = donorRes.data)
            }

            donorRes
        }
    }

    /**
     * Loads the [donor] data into the UI.
     */
    private suspend fun loadDonorData(donor: Donor) {
        _isDonorSignedIn.updateValueAsync(true)
        donorSignedIn = donor

        donor.legalName?.let { _donorName.updateValueAsync(it) }
        donor.dateOfBirth?.let { _donorDateOfBirth.updateValueAsync(it) }
        donor.address?.let { _donorAddress.updateValueAsync(it) }
        donor.govId?.let { _donorGovId.updateValueAsync(it) }
        donor.phoneNumber?.let { _donorPhoneNumber.updateValueAsync(it) }
        donor.taxId?.let { _donorTaxId.updateValueAsync(it) }
        donor.bankAccountNumber?.let { _donorBankAccNumber.updateValueAsync(it) }
        donor.disbursementSettings[SCHOOL]?.let { _schoolDisbRecipient.updateValueAsync(it) }
        donor.signature?.let { _donorSignature.updateValueAsync(it.value) }
        donor.isMarketingEmailsEnabled?.let { _isDonorMarketingEmailsEnabled.updateValueAsync(it) }
    }

    /**
     * Creates and updates a new [Donor] object for the [user].
     */
    private suspend fun createDonor(user: User): Resource<Donor> {
        val timestampNow = Date().getTime().toLong()

        val donor = Donor(id = user.id,
                          name = user.name,
                          email = user.email,
                          profileImgUrl = user.profileImgUrl,
                          signUpDate = timestampNow,
                          phoneNumber = null,
                          otherEmails = setOf(),
                          address = null,
                          legalName = null,
                          dateOfBirth = null,
                          govId = null,
                          taxId = null,
                          bankAccountNumber = null,
                          childrenNames = setOf(),
                          signature = null,
                          donees = setOf(),
                          donationPlatforms = setOf(),
                          disbursementSettings = mutableMapOf(),
                          activeStatus = Active(lastUpdateTimestamp = timestampNow),
                          setupCompleteTimestamp = null)

        return donorRepo.createDonor(donor, provider = KINDO)
    }

    /**
     * Links the [Donor] to Kindo on our system, then sends a message to Kindo letting them know the result of the
     * link.
     */
    public fun linkDonor(email: String,
                         password: String,
                         firstName: String,
                         middleName: String,
                         lastName: String,
                         phoneNumber: String,
                         address: String,
                         dateOfBirth: LocalDate?,
                         govIdType: IdDocument.Type?,
                         govIdNumber: String,
                         driverLicenceVersion: String,
                         govIdExpiryDate: LocalDate?,
                         bankAccountNumber: String,
                         taxId: TaxId,
                         disbRecipientType: DisbursementRecipient.Type?,
                         signatureStr: String?,
                         isMarketingEmailsEnabled: Boolean,
                         ataTermsAccepted: Set<AuthorityToActTerm>) {
        val signature = signatureStr?.let { Signature(it) }
        val govIdNumberTrim = govIdNumber.trim()

        val errors = validateData(email = email,
                                  firstName = firstName,
                                  lastName = lastName,
                                  phoneNumber = phoneNumber,
                                  address = address,
                                  dateOfBirth = dateOfBirth,
                                  govIdType = govIdType,
                                  govIdNumber = govIdNumberTrim,
                                  driverLicenceVersion = driverLicenceVersion,
                                  govIdExpiryDate = govIdExpiryDate,
                                  bankAccountNumber = bankAccountNumber,
                                  taxId = taxId,
                                  disbRecipientType = disbRecipientType,
                                  signature = signature,
                                  ataTermsAccepted = ataTermsAccepted)

        if (errors.isNotEmpty()) {
            reportErrors(errors)
            return
        }


        val govId = when (govIdType!!) {
            DRIVER_LICENCE_NZ -> IdDocument.DriverLicenceNz(number = govIdNumberTrim,
                                                            version = driverLicenceVersion.trim())
            PASSPORT -> IdDocument.Passport(number = govIdNumberTrim, expiryDate = govIdExpiryDate!!)
        }

        _isLinkInProgress.updateValue(true)

        launch {
            try {
                val donorToSave = if (donorSignedIn == null) {
                    // If the donor didn't sign in then create a new account for them
                    val authRes = authRepo.signUp(email, password)
                    if (authRes is Error) {
                        // We failed to sign up the user to our own system. This is probably because an account exists
                        // already. Don't return anything to Kindo so that the user can try again.
                        reportError(authRes.type)
                        return@launch
                    }

                    val authResult = (authRes as Success).data
                    logAuthEvent(authResult)

                    // Create donor
                    val donorRes = createDonor(authResult.user)
                    if (donorRes is Error) {
                        reportError(donorRes.type)
                        // We failed to create a donor object for the user in our own system, so send
                        // INTERNAL_SERVER_ERROR to Kindo
                        sendErrorToKindo(INTERNAL_SERVER_ERROR)
                        return@launch
                    }

                    // Update the UI to remove the password field after the user is created on SG
                    _isDonorSignedIn.updateValueAsync(true)

                    (donorRes as Success).data
                } else {
                    // Update the existing donor if they signed in already
                    donorSignedIn!!
                }.withValues(email = email,
                             firstName = firstName,
                             middleName = middleName,
                             lastName = lastName,
                             dateOfBirth = dateOfBirth!!,
                             phoneNumber = phoneNumber,
                             address = address,
                             govId = govId,
                             bankAccountNumber = bankAccountNumber,
                             taxId = taxId,
                             schoolsDisbRecipient = disbRecipientType!!,
                             signature = signature,
                             isMarketingEmailsEnabled = isMarketingEmailsEnabled)

                // Update donor to save object with updated properties
                donorRepo.saveDonor(donorToSave).ifError {
                    reportError(LINK_DONOR_ERROR)
                    // We failed to save the donor on our own system, so send INTERNAL_SERVER_ERROR to Kindo
                    sendErrorToKindo(INTERNAL_SERVER_ERROR)
                    return@launch
                }

                donorRepo.linkDonorToKindo(donorId = donorToSave.id, kindoLinkParams = kindoLinkParams!!)
                        .ifError {
                            reportError(LINK_DONOR_ERROR)
                            // We failed to link the donor on our own system, so send INTERNAL_SERVER_ERROR to Kindo
                            sendErrorToKindo(INTERNAL_SERVER_ERROR)
                            return@launch
                        }

                donationPlatformRepo.sendDonorLinkSuccessMsgToKindo(donorId = donorToSave.id,
                                                                    isNewAccount = isNewDonor,
                                                                    kindoLinkParams = kindoLinkParams!!)
                        .ifError {
                            reportError(POST_MESSAGE_ERROR)
                            return@launch
                        }

                _isLinkSuccessful.updateValueAsync(true)
            } finally {
                _isLinkInProgress.updateValueAsync(false)
            }
        }
    }

    /**
     * Sets the user data inside [authResult] in the [analyticsRepo] and logs an auth event for them.
     */
    private fun logAuthEvent(authResult: AuthResult) {
        // Set the donor ID for analytics purposes
        analyticsRepo.setUser(user = authResult.user,
            // Only new users are considered as "provided" by Kindo
                              donorProvider = if (authResult.isNewUser) KINDO else null)

        analyticsRepo.logEvent(AnalyticsEvent.Auth(action = if (authResult.isNewUser) SIGN_UP else SIGN_IN,
                                                   authProvider = authResult.authProvider))
    }

    /**
     * Validates the data received and returns any errors found.
     */
    private fun validateData(email: String,
                             firstName: String,
                             lastName: String,
                             phoneNumber: String,
                             address: String,
                             dateOfBirth: LocalDate?,
                             govIdType: IdDocument.Type?,
                             govIdNumber: String,
                             driverLicenceVersion: String,
                             govIdExpiryDate: LocalDate?,
                             bankAccountNumber: String,
                             taxId: TaxId,
                             disbRecipientType: DisbursementRecipient.Type?,
                             signature: Signature?,
                             ataTermsAccepted: Set<AuthorityToActTerm>): Set<AppErrorCode> {
        val errors = mutableSetOf<AppErrorCode>()

        if (email.isBlank()) {
            errors += INVALID_EMAIL
        }
        if (firstName.isBlank()) {
            errors += FIRST_NAME_MISSING
        }
        if (lastName.isBlank()) {
            errors += LAST_NAME_MISSING
        }
        if (phoneNumber.isBlank()) {
            errors += PHONE_NUMBER_MISSING
        }
        if (address.isBlank()) {
            errors += ADDRESS_MISSING
        }
        if (dateOfBirth == null) {
            errors += DATE_OF_BIRTH_MISSING
        }

        errors += when (govIdType) {
            null -> setOf(GOV_ID_TYPE_MISSING)
            DRIVER_LICENCE_NZ -> validateDriverLicence(number = govIdNumber, version = driverLicenceVersion)
            PASSPORT -> validatePassport(number = govIdNumber, expiryDate = govIdExpiryDate)
        }

        errors += validateTaxId(taxId)

        if (disbRecipientType == null) {
            errors += SCHOOL_DISB_RECIPIENT_MISSING
        }

        if (signature?.value.isNullOrBlank()) {
            errors += SIGNATURE_MISSING
        } else if (!signature!!.isImage) {
            // If the signature is not an image-type signature then make sure the signature matches the name
            val expectedSignature = "$firstName $lastName"
            if (!signature.value.equals(expectedSignature, ignoreCase = true)) {
                errors += SIGNATURE_INVALID
            }
        }

        if (disbRecipientType == DisbursementRecipient.Type.DONOR) {
            if (bankAccountNumber.isBlank()) {
                errors += BANK_ACC_MISSING
            } else if (!bankAccountNumber.isValid()) {
                errors += BANK_ACC_INVALID
            }
        }

        val ataTermsNotAccepted = ataTerms - ataTermsAccepted
        for (ataTerm in ataTermsNotAccepted) {
            errors += when (ataTerm) {
                TAX_AGENT -> ATA_TERM_NOT_ACCEPTED_TAX_AGENT
                SG_OPT_OUT -> ATA_TERM_NOT_ACCEPTED_SG_OPT_OUT
                TAX_ACCOUNT_ACCESS -> ATA_TERM_NOT_ACCEPTED_TAX_ACCOUNT_ACCESS
                TAX_COMMUNICATION -> ATA_TERM_NOT_ACCEPTED_TAX_COMMUNICATION
                TAX_CORRESPONDENCE -> ATA_TERM_NOT_ACCEPTED_TAX_CORRESPONDENCE
                SG_BANK_ACCOUNT -> ATA_TERM_NOT_ACCEPTED_SG_BANK_ACCOUNT
                SG_FEE -> ATA_TERM_NOT_ACCEPTED_SG_FEE
            }
        }

        return errors
    }

    /**
     * Validates the driver licence values are valid.
     *
     * @return A `Set` of all the errors that occurred, or an empty `Set` if it is valid.
     */
    private fun validateDriverLicence(number: String?, version: String?): Set<AppErrorCode> {
        return buildSet {
            // Check the number is valid. Should be 2 alphabet characters then 6 numbers exactly.
            when {
                number.isNullOrBlank() -> add(GOV_ID_NUMBER_MISSING)
                !Regex("^[A-Za-z]{2}[0-9]{6}$").matches(number) -> add(DRIVER_LICENCE_NUMBER_INVALID)
            }

            // Check the version is valid. Should be 3 numbers long exactly.
            when {
                version.isNullOrBlank() -> add(DRIVER_LICENCE_VERSION_MISSING)
                !Regex("^[0-9]{3}$").matches(version) -> add(DRIVER_LICENCE_VERSION_INVALID)
            }
        }
    }

    /**
     * Validates the passport values are valid.
     *
     * @return A `Set` of all the errors that occurred, or an empty `Set` if it is valid.
     */
    private fun validatePassport(number: String?, expiryDate: LocalDate?): Set<AppErrorCode> {
        return buildSet {
            // Check the number is valid. Should be 7 or 8 characters exactly.
            when {
                number.isNullOrBlank() -> add(GOV_ID_NUMBER_MISSING)
                number.length < 7 || number.length > 8 -> add(PASSPORT_NUMBER_INVALID)
            }

            // Check the expiry date is valid. // TODO: Make sure it is not expired.
            if (expiryDate == null) {
                add(PASSPORT_EXPIRY_DATE_MISSING)
            }
        }
    }

    /**
     * Validates the [taxId] is valid.
     *
     * @return A `Set` of all the errors that occurred, or an empty `Set` if it is valid.
     */
    private fun validateTaxId(taxId: TaxId): Set<AppErrorCode> {
        return when {
            taxId.isBlank() -> setOf(TAX_ID_MISSING)
            !Regex("^[0-9]{8,9}$").matches(taxId.replace("-", "")) -> setOf(TAX_ID_INVALID)
            else -> emptySet()
        }
    }

    /**
     * Copies the provided values to the [Donor] and returns the updated object.
     */
    private fun Donor.withValues(email: String,
                                 firstName: String,
                                 middleName: String,
                                 lastName: String,
                                 dateOfBirth: LocalDate,
                                 phoneNumber: String,
                                 address: String,
                                 govId: IdDocument,
                                 bankAccountNumber: String,
                                 taxId: TaxId,
                                 schoolsDisbRecipient: DisbursementRecipient.Type,
                                 signature: Signature?,
                                 isMarketingEmailsEnabled: Boolean): Donor {
        val fullName = (this.legalName ?: FullName(firstName = "", lastName = "")).copy(firstName = firstName,
                                                                                        middleName = middleName,
                                                                                        lastName = lastName)
        val disbSettings = this.disbursementSettings + (SCHOOL to schoolsDisbRecipient)
        val otherEmails = if (this.email != email) {
            this.otherEmails + email
        } else {
            this.otherEmails
        }

        return this.copy(legalName = fullName,
                         dateOfBirth = dateOfBirth,
                         address = address,
                         govId = govId,
                         phoneNumber = phoneNumber,
                         bankAccountNumber = bankAccountNumber,
                         disbursementSettings = disbSettings.toMutableMap(),
                         otherEmails = otherEmails,
                         taxId = taxId,
                         signature = signature,
                         setupCompleteTimestamp =  if (this.signature != signature) {
                             Date.now().toLong()
                         } else {
                             this.setupCompleteTimestamp
                         },
                         isMarketingEmailsEnabled = isMarketingEmailsEnabled)
    }

    /**
     * Cancels the link between SG and Kindo.
     */
    public fun cancelLink() {
        launch {
            donationPlatformRepo.sendDonorLinkCancelMsgToKindo(kindoLinkParams = kindoLinkParams!!)
                    .ifError { reportError(POST_MESSAGE_ERROR) }
        }
    }

    /**
     * Searches for addresses using the [query] received and updates the [addressSearchResults] with the ones that
     * match, or reports an error through [errors].
     *
     * If the length of the [query] is less than 6 characters then no search is performed. This is done to save money
     * on searches that will return too many results because we use a third-party system for it.
     */
    public fun searchAddress(query: String) {
        launch {
            executeAction(updateLoadingStatus = false) { locationRepo.searchAddress(query) }?.let { searchResults ->
                _addressSearchResults.updateValueAsync(Event(searchResults))
            }
        }
    }

    /**
     * Reports the error with [errorCode] to Kindo. If reporting it to Kindo fails then log a
     * [KindoLinkError.POST_MESSAGE_ERROR].
     */
    private suspend fun sendErrorToKindo(errorCode: Int) {
        donationPlatformRepo.sendDonorLinkErrorMsgToKindo(errorCode)
                .ifError { reportError(POST_MESSAGE_ERROR) }
    }

    /**
     * Executes [errorAction] if the [Resource] is a [Resource.Error].
     */
    private inline fun Resource<*>.ifError(errorAction: () -> Unit) {
        if (this is Error) {
            errorAction()
        }
    }

}