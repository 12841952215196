package supergenerous.app.donor.setup.view

import com.supergenerous.common.disbursement.DisbursementRecipient.Type.DONOR
import com.supergenerous.common.disbursement.DisbursementRecipient.Type.RELIGIOUS_ORGS
import com.supergenerous.common.donee.Donee.Type.RELIGIOUS_ORG
import react.RBuilder
import react.State
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.RELIGIOUS_ORGS_SELECTION
import supergenerous.app.donor.setup.viewmodel.SetupViewModel

/**
 * Screen that allows donors to select which religious orgs they donate to.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class ReligiousOrgsSelectionScreen : SetupScreen<SetupScreenProps, State>() {

    override val setupStep: SetupStep = RELIGIOUS_ORGS_SELECTION


    override fun RBuilder.render() {
        doneesSelectionScreen(doneeType = RELIGIOUS_ORG,
                              disbRecipientTypes = listOf(RELIGIOUS_ORGS, DONOR),
                              setupViewModel = props.viewModel,
                              onBackBtnClick = ::goBack)
    }

}

/**
 * Renders a [ReligiousOrgsSelectionScreen] component.
 */
public fun RBuilder.religiousOrgsSelectionScreen(setupViewModel: SetupViewModel) {
    withRouter(ReligiousOrgsSelectionScreen::class) {
        attrs.viewModel = setupViewModel
    }
}