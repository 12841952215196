package supergenerous.app.core.util.format

/**
 * Formatter used to parse NZ Bank Account numbers.
 *
 * Formatting rules taken from [https://en.wikipedia.org/wiki/New_Zealand_bank_account_number]
 *
 * Formats to BB-bbbb-AAAAAAA-SSS, with SSS able to be either 2 or 3 digits
 *
 * * BB: bank
 * * bbbb: branch
 * * AAAAAAA: account
 * * SSS: suffix
 */
public class BankAccountFormatter : TextFormatter(format = "^[0-9]{0,2}-?[0-9]{0,4}-?[0-9]{0,7}-?[0-9]{0,3}$") {

    /**
     * Formats the [value] of any length into bank account format.
     *
     * Any hyphens (`-`) and spaces (` `) are stripped out.
     *
     * @return a string in the format BB-bbbb-AAAAAAA-SSS
     */
    override fun format(value: String): String {
        val shouldEndWithHyphen = value.endsWith("-") || value.endsWith(" ")
        val cleanedNumber = value.replace("-", "").replace(" ", "")
        // split the given string up into the 4 sections of a bank account number
        return listOf(cleanedNumber.substring(0, 2), // First 2 digits (bank)
                      cleanedNumber.substring(2, 6), // Next 4 digits (branch)
                      cleanedNumber.substring(6, 13), // Next 7 digits (account)
                      cleanedNumber.substring(13, 16)) // Last 2-3 digits (suffix)
                // filter out any substrings that are empty so we don't add extra hyphens
                .filter { it.isNotBlank() }
                .joinToString("-", postfix = if (shouldEndWithHyphen) "-" else "")
    }
}