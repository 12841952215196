package supergenerous.app.donor.dashboard.viewmodel

import com.hipsheep.kore.resource.Resource.Success
import com.hipsheep.kore.viewmodel.BaseViewModel
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.coroutine.asLiveData
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.supergenerous.common.donor.Donor
import kotlinx.coroutines.flow.mapNotNull
import supergenerous.app.donor.dashboard.view.personalInfoScreen
import supergenerous.app.donor.donor.DonorRepository

/**
 * [ViewModel] that provides data to the [personalInfoScreen].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
public class PersonalInfoViewModel(

    donorRepo: DonorRepository

) : BaseViewModel() {

    /**
     * Observable that receives updates when the data of the currently signed in [Donor] changes.
     */
    public val donor: LiveData<Donor> = donorRepo.getDonorUpdates()
            .mapNotNull { resource -> (resource as? Success)?.data }
            .asLiveData()

}