package supergenerous.app.core.search.model

/**
 * The fields available on the search service.
 */
public enum class SearchField(

    public val value: String,

) {

    NAME("name"),
    LEGAL_NAME("legalName"),
    OTHER_NAMES("otherNames"),
    TYPE("type")

}