package supergenerous.app.donor.dashboard.view

import com.hipsheep.kore.error.ErrorType
import com.supergenerous.common.donee.DoneeBasicInfo
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.network.CoreUrl.Path.SIGN_IN
import kotlinx.css.Display
import kotlinx.css.GridColumn
import kotlinx.css.GridRow
import kotlinx.css.display
import kotlinx.css.gridColumn
import kotlinx.css.gridRow
import kotlinx.css.height
import kotlinx.css.marginBottom
import kotlinx.css.padding
import kotlinx.css.paddingBottom
import kotlinx.css.px
import kotlinx.css.width
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.button.ButtonSize
import supergenerous.app.core.component.button.ButtonType
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.dialog.DialogSize
import supergenerous.app.core.component.dialog.dialog
import supergenerous.app.core.component.dividerHorizontal
import supergenerous.app.core.component.layout.grid
import supergenerous.app.core.component.menu.MenuItem
import supergenerous.app.core.component.menu.profileMenu
import supergenerous.app.core.component.snackbar.SnackbarMessageQueue
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.core.util.RouteProps
import supergenerous.app.core.util.component.toolbar
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.push
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.dashboard.view.DashboardScreen.ProfileMenuOption.*
import supergenerous.app.donor.dashboard.viewmodel.DashboardViewModel
import supergenerous.app.donor.dashboard.viewmodel.RebateClaimsViewModel
import supergenerous.app.donor.util.Url

/**
 * Screen that allows donors to view their account data and progress of claims by Supergenerous.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private class DashboardScreen : LifecycleOwnerComponent<DashboardScreenProps, DashboardScreenState>() {

    override fun DashboardScreenState.init() {
        showPersonalInfoDialog = false
        addressSearchResults = emptyList()
        doneeSearchResults = emptyList()
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                marginBottom = 48.px
            }

            toolbar(
                actionsRight = {
                    // Sign out button (shown only on desktop)
                    styledDiv {
                        css {
                            mobileScreen { display = Display.none }
                        }

                        button(label = SIGN_OUT.label,
                               type = ButtonType.ACCENT,
                               size = ButtonSize.EXTRA_SMALL,
                               onClick = ::signOut)
                    }

                    // Profile menu (shown only on mobile)
                    styledDiv {
                        css {
                            display = Display.none
                            mobileScreen { display = Display.block }

                            width = 40.px
                            height = 40.px
                        }

                        state.donor?.let { donor -> donorProfileMenu(donor) }
                    }
                }
            )
        }

        grid(isCentered = true) {
            css {
                paddingBottom = 24.px

                mobileScreen { paddingBottom = 32.px }
            }

            state.donor?.let { donor ->
                // Personal info panel (only shown on desktop)
                styledDiv {
                    css {
                        gridRow = GridRow("1 / 4")
                        gridColumn = GridColumn("1 / 5")
                        padding(top = 56.px, right = 24.px, bottom = 24.px, left = 24.px)

                        mobileScreen {
                            display = Display.none
                        }
                    }

                    personalInfoPanel(
                        donor = donor,
                        onEditInfoClick = { setState { showPersonalInfoDialog = true } }
                    )
                }

                styledDiv {
                    css {
                        gridColumn = GridColumn("5 / 13")

                        mobileScreen {
                            gridColumn = GridColumn("1 / 3")
                            padding(horizontal = 24.px)
                        }
                    }

                    // Donee orgs selection
                    orgSelectionPanel(
                        donor = donor,
                        isSavingData = state.isSavingData,
                        errors = state.errors,
                        onDoneesChange = props.viewModel::saveDoneesAndDisbSetting,
                        doneeSearchResults = state.doneeSearchResults,
                        onDoneeSearch = { namePartial, doneeType ->
                            props.viewModel.searchDonees(namePartial = namePartial, doneeType = doneeType)
                        }
                    )

                    dividerHorizontal(margin = 32.px)

                    // Claims section
                    rebateClaimsPanel(viewModel = props.rebateClaimsViewModel)
                }

                if (state.showPersonalInfoDialog) {
                    dialog(
                        title = "Edit details",
                        size = DialogSize.MEDIUM,
                        onClose = { setState { showPersonalInfoDialog = false } }
                    ) {
                        personalInfoEditPanel(
                            donor = donor,
                            onDonorChange = { donorUpdated, govIdInput ->
                                props.viewModel.saveDonor(donor = donorUpdated,
                                                          govIdInput = govIdInput)
                            },
                            onAddressSearch = props.viewModel::searchAddress,
                            addressSearchResults = state.addressSearchResults,
                            onAddressSelect = { setState { addressSearchResults = emptyList() } },
                            isSavingData = state.isSavingData,
                            errors = state.errors
                        )
                    }
                }
            }
        }
    }

    /**
     * Renders a menu that displays the [Donor]'s profile picture, or their first name initial if they don't have one.
     * When this image is clicked a menu pop-up with options.
     */
    private fun RBuilder.donorProfileMenu(donor: Donor) {
        profileMenu(
            items = listOf(MenuItem(label = PERSONAL_INFO.label, value = PERSONAL_INFO.name),
                           MenuItem(label = SIGN_OUT.label, value = SIGN_OUT.name)),
            user = donor,
            profileMenuSize = 40.px,
            onSelect = { menuItem ->
                when (valueOf(menuItem)) {
                    PERSONAL_INFO -> showPersonalInfoScreen()
                    SIGN_OUT -> signOut()
                }
            }
        )
    }

    /**
     * Signs out the [Donor].
     */
    private fun signOut() {
        props.viewModel.signOut()
    }

    /**
     * Navigates to [Url.Path.PERSONAL_INFO].
     */
    private fun showPersonalInfoScreen() {
        props.history.push(Url.Path.PERSONAL_INFO.value)
    }

    override fun componentDidMount() {
        super.componentDidMount()

        props.viewModel.donor.observe { setState { donor = it } }

        props.viewModel.addressSearchResults.observeEvent { setState { addressSearchResults = it } }
        props.viewModel.doneeSearchResults.observeEvent { setState { doneeSearchResults = it } }

        props.viewModel.isActionInProgress.observe { setState { isSavingData = it } }
        // When the donor is saved successfully, close the edit details dialog.
        props.viewModel.donorSavedEvent.observeEvent {
            SnackbarMessageQueue.add(body = "Saved successfully")
            setState { showPersonalInfoDialog = false }
        }

        props.viewModel.signOutSuccessEvent.observeEvent { props.history.push(SIGN_IN) }

        props.viewModel.errors.observeEvent { setState { errors = it } }
    }

    /*
     * Inner types
     */

    /**
     * Options displayed in the profile menu.
     */
    private enum class ProfileMenuOption(val label: String) {

        PERSONAL_INFO("My details"),
        SIGN_OUT("Log out")

    }

}

/**
 * Properties of the [DashboardScreen] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface DashboardScreenProps : RouteProps {

    /**
     * The view model for the dashboard.
     */
    var viewModel: DashboardViewModel

    /**
     * The view model for the rebate claims panel.
     */
    var rebateClaimsViewModel: RebateClaimsViewModel

}

/**
 * The state of the [DashboardScreen] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface DashboardScreenState : State {

    /**
     * Whether some data is currently being saved.
     */
    var isSavingData: Boolean

    /**
     * The latest errors that have occurred.
     */
    var errors: Set<ErrorType>?

    /**
     * The [Donor] to display details for.
     */
    var donor: Donor?

    /**
     * If `true` show the [PersonalInfoPanel] inside a dialog. If `false` then hide it.
     */
    var showPersonalInfoDialog: Boolean

    /**
     * The addresses that match what the user is typing in the address search field.
     */
    var addressSearchResults: List<TextSearchResult<String>>

    /**
     * The donees that match what the user is typing in the donee search field.
     */
    var doneeSearchResults: List<TextSearchResult<DoneeBasicInfo>>

}

/**
 * Renders a [DashboardScreen] component.
 */
public fun RBuilder.dashboardScreen(dashboardViewModel: DashboardViewModel,
                                    rebateClaimsViewModel: RebateClaimsViewModel) {
    withRouter(DashboardScreen::class) {
        attrs.viewModel = dashboardViewModel
        attrs.rebateClaimsViewModel = rebateClaimsViewModel
    }
}