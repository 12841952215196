package com.supergenerous.common.signature

import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * A signature provided by a user.
 */
@JvmInline
@Serializable
public value class Signature(private val v: String) {

    /**
     * The signature value (trimmed to remove extra spaces).
     */
    public val value: String
        get() = v.trim()

    /**
     * `true` if the signature is an image encoded as a Base64 `String`, or `false` if it's a "text" signature.
     */
    public val isImage: Boolean
        get() = value.startsWith("data:")

    /**
     * The data necessary to decode the image signature (removing the prefix).
     *
     * If [isImage] is `false` then this value is `null`.
     */
    public val imageData: String?
        get() = if (isImage) {
            value.removePrefix("data:image/png;base64,")
        } else {
            null
        }

}