package com.supergenerous.common.email

/**
 * Email addresses used in several parts of the system.
 */
public object EmailAddress {

    /**
     * Email address used to contact SG's CS team.
     */
    public const val SG_SUPPORT: String = "support@supergenerous.co.nz"

    /**
     * Email address used to handle all receipt related requests from donees and donation platforms.
     */
    public const val SG_RECEIPTS: String = "receipts@supergenerous.co.nz"

    /**
     * Email address for the archive of emails sent by the SG Bot.
     *
     * Every email send by the Bot should be BCC'd here.
     */
    public const val SG_BOT_EMAILS: String = "bot.emails@supergenerous.co.nz"

}