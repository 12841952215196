package supergenerous.app.core.auth.view

import kotlinx.css.Align
import kotlinx.css.CssBuilder
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.JustifyContent
import kotlinx.css.alignItems
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.justifyContent
import styled.StyleSheet

/**
 * List of CSS styles used in the auth views.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public object AuthStyle : StyleSheet("AuthStyle", isStatic = true) {

    /**
     * Aligns its items on a column and makes them stretch to fill the available width.
     */
    public val centredColumnFlex: CssBuilder.() -> Unit by css {
        display = Display.flex
        alignItems = Align.stretch
        justifyContent = JustifyContent.center
        flexDirection = FlexDirection.column
    }

}