package supergenerous.app.core.component.dialog

import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.Float
import kotlinx.css.Overflow
import kotlinx.css.borderRadius
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.float
import kotlinx.css.gap
import kotlinx.css.margin
import kotlinx.css.overflowY
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import materialui.dialog.MuiDialog
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import styled.css
import styled.styled
import styled.styledDiv
import supergenerous.app.core.component.button.iconButton
import supergenerous.app.core.component.dialog.DialogSize.*
import supergenerous.app.core.component.subheading1
import supergenerous.app.core.res.image.CoreIcon
import supergenerous.app.core.util.mobileScreen

/**
 * Dialog component used throughout the app.
 */
@JsExport
private class Dialog : RComponent<DialogProps, State>() {

    /**
     * Equivalent value of the [DialogSize] on Material UI.
     */
    private val DialogSize.muiValue: String
        get() = when (this) {
            SMALL -> "sm"
            MEDIUM -> "md"
            LARGE -> "lg"
        }


    override fun RBuilder.render() {
        @Suppress("DEPRECATION")
        styled(MuiDialog)() {
            css {
                descendants(".MuiPaper-root") {
                    borderRadius = 0.px
                }
            }

            attrs {
                open = true
                maxWidth = props.size.muiValue

                onBackdropClick = props.onClose
                onEscapeKeyDown = props.onClose
            }

            // 'X' button
            styledDiv {
                css {
                    width = 100.pct
                    padding(top = 20.px, right = 20.px)

                    // make the button align to the right
                    children {
                        float = Float.right
                    }

                    mobileScreen {
                        padding(top = 8.px, right = 8.px)
                    }
                }

                iconButton(
                    icon = CoreIcon.CLOSE,
                    size = 48.px,
                    onClick = props.onClose,
                )
            }

            styledDiv {
                css {
                    padding(top = 12.px, bottom = 48.px, left = 64.px, right = 64.px)

                    display = Display.flex
                    flexDirection = FlexDirection.column
                    gap = 24.px

                    // Makes the section with heading and contents scroll if it's too large, so that the close icon
                    // stays at the top of the dialog.
                    overflowY = Overflow.auto

                    mobileScreen {
                        margin(top = 8.px, bottom = 24.px, left = 32.px, right = 32.px)
                    }
                }

                // Header
                subheading1 { +props.title }

                // Content
                props.content(this)
            }
        }
    }

}

/**
 * Properties used by the [Dialog] component.
 */
private external interface DialogProps : Props {

    /**
     * Size to render the component.
     */
    var size: DialogSize

    /**
     * The dialog title.
     */
    var title: String

    /**
     * Content to render into the dialog below the [title].
     */
    var content: (RBuilder) -> Unit

    /**
     * Function called when the background is clicked or the "escape" key is pressed.
     */
    var onClose: () -> Unit

}

/**
 * Renders a [Dialog] component of [size] with the [title] and [content].
 *
 * [onClose] is called when the dialog is closed.
 */
public fun RBuilder.dialog(size: DialogSize = SMALL,
                           title: String,
                           onClose: () -> Unit,
                           content: RBuilder.() -> Unit) {
    child(Dialog::class) {
        attrs {
            this.size = size
            this.title = title
            this.content = content
            this.onClose = onClose
        }
    }
}