package supergenerous.app.core.util.format

import com.hipsheep.kore.util.capitalizeWords
import supergenerous.app.core.util.format.TextCapitalization.*

/**
 * Formats text into the specified [capitalization]. [FreeTextFormatter].[matchesFormat] always returns true.
 */
public class FreeTextFormatter(

    private val capitalization: TextCapitalization

) : TextFormatter(format = ".*") {

    /**
     * Formats [value] to [capitalization]
     */
    override fun format(value: String): String {
        return when (capitalization) {
            CAPITALIZE -> value.capitalizeWords()
            UPPERCASE -> value.toUpperCase()
            LOWERCASE -> value.toLowerCase()
            NONE -> value
        }
    }
}