package com.supergenerous.common.donor

import com.supergenerous.common.data.Dto
import com.supergenerous.common.donation.Donation
import kotlinx.serialization.Serializable

/**
 * Basic info from a [Donor] that is stored inside other classes (e.g., [Donation.donor]).
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class DonorBasicInfo(

    /**
     * Internal ID assigned by the SG system.
     */
    val id: String,

    /**
     * Name of the donor.
     */
    val name: String

) : Dto<DonorBasicInfoDbo> {

    override fun toDbo(): DonorBasicInfoDbo {
        return DonorBasicInfoDbo(id = id, name = name)
    }

}