package supergenerous.app.donor.dashboard.viewmodel

import com.hipsheep.kore.resource.Resource.Success
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.event.Event
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValue
import com.hipsheep.kore.viewmodel.lifecycle.updateValueAsync
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeBasicInfo
import com.supergenerous.common.donor.Donor
import kotlinx.coroutines.launch
import supergenerous.app.core.auth.model.AuthRepository
import supergenerous.app.core.location.LocationRepository
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.donor.dashboard.model.DashboardError.DISB_SETTINGS_MISSING
import supergenerous.app.donor.dashboard.view.dashboardScreen
import supergenerous.app.donor.donee.DoneeRepository
import supergenerous.app.donor.donor.DonorRepository

/**
 * [ViewModel] that provides data to the [dashboardScreen].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
public class DashboardViewModel(

    private val donorRepo: DonorRepository,
    private val authRepo: AuthRepository,
    private val doneeRepo: DoneeRepository,

    locationRepo: LocationRepository

) : PersonalInfoEditViewModel(donorRepo, locationRepo) {

    /**
     * Backing field for [signOutSuccessEvent].
     */
    private val _signOutSuccessEvent = MutableLiveData<Event<Unit>>()
    /**
     * Observable called when a [Donor] signs out successfully.
     */
    public val signOutSuccessEvent: LiveData<Event<Unit>> = _signOutSuccessEvent

    /**
     * Backing property for [doneeSearchResults].
     */
    private val _doneeSearchResults = MutableLiveData<Event<List<TextSearchResult<DoneeBasicInfo>>>>()
    /**
     * Observable that receives the result of the donee search action.
     */
    public val doneeSearchResults: LiveData<Event<List<TextSearchResult<DoneeBasicInfo>>>> = _doneeSearchResults


    /**
     * Overrides the [donees] of a certain [doneeType] for the signed in [Donor] and sets the [disbRecipientType] for
     * the [doneeType].
     */
    public fun saveDoneesAndDisbSetting(donees: Set<DoneeBasicInfo>,
                                        doneeType: Donee.Type,
                                        disbRecipientType: DisbursementRecipient.Type?,
                                        childrenNames: Set<String>) {
        if (donees.isNotEmpty() && disbRecipientType == null) {
            reportError(DISB_SETTINGS_MISSING)
            return
        }

        launch {
            executeAction {
                donorRepo.saveDoneesAndDisbSetting(donees, doneeType, disbRecipientType, childrenNames)
            }?.let { _donorSavedEvent.updateValue(Event(Unit)) }
        }
    }

    /**
     * Searches for donees that match [namePartial] and [doneeType].
     */
    public fun searchDonees(namePartial: String, doneeType: Donee.Type) {
        launch {
            val searchRes = doneeRepo.searchDonees(namePartial = namePartial, doneeType = doneeType)

            // Don't use "executeAction()" because in the event of an error we don't want to highlight that to the user
            _doneeSearchResults.updateValueAsync(Event((searchRes as? Success)?.data ?: emptyList()))
        }
    }

    /**
     * Signs out the current user.
     */
    public fun signOut() {
        launch {
            executeAction { authRepo.signOut() }
                    ?.let { _signOutSuccessEvent.updateValueAsync(Event(Unit)) }
        }
    }

}