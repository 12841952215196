package supergenerous.app.donor.setup.view

import com.hipsheep.kore.error.ErrorType
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.Donee.Type.*
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.gap
import kotlinx.css.marginBottom
import kotlinx.css.marginLeft
import kotlinx.css.px
import react.RBuilder
import react.State
import react.dom.div
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.common.valuePlural
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.body2
import supergenerous.app.core.component.checkbox
import supergenerous.app.core.component.subheading2
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.setup.model.SetupError.DONEE_TYPE_MISSING
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.DONEE_TYPES_SELECTION
import supergenerous.app.donor.setup.viewmodel.SetupViewModel

/**
 * Screen that allows donors to select which types of donees they donate to.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class DoneeTypesSelectionScreen : SetupScreen<SetupScreenProps, DoneeTypesSelectionScreenState>() {

    override val setupStep: SetupStep = DONEE_TYPES_SELECTION


    override fun DoneeTypesSelectionScreenState.init() {
        doneeTypesSelected = setOf()
        showDoneeTypeError = false
    }

    override fun RBuilder.render() {
        setupInputContainer(
            title = { +"Your donations" },
            subtitle = { +"Kia ora! To get started, please select the organisation types you have donated to recently, or in the last 4 years." },
            setupViewModel = props.viewModel,
            onNextBtnClick = ::saveDoneeTypes,
            onBackBtnClick = ::goBack
        ) {
            styledDiv {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    gap = 16.px
                }

                div {
                    subheading2 {
                        css {
                            marginBottom = 4.px
                        }

                        +"Direct donations"
                    }

                    body1 { +"Donations you have made directly to a charity, not through a donation platform." }
                }

                checkbox(doneeType = CHARITY)

                checkbox(doneeType = RELIGIOUS_ORG)

                div {
                    checkbox(doneeType = SCHOOL)
                    body2 {
                        css {
                            marginLeft = 38.px
                        }

                        +"School fees usually include donations."
                    }
                }
            }
        }
    }

    /**
     * Creates a checkbox for the [doneeType] and returns it.
     */
    private fun RBuilder.checkbox(doneeType: Donee.Type) {
        checkbox(
            label = doneeType.valuePlural.capitalize(),
            isChecked = state.doneeTypesSelected.contains(doneeType),
            onChange = { isChecked ->
                setState {
                    doneeTypesSelected = if (isChecked) {
                        doneeTypesSelected + doneeType
                    } else {
                        doneeTypesSelected - doneeType
                    }

                    showDoneeTypeError = false
                }
            },
            showError = state.showDoneeTypeError
        )
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // Load the values already provided by the donor (if any)
        props.viewModel.donor.observe { donor ->
            // The donee types selected by the user before are the ones they provided donees for
            val doneeTypes = donor.donees.map { it.type }.toSet()
            setState { doneeTypesSelected = doneeTypes }
        }

        props.viewModel.errors.observeEvent { errors ->
            errors.forEach { error ->
                when ((error as? ErrorType.AppError)?.code) {
                    DONEE_TYPE_MISSING -> setState { showDoneeTypeError = true }
                }
            }
        }
    }

    /**
     * @see [SetupViewModel.saveDoneeTypes]
     */
    private fun saveDoneeTypes() {
        props.viewModel.saveDoneeTypes(state.doneeTypesSelected)
    }

}

/**
 * State of the [DoneeTypesSelectionScreen] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface DoneeTypesSelectionScreenState : State {

    /**
     * Donee types selected by the user.
     */
    var doneeTypesSelected: Set<Donee.Type>

    /**
     * `true` if there was an error that occurred concerning the [doneeTypesSelected], or `false` otherwise.
     */
    var showDoneeTypeError: Boolean

}

/**
 * Renders a [DoneeTypesSelectionScreen] component.
 */
public fun RBuilder.doneeTypesSelectionScreen(setupViewModel: SetupViewModel) {
    withRouter(DoneeTypesSelectionScreen::class) {
        attrs.viewModel = setupViewModel
    }
}