package supergenerous.app.core.util.format

/**
 * Formats text as an email. Matches on any characters valid in (most) email addresses.
 */
public class EmailFormatter : TextFormatter(format = "^\\S*$") {

    /**
     * Formats [value] to lowercase and removes white spaces.
     */
    override fun format(value: String): String {
        return value.lowercase().replace(oldValue = " ", newValue = "")
    }

}