package supergenerous.app.core.res

/**
 * The available colors for the SG logo to be.
 */
public enum class SgLogoColor {

    GREEN,
    WHITE

}