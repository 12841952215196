package supergenerous.app.core.component.snackbar

/**
 * Message to show in a [snackbar].
 */
internal data class SnackbarMessage(

    /**
     * Optional title to show at the top.
     */
    val title: String?,

    /**
     * Body fo the message to show under the [title], or by itself if [title] is `null`.
     */
    val body: String

)