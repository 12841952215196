package supergenerous.app.core.component.textfield

/**
 * Types of [TextField]s used in the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class TextFieldType {

    /**
     * Any text character can be used as input.
     */
    TEXT,

    /**
     * Only numbers can be used as input.
     */
    NUMBER,

    /**
     * Only numbers and spaces can be used as input.
     */
    PHONE_NUMBER,

    /**
     * Formats as email address.
     */
    EMAIL,

    /**
     * Formats input text as IRD number format [#]##-###-###
     */
    IRD_NUMBER,

    /**
     * Formats input text as NZ bank account number format BB-bbbb-AAAAAAA-SSS
     */
    BANK_ACCOUNT_NUMBER,

    /**
     * Allows any character, masks the text input.
     */
    PASSWORD,

}