package supergenerous.app.core.component.tabs

import kotlinx.css.Align
import kotlinx.css.BorderStyle
import kotlinx.css.Cursor
import kotlinx.css.Display
import kotlinx.css.JustifyContent
import kotlinx.css.LinearDimension.Companion.auto
import kotlinx.css.alignItems
import kotlinx.css.borderBottomColor
import kotlinx.css.borderBottomStyle
import kotlinx.css.borderBottomWidth
import kotlinx.css.cursor
import kotlinx.css.display
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import react.RBuilder
import react.RComponent
import react.State
import react.dom.div
import react.dom.onClick
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.dividerHorizontal
import supergenerous.app.core.res.Color
import supergenerous.app.core.util.ScreenSizeProps
import supergenerous.app.core.util.withScreenSize

/**
 * Tabs component used to switch between different content.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@JsExport
private class Tabs : RComponent<TabsProps, State>() {

    override fun RBuilder.render() {
        div {
            styledDiv {
                css {
                    display = Display.flex
                    width = 100.pct
                }

                props.tabs.forEach { tab ->
                    tab(tab)
                }
            }

            // Add a horizontal line under the tabs to separate them from the content
            dividerHorizontal(margin = 0.px)
        }
    }

    /**
     * Renders the [tab].
     */
    private fun RBuilder.tab(tab: Tab) {
        styledDiv {
            css {
                cursor = Cursor.pointer
                padding(all = 8.px)

                if (props.isMobileScreen) {
                    // For mobile, use default values which minimise space used
                    width = auto
                    height = auto
                } else {
                    // If this is viewed on the desktop then use larger fixed width tabs with centred text
                    display = Display.flex
                    alignItems = Align.center
                    justifyContent = JustifyContent.center

                    width = 200.px
                    height = 48.px
                }

                // Highlight the item that is currently selected
                if (tab.value == props.tabSelectedValue) {
                    borderBottomWidth = 4.px
                    borderBottomColor = Color.PRIMARY.cssValue
                    borderBottomStyle = BorderStyle.solid
                }
            }

            attrs.onClick = { props.onSelect(tab.value) }

            body1 { +tab.label }
        }
    }

}

/**
 * Properties used by the [Tabs] component.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
private external interface TabsProps : ScreenSizeProps {

    /**
     * The [tabs] to render.
     */
    var tabs: List<Tab>

    /**
     * The [Tab.value] of a tab in [tabs], to render as selected.
     */
    var tabSelectedValue: String

    /**
     * Function called when a tab is selected.
     */
    var onSelect: (tabValue: String) -> Unit

}

/**
 * Renders a [Tabs] component.
 */
public fun RBuilder.tabs(tabs: List<Tab>,
                         tabSelectedValue: String,
                         onSelect: (tabValue: String) -> Unit) {
    withScreenSize(Tabs::class) {
        attrs.tabs = tabs
        attrs.tabSelectedValue = tabSelectedValue
        attrs.onSelect = onSelect
    }
}