package supergenerous.app.donor.setup.view

import com.hipsheep.kore.viewmodel.ViewModel
import kotlinx.css.GridColumn
import kotlinx.css.gridColumn
import kotlinx.css.marginTop
import kotlinx.css.px
import react.Props
import react.RBuilder
import react.State
import react.router.dom.History
import react.router.dom.Switch
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.layout.grid
import supergenerous.app.core.util.component.toolbar
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.push
import supergenerous.app.core.util.redirect
import supergenerous.app.core.util.route
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.*
import supergenerous.app.donor.setup.model.SetupStep.PERSONAL_INFO
import supergenerous.app.donor.setup.viewmodel.SetupViewModel
import supergenerous.app.donor.util.Url
import supergenerous.app.donor.util.Url.Path.*

/**
 * Component that contains all the screens of the setup process.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class SetupContainer : LifecycleOwnerComponent<SetupContainerProps, State>() {

    /**
     * The [Url.Path] that corresponds to the [SetupStep].
     */
    private val SetupStep.path: Url.Path
        get() = when (this) {
            PERSONAL_INFO -> SETUP_PERSONAL_INFO
            CONTACT_INFO -> SETUP_CONTACT_INFO
            TAX_ID -> SETUP_TAX_ID
            AUTHORITY_TO_ACT -> SETUP_AUTHORITY
            GOV_ID -> SETUP_GOV_ID
            DONEE_TYPES_SELECTION -> SETUP_DONEE_TYPES
            CHARITIES_SELECTION -> SETUP_CHARITIES
            RELIGIOUS_ORGS_SELECTION -> SETUP_RELIGIOUS_ORGS
            SCHOOLS_SELECTION -> SETUP_SCHOOLS
            BANK_ACCOUNT -> SETUP_BANK_ACCOUNT
            END -> SETUP_END
        }

    override fun RBuilder.render() {
        toolbar()

        grid(isCentered = true) {
            styledDiv {
                css {
                    marginTop = 32.px
                    gridColumn = GridColumn("3 / 11")
                    mobileScreen {
                        gridColumn = GridColumn("1 / 3")
                    }
                }

                Switch {
                    // If the user navigates to '/setup' without a setup step, then go to the first step.
                    route(SETUP, exact = true) { redirect(SetupStep.values().first().path) }

                    route(SETUP_PERSONAL_INFO, exact = true) { personalInfoScreen(props.setupViewModel) }
                    route(SETUP_CONTACT_INFO, exact = true) { contactInfoScreen(props.setupViewModel) }
                    route(SETUP_TAX_ID, exact = true) { taxIdScreen(props.setupViewModel) }
                    route(SETUP_GOV_ID, exact = true) { govIdScreen(props.setupViewModel) }
                    route(SETUP_AUTHORITY, exact = true) { authorityToActScreen(props.setupViewModel) }
                    route(SETUP_DONEE_TYPES, exact = true) { doneeTypesSelectionScreen(props.setupViewModel) }
                    route(SETUP_CHARITIES, exact = true) { charitiesSelectionScreen(props.setupViewModel) }
                    route(SETUP_RELIGIOUS_ORGS, exact = true) { religiousOrgsSelectionScreen(props.setupViewModel) }
                    route(SETUP_SCHOOLS, exact = true) { schoolsSelectionScreen(props.setupViewModel) }
                    route(SETUP_BANK_ACCOUNT, exact = true) { bankAccountScreen(props.setupViewModel) }
                    route(SETUP_END, exact = true) { setupEndScreen(props.setupViewModel) }
                }
            }
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // Observe the changes in the setup process steps and show the proper screen as the user moves through them
        props.setupViewModel.nextSetupStep.observe { setupStep -> props.routeHistory.push(setupStep.path) }
    }

}

/**
 * Properties used by the [SetupContainer] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface SetupContainerProps : Props {

    /**
     * [ViewModel] shared between the setup process screens.
     */
    var setupViewModel: SetupViewModel

    var routeHistory: History

}

/**
 * Renders a [SetupContainer] component.
 */
public fun RBuilder.setupContainer(setupViewModel: SetupViewModel, routeHistory: History) {
    child(SetupContainer::class) {
        attrs {
            this.setupViewModel = setupViewModel
            this.routeHistory = routeHistory
        }
    }
}