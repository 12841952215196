package supergenerous.app.donor.dashboard.view

import com.hipsheep.kore.error.ErrorType
import com.supergenerous.common.donor.Donor
import kotlinx.css.GridColumn
import kotlinx.css.gridColumn
import kotlinx.css.marginBottom
import kotlinx.css.padding
import kotlinx.css.paddingBottom
import kotlinx.css.px
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.layout.grid
import supergenerous.app.core.util.RouteProps
import supergenerous.app.core.util.component.toolbar
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.push
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.dashboard.viewmodel.PersonalInfoViewModel
import supergenerous.app.donor.util.Url

/**
 * Screen that allows donors to view their personal information.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private class PersonalInfoScreen : LifecycleOwnerComponent<PersonalInfoScreenProps, PersonalInfoScreenState>() {

    override fun RBuilder.render() {
        styledDiv {
            css {
                marginBottom = 48.px
            }

            toolbar(
                onBackBtnClick = { props.history.push(Url.Path.DASHBOARD) }
            )
        }

        grid(isCentered = true) {
            css {
                paddingBottom = 24.px

                mobileScreen { paddingBottom = 32.px }
            }

            styledDiv {
                css {
                    gridColumn = GridColumn("4 / 10")
                    padding(top = 0.px, right = 24.px, bottom = 24.px, left = 24.px)

                    mobileScreen {
                        gridColumn = GridColumn("1 / 3")
                    }
                }

                state.donor?.let { donor ->
                    personalInfoPanel(
                        donor = donor,
                        onEditInfoClick = { props.history.push(Url.Path.PERSONAL_INFO_EDIT) }
                    )
                }
            }
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        props.viewModel.donor.observe { setState { donor = it } }

        props.viewModel.isActionInProgress.observe { setState { isSavingData = it } }

        props.viewModel.errors.observeEvent { setState { errors = it } }
    }

}

/**
 * Properties of the [PersonalInfoScreen] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface PersonalInfoScreenProps : RouteProps {

    /**
     * View model used to get/update data.
     */
    var viewModel: PersonalInfoViewModel

}

/**
 * The state of the [PersonalInfoScreen] component.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface PersonalInfoScreenState : State {

    /**
     * The [Donor] to display info for.
     */
    var donor: Donor?

    /**
     * Whether the data is currently being saved.
     */
    var isSavingData: Boolean

    /**
     * The latest errors that have occurred.
     */
    var errors: Set<ErrorType>?

}

/**
 * Renders a [PersonalInfoScreen] component.
 */
public fun RBuilder.personalInfoScreen(viewModel: PersonalInfoViewModel) {
    withRouter(PersonalInfoScreen::class) {
        attrs.viewModel = viewModel
    }
}