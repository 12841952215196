package supergenerous.app.donor.setup.viewmodel

import com.hipsheep.kore.error.AppErrorCode
import com.hipsheep.kore.resource.Resource
import com.hipsheep.kore.resource.Resource.Success
import com.hipsheep.kore.viewmodel.BaseViewModel
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.coroutine.asLiveData
import com.hipsheep.kore.viewmodel.event.Event
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.data
import com.hipsheep.kore.viewmodel.lifecycle.updateValue
import com.hipsheep.kore.viewmodel.lifecycle.updateValueAsync
import com.supergenerous.common.bank.isValid
import com.supergenerous.common.bank.toDashFormat
import com.supergenerous.common.disbursement.DisbursementRecipient
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.Donee.Type.*
import com.supergenerous.common.donee.DoneeBasicInfo
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.donor.firstName
import com.supergenerous.common.donor.lastName
import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.id.IdDocument.Type.*
import com.supergenerous.common.name.FullName
import com.supergenerous.common.signature.Signature
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate
import supergenerous.app.core.analytics.AnalyticsEvent.AtaSigned
import supergenerous.app.core.analytics.AnalyticsRepository
import supergenerous.app.core.location.LocationRepository
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.donor.donee.DoneeRepository
import supergenerous.app.donor.donor.DonorRepository
import supergenerous.app.donor.setup.model.AuthorityToActTerm
import supergenerous.app.donor.setup.model.AuthorityToActTerm.*
import supergenerous.app.donor.setup.model.SetupError
import supergenerous.app.donor.setup.model.SetupError.*
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.*

/**
 * [ViewModel] that provides data to the screens involved in the setup process.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public class SetupViewModel(

    private val donorRepo: DonorRepository,
    private val doneeRepo: DoneeRepository,
    private val locationRepo: LocationRepository,
    private val analyticsRepo: AnalyticsRepository

) : BaseViewModel() {

    /**
     * List of steps to take the donor through for setup/onboarding.
     *
     * The steps in this list depend on the donee types selected by the donor during the process.
     */
    private var setupSteps: MutableSet<SetupStep> = SetupStep.values().toMutableSet()

    /**
     * Backing property for [nextSetupStep].
     */
    private val _currentSetupStep = MutableLiveData<SetupStep>()
    /**
     * Observable that receives the current [SetupStep] the donor is in.
     */
    public val currentSetupStep: LiveData<SetupStep> = _currentSetupStep

    /**
     * Backing property for [nextSetupStep].
     */
    private val _nextSetupStep = MutableLiveData<SetupStep>()
    /**
     * Observable that receives the next [SetupStep] to show.
     */
    public val nextSetupStep: LiveData<SetupStep> = _nextSetupStep

    /**
     * Backing property for [setupProgress].
     */
    private val _setupProgress = MutableLiveData<Int>()
    /**
     * Observable that receives updates of the setup progress as the donor moves through the process.
     */
    public val setupProgress: LiveData<Int> = _setupProgress

    // TODO: Remove once the dashboard is live
    private lateinit var donorCache: Donor

    /**
     * Observable that receives updates when the data of the currently signed in [Donor] changes.
     */
    public val donor: LiveData<Donor> = donorRepo.getDonorUpdates()
            .filter { resource ->
                // TODO: Careful when doing this as it sends an error when the user signs out
                //                if (resource is Error) {
                //                    reportError(resource)
                //                }

                resource is Success
            }.map { resource -> (resource as Success).data }
            // use onEach to keep side-effects out of other pure functions.
            .onEach { donor -> donorCache = donor }
            .asLiveData()

    /**
     * Backing property for [addressSearchResults].
     */
    private val _addressSearchResults = MutableLiveData<Event<List<TextSearchResult<String>>>>()
    /**
     * Observable that receives the result of the addresses search action.
     *
     * @see [searchAddress]
     */
    public val addressSearchResults: LiveData<Event<List<TextSearchResult<String>>>> = _addressSearchResults

    /**
     * Backing property for [doneeSearchResults].
     */
    private val _doneeSearchResults = MutableLiveData<Event<List<TextSearchResult<DoneeBasicInfo>>>>()
    /**
     * Observable that receives the result of the donee search action.
     */
    public val doneeSearchResults: LiveData<Event<List<TextSearchResult<DoneeBasicInfo>>>> = _doneeSearchResults

    /**
     * List of terms that need to be accepted by the user.
     */
    public val ataTerms: Set<AuthorityToActTerm> = AuthorityToActTerm.values().toSet()



    /**
     * Sets the setup step the donor is currently in and updates the progress accordingly.
     */
    public fun setCurrentSetupStep(setupStep: SetupStep) {
        _currentSetupStep.updateValue(setupStep)

        // Update setup progress
        _setupProgress.updateValue(setupStep.position)
    }

    /**
     * @see [DonorRepository.savePersonalInfo]
     */
    public fun savePersonalInfo(firstName: String?,
                                middleName: String?,
                                lastName: String?,
                                address: String?) {
        val errors = mutableSetOf<SetupError>()

        if (firstName.isNullOrBlank()) errors += FIRST_NAME_MISSING
        if (lastName.isNullOrBlank()) errors += LAST_NAME_MISSING
        if (address.isNullOrBlank()) errors += ADDRESS_MISSING

        if (errors.isNotEmpty()) {
            reportErrors(errors)
            return
        }

        val legalName = FullName(firstName = firstName!!.trim(),
                                 middleName = middleName?.trim(),
                                 lastName = lastName!!.trim())

        // If the donor already signed the ATA then remove that step from the flow
        if (!donorCache.signature?.value.isNullOrBlank()) {
            setupSteps.remove(AUTHORITY_TO_ACT)
        }

        executeDonorAction { donorRepo.savePersonalInfo(legalName, address!!) }
    }

    /**
     * @see [DonorRepository.saveContactInfo]
     */
    public fun saveContactInfo(otherEmails: Set<String>, phoneNumber: String?, isMarketingEmailsEnabled: Boolean) {
        if (phoneNumber.isNullOrBlank()) {
            reportError(PHONE_NUMBER_MISSING)
            return
        }

        executeDonorAction {
            donorRepo.saveContactInfo(otherEmails = otherEmails,
                                      phoneNumber = phoneNumber,
                                      isMarketingEmailsEnabled = isMarketingEmailsEnabled)
        }
    }

    /**
     * Validates and, if valid, saves the [taxId].
     *
     * @see [DonorRepository.saveTaxId]
     */
    public fun saveTaxId(taxId: String?) {
        val taxIdSanitised = taxId?.replace("-", "")
        when {
            taxId.isNullOrBlank() -> {
                reportError(TAX_ID_MISSING)
                return
            }
            !Regex("^[0-9]{8,9}$").matches(taxIdSanitised!!) -> {
                reportError(TAX_ID_INVALID)
                return
            }
        }

        executeDonorAction { donorRepo.saveTaxId(taxId!!) }
    }

    /**
     * @see [DonorRepository.saveSignature]
     */
    public fun saveSignature(signatureStr: String?, termsAccepted: Set<AuthorityToActTerm>) {
        val signature = signatureStr?.let { Signature(it) }
        val errors = mutableSetOf<SetupError>()

        if (signature?.value.isNullOrBlank()) {
            errors += ATA_SIGNATURE_MISSING
        } else {
            if (!signature!!.isImage) {
                val signatureExpected = "${donorCache.firstName} ${donorCache.lastName}"

                if (!signature.value.equals(other = signatureExpected, ignoreCase = true)) {
                    errors += ATA_SIGNATURE_INVALID
                }
            }
        }

        errors += (ataTerms - termsAccepted).map { termNotAccepted ->
            when (termNotAccepted) {
                TAX_AGENT -> ATA_TERM_NOT_ACCEPTED_TAX_AGENT
                SG_OPT_OUT -> ATA_TERM_NOT_ACCEPTED_SG_OPT_OUT
                TAX_ACCOUNT_ACCESS -> ATA_TERM_NOT_ACCEPTED_TAX_ACCOUNT_ACCESS
                TAX_COMMUNICATION -> ATA_TERM_NOT_ACCEPTED_TAX_COMMUNICATION
                TAX_CORRESPONDENCE -> ATA_TERM_NOT_ACCEPTED_TAX_CORRESPONDENCE
                SG_BANK_ACCOUNT -> ATA_TERM_NOT_ACCEPTED_SG_BANK_ACCOUNT
                SG_FEE -> ATA_TERM_NOT_ACCEPTED_SG_FEE
            }
        }

        if (errors.isNotEmpty()) {
            reportErrors(errors)
            return
        }

        executeDonorAction { donorRepo.saveSignature(signature!!) }
    }

    /**
     * Searches for addresses using the [query] received and updates the [addressSearchResults] with the ones that
     * match, or reports an error through [errors].
     *
     * If the length of the [query] is less than 6 characters then no search is performed. This is done to save money
     * on searches that will return too many results because we use a third-party system for it.
     */
    public fun searchAddress(query: String) {
        launch {
            executeAction(updateLoadingStatus = false) { locationRepo.searchAddress(query) }?.let { searchResults ->
                _addressSearchResults.updateValueAsync(Event(searchResults))
            }
        }
    }

    /**
     * Updates the [setupSteps] list by removing the donee selection steps that don't relate to the
     * [doneeTypesSelected] by the donor.
     */
    public fun saveDoneeTypes(doneeTypesSelected: Set<Donee.Type>) {
        if (doneeTypesSelected.isEmpty()) {
            reportError(DONEE_TYPE_MISSING)
            return
        }

        /*
         * Reset the donee selection setup steps list before removing the donee selection steps not selected by the user
         * (otherwise this won't work properly when the user goes back to the donee types selection screen from the
         * screen that comes after)
         */
        val doneeSelectionSteps = setOf(CHARITIES_SELECTION, SCHOOLS_SELECTION, RELIGIOUS_ORGS_SELECTION)
        setupSteps = (setupSteps + doneeSelectionSteps).sortedBy { it.position }.toMutableSet()

        val doneeTypesAll = listOf(CHARITY, SCHOOL, RELIGIOUS_ORG)
        // Calculate the donee types NOT selected by the donor
        val doneeTypesNotSelected = doneeTypesAll - doneeTypesSelected

        // Get the donee selection steps to skip that are linked to the donee types not selected by the donor
        val setupStepsToSkip = doneeTypesNotSelected.map { doneeType ->
            when (doneeType) {
                CHARITY -> CHARITIES_SELECTION
                SCHOOL -> SCHOOLS_SELECTION
                RELIGIOUS_ORG -> RELIGIOUS_ORGS_SELECTION
            }
        }

        setupSteps.removeAll(setupStepsToSkip)

        // Move to the next step
        launch { updateNextSetupStep() }
    }

    /**
     * Overrides the [donees] of a certain [doneeType] for the signed in [Donor] and sets the [disbRecipientType] for
     * the [doneeType].
     */
    public fun saveDoneesAndDisbSetting(donees: Set<DoneeBasicInfo>,
                                        doneeType: Donee.Type,
                                        disbRecipientType: DisbursementRecipient.Type?,
                                        childrenNames: Set<String>) {
        if (donees.isEmpty()) {
            // If donees is empty we can return without checking other things because there must be a selection in order
            // to see the other fields
            reportError(DONEE_SELECTION_MISSING)
            return
        }

        if (disbRecipientType == null) {
            reportError(DONEE_DISB_RECIPIENT_MISSING)
            return
        }

        executeDonorAction { donorRepo.saveDoneesAndDisbSetting(donees, doneeType, disbRecipientType, childrenNames) }
    }

    /**
     * Validates the [bankAccountNumber] and, if valid, saves it. Otherwise, reports an error to [errors].
     *
     * @see [DonorRepository.saveBankAccountNumber]
     */
    public fun saveBankAccountNumber(bankAccountNumber: String?) {
        val bankAccNumberTrimmed = bankAccountNumber?.trim()
        when {
            bankAccNumberTrimmed.isNullOrBlank() -> {
                reportError(BANK_ACCOUNT_MISSING)
                return
            }
            !bankAccNumberTrimmed.isValid() -> {
                reportError(BANK_ACCOUNT_INVALID)
                return
            }
        }

        executeDonorAction { donorRepo.saveBankAccountNumber(bankAccNumberTrimmed!!.toDashFormat()) }
    }

    /**
     * Executes the [action] received, sending updates to the [isActionInProgress] observable as the action is being
     * executed, and shows the next setup screen if the action completed successfully, or sends the error that occurred
     * to the UI through the [errors] observable otherwise.
     */
    private fun executeDonorAction(action: suspend () -> Resource<Donor>) {
        launch {
            executeAction(action = action)?.let {
                if (currentSetupStep.data == AUTHORITY_TO_ACT) {
                    // Log event that indicates the donor signed the ATA
                    analyticsRepo.logEventOnce(AtaSigned)
                }

                updateNextSetupStep()
            }
        }
    }

    /**
     * Updates the [nextSetupStep] by getting the value of the step that is after [currentSetupStep] in [setupSteps].
     */
    private suspend fun updateNextSetupStep() {
        val nextStepPos = setupSteps.indexOf(currentSetupStep.data) + 1
        _nextSetupStep.updateValueAsync(setupSteps.elementAt(nextStepPos))
    }

    /**
     * Searches for donees that match [namePartial] and [doneeType].
     */
    public fun searchDonees(namePartial: String, doneeType: Donee.Type) {
        launch {
            val searchRes = doneeRepo.searchDonees(namePartial = namePartial, doneeType = doneeType)

            // Don't use "executeAction()" because in the event of an error we don't want to highlight that to the user
            _doneeSearchResults.updateValueAsync(Event((searchRes as? Success)?.data ?: emptyList()))
        }
    }

    /**
     * @see [DonorRepository.saveGovId]
     */
    public fun saveGovId(dateOfBirth: LocalDate?,
                         govIdType: IdDocument.Type?,
                         govIdNumber: String?,
                         driverLicenceVersion: String?,
                         govIdExpiryDate: LocalDate?) {
        val govIdNumberTrim = govIdNumber?.trim()

        val errors = mutableSetOf<AppErrorCode>()

        if (dateOfBirth == null) {
            errors += DATE_OF_BIRTH_MISSING
        }

        errors += when (govIdType) {
            null -> setOf(GOV_ID_TYPE_MISSING)
            DRIVER_LICENCE_NZ -> validateDriverLicence(number = govIdNumberTrim, version = driverLicenceVersion)
            PASSPORT -> validatePassport(number = govIdNumberTrim, expiryDate = govIdExpiryDate)
        }

        if (errors.isNotEmpty()) {
            reportErrors(errors)
            return
        }

        val govId = when (govIdType!!) {
            DRIVER_LICENCE_NZ -> IdDocument.DriverLicenceNz(number = govIdNumberTrim!!,
                                                            version = driverLicenceVersion!!.trim())
            PASSPORT -> IdDocument.Passport(number = govIdNumberTrim!!, expiryDate = govIdExpiryDate!!)
        }

        executeDonorAction { donorRepo.saveGovId(govId, dateOfBirth!!) }
    }

    /**
     * Validates the driver licence values are valid.
     *
     * @return A `Set` of all the errors that occurred, or an empty `Set` if it is valid.
     */
    private fun validateDriverLicence(number: String?, version: String?): Set<AppErrorCode> {
        return buildSet {
            // Check the number is valid. Should be 2 alphabet characters then 6 numbers exactly.
            when {
                number.isNullOrBlank() -> add(GOV_ID_NUMBER_MISSING)
                !Regex("^[A-Za-z]{2}[0-9]{6}$").matches(number) -> add(DRIVER_LICENCE_NUMBER_INVALID)
            }

            // Check the version is valid. Should be 3 numbers long exactly.
            when {
                version.isNullOrBlank() -> add(DRIVER_LICENCE_VERSION_MISSING)
                !Regex("^[0-9]{3}$").matches(version) -> add(DRIVER_LICENCE_VERSION_INVALID)
            }
        }
    }

    /**
     * Validates the passport values are valid.
     *
     * @return A `Set` of all the errors that occurred, or an empty `Set` if it is valid.
     */
    private fun validatePassport(number: String?, expiryDate: LocalDate?): Set<AppErrorCode> {
        return buildSet {
            // Check the number is valid. Should be 7 or 8 characters exactly.
            when {
                number.isNullOrBlank() -> add(GOV_ID_NUMBER_MISSING)
                number.length < 7 || number.length > 8 -> add(PASSPORT_NUMBER_INVALID)
            }

            // Check the expiry date is valid. // TODO: Make sure it is not expired.
            if (expiryDate == null) {
                add(PASSPORT_EXPIRY_DATE_MISSING)
            }
        }
    }

}