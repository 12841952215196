package com.supergenerous.common.donor

import com.supergenerous.common.data.Dto
import com.supergenerous.common.donor.ActiveStatus.Id.*
import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable

/**
 * Possible active statuses donors can be in.
 */
@Serializable
public sealed class ActiveStatus(

    /**
     * ID of the [ActiveStatus], used for storing the status in the DB and to get all possible statuses from
     * [ActiveStatus.Id] (as there is no way to get all subclasses of a sealed class).
     */
    public val id: Id

) : Dto<ActiveStatusDbo> {

    /**
     * Timestamp when the donor's [ActiveStatus] was last updated.
     *
     * This value is `null` for accounts whose status changed before the release of
     * https://github.com/supergenerous/issues/issues/513
     */
    public abstract val lastUpdateTimestamp: Timestamp?


    override fun toDbo(): ActiveStatusDbo {
        return ActiveStatusDbo(id = id,
                               lastUpdateTimestamp = lastUpdateTimestamp)
    }

    /*
     * Subclasses
     */

    /**
     * The donor is responsive and has provided all the information necessary to claim their rebates or is going to
     * provide it in the near future.
     *
     * By default we always assume the donor is active.
     */
    @Serializable
    public data class Active(override val lastUpdateTimestamp: Timestamp?) : ActiveStatus(id = ACTIVE)

    /**
     * The donor has not provided some of the information necessary to claim their rebates (e.g., [Donor.taxId]) and
     * they are not responding to any of the communications we send them.
     */
    @Serializable
    public data class Inactive(override val lastUpdateTimestamp: Timestamp?) : ActiveStatus(id = INACTIVE)

    /**
     * The donor has explicitly requested SG to stop processing their rebates.
     */
    @Serializable
    public data class OptedOut(override val lastUpdateTimestamp: Timestamp?) : ActiveStatus(id = OPTED_OUT)

    /*
     * Inner types
     */

    /**
     * IDs allowed for the [ActiveStatus] subclasses.
     */
    public enum class Id {

        ACTIVE,
        INACTIVE,
        OPTED_OUT;

        /**
         * Creates an [ActiveStatus] with the [ActiveStatus.Id] and the [lastUpdateTimestamp] and returns it.
         */
        public fun createActiveStatus(lastUpdateTimestamp: Timestamp?): ActiveStatus {
            return when (this) {
                ACTIVE -> Active(lastUpdateTimestamp = lastUpdateTimestamp)
                INACTIVE -> Inactive(lastUpdateTimestamp = lastUpdateTimestamp)
                OPTED_OUT -> OptedOut(lastUpdateTimestamp = lastUpdateTimestamp)
            }
        }

    }

}