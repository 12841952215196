package com.supergenerous.common.donee

import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * ID of a [Donee].
 *
 * This is kept as a value class because it's used as a `key` in certain `Map`s.
 */
@JvmInline
@Serializable
public value class DoneeId(

    /**
     * ID value.
     */
    public val value: String

)