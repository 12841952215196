package supergenerous.app.donor.dashboard.model

import com.hipsheep.kore.error.AppErrorCode
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.name.FullName

/**
 * Errors that can occur on the dashboard.
 */
public enum class DashboardError : AppErrorCode {

    /**
     * The [Donor.bankAccountNumber] was missing.
     */
    BANK_ACCOUNT_MISSING,

    /**
     * The [Donor.bankAccountNumber] was invalid.
     */
    BANK_ACCOUNT_INVALID,

    /**
     * The [Donor.disbursementSettings] were missing.
     */
    DISB_SETTINGS_MISSING,

    /**
     * The [Donor.taxId] was missing.
     */
    TAX_ID_MISSING,

    /**
     * The [Donor.taxId] was invalid.
     */
    TAX_ID_INVALID,

    /**
     * The [Donor]'s [FullName.firstName] was missing.
     */
    FIRST_NAME_MISSING,

    /**
     * The [Donor]'s [FullName.lastName] was missing.
     */
    LAST_NAME_MISSING,

    /**
     * The [Donor.address] was missing.
     */
    HOME_ADDRESS_MISSING,

    /**
     * The [Donor.phoneNumber] was missing.
     */
    PHONE_NUMBER_MISSING,

    /**
     * The [Donor.dateOfBirth] was missing.
     */
    DATE_OF_BIRTH_MISSING,

    /**
     * The [IdDocument.Type] was missing.
     */
    GOV_ID_TYPE_MISSING,

    /**
     * The [IdDocument.Passport.number] or [IdDocument.DriverLicenceNz.number] was missing.
     */
    GOV_ID_NUMBER_MISSING,

    /**
     * The [IdDocument.DriverLicenceNz.number] was invalid.
     */
    DRIVER_LICENCE_NUMBER_INVALID,

    /**
     * The [IdDocument.DriverLicenceNz.version] was missing.
     */
    DRIVER_LICENCE_VERSION_MISSING,

    /**
     * The [IdDocument.DriverLicenceNz.version] was invalid.
     */
    DRIVER_LICENCE_VERSION_INVALID,

    /**
     * The [IdDocument.Passport.number] was invalid.
     */
    PASSPORT_NUMBER_INVALID,

    /**
     * The [IdDocument.Passport.expiryDate] was missing.
     */
    PASSPORT_EXPIRY_DATE_MISSING,

    /**
     * The [IdDocument.Passport.expiryDate] was invalid.
     */
    PASSPORT_EXPIRY_DATE_INVALID

}