package com.supergenerous.common.util

import com.supergenerous.common.rebate.Rebate
import com.supergenerous.common.tax.region.TaxRegion
import kotlinx.datetime.LocalDate
import kotlinx.datetime.Month.FEBRUARY

/**
 * Calculates service fees.
 */
public object FeeCalculator {

    /**
     * Date when the service fee changed to 15% for submitted rebates. Before this date it was 10%.
     */
    private val serviceFeeChangeDate = LocalDate(year = 2023, month = FEBRUARY, dayOfMonth = 1)


    /**
     * Calculates the service fee that will be charged for the [rebate] received.
     *
     * [Rebate]s submitted before [serviceFeeChangeDate] will be calculated with a service fee of 10%, and the ones
     * submitted from [serviceFeeChangeDate] onwards will be charged a 15% fee.
     */
    // TODO: Remove default value for taxRegion when we start providing the service on other markets
    public fun calculateServiceFee(rebate: Rebate, taxRegion: TaxRegion = TaxRegion.NZ): Float {
        // If the rebate was not claimed by SG we won't charge a fee for that rebate
        if (!rebate.claimedBySg
            // If the rebate has not been received yet then no fee has been charged yet
            || rebate.amountReceived == null) {
            return 0f
        }

        // dateSubmitted is "null" for very old rebates that were claimed manually and for which we don't have the
        // submission date
        val serviceFeePercent = if (rebate.dateSubmitted != null && rebate.dateSubmitted >= serviceFeeChangeDate) {
            0.15f
        } else {
            0.1f
        }

        // Calculate the service fee charged for the rebate
        val serviceFee = rebate.amountReceived!! * serviceFeePercent
        // Calculate the tax added to the service fee
        val serviceFeeTax = serviceFee * taxRegion.serviceFeeAddedTaxPercent

        // Return the service fee + tax
        return (serviceFee + serviceFeeTax).round(precision = 2)
    }

}