@file:Suppress("unused")

package supergenerous.app.core.util

import kotlinx.css.Align
import kotlinx.css.CssBuilder
import kotlinx.css.GridTemplateColumns
import kotlinx.css.LinearDimension
import kotlinx.css.RuleSet
import kotlinx.css.StyledElement
import kotlinx.css.backgroundSize
import org.w3c.dom.css.CSSStyleDeclaration

/*
 * @author Franco Sabadini (franco@supergenerous.com)
 */

/**
 * Creates a [CSSStyleDeclaration] with the [props] received and returns it.
 *
 * In most cases we should use `kotlin-styled` to style components, but sometimes that is not possible,
 * in which case this function is useful.
 */
public fun cssStyle(props: CSSStyleDeclaration.() -> Unit): CSSStyleDeclaration {
    return createCssStyleDeclaration().apply(props)
}

/**
 * Creates a [CSSStyleDeclaration] and returns it.
 */
@Suppress("UnsafeCastFromDynamic")
private fun createCssStyleDeclaration(): CSSStyleDeclaration = js("{}")

/**
 * Adds a media query that applies the [rules] received only to mobile screens.
 */
public fun CssBuilder.mobileScreen(rules: RuleSet) {
    media(query = "only screen and (max-width: ${MOBILE_SCREEN_MAX_SIZE_PIXELS}px)", block = rules)
}

/**
 * Adds a media query that applies the [rules] received only to extra large screens.
 */
public fun CssBuilder.extraLargeScreen(rules: RuleSet) {
    media(query = "only screen and (min-width: 2000px)", block = rules)
}

/*
 * CSS properties not available in kotlin-styled
 */

public fun StyledElement.placeSelf(value: Align) {
    put("place-self", value.toString())
}

public fun StyledElement.alignContent(value: AlignContent) {
    put("align-content", value.toString())
}

public fun StyledElement.alignItems(value: AlignItems) {
    put("align-items", value.toString())
}

public fun StyledElement.justifyItems(value: JustifyItems) {
    put("justify-items", value.toString())
}

public fun StyledElement.justifySelf(value: JustifySelf) {
    put("justify-self", value.toString())
}

public fun StyledElement.backgroundSize(value: BackgroundSize) {
    backgroundSize = value.toString()
}

/**
 * Creates a [GridTemplateColumns] that fits as many columns of [columnSize] as possible in the available space.
 */
public fun GridTemplateColumns.Companion.autoFit(columnSize: LinearDimension): GridTemplateColumns {
    return repeat("auto-fit, ${columnSize.value}")
}

/*
 * Types
 */

/**
 * List of available values for the [kotlinx.css.backgroundSize] CSS property.
 */
@Suppress("EnumEntryName")
public enum class BackgroundSize {

    auto, contain, cover, inherit, initial, unset

}

/**
 * List of available values for the [alignContent] CSS property.
 */
@Suppress("EnumEntryName")
public enum class AlignContent {

    start, end, center, stretch

}

/**
 * List of available values for the [alignItems] CSS property.
 */
@Suppress("EnumEntryName")
public enum class AlignItems {

    start, end, center, stretch

}

/**
 * List of available values for the [justifyItems] CSS property.
 */
@Suppress("EnumEntryName")
public enum class JustifyItems {

    start, end, center, stretch

}

/**
 * List of available values for the [justifySelf] CSS property.
 */
@Suppress("EnumEntryName")
public enum class JustifySelf {

    start, end, center, stretch

}