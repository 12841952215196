package com.hipsheep.kore.util

import com.hipsheep.kore.util.LogLevel.*

/**
 * Utility interface used to avoid passing a logger tag when logging messages to the console.
 */
public interface ILogger {

    /**
     * Tag used to identify the source of the log messages. It usually identifies the class where the log calls occur.
     */
    public val tag: String
        get() = this.logTag

}

/**
 * Abstract class that provides utility methods to log messages to the console.
 *
 * This class should be extended from the `companion object` of the class that is going to log the messages (or from
 * the class itself if it's a singleton).
 */
public abstract class Logger : ILogger {

    override val tag: String = logTag

}

/**
 * Tag that can be used to identify the source of the log messages.
 */
internal expect val Any.logTag: String

/**
 * Logs a message with the [VERBOSE] severity.
 *
 * The log message will not be written if the current log level is above [VERBOSE].
 * The default log level is [INFO].
 *
 * @param message The message to log. `null` will be represented as "null", for any other value the [Any.toString]
 * method will be invoked.
 * @param cause Exception to log (optional).
 */
public fun ILogger.logVerbose(message: Any?, cause: Throwable? = null) {
    log(this, message, cause, VERBOSE)
}

/**
 * Logs a message with the [VERBOSE] severity.
 *
 * The log message will not be written if the current log level is above [VERBOSE].
 * The default log level is [INFO].
 *
 * @param message The function that returns the message to log. `null` will be represented as "null", for any other
 * value the [Any.toString] method will be invoked.
 */
public inline fun ILogger.logVerbose(message: () -> Any) {
    log(this, message, VERBOSE)
}

/**
 * Logs a message with the [DEBUG] severity.
 *
 * The log message will not be written if the current log level is above [DEBUG].
 * The default log level is [INFO].
 *
 * @param message The message to LogLevel. `null` will be represented as "null", for any other value the [Any.toString]
 * method will be invoked.
 * @param cause Exception to log (optional).
 */
public fun ILogger.logDebug(message: Any?, cause: Throwable? = null) {
    log(this, message, cause, DEBUG)
}

/**
 * Logs a message with the [DEBUG] severity.
 *
 * The log message will not be written if the current log level is above [DEBUG].
 * The default log level is [INFO].
 *
 * @param message The function that returns the message to log. `null` will be represented as "null", for any other
 * value the [Any.toString] method will be invoked.
 */
public inline fun ILogger.logDebug(message: () -> Any) {
    log(this, message, DEBUG)
}

/**
 * Logs a message with the [INFO] severity.
 *
 * The log message will not be written if the current log level is above [INFO].
 * The default log level is [INFO].
 *
 * @param message The message to LogLevel. `null` will be represented as "null", for any other value the [Any.toString]
 * method will be invoked.
 * @param cause Exception to log (optional).
 */
public fun ILogger.logInfo(message: Any?, cause: Throwable? = null) {
    log(this, message, cause, INFO)
}

/**
 * Logs a message with the [INFO] severity.
 *
 * The log message will not be written if the current log level is above [INFO].
 * The default log level is [INFO].
 *
 * @param message The function that returns the message to log. `null` will be represented as "null", for any other
 * value the [Any.toString] method will be invoked.
 */
public inline fun ILogger.logInfo(message: () -> Any) {
    log(this, message, INFO)
}

/**
 * Logs a message with the [WARN] severity.
 *
 * The log message will not be written if the current log level is above [WARN].
 * The default log level is [INFO].
 *
 * @param message The message to LogLevel. `null` will be represented as "null", for any other value the [Any.toString]
 * method will be invoked.
 * @param cause Exception to log (optional).
 */
public fun ILogger.logWarn(message: Any?, cause: Throwable? = null) {
    log(this, message, cause, WARN)
}

/**
 * Logs a message with the [WARN] severity.
 *
 * The log message will not be written if the current log level is above [WARN].
 * The default log level is [INFO].
 *
 * @param message The function that returns the message to log. `null` will be represented as "null", for any other
 * value the [Any.toString] method will be invoked.
 */
public inline fun ILogger.logWarn(message: () -> Any) {
    log(this, message, WARN)
}

/**
 * Logs a message with the [ERROR] severity.
 *
 * The log message will not be written if the current log level is above [ERROR].
 * The default log level is [INFO].
 *
 * @param message The message to LogLevel. `null` will be represented as "null", for any other value the [Any.toString]
 * method will be invoked.
 * @param cause Exception to log (optional).
 */
public fun ILogger.logError(message: Any?, cause: Throwable? = null) {
    log(this, message, cause, ERROR)
}

/**
 * Logs a message with the [ERROR] severity.
 *
 * The log message will not be written if the current log level is above [ERROR].
 * The default log level is [INFO].
 *
 * @param message The function that returns the message to log. `null` will be represented as "null", for any other
 * value the [Any.toString] method will be invoked.
 */
public inline fun ILogger.logError(message: () -> Any) {
    log(this, message, ERROR)
}

/**
 * Logs the [message] received with the severity [level] indicated.
 *
 * If the current log level is above the one received, then the message will not be logged.
 *
 * If the [cause] received is not `null`, then it will be added to the end of the message to log.
 */
internal expect fun log(logger: ILogger, message: Any?, cause: Throwable?, level: LogLevel)

/**
 * Logs the [message] received with the severity [level] indicated.
 *
 * If the current log level is above the one received, then the message will not be logged.
 *
 * IMPORTANT: DO NOT use this function outside this file. We leave it as `public` because it can't be used from other
 * `inline` functions if it's `private`.
 */
public expect inline fun log(logger: ILogger, message: () -> Any, level: LogLevel)

/**
 * Log levels supported by the [ILogger].
 */
public enum class LogLevel {
    VERBOSE,
    DEBUG,
    INFO,
    WARN,
    ERROR
}