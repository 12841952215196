package supergenerous.app.core.location

import com.hipsheep.kore.model.network.HttpMethod.GET
import com.hipsheep.kore.model.network.HttpService
import com.hipsheep.kore.model.network.HttpStatusCode.NOT_FOUND
import com.hipsheep.kore.model.network.Response
import com.hipsheep.kore.model.network.sendRequest
import com.hipsheep.kore.util.isNotNullOrBlank

/**
 * [HttpService] used to read IP address data from the third-party service used.
 */
public class IpService : HttpService() {

    /**
     * URL used to get the client device's IP address.
     */
    private val ipServiceUrl: String = "https://api.ipify.org"


    /**
     * Gets the IP address of the client device and returns it, or returns an empty `String` if it couldn't be fetched
     * (e.g., if the user is using an ad-blocker).
     */
    public suspend fun getIpAddress(): Response<String> {
        val resp = httpClient.sendRequest<String>(GET, url = ipServiceUrl)

        return when {
            resp.isSuccessful && resp.body.isNotNullOrBlank() -> resp
            // If the call succeeded but no IP address was returned then the user might be using an ad-blocker or
            // something like that
            resp.isSuccessful && resp.body.isNullOrBlank() -> Response(NOT_FOUND)
            else -> resp
        }
    }

}