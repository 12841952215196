package supergenerous.app.donor.dashboard.viewmodel

import com.hipsheep.kore.resource.Resource.*
import com.hipsheep.kore.viewmodel.BaseViewModel
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.coroutine.asLiveData
import com.hipsheep.kore.viewmodel.event.Event
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValue
import com.hipsheep.kore.viewmodel.lifecycle.updateValueAsync
import com.supergenerous.common.bank.isValid
import com.supergenerous.common.bank.toDashFormat
import com.supergenerous.common.donor.Donor
import com.supergenerous.common.id.IdDocument
import com.supergenerous.common.id.IdDocument.Type.*
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.launch
import supergenerous.app.core.location.LocationRepository
import supergenerous.app.core.search.model.TextSearchResult
import supergenerous.app.donor.dashboard.model.DashboardError
import supergenerous.app.donor.dashboard.model.DashboardError.*
import supergenerous.app.donor.dashboard.view.personalInfoEditScreen
import supergenerous.app.donor.donor.DonorRepository
import supergenerous.app.donor.donor.GovIdInput

/**
 * [ViewModel] that provides data to the [personalInfoEditScreen].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
public open class PersonalInfoEditViewModel(

    private val donorRepo: DonorRepository,
    private val locationRepo: LocationRepository

) : BaseViewModel() {

    /**
     * Backing field for [addressSearchResults].
     */
    private val _addressSearchResults: MutableLiveData<Event<List<TextSearchResult<String>>>> = MutableLiveData()
    /**
     * The results for the current search string in the address finder.
     */
    public val addressSearchResults: LiveData<Event<List<TextSearchResult<String>>>> = _addressSearchResults

    /**
     * Backing field for [donorSavedEvent].
     */
    protected val _donorSavedEvent: MutableLiveData<Event<Unit>> = MutableLiveData()
    /**
     * Event fired when the donor was saved successfully.
     */
    public val donorSavedEvent: LiveData<Event<Unit>> = _donorSavedEvent

    /**
     * Observable that receives updates when the data of the currently signed in [Donor] changes.
     */
    public val donor: LiveData<Donor> = donorRepo.getDonorUpdates()
            .mapNotNull { resource -> (resource as? Success)?.data }
            .asLiveData()


    /**
     * Searches for addresses using the [query] received and updates the [addressSearchResults] with the ones that
     * match, or reports an error through [errors].
     *
     * If the length of the [query] is less than 6 characters then no search is performed. This is done to save money
     * on searches that will return too many results because we use a third-party system for it.
     */
    public fun searchAddress(query: String) {
        launch {
            executeAction(updateLoadingStatus = false) { locationRepo.searchAddress(query) }?.let { searchResults ->
                _addressSearchResults.updateValueAsync(Event(searchResults))
            }
        }
    }

    /**
     * Saves the [donor] data on the server.
     */
    public fun saveDonor(donor: Donor,
                         govIdInput: GovIdInput) {
        val errors = mutableSetOf<DashboardError>()
        checkValueExists(donor.legalName?.firstName, FIRST_NAME_MISSING)?.let { errors += it }
        checkValueExists(donor.legalName?.lastName, LAST_NAME_MISSING)?.let { errors += it }

        checkValueExists(donor.address, HOME_ADDRESS_MISSING)?.let { errors += it }
        checkValueExists(donor.phoneNumber, PHONE_NUMBER_MISSING)?.let { errors += it }

        validateTaxId(donor.taxId)?.let { errors += it }

        validateBankAccount(bankAccountNumber = donor.bankAccountNumber)?.let { errors += it }

        if (donor.needsIdVerification && donor.dateOfBirth == null) {
            errors += DATE_OF_BIRTH_MISSING
        }

        if (donor.needsIdVerification || govIdInput.govIdType != null) {
            errors += validateGovId(govIdInput = govIdInput)
        }

        if (errors.isNotEmpty()) {
            reportErrors(errors)
        } else {
            val govId = if (donor.needsIdVerification && govIdInput.govIdType != null) {
                govIdInput.toIdDocument()
            } else {
                donor.govId
            }

            launch {
                executeAction {
                    donorRepo.saveDonor(donor.copy(bankAccountNumber = donor.bankAccountNumber?.toDashFormat(),
                                                   govId = govId))
                }?.let { _donorSavedEvent.updateValue(Event(Unit)) }
            }
        }
    }

    /**
     * Validates the [bankAccountNumber].
     *
     * @return `null` if valid, otherwise an [Error].
     */
    private fun validateBankAccount(bankAccountNumber: String?): DashboardError? {
        return checkValueExists(bankAccountNumber, BANK_ACCOUNT_MISSING)
                ?: if (!bankAccountNumber!!.isValid()) {
                    BANK_ACCOUNT_INVALID
                } else {
                    null
                }
    }

    /**
     * Validates the [govIdInput] to ensure there is a valid [IdDocument].
     *
     * @return an empty set if valid, otherwise a set of all the errors for the ID document fields.
     */
    private fun validateGovId(govIdInput: GovIdInput): Set<DashboardError> {
        val number = govIdInput.govIdNumber
        val driverLicenceVersion = govIdInput.driverLicenceVersion
        val passportExpiryDate = govIdInput.passportExpiryDate

        return when (govIdInput.govIdType) {
            null -> setOf(GOV_ID_TYPE_MISSING)
            DRIVER_LICENCE_NZ -> buildSet {
                // Check the number is valid. Should be 2 alphabet characters then 6 numbers exactly.
                when {
                    number.isNullOrBlank() -> add(GOV_ID_NUMBER_MISSING)
                    !Regex("^[A-Za-z]{2}[0-9]{6}$").matches(number) -> add(DRIVER_LICENCE_NUMBER_INVALID)
                }

                // Check the version is valid. Should be 3 numbers long exactly.
                when {
                    driverLicenceVersion.isNullOrBlank() -> add(DRIVER_LICENCE_VERSION_MISSING)
                    !Regex("^[0-9]{3}$").matches(driverLicenceVersion) -> add(DRIVER_LICENCE_VERSION_INVALID)
                }
            }
            PASSPORT -> buildSet {
                // Check the number is valid. Should be 7 or 8 characters exactly.
                when {
                    number.isNullOrBlank() -> add(GOV_ID_NUMBER_MISSING)
                    number.length < 7 || number.length > 8 -> add(PASSPORT_NUMBER_INVALID)
                }

                // Check the expiry date is valid. // TODO: Make sure it is not expired.
                if (passportExpiryDate == null) {
                    add(PASSPORT_EXPIRY_DATE_MISSING)
                }
            }
        }
    }

    /**
     * Validates the [taxId].
     *
     * @return `null` if valid, otherwise an [Error].
     */
    private fun validateTaxId(taxId: String?): DashboardError? {
        val sanitisedTaxId = taxId?.replace("-", "") ?: ""

        return checkValueExists(taxId, TAX_ID_MISSING)
                ?: if (sanitisedTaxId.length < 8 || sanitisedTaxId.length > 9) {
                    TAX_ID_INVALID
                } else {
                    null
                }
    }

    /**
     * Validates the [value] is not `null` or blank.
     *
     * @return `null` if valid, otherwise the [error].
     */
    private fun checkValueExists(value: String?, error: DashboardError): DashboardError? {
        return if (value.isNullOrBlank()) error else null
    }

}