package supergenerous.app.core.component.tooltip

import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.alignItems
import kotlinx.css.borderRadius
import kotlinx.css.display
import kotlinx.css.margin
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.html.tabIndex
import react.RBuilder
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.image.icon
import supergenerous.app.core.component.style.InputStyle
import supergenerous.app.core.res.image.CoreIcon

/**
 * Renders a help icon that shows the [helpInfo] on a pop-up when active/hovered.
 */
public fun RBuilder.helpIcon(helpInfo: String) {
    tooltip(
        anchor = {
            styledDiv {
                css {
                    // Make the padding 8px to add more hover-able space so the icon isn't too small, but then remove
                    // 8px from the margin to make the icon sit where it would if there was no extra padding getting
                    // added.
                    margin(all = (-8).px)
                    padding(all = 8.px)
                }

                styledDiv {
                    css {
                        +InputStyle.focusRing

                        // Make the focus ring a circle to fit nicely around the question circle
                        borderRadius = 50.pct

                        // Vertically align the icon
                        display = Display.flex
                        alignItems = Align.center
                    }

                    // Make the icon focusable to view the help message
                    attrs.tabIndex = "0"

                    icon(icon = CoreIcon.QUESTION_CIRCLE, size = 16.px)
                }
            }
        },
        text = helpInfo
    )
}