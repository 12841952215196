package supergenerous.app.core.component.layout

import kotlinx.css.Align
import kotlinx.css.CssBuilder
import kotlinx.css.Display
import kotlinx.css.GridTemplateColumns
import kotlinx.css.JustifyContent
import kotlinx.css.RuleSet
import kotlinx.css.alignItems
import kotlinx.css.columnGap
import kotlinx.css.display
import kotlinx.css.gridTemplateColumns
import kotlinx.css.justifyContent
import kotlinx.css.margin
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import kotlinx.html.DIV
import react.RBuilder
import styled.StyleSheet
import styled.StyledDOMBuilder
import styled.css
import styled.styledDiv
import supergenerous.app.core.util.mobileScreen

/**
 * Creates a responsive grid that centres itself above a certain size if [isCentered] is `true`, or takes the full page
 * width if it's `false`.
 */
public fun RBuilder.grid(isCentered: Boolean = false, block: StyledDOMBuilder<DIV>.() -> Unit) {
    styledDiv {
        if (isCentered) {
            css {
                width = 100.pct
                display = Display.flex
                alignItems = Align.start
                justifyContent = JustifyContent.center
            }

            styledDiv {
                css {
                    +GridStyle.columnsCentred
                }

                block()
            }
        } else {
            css { +GridStyle.stretchColumns }

            block()
        }
    }
}

/*
 * Types
 */

/**
 * List of CSS styles used for grid elements in the app.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
private object GridStyle : StyleSheet("ContainerStyle", isStatic = true) {

    val stretchColumns by css {
        display = Display.grid
        columnGap = 24.px

        screenScale(mobileRules = {
            gridTemplateColumns = GridTemplateColumns.repeat("2, 1fr")
            columnGap = 8.px
        },
                    smallRules = {
                        gridTemplateColumns = GridTemplateColumns.repeat("12, 1fr")
                        columnGap = 8.px
                    },
                    mediumRules = {
                        columnGap = 12.px
                    },
                    largeRules = {
                        columnGap = 16.px
                    },
                    extraLargeRules = {
                        columnGap = 24.px
                    })
    }

    val columnsCentred by css {
        display = Display.grid
        columnGap = 24.px

        screenScale(mobileRules = {
            margin(horizontal = 16.px)
            gridTemplateColumns = GridTemplateColumns.repeat("2, 1fr")
            columnGap = 8.px
        },
                    smallRules = {
                        gridTemplateColumns = GridTemplateColumns.repeat("12, 51px")
                        columnGap = 8.px
                    },
                    mediumRules = {
                        gridTemplateColumns = GridTemplateColumns.repeat("12, 64px")
                        columnGap = 12.px
                    },
                    largeRules = {
                        gridTemplateColumns = GridTemplateColumns.repeat("12, 81px")
                        columnGap = 16.px
                    },
                    extraLargeRules = {
                        gridTemplateColumns = GridTemplateColumns.repeat("12, 81px")
                        columnGap = 24.px
                    })
    }

    /**
     * Adds a media query that applies the rules received to screens.
     */
    private fun CssBuilder.screenScale(mobileRules: RuleSet? = null,
                                       smallRules: RuleSet? = null,
                                       mediumRules: RuleSet? = null,
                                       largeRules: RuleSet? = null,
                                       extraLargeRules: RuleSet? = null) {
        // less than 721px
        mobileRules?.let { mobileScreen(it) }

        // 769px to 924px
        smallRules?.let { media(query = "only screen and (min-width: 769px)", block = it) }

        // 924px to 1179px
        mediumRules?.let { media(query = "only screen and (min-width: 924px)", block = it) }

        // 1180px to 1499px
        largeRules?.let { media(query = "only screen and (min-width: 1180px)", block = it) }

        // 1500px and more
        extraLargeRules?.let { media(query = "only screen and (min-width: 1500px)", block = it) }
    }

}