package supergenerous.app.core.component.button

import kotlinx.css.label
import materialui.button.MuiButton
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.buildElement
import styled.css
import styled.styled
import supergenerous.app.core.component.button.ButtonSize.*
import supergenerous.app.core.component.button.ButtonType.*
import supergenerous.app.core.component.image.icon
import supergenerous.app.core.component.progress.loadingCircle
import supergenerous.app.core.res.image.Icon

/**
 * Button component used throughout the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class Button : RComponent<ButtonProps, State>() {

    override fun RBuilder.render() {
        @Suppress("DEPRECATION")
        styled(MuiButton)() {
            css {
                specific {
                    when (props.type) {
                        PRIMARY -> +ButtonStyle.primary
                        SECONDARY -> +ButtonStyle.secondary
                        ACCENT -> +ButtonStyle.accent
                        LOW_PROFILE -> +ButtonStyle.lowProfile
                    }

                    when (props.size) {
                        LARGE -> +ButtonStyle.large
                        SMALL -> +ButtonStyle.small
                        EXTRA_SMALL -> +ButtonStyle.extraSmall
                    }
                }
            }

            attrs {
                disableFocusRipple = true
                disableRipple = true

                val iconSize = when(props.size) {
                    LARGE -> ButtonStyle.FONT_SIZE_LARGE
                    SMALL, EXTRA_SMALL -> ButtonStyle.FONT_SIZE_SMALL
                }

                if (props.showLoadingIcon) {
                    // Create a loading circle with the same size as the button's font
                    endIcon = buildElement { loadingCircle(size = iconSize) }
                } else {
                    props.icon?.let {
                        endIcon = buildElement { icon(icon = it, size = iconSize) }
                    }
                }

                disabled = props.isDisabled

                onClick = {
                    // Don't pass the event to the parent component when the button is in the "loading" status
                    if (!props.showLoadingIcon) {
                        props.onClick()
                    }
                }

                type = if (props.isSubmitButton) "submit" else "button"
            }

            +props.label
        }
    }

}

/**
 * Properties used by the [Button] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface ButtonProps : Props {

    /**
     * Label shown in the [Button].
     */
    var label: String

    /**
     * Icon shown in the [Button] to the right of the [label].
     *
     * If not set, no icon will be shown.
     */
    var icon: Icon?

    /**
     * Type of [Button] to render.
     *
     * If not set, [PRIMARY] will be used.
     */
    var type: ButtonType

    /**
     * Sie of the [Button] to render.
     *
     * If not set, [LARGE] will be used.
     */
    var size: ButtonSize

    /**
     * `true` if the [Button] is disabled, or `false` if it's enabled.
     *
     * If not set, it will be rendered as enabled.
     */
    var isDisabled: Boolean

    /**
     * `true` if a loading icon should be shown in the [Button], or `false` otherwise.
     *
     * When the loading icon is shown, the [icon] will be ignored.
     */
    var showLoadingIcon: Boolean

    /**
     * Function called when the button is clicked.
     */
    var onClick: () -> Unit

    /**
     * Whether this button should submit any form it is part of.
     */
    var isSubmitButton: Boolean

}

/**
 * Renders a [Button] component.
 */
public fun RBuilder.button(label: String,
                           icon: Icon? = null,
                           type: ButtonType = PRIMARY,
                           size: ButtonSize = LARGE,
                           isDisabled: Boolean = false,
                           showLoadingIcon: Boolean = false,
                           isSubmitButton: Boolean = false,
                           onClick: () -> Unit) {
    child(Button::class) {
        attrs.label = label
        attrs.icon = icon
        attrs.type = type
        attrs.size = size
        attrs.isDisabled = isDisabled
        attrs.showLoadingIcon = showLoadingIcon
        attrs.isSubmitButton = isSubmitButton
        attrs.onClick = onClick
    }
}