package supergenerous.app.donor.setup.view

import com.hipsheep.kore.error.ErrorType
import com.supergenerous.common.email.EmailAddress
import com.supergenerous.common.network.CoreUrl.SG_FAQS
import kotlinx.browser.window
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.GridColumn
import kotlinx.css.GridRow
import kotlinx.css.GridTemplateColumns
import kotlinx.css.GridTemplateRows
import kotlinx.css.LinearDimension
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.fr
import kotlinx.css.gap
import kotlinx.css.gridColumn
import kotlinx.css.gridRow
import kotlinx.css.gridTemplateColumns
import kotlinx.css.gridTemplateRows
import kotlinx.css.margin
import kotlinx.css.marginBottom
import kotlinx.css.marginTop
import kotlinx.css.px
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.body2
import supergenerous.app.core.component.button.ButtonSize
import supergenerous.app.core.component.button.ButtonType
import supergenerous.app.core.component.button.button
import supergenerous.app.core.component.inputTitle
import supergenerous.app.core.component.textLink
import supergenerous.app.core.component.textLinkEmail
import supergenerous.app.core.component.textfield.TextFieldType.IRD_NUMBER
import supergenerous.app.core.component.textfield.textField
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.open
import supergenerous.app.core.util.withRouter
import supergenerous.app.donor.setup.model.SetupError.*
import supergenerous.app.donor.setup.model.SetupStep
import supergenerous.app.donor.setup.model.SetupStep.TAX_ID
import supergenerous.app.donor.setup.viewmodel.SetupViewModel
import supergenerous.app.donor.util.Url

/**
 * Screen that allows donors to enter their tax ID.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private class TaxIdScreen : SetupScreen<SetupScreenProps, TaxIdScreenState>() {

    override val setupStep: SetupStep = TAX_ID


    override fun RBuilder.render() {
        setupInputContainer(
            title = { +"Your IRD number" },
            subtitle = { +"We need this to claim your donation tax rebates from the IRD." },
            setupViewModel = props.viewModel,
            onNextBtnClick = ::saveTaxId,
            onBackBtnClick = ::goBack
        ) {
            styledDiv {
                css {
                    display = Display.grid
                    gridTemplateColumns = GridTemplateColumns(1.fr, 1.fr)
                    gridTemplateRows = GridTemplateRows(LinearDimension.auto, LinearDimension.auto)

                    gap = 8.px

                    mobileScreen {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                    }
                }

                styledDiv {
                    css {
                        gridColumn = GridColumn("1 / span 1")
                        gridRow = GridRow("1 / span 1")
                    }

                    inputTitle(title = "IRD number")
                }

                styledDiv {
                    css {
                        gridColumn = GridColumn("1 / span 1")
                        gridRow = GridRow("2 / span 1")
                        // Make the textField not fill the width of the parent
                        display = Display.flex
                    }

                    textField(
                        type = IRD_NUMBER,
                        placeholder = "Enter 8 or 9 numbers",
                        errorMessage = state.taxIdError,
                        value = state.taxId,
                        onTextChange = {
                            setState {
                                taxId = it
                                taxIdError = null
                            }
                        }
                    )
                }

                styledDiv {
                    css {
                        gridColumn = GridColumn("2 / span 1")
                        gridRow = GridRow("1 / span 1")
                    }

                    body1 { +"Can't find it?" }
                }

                styledDiv {
                    css {
                        gridColumn = GridColumn("2 / span 1")
                        gridRow = GridRow("2 / span 1")

                        marginTop = 8.px
                    }

                    button(
                        label = "Find my IRD number",
                        size = ButtonSize.SMALL,
                        type = ButtonType.PRIMARY,
                        onClick = { window.open(url = Url.FIND_IRD_NUMBER, useNewTab = true) }
                    )
                }
            }

            body2 {
                css {
                    marginTop = 32.px
                    marginBottom = 8.px
                }

                +"IMPORTANT:"
            }

            body2 {
                css {
                    margin(bottom = 8.px)
                }

                +"""You’ll notice that the IRD will send you updates as we work away in the background. 
                    |This will only affect your donation tax rebates, 
                    |but if you have any questions please email us at """.trimMargin()
                textLinkEmail(email = EmailAddress.SG_SUPPORT)
                +"."
            }

            body2 {
                +"For more information check our "
                textLink(url = SG_FAQS) { +"FAQs" }
                +"."
            }
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // Load the values already provided by the donor (if any)
        props.viewModel.donor.observe { donor -> setState { taxId = donor.taxId } }
        props.viewModel.errors.observeEvent { errors ->
            errors.forEach { error ->
                when ((error as? ErrorType.AppError)?.code) {
                    TAX_ID_MISSING -> setState { taxIdError = "Required" }
                    TAX_ID_INVALID -> setState { taxIdError = "Should be 8 or 9 numbers" }
                }
            }
        }
    }

    /**
     * Saves the tax ID provided through the UI.
     */
    private fun saveTaxId() {
        props.viewModel.saveTaxId(taxId = state.taxId)
    }

}

/**
 * State of the [TaxIdScreen] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface TaxIdScreenState : State {

    /**
     * Tax ID provided by the user.
     */
    var taxId: String?

    /**
     * The error that occurred in the tax ID field.
     */
    var taxIdError: String?

}

/**
 * Renders a [TaxIdScreen] component.
 */
public fun RBuilder.taxIdScreen(setupViewModel: SetupViewModel) {
    withRouter(TaxIdScreen::class) {
        attrs.viewModel = setupViewModel
    }
}