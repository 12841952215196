package com.hipsheep.kore.viewmodel.lifecycle

import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

public actual open class MutableLiveData<T> actual constructor() : LiveData<T>()

public actual fun <T> MutableLiveData<T>.updateValue(value: T) {
    this.value = value
}

public actual suspend fun <T> MutableLiveData<T>.updateValueAsync(value: T) {
    withContext(Dispatchers.Main) {
        updateValue(value)
    }
}