package supergenerous.app.donor.dashboard.model

import com.hipsheep.kore.error.AppErrorCode
import supergenerous.app.donor.dashboard.view.rebateClaimsPanel

/**
 * Errors that can occur on the [rebateClaimsPanel].
 */
public enum class RebateClaimsError : AppErrorCode {

    /**
     * There was an error loading the rebate claims data.
     */
    REBATE_CLAIMS_MISSING

}