package kotlinx.html.js

import kotlinx.html.*
import kotlinx.html.org.w3c.dom.events.Event

/*******************************************************************************
    DO NOT EDIT
    This file was generated by module generate
*******************************************************************************/

var CommonAttributeGroupFacade.onAbortFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onAbort")
    set(newValue) {consumer.onTagEvent(this, "onabort", newValue)}

var CommonAttributeGroupFacade.onBlurFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onBlur")
    set(newValue) {consumer.onTagEvent(this, "onblur", newValue)}

var CommonAttributeGroupFacade.onCanPlayFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onCanPlay")
    set(newValue) {consumer.onTagEvent(this, "oncanplay", newValue)}

var CommonAttributeGroupFacade.onCanPlayThroughFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onCanPlayThrough")
    set(newValue) {consumer.onTagEvent(this, "oncanplaythrough", newValue)}

var CommonAttributeGroupFacade.onChangeFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onChange")
    set(newValue) {consumer.onTagEvent(this, "onchange", newValue)}

var CommonAttributeGroupFacade.onClickFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onClick")
    set(newValue) {consumer.onTagEvent(this, "onclick", newValue)}

var CommonAttributeGroupFacade.onContextMenuFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onContextMenu")
    set(newValue) {consumer.onTagEvent(this, "oncontextmenu", newValue)}

var CommonAttributeGroupFacade.onDoubleClickFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onDoubleClick")
    set(newValue) {consumer.onTagEvent(this, "ondblclick", newValue)}

var CommonAttributeGroupFacade.onDragFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onDrag")
    set(newValue) {consumer.onTagEvent(this, "ondrag", newValue)}

var CommonAttributeGroupFacade.onDragEndFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onDragEnd")
    set(newValue) {consumer.onTagEvent(this, "ondragend", newValue)}

var CommonAttributeGroupFacade.onDragEnterFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onDragEnter")
    set(newValue) {consumer.onTagEvent(this, "ondragenter", newValue)}

var CommonAttributeGroupFacade.onDragLeaveFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onDragLeave")
    set(newValue) {consumer.onTagEvent(this, "ondragleave", newValue)}

var CommonAttributeGroupFacade.onDragOverFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onDragOver")
    set(newValue) {consumer.onTagEvent(this, "ondragover", newValue)}

var CommonAttributeGroupFacade.onDragStartFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onDragStart")
    set(newValue) {consumer.onTagEvent(this, "ondragstart", newValue)}

var CommonAttributeGroupFacade.onDropFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onDrop")
    set(newValue) {consumer.onTagEvent(this, "ondrop", newValue)}

var CommonAttributeGroupFacade.onDurationChangeFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onDurationChange")
    set(newValue) {consumer.onTagEvent(this, "ondurationchange", newValue)}

var CommonAttributeGroupFacade.onEmptiedFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onEmptied")
    set(newValue) {consumer.onTagEvent(this, "onemptied", newValue)}

var CommonAttributeGroupFacade.onEndedFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onEnded")
    set(newValue) {consumer.onTagEvent(this, "onended", newValue)}

var CommonAttributeGroupFacade.onErrorFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onError")
    set(newValue) {consumer.onTagEvent(this, "onerror", newValue)}

var CommonAttributeGroupFacade.onFocusFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onFocus")
    set(newValue) {consumer.onTagEvent(this, "onfocus", newValue)}

var CommonAttributeGroupFacade.onFocusInFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onFocusIn")
    set(newValue) {consumer.onTagEvent(this, "onfocusin", newValue)}

var CommonAttributeGroupFacade.onFocusOutFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onFocusOut")
    set(newValue) {consumer.onTagEvent(this, "onfocusout", newValue)}

var CommonAttributeGroupFacade.onFormChangeFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onFormChange")
    set(newValue) {consumer.onTagEvent(this, "onformchange", newValue)}

var CommonAttributeGroupFacade.onFormInputFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onFormInput")
    set(newValue) {consumer.onTagEvent(this, "onforminput", newValue)}

var CommonAttributeGroupFacade.onInputFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onInput")
    set(newValue) {consumer.onTagEvent(this, "oninput", newValue)}

var CommonAttributeGroupFacade.onInvalidFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onInvalid")
    set(newValue) {consumer.onTagEvent(this, "oninvalid", newValue)}

var CommonAttributeGroupFacade.onKeyDownFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onKeyDown")
    set(newValue) {consumer.onTagEvent(this, "onkeydown", newValue)}

var CommonAttributeGroupFacade.onKeyPressFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onKeyPress")
    set(newValue) {consumer.onTagEvent(this, "onkeypress", newValue)}

var CommonAttributeGroupFacade.onKeyUpFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onKeyUp")
    set(newValue) {consumer.onTagEvent(this, "onkeyup", newValue)}

var CommonAttributeGroupFacade.onLoadFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onLoad")
    set(newValue) {consumer.onTagEvent(this, "onload", newValue)}

var CommonAttributeGroupFacade.onLoadedDataFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onLoadedData")
    set(newValue) {consumer.onTagEvent(this, "onloadeddata", newValue)}

var CommonAttributeGroupFacade.onLoadedMetaDataFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onLoadedMetaData")
    set(newValue) {consumer.onTagEvent(this, "onloadedmetadata", newValue)}

var CommonAttributeGroupFacade.onLoadStartFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onLoadStart")
    set(newValue) {consumer.onTagEvent(this, "onloadstart", newValue)}

var CommonAttributeGroupFacade.onMouseDownFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onMouseDown")
    set(newValue) {consumer.onTagEvent(this, "onmousedown", newValue)}

var CommonAttributeGroupFacade.onMouseMoveFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onMouseMove")
    set(newValue) {consumer.onTagEvent(this, "onmousemove", newValue)}

var CommonAttributeGroupFacade.onMouseOutFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onMouseOut")
    set(newValue) {consumer.onTagEvent(this, "onmouseout", newValue)}

var CommonAttributeGroupFacade.onMouseOverFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onMouseOver")
    set(newValue) {consumer.onTagEvent(this, "onmouseover", newValue)}

var CommonAttributeGroupFacade.onMouseUpFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onMouseUp")
    set(newValue) {consumer.onTagEvent(this, "onmouseup", newValue)}

var CommonAttributeGroupFacade.onMouseWheelFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onMouseWheel")
    set(newValue) {consumer.onTagEvent(this, "onmousewheel", newValue)}

var CommonAttributeGroupFacade.onPauseFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onPause")
    set(newValue) {consumer.onTagEvent(this, "onpause", newValue)}

var CommonAttributeGroupFacade.onPlayFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onPlay")
    set(newValue) {consumer.onTagEvent(this, "onplay", newValue)}

var CommonAttributeGroupFacade.onPlayingFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onPlaying")
    set(newValue) {consumer.onTagEvent(this, "onplaying", newValue)}

var CommonAttributeGroupFacade.onProgressFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onProgress")
    set(newValue) {consumer.onTagEvent(this, "onprogress", newValue)}

var CommonAttributeGroupFacade.onRateChangeFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onRateChange")
    set(newValue) {consumer.onTagEvent(this, "onratechange", newValue)}

var CommonAttributeGroupFacade.onReadyStateChangeFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onReadyStateChange")
    set(newValue) {consumer.onTagEvent(this, "onreadystatechange", newValue)}

var CommonAttributeGroupFacade.onScrollFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onScroll")
    set(newValue) {consumer.onTagEvent(this, "onscroll", newValue)}

var CommonAttributeGroupFacade.onSearchFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onSearch")
    set(newValue) {consumer.onTagEvent(this, "onsearch", newValue)}

var CommonAttributeGroupFacade.onSeekedFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onSeeked")
    set(newValue) {consumer.onTagEvent(this, "onseeked", newValue)}

var CommonAttributeGroupFacade.onSeekingFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onSeeking")
    set(newValue) {consumer.onTagEvent(this, "onseeking", newValue)}

var CommonAttributeGroupFacade.onSelectFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onSelect")
    set(newValue) {consumer.onTagEvent(this, "onselect", newValue)}

var CommonAttributeGroupFacade.onShowFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onShow")
    set(newValue) {consumer.onTagEvent(this, "onshow", newValue)}

var CommonAttributeGroupFacade.onStalledFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onStalled")
    set(newValue) {consumer.onTagEvent(this, "onstalled", newValue)}

var CommonAttributeGroupFacade.onSubmitFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onSubmit")
    set(newValue) {consumer.onTagEvent(this, "onsubmit", newValue)}

var CommonAttributeGroupFacade.onSuspendFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onSuspend")
    set(newValue) {consumer.onTagEvent(this, "onsuspend", newValue)}

var CommonAttributeGroupFacade.onTimeUpdateFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onTimeUpdate")
    set(newValue) {consumer.onTagEvent(this, "ontimeupdate", newValue)}

var CommonAttributeGroupFacade.onTouchCancelFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onTouchCancel")
    set(newValue) {consumer.onTagEvent(this, "ontouchcancel", newValue)}

var CommonAttributeGroupFacade.onTouchEndFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onTouchEnd")
    set(newValue) {consumer.onTagEvent(this, "ontouchend", newValue)}

var CommonAttributeGroupFacade.onTouchMoveFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onTouchMove")
    set(newValue) {consumer.onTagEvent(this, "ontouchmove", newValue)}

var CommonAttributeGroupFacade.onTouchStartFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onTouchStart")
    set(newValue) {consumer.onTagEvent(this, "ontouchstart", newValue)}

var CommonAttributeGroupFacade.onVolumeChangeFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onVolumeChange")
    set(newValue) {consumer.onTagEvent(this, "onvolumechange", newValue)}

var CommonAttributeGroupFacade.onWaitingFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onWaiting")
    set(newValue) {consumer.onTagEvent(this, "onwaiting", newValue)}

var CommonAttributeGroupFacade.onWheelFunction : (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onWheel")
    set(newValue) {consumer.onTagEvent(this, "onwheel", newValue)}

